<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h2 class="headline">Notifications</h2>
            </v-col>
            <v-divider></v-divider>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>New Notification</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <text-box label="Title" v-model="message.Title" />
<!--                                <text-box label="Message" v-model="message.Message" rows="3" multi-row class="mt-2" />-->
                                <tiptap-vuetify v-model="message.Message"
                                                :extensions="extensions"
                                                class="mt-2"  />
                            </v-col>
                            <v-col cols="5">
                                <combo-box label="Group" :items="orgHierarchy" item-text="Name" item-value="HierarchyID"
                                           v-model="message.ForFKHierarchyIDs" multiple />
                                <combo-box label="Rank"  :items="ranks" item-text="Description" item-value="Value01"
                                           v-model="message.ForFKRankIDs" class="mt-2" multiple />
                                <combo-box label="Gender" :items="['All', 'Gents', 'Ladies']"
                                           v-model="message.ForGender" class="mt-2" />
                                <v-row>
                                    <v-col>
                                        <text-box label="From Age" type="number" v-model="message.AgeFrom" class="mt-2" />
                                    </v-col>
                                    <v-col>
                                        <text-box label="To Age" type="number" v-model="message.AgeTo" class="mt-2" />
                                    </v-col>
                                </v-row>
                                <text-box label="Its IDs" v-model="message.ForFKItsIDs" rows="3" multi-row class="mt-2" @input="itsEntered" />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-end">
                        <v-btn @click="clearMessage" v-if="message.NotificationID > 0" text>Clear</v-btn>
                        <v-btn color="primary" @click="saveMessage">Send</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title class="d-flex justify-end">
                        <v-btn small class="py-4" color="primary" @click="sendMessage">
                            <v-icon left>fa-paper-plane</v-icon>Send Pending Messages
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-list dense v-if="messages.length > 0">
                            <template v-for="(message, i) in messages">
                                <v-list-item :key="message.NotificationID" dense>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ message.Title }}
                                            <div class="float-right">
                                                <v-btn icon @click="editMessage(message)"><v-icon>fa-pencil</v-icon></v-btn>
                                                <v-btn color="red darken-4" icon @click="deleteMessage(message)"><v-icon>fa-trash</v-icon></v-btn>
                                            </div>
                                        </v-list-item-title>
                                        <div v-html="message.Message"></div>
<!--                                        <v-list-item-title style="font-size: 13pt;" v-html="message.Message">-->
<!--&lt;!&ndash;                                            {{ message.Message }}&ndash;&gt;-->
<!--                                        </v-list-item-title>-->
                                        <v-list-item-subtitle class="mt-2">
                                            Sent: <v-icon small :color="message.IsSent ? 'green' : 'red'">{{ message.IsSent ? 'fa-check-circle' : 'fa-times-circle' }}</v-icon>
                                            <template v-if="message.IsSent" class="ml-2">
                                                | Sent Date: {{ message.SentDate }}
                                                | Success: {{ message.SuccessCount }}
                                                | Fail: {{ message.FailureCount }}
                                            </template>
                                            <span style="float: right;">
                                                {{ message.CreateDate }}
                                            </span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-divider :key="`dv_${message.NotificationID}`" class="my-3" v-if="(i+1) < messages.length"></v-divider>
                            </template>
                        </v-list>

                        <h4 class="text-center" v-else>There are no notifications available</h4>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from "axios";
    import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

    // import moment from 'moment';

    export default {
        title: "Notifications",
        components: { TiptapVuetify },
        data(){
            return {
                extensions: [
                    History,
                    Blockquote,
                    Link,
                    Underline,
                    Strike,
                    Italic,
                    ListItem,
                    BulletList,
                    OrderedList,
                    [Heading, {
                        options: {
                            levels: [1, 2, 3]
                        }
                    }],
                    Bold,
                    Code,
                    HorizontalRule,
                    Paragraph,
                    HardBreak
                ],
                message: {
                    NotificationID: 0,
                    Title: "",
                    Message: "",
                    NotificationText: "",
                    ForFKHierarchyIDs: [0],
                    ForFKRankIDs: [0],
                    ForGender: "All",
                    ForAge: "",
                    ForFKItsIDs: "",
                    AgeFrom: 0,
                    AgeTo: 0,
                },
                messages: [],
                orgHierarchy: [],
                ranks: [],
            };
        },
        computed: {
            user(){
                return this.$store.getters.user;
            }
        },
        methods: {
            async getData() {
                this.$loadingDialog.show();

                await axios.post(`FCM/GetData`, {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            this.messages = data.Data;
                        }
                        else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            itsEntered(event){
                this.message.ForFKItsIDs = event.replace(/\n/g, ",").replace(/,\s*$/, "");
            },
            async clearMessage(){
                const isClear = await this.$confirmDialog.show({
                    title: 'Edit Notification',
                    message: 'Do you want to stop editing the notification and clear the changes? Please confirm.'});

                if(!isClear)
                    return;

                this.message = {
                    NotificationID: 0,
                        Title: "",
                        Message: "",
                        NotificationText: "",
                        ForFKHierarchyIDs: [0],
                        ForFKRankIDs: [0],
                        ForGender: "All",
                        ForAge: "",
                        ForFKItsIDs: "",
                        AgeFrom: 0,
                        AgeTo: 0,
                };
            },
            editMessage(item){
                this.message = {
                    NotificationID: item.NotificationID,
                    Title: item.Title,
                    Message: item.Message,
                    NotificationText: item.NotificationText,
                    ForFKHierarchyIDs: item.ForFKHierarchyIDs.split(',').map(x => parseInt(x, 10)),
                    ForFKRankIDs: item.ForFKRankIDs.split(',').map(x => parseInt(x, 10)),
                    ForGender: item.ForGender,
                    ForAge: item.ForAge,
                    ForFKItsIDs: item.ForFKItsIDs,
                    AgeFrom: item.ForAge.split('-')[0],
                    AgeTo: item.ForAge.split('-')[1],
                };
            },
            async saveMessage(){
                if(this.message.Title.length == 0)
                {
                    this.$snackBar.error("Please enter a title");
                    return;
                }
                if(this.message.Message.length == 0)
                {
                    this.$snackBar.error("Please enter a message");
                    return;
                }

                this.$loadingDialog.show();

                const formData = {
                    ...this.message
                }

                // const { htmlToText } = require('html-to-text');

                formData.ForFKHierarchyIDs = formData.ForFKHierarchyIDs.join();
                formData.ForFKRankIDs = formData.ForFKRankIDs.join();
                formData.ForAge = `${formData.AgeFrom}-${formData.AgeTo}`;
                formData.NotificationText = formData.Message.replace(/<[^>]+>/g, '');
                // formData.NotificationText = htmlToText(formData.Message, {wordwrap: 130});
                // formData.NotificationText = this.convertToPlainText(document, formData.Message);

                console.log(formData);

                const result = await axios.post(`FCM/AddEdit`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result)
                    await this.getData();
            },
            async sendMessage() {
                this.$loadingDialog.show();

                await axios.post(`FCM/SendPendingMessages`, {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async deleteMessage(item) {
                const isDelete = await this.$confirmDialog.show({
                    title: "Delete Notification",
                    message: `Are you sure you want to delete this notification? This action cannot be undone, please confirm.`,
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                this.$loadingDialog.show();

                const result = await axios.post(`FCM/Delete`, {NotificationID: item.NotificationID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result)
                    await this.getData();
            }
        },
        async mounted(){
            this.$loadingDialog.show();
            this.orgHierarchy = await this.getOrgHierarchy(2, 0, 0, 0);
            this.ranks = await this.getGeneral(2, 0);

            if(this.user.FKRankID <= 2)
                this.orgHierarchy.splice(0, 0, { Name: "All", HierarchyID: 0 });

            this.ranks.splice(0, this.user.FKRankID <= 2 ? 0 : 2, { Description: "All", Value01: 0 });

            this.message.ForFKHierarchyIDs = [this.orgHierarchy[0]['HierarchyID']];

            this.$loadingDialog.hide();

            await this.getData();
        }
    }
</script>

<style scoped>

</style>