import snackBar from "./snackBar.vue";

const SnackBar = {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    // We must create new Eventbus
    // which is just another Vue instance that will be listening for and emitting events from our main instance
    // this EventBus will be available as Modal.EventBus
    this.EventBus = new Vue();

    // making our modal component global
    Vue.component('snack-bar', snackBar);

    // exposing global $modal object with method show()
    // method show() takes object params as argument
    // inside this object we can have modal title, text, styles... and also our callback confirm function
    Vue.prototype.$snackBar = {
      show(params) {
        // if we use this.$modal.show(params) inside our original Vue instance
        // we will emit 'show' event with parameters 'params'
        SnackBar.EventBus.$emit('show', params)
      },
      info(message) {
        // if we use this.$modal.show(params) inside our original Vue instance
        // we will emit 'show' event with parameters 'params'
        const params = {
          message: message,
          color: 'info',
        };
        SnackBar.EventBus.$emit('show', params)
      },
      error(message) {
        // if we use this.$modal.show(params) inside our original Vue instance
        // we will emit 'show' event with parameters 'params'
        const params = {
          message: message,
          color: 'error',
        };
        SnackBar.EventBus.$emit('show', params)
      },
      success(message) {
        // if we use this.$modal.show(params) inside our original Vue instance
        // we will emit 'show' event with parameters 'params'
        const params = {
          message: message,
          color: 'success',
        };
        SnackBar.EventBus.$emit('show', params)
      }
    }
  }
}

export default SnackBar
