<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="8" offset-md="2">
                <v-card>
                    <v-card-title>Jamaat - Team Settings</v-card-title>
                    <v-card-text>
                        <v-form @submit.prevent="saveJamaat">
                            <v-row>
                                    <v-col class="d-flex">
                                        <combo-box label="Team" class="mr-2" v-model="jamaat.FKHierarchyID"
                                                   :items="groups" item-text="NameWithID" item-value="HierarchyID" />
                                        <combo-box label="Jamaat" class="mr-2" v-model="jamaat.FKJamaatID"
                                                   :items="jamaats" item-text="jamaat_name" item-value="jamaat_id" />
                                        <v-btn color="primary" small class="py-4" type="submit"><v-icon left small>fa-plus</v-icon> Add</v-btn>
                                    </v-col>
                            </v-row>
                        </v-form>
                        <v-divider class="mt-5" />
                    </v-card-text>
                    <v-card-text style="height: 67vh; overflow: auto;">
                        <template v-if="items.length > 0">
                            <div class="d-flex justify-end">
                                <text-box label="Search" prefix-icon="fa-search" />
                            </div>
                            <v-list dense>
                                <template v-for="(item, i) in items">
                                    <v-list-item dense :key="item.FKHierarchyID">
                                        <v-list-item-title>{{ item.Team }}</v-list-item-title>
                                        <v-icon small>fa-chevron-right</v-icon>
                                        <v-list-item-title class="text-right">{{ item.Jamaat }}</v-list-item-title>
                                        <v-btn icon small color="red darken-3" class="ml-3"
                                               @click="deleteJamaat(item)">
                                            <v-icon small>fa-trash</v-icon>
                                        </v-btn>
                                    </v-list-item>
                                    <v-divider v-if="i < items.length - 1" />
                                </template>
                            </v-list>
                        </template>
                        <h4 class="text-center my-5" v-else>No Data Available</h4>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "jamaat",
        title: "Jamaat Settings",
        data(){
            return {
                jamaats: [],
                groups: [],
                search: "",
                jamaat: {
                    FKHierarchyID: 0,
                    FKJamaatID: 0,
                },
                items: [],
            };
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                await axios.post("OrgHierarchy/Jamaat_GetData", {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.items = data.Data;

                        console.log(this.items);

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async saveJamaat(){
                this.$loadingDialog.show();

                const result = await axios.post("OrgHierarchy/Jamaat_AddEdit", this.jamaat)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                    await this.getData();

                this.$loadingDialog.hide();
            },
            async deleteJamaat(item){
                const isDelete = await this.$confirmDialog.show({
                    title: 'Delete Jamaat Link',
                    message: `Are you sure you want to delete ${item.Team} = ${item.Jamaat} link? Please confirm`,
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                this.$loadingDialog.show();

                const formData = {
                    FKHierarchyID: item.FKHierarchyID,
                    FKJamaatID: item.FKJamaatID,
                };

                const result = await axios.post("OrgHierarchy/Jamaat_Delete", formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                    await this.getData();

                this.$loadingDialog.hide();
            },
        },
        async mounted(){
            this.$loadingDialog.show();

            this.groups = await this.getOrgHierarchy(2, 5, 0, '0');
            this.jamaats = await this.getJamaat();

            this.$loadingDialog.hide();

            await this.getData();
        },
    }
</script>

<style scoped>

</style>