<template>
    <v-app :style="{backgroundImage: 'url(' + require('@/assets/bg_02.jpg') + ')'}">
        <v-app-bar app color="primary">
            <v-toolbar-title class="headline white--text d-flex justify-center align-center">
                <v-img src="@/assets/BGIlogo.png" contain
                       :height="$vuetify.breakpoint.smAndDown ? '50px' : '80px'"
                       :width="$vuetify.breakpoint.smAndDown ? '50px' : '80px'"
                       :class="$vuetify.breakpoint.smAndDown ? 'elevation-4 pa-2' : 'mt-5 elevation-4 pa-2'"
                       style="background-color: #fff; border-radius: 70px;" />
            </v-toolbar-title>
            <v-toolbar-items class="ml-5">
                <v-btn text small color="white" @click="goHome" v-if="selectedTeam.showMembersView"><v-icon small left>fa-home</v-icon> Home</v-btn>
            </v-toolbar-items>
        </v-app-bar>

        <v-main app style="padding-top: 74px;">
            <v-container fluid>
                <v-overlay v-if="isLoading">
                    <v-progress-circular color="white" indeterminate size="53"
                                         class="mt-2 ml-3" />
                </v-overlay>

                <v-row>
                    <v-col>
                        <v-card class="py-3">
                            <h2 class="headline text-center">Ummal Kiram Dashboard</h2>
                            <v-divider class="my-3" />
                            <div v-if="'Jamaat' in jamaat" class="d-flex flex-column align-center">
                                <h3 class="text-center">
                                    {{ jamaat.Jamaat }} - {{ jamaat.Jamiat }}
                                    <span v-if="form.showFormView"> | {{ form.member.ItsID }} - {{ form.member.FullName }}</span>
                                </h3>
                                <v-btn color="accent" class="mt-2 py-4" small
                                       v-if="form.showFormView"
                                       @click="form.showFormView = false">
                                    <v-icon left small>fa-chevron-left</v-icon> View Members
                                </v-btn>
                                <v-btn color="accent" class="mt-2 py-4" small
                                       v-if="selectedTeam.showMembersView && !form.showFormView"
                                       @click="selectedTeam.showMembersView = false">
                                    <v-icon left small>fa-chevron-left</v-icon> View Teams
                                </v-btn>
                            </div>
<!--                            <template >-->
<!--                                <v-divider class="my-3" />-->
<!--                                <h3 class="text-center"></h3>-->
<!--                                <h4 class="text-center"> {{ form.member.Jamaat }} - {{ form.member.Rank }}</h4>-->
<!--                            </template>-->
                        </v-card>

                        <template v-if="selectedTeam.showMembersView == false && form.showFormView == false">
                            <v-card class="mt-3">
                                <team-view :jamaat="jamaat" @viewMembers="viewMembers" />
                            </v-card>

<!--                            <v-card class="mt-3">-->
<!--                                <h4 class="text-center py-3">Members In Other Teams</h4>-->
<!--                            </v-card>-->
                            <v-card class="mt-3">
                                <h4 class="text-center py-3">New Member Requests</h4>
                                <enrollment-view :jamaat="jamaat" @viewForm="viewForm" />
                            </v-card>

                            <v-card class="mt-3">
                                <h4 class="text-center py-3">Termination Requests</h4>
                                <requestsView :jamaat="jamaat" />
                            </v-card>
                        </template>

                        <template v-else-if="selectedTeam.showMembersView == true && form.showFormView == false">
                            <v-card class="mt-3">
                                <members-view :hierarchy-id="selectedTeam.hierarchyId" :status="selectedTeam.status"
                                              @viewForm="viewForm" />
                            </v-card>
                        </template>

                        <template v-else-if="form.showFormView == true">
                            <v-card class="mt-3">
                                <form-view :member="form.member" @viewMembers="form.showFormView = false" />
                            </v-card>
                        </template>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>

        <confirm-dialog />
        <input-dialog />
        <snack-bar />

    </v-app>
</template>

<script>
    import teamView from './teamView';
    import requestsView from "./requestsView";
    import membersView from './membersView';
    import formView from './formView';
    import enrollmentView from "./enrollmentView";

    export default {
        title: 'Ummal Dashboard',
        components: {
            teamView: teamView,
            membersView: membersView,
            formView: formView,
            requestsView,
            enrollmentView,
        },
        data(){
            return {
                isLoading: false,
                jamaat: {},
                selectedTeam: {
                    showMembersView: false,
                    hierarchyId: 0,
                    status: 1,
                },
                form: {
                    member: {},
                    showFormView: false,
                },
            };
        },
        methods: {
            viewMembers(hierarchyId, status){
                this.selectedTeam.showMembersView = true;
                this.selectedTeam.hierarchyId = hierarchyId;
                this.selectedTeam.status = status;
            },
            viewForm(member){
                this.form.member = member;
                this.form.showFormView = true;
            },
            goHome(){
                this.selectedTeam.showMembersView = false;
                this.form.showFormView = false;
            }
        },
        async created(){
            const query = this.$route.query.dt;
            const params = JSON.parse(window.atob(query));
            this.jamaat = params;

            console.log(this.jamaat);
        },
    }
</script>

<style scoped>
</style>