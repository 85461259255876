<template>
    <v-treeview :items="data"
                item-key="id"
                item-text="name"
                item-children="children"
                :open="[1]">
        <template v-slot:label="{item}">
            {{ item.name }} ({{item.title}}) ({{item.MembersCount}} Members)
        </template>
<!--        <template v-slot:append="{item}">-->
<!--            <context-menu :menu-items="menu" :data-item="item" v-if="item.id != 1"></context-menu>-->
<!--        </template>-->
    </v-treeview>
</template>

<script>
    import axios from "axios";

    export default {
        name: "treeView",
        data(){
            return {
                data: [],
                menu: [
                    {title: 'Members', icon: 'fa-users', onClick: this.viewMembers},
                    {title: 'Edit', icon: 'fa-pencil', onClick: this.editGroup},
                    {title: 'Delete', icon: 'fa-trash', onClick: this.deleteGroup},
                ]
            };
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                let formData = {
                    HierarchyID: 0,
                    ParentID: 0,
                    HasFilter: false,
                    Filters: {},
                };

                await axios.post(`OrgHierarchy/GetData_TreeView`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);
                        else
                        {
                            this.data = data.Data.Items;
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            viewMembers(item){
                this.$emit('viewMembers', item);
            },
            editGroup(item){
                this.$emit('editGroup', item);
            },
            deleteGroup(item){
                this.$emit('deleteGroup', item);
            },
        },
        async mounted(){
            await this.getData();
        }
    }
</script>

<style scoped>

</style>