<template>
    <v-container fluid>
        <v-row>
            <v-col align="end">
                <v-btn-toggle dense color="primary" v-model="option" @change="optionChanged">
                    <v-btn :value="1" text>Miqaat History</v-btn>
                    <v-btn :value="2" text>Attendance History</v-btn>
                </v-btn-toggle>
                <data-table :table-data="tableData" class="mt-2" hide-filters>
                    <template v-slot:item.Duration="{item}">
                        {{ item.StartDate }} - {{ item.EndDate }}
                    </template>
                    <template v-slot:item.City="{item}">
                        {{ item.City }}, {{ item.Country }}
                    </template>
                    <template v-slot:item.IsArrived="{item}">
                        <v-icon color="primary" v-if="item.IsArrived">fa-check-circle</v-icon>
                        <v-icon color="red darken-3" v-else>fa-times-circle</v-icon>
                    </template>
                </data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        name: "membersHistory",
        props: ['its'],
        data(){
            return {
                option: 1,
                tableData: {
                    header: [
                        {text: "S.No", value: 'SNo'},
                        {text: "Miqaat", value: 'Name'},
                        {text: "Duration", value: 'Duration'},
                        {text: "City", value: 'City'},
                        {text: "Attended", value: 'IsArrived'},
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                    totalPages: 0,
                    totalRecords: 0,
                    noOfRows: 10,
                    pageNo: 1,
                }
            };
        },
        methods: {
            async optionChanged(){
                await this.getData();

                if(this.option == 1) {
                    this.tableData.header = [
                        {text: "S.No", value: 'SNo'},
                        {text: "Miqaat", value: 'Name'},
                        {text: "Duration", value: 'Duration'},
                        {text: "City", value: 'City'},
                        {text: "Attended", value: 'IsArrived'},
                    ];
                }
                else {
                    this.tableData.header = [
                        {text: "S.No", value: 'SNo'},
                        {text: "Miqaat", value: 'Miqaat'},
                        {text: "Event", value: 'Event'},
                        {text: "Duty", value: 'Name'},
                        {text: "Reporting", value: 'ReportingTime'},
                    ];
                }
            },
            async getData() {
                this.$loadingDialog.show();

                await axios.post('App/Miqaat_GetHistory', {Option: this.option, MemberID: this.its})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.tableData.data = data.Data;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
        },
        async mounted(){
            // this.$loadingDialog.show();
            this.tableData.noOfRows = this.tableRows;

            await this.getData();
            // this.$loadingDialog.hide();
        }
    }
</script>

<style scoped>

</style>