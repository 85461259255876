<template>
    <v-app-bar app color="primary">
        <v-app-bar-nav-icon class="white--text" @click="drawerStatus" v-if="$vuetify.breakpoint.smAndDown"></v-app-bar-nav-icon>
        <v-toolbar-title class="white--text" v-if="$vuetify.breakpoint.smAndDown">
            Burhani Guards
        </v-toolbar-title>
        <v-toolbar-title class="headline white--text d-flex justify-center align-center" v-else>
            <v-img src="@/assets/BGIlogo.png" contain
                   :height="$vuetify.breakpoint.smAndDown ? '50px' : '80px'"
                   :width="$vuetify.breakpoint.smAndDown ? '50px' : '80px'"
                   :class="$vuetify.breakpoint.smAndDown ? 'elevation-4 pa-2' : 'mt-5 elevation-4 pa-2'"
                   style="background-color: #fff; border-radius: 70px;" />
        </v-toolbar-title>
        <v-toolbar-items>
            <template v-if="$vuetify.breakpoint.mdAndUp">
                <template v-for="(mod, i) in modules">
                    <v-btn
                            v-if="mod.Links.length == 0"
                            text
                            small
                            color="white"
                            :class="i == 0 ? 'ml-5' : ''"
                            :key="mod.id"
                            :to="navigate(mod)">
                        {{ mod.DisplayName }}
                    </v-btn>

                    <v-menu offset-y v-else :key="mod.id">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    text
                                    color="white"
                                    :class="i == 0 ? 'ml-5' : ''">
                                {{ mod.DisplayName }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                    v-if="mod.ModuleID == 3027"
                                    ripple
                                    link
                                    href="https://api.burhaniguards.com/Downloads/BGI_Manual.pdf" target="_blank">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        BGI Manual 4.0
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                    v-for="link in mod.Links"
                                    ripple
                                    link
                                    :key="link.id"
                                    :to="{name: link.LinkName}">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ link.DisplayName }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </template>
        </v-toolbar-items>
        <v-spacer></v-spacer>

<!--        <v-btn v-if="$vuetify.breakpoint.mdAndUp"-->
<!--               text-->
<!--               small-->
<!--               color="white"-->
<!--               class="mr5" href="https://api.burhaniguards.com/Downloads/BGI_Manual.pdf" target="_blank">-->
<!--            BGI Manual-->
<!--        </v-btn>-->
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        dark
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="white" class="mr-1">
                    <v-icon>fa-user-circle</v-icon>
                </v-btn>
<!--                    <v-btn-->
<!--                            dark-->
<!--                            v-bind="attrs"-->
<!--                            v-on="on"-->
<!--                            text-->
<!--                            color="white">-->
<!--                        {{ user.FullName }}-->
<!--                    </v-btn>-->
            </template>
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>{{ user.FullName }}</v-list-item-title>
                        <v-list-item-subtitle>{{ user.Rank }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item ripple @click="logout">
                    <v-list-item-content>
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
    import moment from 'moment';

    export default {
        name: "navBar",
        // props: ['modules'],
        data(){
          return {
              drawer: false,
          }
        },
        computed: {
            modules(){
                return this.$store.getters.modules;
            },
            user(){
                return this.$store.getters.user;
            },
            isShowManual(){
                let currentTimeUTC = new Date().toUTCString();
                let liveTimeUTC = new Date("2022-10-01 11:15 AM GMT").toUTCString();
                // console.log(currentTimeUTC, liveTimeUTC);

                return moment(currentTimeUTC).isAfter(liveTimeUTC);
            },
        },
        methods: {
            navigate(mod){
                if(mod.LinkName == 'membersAddEdit') {
                    let its = this.encryptForEdit(this.user.ItsID);
                    return { name: mod.LinkName, params: {action: 'edit'}, query: {its: its} };
                }
                else
                    return { name: mod.LinkName };
            },
            async logout(){
                this.$emit('logout');
            },
            drawerStatus(){
                this.drawer = !this.drawer;
                this.$emit('onDrawerClick', this.drawer);
            }
        },
    }
</script>

<style scoped>

</style>