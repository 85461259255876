<template>
    <custom-dialog ref="dlg" :title="title" max-width="500" :is-critical="isCritical">
        <template v-slot:content>
            <p v-html="message"></p>
            <text-box v-model="value" class="mt-2" autofocus @keypress.enter="ok" />
        </template>
        <template v-slot:actions>
            <v-btn text @click="hide">{{ cancelButtonText }}</v-btn>
            <v-btn :color="isCritical ? 'red darken-4' : 'primary'" class="white--text" @click="ok">{{ confirmButtonText }}</v-btn>
        </template>
    </custom-dialog>
</template>

<script>
    import customDialog from "../customDialog";
    import inputDialog from "./inputDialog.js";

    export default {
        name: "inputDialog",
        components: {
            customDialog,
        },
        data(){
            return {
                title: '',
                message: '',
                value: '',
                isCritical: false,
                isAllowBlank: false,
                confirmButtonText: 'Save',
                cancelButtonText: 'Cancel',
                promiseResolve: undefined,
                promiseReject: undefined
            }
        },
        methods: {
            show(params){
                this.title = params.title;
                this.message = params.message;
                this.isCritical = params.isCritical;

                if('isAllowBlank' in params)
                    this.isAllowBlank = params.isAllowBlank;

                if('confirmButtonText' in params)
                    this.confirmButtonText = params.confirmButtonText;

                if('cancelButtonText' in params)
                    this.cancelButtonText = params.cancelButtonText;

                if('defaultValue' in params)
                    this.value = params.defaultValue;
                else
                    this.value = '';

                this.$refs.dlg.show();
            },
            hide(){
                this.promiseResolve(false);
                this.$refs.dlg.hide();
            },
            ok(){
                if(!this.isAllowBlank && this.value.length == 0)
                {
                    this.$snackBar.error('Please enter an answer');
                    return;
                }

                this.promiseResolve(this.value);
                this.$refs.dlg.hide();
            }
        },
        beforeMount() {
            // here we need to listen for emitted events
            // we declared those events inside our plugin
            inputDialog.EventBus.$on('show', (params) => {
                this.promiseResolve = params.resolve;
                this.promiseReject = params.reject;
                this.show(params);
            })
        }
    }
</script>

<style scoped>

</style>