<template>
    <data-table ref="tbl"
                :items-per-page="tableData.noOfRows"
                :table-data="tableData"
                :total-pages="this.tableData.totalPages"
                @pageChanged="pageChanged"
                @filterChanged="filterChanged"
                @click:row="viewMembers"
                :item-class="itemClass" disable-sort wrap-text>
<!--        <template v-slot:item.Name="{item}">-->
<!--            {{ item.Name }} ({{item.TeamID}})-->
<!--        </template>-->
        <template v-slot:item.Incharge="{item}">
            <div v-if="item.FKTypeID == 3" class="my-1">
                {{ item.FKInchargeITS }} - {{ item.Incharge }} <br/><v-divider class="my-1" />
                {{ item.FKLadiesInchargeITS }} - {{ item.LadiesIncharge }}
            </div>
            <template v-else>
                {{ item.FKInchargeITS }} - {{ item.Incharge }}
            </template>
        </template>
        <template v-slot:item.Actions="{item}">
            <context-menu :menu-items="menu" :data-item="item" />
        </template>
    </data-table>
</template>

<script>
    import axios from "axios";

    export default {
        name: "tableView",
        data(){
            return {
                tableData: {
                    header: [
                        {text: "S.No", value: "SNo"},
                        {text: "Group Name", value: "NameWithID", applyFilter: true},
                        {text: "Group Type", value: "Type", applyFilter: true},
                        {text: "Group Parent", value: "ParentLineage", applyFilter: true},
                        {text: "Incharge", value: "Incharge", applyFilter: true},
                        {text: "Total Members", value: "MembersCount"},
                        {text: "Actions", value: "Actions", width: "75px"},
                    ],
                    data: [],
                    filters: {},
                    noOfRows: this.tableRows,
                    totalPages: 1,
                    pageNo: 1,
                },
                menu: [],
                rights: {
                    allowAdd: this.verifyRights(this.$route.name, 'A'),
                    allowEdit: this.verifyRights(this.$route.name, 'E'),
                    allowDelete: this.verifyRights(this.$route.name, 'D'),
                },
            };
        },
        methods: {
            itemClass(){
                return 'hand-cursor';
            },
            async getData(){
                this.$loadingDialog.show();

                let formData = {
                    HierarchyID: 0,
                    ParentID: 0,
                    PageNumber: this.tableData.pageNo,
                    NoOfRows: this.tableData.noOfRows,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                };

                await axios.post(`OrgHierarchy/GetData`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);
                        else
                        {
                            this.tableData.data = data.Data.Items;
                            this.tableData.totalPages = data.Data.TotalPages;
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            viewMembers(item){
                this.$emit('viewMembers', item);
            },
            editGroup(item){
                this.$emit('editGroup', item);
            },
            deleteGroup(item){
                this.$emit('deleteGroup', item);
            },
            pageChanged(pageNo){
                this.tableData.pageNo = pageNo;
                this.getData();
            },
            filterChanged(){
                this.tableData.pageNo = 1;
                this.getData();
            },
            async exportToExcel(){
                this.$loadingDialog.show();

                let formData = {
                    HierarchyID: 0,
                    ParentID: 0,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                };

                await axios.post(`OrgHierarchy/GetData_Excel`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        if(status) {
                            let fileUrl = data.Data;
                            window.open(fileUrl);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
        },
        async mounted(){
            this.$loadingDialog.show();

            this.tableData.noOfRows = this.tableRows;

            await this.getData();

            if(this.rights.allowEdit)
                this.menu.push({title: 'Edit', icon: 'fa-pencil', onClick: this.editGroup});

            if(this.rights.allowDelete)
                this.menu.push({title: 'Delete', icon: 'fa-trash', onClick: this.deleteGroup});

            this.$loadingDialog.hide();
        }
    }
</script>

<style scoped>

</style>