<template>
    <v-container fluid>
        <v-row>
            <v-col cols="9">
                <h2 class="headline">Videos</h2>
            </v-col>
            <v-col cols="3" align="end">
                <v-btn color="primary" @click="addVideo" v-if="rights.allowAdd">
                    <v-icon left>fa-plus</v-icon>Add Video
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn color="primary" small
                       :disabled="selectedData.length == 0" @click="updateStatus(1)">
                    Mark As Active
                </v-btn>
                <v-btn color="primary" small class="ml-2"
                       :disabled="selectedData.length == 0" @click="updateStatus(0)">
                    Mark As In-Active
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>
                        <data-table v-model="tableData.selected"
                                    :table-data="tableData" @pageChanged="pageChanged"
                                    @filterChanged="filterChanged"
                                    :total-pages="tableData.totalPages"
                                    item-key="VideoID"
                                    show-select>
                            <template v-slot:item.StreamLink="{item}">
                                <a :href="`https://www.youtube.com/watch?v=${item.StreamLink}`">Youtube Link</a>
                            </template>
                            <template v-slot:item.IsActive="{item}">
                                <v-chip label color="primary" v-if="item.IsActive" small>Active</v-chip>
                                <v-chip label v-else small>In-Active</v-chip>
                            </template>
                            <template v-slot:item.Actions="{item}">
                                <context-menu :data-item="item" :menu-items="tableData.menu" />
                            </template>
                        </data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgVideo" width="600" :title="title" :on-confirm="saveVideo">
            <template v-slot:content>
                <v-form @submit="saveVideo">
                    <v-row>
                        <v-col>
                            <text-box label="Title" v-model="video.Title" />
                            <combo-box label="Time Zone" :items="timeZones"
                                       v-model="video.TimeZone" class="mt-2" />
                            <date-picker label="Start Date" v-model="video.StartDate" class="mt-2" />
                            <time-picker label="Start Time" v-model="video.StartTime" class="mt-2" />
                            <combo-box label="Group" :items="orgHierarchy" item-text="Name" item-value="HierarchyID"
                                       v-model="video.ForFKHierarchyIDs" multiple class="mt-2" />
                            <combo-box label="Rank"  :items="ranks" item-text="Description" item-value="Value01"
                                       v-model="video.ForFKRankIDs" class="mt-2" multiple />
                            <combo-box label="Gender" :items="genders" item-text="name" item-value="value"
                                       v-model="video.ForGender" class="mt-2" />
                            <v-row>
                                <v-col>
                                    <text-box label="From Age" type="number" v-model="video.AgeFrom" class="mt-2" />
                                </v-col>
                                <v-col>
                                    <text-box label="To Age" type="number" v-model="video.AgeTo" class="mt-2" />
                                </v-col>
                            </v-row>

                            <text-box label="Its IDs" v-model="video.ForFKItsIDs" rows="3" multi-row class="mt-2" @input="itsEntered" />
                            <text-box label="Streaming Link" v-model="video.StreamLink" class="mt-2" />
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </simple-dialog>
    </v-container>
</template>

<script>
    import moment from 'moment';
    import axios from "axios";

    export default {
        name: "videos",
        title: 'Videos',
        computed: {
            title(){
                return this.video.VideoID == 0 ? 'New Video' : `Edit ${this.video.Title}`;
            },
            selectedData(){
                let selected = [];
                this.tableData.selected.forEach(item => {
                    selected.push(item.VideoID);
                });
                return selected;
            },
        },
        data(){
            return {
                orgHierarchy: [],
                ranks: [],
                genders: [
                    {name: 'All', value: 'All'},
                    {name: 'Gents', value: 'Male'},
                    {name: 'Ladies', value: 'Female'},
                ],
                timeZones: [
                    'India Standard Time',
                    'Pakistan Standard Time',
                    'Arabian Standard Time',
                    // 'Sri Lanka Standard Time',
                    // 'India Standard Time',
                ],
                tableData: {
                    header: [
                        {text: "S.No", value: 'SNo'},
                        {text: "Title", value: 'Title', applyFilter: true},
                        {text: "Date & Time", value: 'StartDateTime', applyFilter: true},
                        {text: "For Hierarchy", value: 'Hierarchy', applyFilter: true},
                        {text: "For Ranks", value: 'Rank', applyFilter: true},
                        {text: "For Age", value: 'ForAge', applyFilter: true},
                        {text: "For Gender", value: 'ForGender', applyFilter: true},
                        {text: "Link", value: 'StreamLink', applyFilter: true},
                        {text: "Status", value: 'IsActive', applyFilter: true},
                        {text: "Actions", value: 'Actions'},
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                    totalPages: 0,
                    totalRecords: 0,
                    noOfRows: this.tableRows,
                    pageNo: 1,
                    menu: []
                },
                video: {
                    VideoID: 0,
                    Title: '',
                    TimeZone: 'India Standard Time',
                    StartDate: '',
                    StartTime: '',
                    ForFKHierarchyIDs: [1],
                    ForFKRankIDs: [0],
                    ForGender: "All",
                    ForAge: "",
                    ForFKItsIDs: "",
                    AgeFrom: 0,
                    AgeTo: 0,
                    StreamLink: '',
                },
                rights: {
                    allowAdd: this.verifyRights(this.$route.name, 'A'),
                    allowEdit: this.verifyRights(this.$route.name, 'E'),
                    allowDelete: this.verifyRights(this.$route.name, 'D'),
                }
            };
        },
        methods: {
            itsEntered(event){
                this.video.ForFKItsIDs = event.replace(/\n/g, ",").replace(/,\s*$/, "");
            },
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    PageNumber: this.tableData.pageNo,
                    NoOfRows: this.tableData.noOfRows,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                }

                await axios.post('Videos/GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.tableData.totalPages = data.Data.TotalPages;
                        this.tableData.data = data.Data.Items;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            pageChanged(pageNo) {
                this.tableData.pageNo = pageNo;
                this.getData();
            },
            filterChanged(){
                this.getData();
            },
            addVideo(){
                this.$refs.dlgVideo.show();
            },
            editVideo(item){
                this.video = item;

                this.video.StartDate = new moment(this.video.StartDateTime).format('YYYY-MM-DD');
                this.video.StartTime = new moment(this.video.StartDateTime).format('HH:mm');
                this.video.ForFKHierarchyIDs = this.video.ForFKHierarchyIDs.split(',').map(item => parseInt(item));
                this.video.ForFKRankIDs = this.video.ForFKRankIDs.split(',').map(item => parseInt(item));

                const ages = this.video.ForAge.split('-').map(item => parseInt(item));
                this.video.AgeFrom = ages[0];
                this.video.AgeTo = ages[1];

                this.$refs.dlgVideo.show();
            },
            async deleteVideo(item){
                const result = await this.$confirmDialog.show({
                    title: 'Delete Video',
                    message: `Are you sure you want to delete '${item.Title}'? Please confirm`,
                    isCritical: true,
                });

                if(!result)
                    return;

                this.$loadingDialog.show();

                const status = await axios.post(`Videos/Delete`, {VideoID: item.VideoID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(status)
                    await this.getData();
            },
            async saveVideo(){
                if(this.video.Title.length == 0)
                {
                    this.$snackBar.error("Please enter a title");
                    return;
                }

                this.$loadingDialog.show();

                const formData = {
                    ...this.video
                }

                formData.ForFKHierarchyIDs = formData.ForFKHierarchyIDs.join();
                formData.ForFKRankIDs = formData.ForFKRankIDs.join();
                formData.ForAge = `${formData.AgeFrom}-${formData.AgeTo}`;
                formData.StartDateTime = `${formData.StartDate} ${formData.StartTime}`;

                const result = await axios.post(`Videos/AddEdit`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result) {
                    this.$refs.dlgVideo.hide();
                    await this.getData();
                }
            },
            async updateStatus(isActive){
                this.$loadingDialog.show();

                const formData = {
                    VideoIDs: this.selectedData.join(','),
                    IsActive: isActive,
                };

                const result = await axios.post(`Videos/UpdateStatus`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result) {
                    this.tableData.selected = [];
                    await this.getData();
                }
            },
            async getReport(item){
                this.$loadingDialog.show();

                await axios.post('Videos/GetReport', {VideoID: item.VideoID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        if(status) {
                            let fileUrl = data.Data;
                            window.open(fileUrl);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
        },
        async mounted(){
            this.tableData.noOfRows = this.tableRows;

            this.$loadingDialog.show();
            this.orgHierarchy = await this.getOrgHierarchy(2, 0, 0, 0);
            this.ranks = await this.getGeneral(2, 0);

            this.ranks.splice(0, 0, { Description: "All", Value01: 0 });

            this.video.StartDate = new moment().format('YYYY-MM-DD');
            this.video.StartTime = new moment().format('HH:mm');

            this.$loadingDialog.hide();

            if(this.rights.allowEdit)
                this.tableData.menu.push({title: 'Edit', icon: 'fa-pencil', onClick: this.editVideo});

            if(this.rights.allowDelete)
                this.tableData.menu.push({title: 'Delete', icon: 'fa-trash', onClick: this.deleteVideo});

            this.tableData.menu.push({title: 'Download Report', icon: 'fa-page', onClick: this.getReport});

            await this.getData();
        },
    }
</script>

<style scoped>

</style>