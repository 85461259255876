<template>
    <v-navigation-drawer app dark v-model="drawer" color="primary">
        <v-list dense>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>{{ user.FullName }}</v-list-item-title>
                    <v-list-item-subtitle>{{ user.Rank }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider light></v-divider>
        <v-list nav dense>
            <template v-for="(mod) in modules">
                <v-list-item ripple
                             link
                             :key="mod.id"
                             :to="navigate(mod)"
                             v-if="mod.Links.length == 0">
                    <v-list-item-title>{{ mod.DisplayName }}</v-list-item-title>
                </v-list-item>

                <v-list-group :key="mod.id" v-else>
                    <template v-slot:activator>
                        <v-list-item-title class="white--text">{{ mod.DisplayName }}</v-list-item-title>
                    </template>

                    <v-list-item
                            v-if="mod.ModuleID == 3027"
                            ripple
                            link
                            href="https://api.burhaniguards.com/Downloads/BGI_Manual.pdf"
                            target="_blank">
                        <v-list-item-title>BGI Manual 4.0</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                            v-for="link in mod.Links"
                            ripple
                            link
                            :key="link.id"
                            :to="{name: link.LinkName}">
                        <v-list-item-content>
                            <v-list-item-title>{{ link.DisplayName }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </template>
            <v-list-item ripple @click="logout">
                <v-list-item-content>
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

    </v-navigation-drawer>
</template>

<script>
    import moment from "moment";

    export default {
        name: "navDrawer",
        // props: ['modules'],
        data(){
            return {
                drawer: false,
            };
        },
        computed: {
            modules(){
                return this.$store.getters.modules;
            },
            user(){
                return this.$store.getters.user;
            },
            isShowManual(){
                let currentTimeUTC = new Date().toUTCString();
                let liveTimeUTC = new Date("2022-10-01 11:15 AM GMT").toUTCString();
                // console.log(currentTimeUTC, liveTimeUTC);

                return moment(currentTimeUTC).isAfter(liveTimeUTC);
            },
        },
        methods: {
            navigate(mod){
                if(mod.LinkName == 'membersAddEdit') {
                    let its = this.encryptForEdit(this.user.ItsID);
                    return { name: mod.LinkName, params: {action: 'edit'}, query: {its: its} };
                }
                else
                    return { name: mod.LinkName };
            },
            async logout(){
                this.$emit('logout');
            },
            openDrawer(){
                this.drawer = !this.drawer;
            },
        }
    }
</script>

<style scoped>

</style>