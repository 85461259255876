<template>
    <v-container fluid>
        <v-row>
            <v-col><h2>Event & Duties Management</h2></v-col>
            <v-col align="end">
                <v-btn color="primary" @click="addEvent" class="py-4" small>New Event</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <data-table v-model="tableData.selected"
                            :table-data="tableData"
                            :items-per-page="tableData.noOfRows"
                            :total-pages="tableData.totalPages"
                            @pageChanged="pageChanged"
                            @filterChanged="filterChanged">
                    <template v-slot:item.actions="{item}">
                        <context-menu :data-item="item" :menu-items="menu.items" />
                    </template>
                </data-table>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgEvent" :title="eventStatus" width="600" :on-confirm="saveEvent">
            <template v-slot:content>
                <v-form @submit.prevent="saveEvent">
                    <v-row>
                        <v-col>
                            <text-box label="Event Name" v-model="event.Name" />
                            <v-btn type="submit" style="display: none;"></v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </simple-dialog>

        <simple-dialog ref="dlgEventOfficers"
                       :title="eventOfficersTitle"
                       width="800"
                       cancel-button-text="Close"
                       :hide-confirm-button="true">
            <template v-slot:content>
                <v-form @submit.prevent="saveMember">
                    <v-row>
                        <v-col cols="7">
                            <combo-box label="Officer"
                                       v-model="groupMember.FKItsID"
                                       :loading="members.loading"
                                       :search-input.sync="members.search"
                                       :items="members.items"
                                       item-text="FullName"
                                       item-value="ItsID"
                                       hide-no-data
                                       cache-items />
                        </v-col>
                        <v-col cols="4">
                            <combo-box label="Role"
                                       v-model="groupMember.FKRoleID"
                                       :items="roles" item-text="Description" item-value="Value01" />
                        </v-col>
                        <v-col cols="1">
                            <v-btn type="submit" color="primary" class="py-4" block small>Add</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
                <v-row>
                    <v-col>
                        <v-list dense>
                            <v-list-item v-for="item in groupMember.Members" :key="item.FKItsID" dense>
                                <v-list-item-title>{{ item.FullName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.Role }}</v-list-item-subtitle>
                                <v-list-item-action><v-btn icon small @click="deleteMember(item)"><v-icon small>fa-trash</v-icon></v-btn></v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>

    </v-container>
</template>

<script>
    import axios from 'axios';
    // import moment from 'moment'


    export default {
        name: 'miqaatEvents',
        props: ['miqaatId', 'miqaatHierarchyId'],
        data() {
            return {
                tableData: {
                    header: [
                        {text: "S.No", value: 'SNo'},
                        {text: "Event", value: 'Name', applyFilter: true},
                        {text: "Incharge", value: 'Incharge', applyFilter: true},
                        {text: "Actions", value: 'actions'},
                    ],
                    data: [],
                    filters: {},
                    noOfRows: this.tableRows,
                    totalPages: 1,
                    pageNo: 1,
                    selected: [],
                },
                menu: {
                    title: '',
                    items: [
                        { icon: 'fa-users', title: 'Officers', onClick: this.eventOfficers },
                        // { icon: 'fa-calendar', title: 'Duties', onClick: this.viewDuties },
                        { icon: 'fa-pencil', title: 'Edit', onClick: this.editEvent },
                        { icon: 'fa-trash', title: 'Delete', onClick: this.deleteEvent },
                    ]
                },
                event: {
                    MiqaatHierarchyID: 0,
                    ParentID: this.miqaatHierarchyId,
                    Name: '',
                },
                members: {
                    loading: false,
                    items: [],
                    search: '',
                },
                roles: [],
                groupMember: {
                    HierarchyType: '',
                    FKMiqaatHierarchyID: 0,
                    FKItsID: 0,
                    FKRoleID: 0,
                    Members: [],
                },
            };
        },
        computed: {
            eventStatus(){
                return `${this.event.MiqaatHierarchyID == 0 ? 'New' : 'Edit'} Event`;
            },
            eventOfficersTitle(){
                return `${this.event.Name}${this.groupMember.HierarchyType == "Duty" ? " - " + this.duty.Name : ''} - ${this.groupMember.HierarchyType} Officers`;
            },
        },
        methods: {
            selectEventClass(){
                return 'eventRow';
            },
            async getData() {
                this.$loadingDialog.show();

                const formData = {
                    MiqaatHierarchyID: this.miqaatHierarchyId,
                    PageNumber: this.tableData.pageNo,
                    NoOfRows: this.tableData.noOfRows,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                }

                await axios.post(`Miqaat/Events_GetData`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if (status) {
                            this.tableData.data = data.Data.Items;
                            this.tableData.totalPages = data.Data.TotalPages;
                        } else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            // async viewDuties(item){
            //     const event = {
            //         MiqaatHierarchyID: item.MiqaatHierarchyID,
            //         ParentID: this.miqaatHierarchyId,
            //         Name: item.Name,
            //     };
            //
            //     // this.$emit('onEventClick', event);
            // },
            pageChanged(pageNo){
                this.tableData.pageNo = pageNo;
                this.getData();
            },
            filterChanged(){
                this.getData();
            },
            addEvent() {
                this.event = {
                    MiqaatHierarchyID: 0,
                    ParentID: this.miqaatHierarchyId,
                    Name: '',
                };

                this.$refs.dlgEvent.show();
            },
            editEvent(item) {
                this.event = {
                    MiqaatHierarchyID: item.MiqaatHierarchyID,
                    ParentID: this.miqaatHierarchyId,
                    Name: item.Name,
                };

                this.$refs.dlgEvent.show();
            },
            async saveEvent(){
                this.$loadingDialog.show();

                const result = await axios.post('Miqaat/Events_AddEdit', this.event)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                {
                    this.$refs.dlgEvent.hide();
                    await this.getData();
                }

                this.$loadingDialog.hide();
            },
            async deleteEvent(item){
                const isDelete = await this.$confirmDialog.show({
                    title: 'Delete Event',
                    message: `Are you sure you want to delete event "${item.Name}"? Please confirm`,
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                this.$loadingDialog.show();

                const formData = {
                    MiqaatHierarchyID: item.MiqaatHierarchyID,
                };

                const result = await axios.post('Miqaat/Events_Delete', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                {
                    await this.getData();
                }

                this.$loadingDialog.hide();
            },
            async eventOfficers(item){
                this.event = {
                    MiqaatHierarchyID: item.MiqaatHierarchyID,
                    ParentID: this.miqaatHierarchyId,
                    Name: item.Name,
                };

                this.groupMember = {
                    HierarchyType: 'Event',
                    FKMiqaatHierarchyID: item.MiqaatHierarchyID,
                    FKItsID: 0,
                    FKRoleID: 0,
                    Members: [],
                };

                this.$loadingDialog.show();
                this.groupMember.Members = await this.getMembersData();
                this.$loadingDialog.hide();

                this.$refs.dlgEventOfficers.show();
            },
            async saveMember(){
                if(this.groupMember.FKItsID == 0)
                {
                    this.$snackBar.error("Please select a member");
                    return;
                }
                if(this.groupMember.FKRoleID == 0)
                {
                    this.$snackBar.error("Please select a role");
                    return;
                }

                this.$loadingDialog.show();

                const result = await axios.post('Miqaat/GroupMembers_AddEdit', this.groupMember)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                {
                    this.groupMember.FKItsID = 0;
                    this.groupMember.FKRoleID = 0;
                    this.groupMember.Members = await this.getMembersData();
                }

                this.$loadingDialog.hide();
            },
            async getMembersData(){
                const formData = {
                    MiqaatHierarchyID: this.groupMember.FKMiqaatHierarchyID,
                    PageNumber: 1,
                    NoOfRows: 9999,
                    HasFilter: false,
                    Filters: {},
                }

                return await axios.post(`Miqaat/GroupMembers_GetData`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            return data.Data.Items;
                        else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                        return [];
                    });
            },

            async deleteMember(item){
                const isDelete = await this.$confirmDialog.show({
                    title: 'Remove Officer',
                    message: `Are you sure you want to remove ${item.FullName} as ${item.Role} from this event? Please confirm`,
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                this.$loadingDialog.show();

                const formData = {
                    FKMiqaatHierarchyID: this.groupMember.FKMiqaatHierarchyID,
                    FKItsID: item.FKItsID,
                };

                const result = await axios.post('Miqaat/GroupMembers_Delete', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                        return false;
                    });

                if(result)
                    this.groupMember.Members = await this.getMembersData();

                this.$loadingDialog.hide();
            },
            async loadMembers(val){
                this.members.loading = true;
                this.members.items = await this.getMembers({"FullName": val});
                this.members.loading = false;
            },
        },
        watch: {
            'members.search'(val) {
                val && val !== this.groupMember.FKItsID && this.loadMembers(val);
            },
        },
        async mounted() {
            this.tableData.noOfRows = this.tableRows;

            this.$loadingDialog.show();
            this.roles = await this.getGeneral(8, 0);
            this.$loadingDialog.hide();

            await this.getData();
        },
    }
</script>

<style>
    .eventRow {
        cursor: pointer;
    }

    .selectedRow {
        background-color: #c6c6c6;
    }
</style>