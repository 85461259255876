<template>
    <v-app :style="{backgroundImage: 'url(' + require('@/assets/bg_02.jpg') + ')'}">
        <v-main>
            <v-container class="fill-height" fluid>
                <v-row align="center" justify="center" class="mt-n16">
                    <v-col md="8">
                        <v-img src="@/assets/BGIlogo.png" height="100px" contain></v-img>
                        <v-card class="mt-5">
                            <v-card-text class="text-center">
                                <h1>ITS Authentication</h1>
                                <v-img class="mt-5" height="210px" src="@/assets/ITSlogo.png" contain></v-img>
                                <h3 class="mt-5">To view the contents of this website, you must login with your ITS Number</h3>
                                <v-form @submit.prevent="login">
                                    <v-row>
                                        <v-col>
                                            <text-box label="Enter your ITS number"
                                                      v-model="formData.username"
                                                      class="mt-4" style="width: 40%; margin: 0 auto;" />
                                            <v-btn color="red darken-4"
                                                   class="white--text mt-5"
                                                   type="submit"
                                                   :loading="isLoading"
                                                   :disabled="isLoading">
                                                Click here for ITS Authentication
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
        <v-footer absolute color="green darken-4" class="white--text">
            <v-col class="text-center">
                 Copyrights {{ new Date().getFullYear() }} - Burhani Guards Idara
            </v-col>
        </v-footer>
        <snack-bar />
    </v-app>
</template>

<script>
    export default {
        title: "Login",
        data(){
            return {
                background: 'background: url(\'@/assets/bg.jpg\')',
                isLoading: false,
                formData: {
                    grant_type: 'password',
                    username: "",
                    password: "",
                    isWaiting: true,
                },
            }
        },
        methods: {
            async login() {
                if(this.formData.username.length == 0) {
                    this.$snackBar.error('Please enter your its number');
                    return;
                }

                this.isLoading = true;
                await this.$store.dispatch('login', this.formData)
                    .then(() => {
                        this.$snackBar.success('You have been successfully authenticated');

                        const vm = this;
                        setTimeout(function(){
                            const module = vm.$store.getters.modules[0];
                            //const user = vm.$store.getters.user;

                            // if(user.FKStatusID == 2) {
                            //     let its = vm.encryptForEdit(user.ItsID);
                            //     vm.$router.push({name: 'membersAddEdit', params: {action: 'edit'}, query: {its: its}});
                            // }
                            // else {
                                if(module.Links.length == 0)
                                    vm.$router.push({name: module.LinkName});
                                else
                                {
                                    const link = module.Links[0];
                                    vm.$router.push({name: link.LinkName, params: {stage: link.LinkID, id: link.LinkID, action: 'new'}});
                                }
                            // }
                        }, 1000);
                    })
                    .catch(error => {
                        console.log(error.response.data);
                        this.$snackBar.error(error.response.data.error_description);
                    });

                this.isLoading = false;
            }
        }
    }
</script>

<style scoped>

</style>