<script>
    import { Doughnut, mixins } from 'vue-chartjs'
    const { reactiveProp } = mixins

    export default {
        extends: Doughnut,
        mixins: [reactiveProp],
        props: ['options'],
        data(){
          return {
              chartOptions: {
                  responsive: true,
                  maintainAspectRatio: false,
                  onClick: this.onItemClick,
              },
          }
        },
        methods: {
            onItemClick(point, event){
                const item = event[0];
                console.log(item);
            },
        },
        beforeMount() {
            if(this.options)
                this.chartOptions = this.options;
        },
        mounted () {
            this.renderChart(this.chartData, this.chartOptions)
        }
    }
</script>

<style scoped>

</style>