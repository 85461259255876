<template>
    <v-container fluid>
        <v-row>
            <v-col class="d-flex">
                <h2 class="headline">Members Requests</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="d-flex">
<!--                <v-btn-toggle v-model="requestType" dark>-->
<!--                    <v-btn small class="py-4" color="primary">Termination Requests</v-btn>-->
<!--                    <v-btn small class="py-4" color="primary" disabled>Transfer Requests</v-btn>-->
<!--                    <v-btn small class="py-4" color="primary" disabled>Leave Requests</v-btn>-->
<!--                </v-btn-toggle>-->
                <v-spacer />
                <text-box label="Search" v-model="searchText" background-color="white" class="mr-2" @keypress.enter="search" />
                <template v-if="tabIndex == 0">
                    <v-btn color="red darken-3 white--text" small class="py-4" :disabled="tableData.selected.length == 0" 
                            @click="deleteSelected">
                        <v-icon left small>fa-times</v-icon> Remove Member(s)
                    </v-btn>
                    <v-btn color="blue darken-3 white--text" small class="py-4 ml-2" :disabled="tableData.selected.length == 0" 
                            @click="rejectSelected">
                        <v-icon left small>fa-trash</v-icon> Reject Request(s)
                    </v-btn>
                </template>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" small class="py-4 ml-2" v-bind="attrs" v-on="on">
                            <v-icon left small>fa-file-excel-o</v-icon> Export To Excel
                            <v-icon right small>fa-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list dense>
                        <v-list-item dense @click="exportToExcel(1)">In-Process</v-list-item>
                        <v-list-item dense @click="exportToExcel(2)">Completed</v-list-item>
                        <v-list-item dense @click="exportToExcel(3)" v-if="tabIndex == 0">Rejected</v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-tabs v-model="tabIndex">
                        <v-tab>Termination Requests</v-tab>
                        <v-tab>Transfer Requests</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tabIndex" style="height: 66vh; overflow: auto;">
                        <v-tab-item>
                            <data-table :table-data="tableData" v-model="tableData.selected"
                                        :total-pages="tableData.totalPages" disable-sort
                                        @pageChanged="pageChanged"
                                        item-key="FKItsID" show-select hide-filters wrap-text class="ma-1">
                                <template v-slot:item.PictureInITS="{item}">
                                    <v-img style="max-height: 60px; min-height: 60px; max-width: 50px; border: 1px Solid #ccc; border-radius: 4px;"
                                           class="my-1"
                                           :src="item.PictureInITS" contain :alt="item.FKItsID.toString()" />
                                </template>
                                <template v-slot:item.Reason="{item}">
                                    {{ item.Reason }} <v-btn icon x-small color="primary" class="ml-2" outlined @click="showDescription(item.Description)"><v-icon x-small>fa-info</v-icon></v-btn>
                                </template>
                                <template v-slot:item.ApprovedByAmil="{item}">
                                    <template v-if="item.ApprovedByAmil == 1 || item.ApprovedByAmil == 2">
                                        <v-chip label style="width: 100%; text-align: center;" class="my-1"
                                                :color="(item.ApprovedByAmil == 1 ? 'orange' : item.ApprovedByAmil == 2 ? 'green' : 'red') + ' darken-3 white--text'">
                                            <template v-if="item.ApprovedByAmil == 1"><v-icon left x-small>fa-exclamation</v-icon> Pending</template>
                                            <template v-if="item.ApprovedByAmil == 2"><v-icon left x-small>fa-check</v-icon> Approved</template>
                                        </v-chip>
                                    </template>
                                    <v-btn v-else-if="item.ApprovedByAmil == 3" small class="py-4" color="red white--text" @click="viewReason(item.ReasonByAmil)">
                                        <v-icon left x-small>fa-times</v-icon> Rejected
                                    </v-btn>
                                </template>
                                <template v-slot:item.ApprovedByCommander="{item}">
                                    <template v-if="item.ApprovedByCommander == 1 || item.ApprovedByCommander == 2">
                                        <v-chip label style="width: 100%; text-align: center;"
                                                :color="(item.ApprovedByCommander == 1 ? 'orange' : item.ApprovedByCommander == 2 ? 'green' : 'red') + ' darken-3 white--text'">
                                            <template v-if="item.ApprovedByCommander == 1"><v-icon left x-small>fa-exclamation</v-icon> Pending</template>
                                            <template v-if="item.ApprovedByCommander == 2"><v-icon left x-small>fa-check</v-icon> Approved</template>
                                        </v-chip>
                                    </template>
                                    <v-btn v-else-if="item.ApprovedByCommander == 3" small class="py-4" color="red white--text" @click="viewReason(item.ReasonByCommander)">
                                        <v-icon left x-small>fa-times</v-icon> Rejected
                                    </v-btn>
                                </template>
                            </data-table>
                        </v-tab-item>
                        <v-tab-item>
                            <data-table :table-data="transferTableData" disable-sort disable-pagination
                                        hide-filters wrap-text class="ma-1">
                                <template v-slot:item.PictureInITS="{item}">
                                    <v-img style="max-height: 60px; min-height: 60px; max-width: 50px; border: 1px Solid #ccc; border-radius: 4px;"
                                           class="my-1"
                                           :src="item.PictureInITS" contain :alt="item.FKItsID.toString()" />
                                </template>
                                <template v-slot:item.TransferToFKHierarchyID="{item}">
                                    <v-form @submit.prevent="completeTransferRequest(item)">
                                        <div class="d-flex">
                                            <combo-box label="Group" background-color="white" v-model="item.TransferToFKHierarchyID"
                                                       :items="groups" item-text="NameWithID" item-value="HierarchyID" class="mr-2" />
                                            <v-btn color="primary" type="submit" small class="py-4"><v-icon small left>fa-refresh</v-icon> Transfer</v-btn>
                                        </div>
                                    </v-form>
                                </template>
                            </data-table>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        name: "membersRequests",
        title: "Members Requests",
        data(){
            return {
                requestType: 0,
                tabIndex: 0,
                groups: [],
                searchText: '',
                tableData: {
                    header: [
                        {text: "S.No", value: 'SNo'},
                        {text: "Photo", value: 'PictureInITS'},
                        {text: "ITS", value: 'FKItsID'},
                        {text: "Full Name", value: 'FullName'},
                        {text: "Team", value: 'Hierarchy'},
                        {text: "Jamaat", value: 'Jamaat'},
                        {text: "Zone", value: 'Zone'},
                        {text: "Age", value: 'Age'},
                        {text: "Rank", value: 'Rank', width: '150' },
                        {text: "Reason", value: 'Reason', width: '150' },
                        {text: "Ummal Kiram", value: 'ApprovedByAmil'},
                        {text: "Commander", value: 'ApprovedByCommander'},
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                    totalRecords: 1,
                    totalPages: 0,
                    pageNo: 1,
                },
                transferTableData: {
                    header: [
                        {text: "S.No", value: 'SNo'},
                        {text: "Photo", value: 'PictureInITS'},
                        {text: "ITS", value: 'FKItsID'},
                        {text: "Full Name", value: 'FullName'},
                        {text: "Team", value: 'Hierarchy'},
                        {text: "Jamaat", value: 'Jamaat'},
                        {text: "Zone", value: 'Zone'},
                        {text: "Age", value: 'Age'},
                        {text: "Rank", value: 'Rank', width: '150' },
                        {text: "Transfer To", value: 'TransferToFKHierarchyID', width: '450' },
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                },
            };
        },
        computed: {
            selectedData(){
                let selected = [];
                this.tableData.selected.forEach(item => {
                    selected.push({ItsID: item.FKItsID, Reason: item.Reason});
                });
                return selected;
            },
        },
        methods: {
            search(){
                if(this.tabIndex == 0)
                    this.getTerminationData();
                else
                    this.getTransferData();
            },
            async getTerminationData(){
                this.$loadingDialog.show();

                const formData = {
                    Search: this.searchText,
                    PageNo: this.tableData.pageNo,
                    NoOfRows: this.tableData.noOfRows,
                }


                await axios.post('MembersRequests/Termination_GetData_ForIdara', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        console.log(data.Data);
                        this.tableData.data = data.Data.Items;
                        this.tableData.totalPages = data.Data.TotalPages;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            pageChanged(pageNo) {
                this.tableData.pageNo = pageNo;
                this.getTerminationData();
            },
            async getTransferData(){
                this.$loadingDialog.show();

                const formData = {
                    HierarchyID: 0,
                    Option: 2,
                }

                await axios.post('MembersRequests/Transfer_GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        console.log(data.Data);
                        this.transferTableData.data = data.Data;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            showDescription(desc){
                this.$infoDialog.show("Termination Reason", desc);
            },
            viewReason(reason){
                this.$infoDialog.show("Disapproval Reason", reason);
            },
            async rejectSelected(){
                const result = await this.$confirmDialog.show({
                    title: 'Reject Request(s)',
                    message: `Are you sure you want to reject ${this.selectedData.length} selected request(s)?\nPlease confirm.`,
                });

                if(!result)
                    return;

                const remarks = await this.$inputDialog.show({
                    title: 'Rejection Reason',
                    message: 'Please enter the reason to reject the selected request(s)',
                });

                if(!remarks)
                    return;

                this.$loadingDialog.show();

                const formData = {
                    Members: this.selectedData,
                    RejectionReason: remarks,
                };

                let status = await axios.post('MembersRequests/Termination_Reject', formData)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(status) {
                    this.tableData.selected = [];
                    await this.getTerminationData();
                }

                this.$loadingDialog.hide();
            },
            async deleteSelected(){
                const result = await this.$confirmDialog.show({
                    title: 'Delete Members',
                    message: 'Are you sure you want to permanently delete selected member(s)?\n This action cannot be undone, please confirm.',
                    isCritical: true,
                });

                if(result) {
                    const formData = {
                        Members: this.selectedData,
                    };

                    let status = await axios.post('MembersRequests/Termination_Complete', formData)
                        .then(async response => {
                            const data = response.data;
                            const status = data.Status;

                            this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                            return status;
                        })
                        .catch(error => {
                            console.log(error);
                            this.$snackBar.error('An unexpected error has occurred');
                        });

                    if(status) {
                        this.tableData.selected = [];
                        await this.getTerminationData();
                    }
                }
            },
            async completeTransferRequest(request){
                const result = await this.$confirmDialog.show({
                    title: 'Delete Members',
                    message: `Are you sure you want to transfer ${request.FullName}? please confirm.`,
                    isCritical: true,
                });

                if(result) {
                    const formData = {
                        ItsID: request.FKItsID,
                        ToFKHierarchyID: request.TransferToFKHierarchyID,
                    };

                    let status = await axios.post('MembersRequests/Transfer_Complete', formData)
                        .then(async response => {
                            const data = response.data;
                            const status = data.Status;

                            this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                            return status;
                        })
                        .catch(error => {
                            console.log(error);
                            this.$snackBar.error('An unexpected error has occurred');
                        });

                    if(status) {
                        await this.getTransferData();
                    }
                }
            },
            async exportToExcel(statusID){
                this.$loadingDialog.show();

                let formData = {
                    Option: this.tabIndex == 0 ? 1 : 2,
                    StatusID: statusID,
                };

                await axios.post('MembersRequests/GetData_ForExcel', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        if(status) {
                            let fileUrl = data.Data;
                            window.open(fileUrl);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            }
        },
        async mounted(){
            this.tableData.noOfRows = this.tableRows;

            await this.getTerminationData();

            this.$loadingDialog.show();
            this.groups = await this.getOrgHierarchy(2, 5, 0, '0');
            this.$loadingDialog.hide();

            await this.getTransferData();
        }
    }
</script>

<style scoped>

</style>