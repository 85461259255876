<template>
    <v-container fluid>
        <v-row>
            <v-col class="d-flex">
                <h2 class="headline">Vouchers</h2>
                <v-spacer />
                <combo-box label="Group" background-color="white" v-model="hierarchyID"
                           :items="groups" item-text="NameWithID" item-value="HierarchyID"
                           @change="getData" />
            </v-col>
        </v-row>
        <v-row>
            <v-col class="d-flex">
                <v-spacer />
                <v-btn color="primary" @click="newVoucher" small class="py-4"><v-icon left small>fa-plus</v-icon> New Voucher</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>
                        <data-table :table-data="tableData" disable-sort wrap-text
                                    @pageChanged="pageChanged"
                                    @filterChanged="filterChanged">
                            <template v-slot:item.Amount="{item}">
                                {{ item.Amount.formatDecimals() }}
                            </template>
                            <template v-slot:item.Attachment="{item}">
                                <v-btn small class="py-4 my-1" color="blue darken-3 white--text" :href="item.Attachment" target="_blank">
                                    <v-icon left small>fa-search</v-icon> View Attachment
                                </v-btn>
                            </template>
                            <template v-slot:item.Status="{item}">
                                <v-chip label :color="statusColor(item.FKStatusID)" class="white--text"><b>{{ item.Status }}</b></v-chip>
                            </template>
                        </data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgVoucher" title="New Voucher" width="500" :on-confirm="saveVoucher">
            <template v-slot:content>
                <v-row>
                    <v-col>
                        <v-form @submit.prevent="saveVoucher">
                            <combo-box label="Collection Head" v-model="voucher.CollectionHeadID"
                                       :items="collectionHeads" item-text="Description" item-value="CollectionHeadID" />
                            <combo-box label="Paid To" class="mt-3" v-model="voucher.OfficeID"
                                       :items="offices" item-text="Description" item-value="Value01" />
                            <combo-box label="Payment Mode" class="mt-3" v-model="voucher.PaymentMode"
                                       :items="paymentModes" item-text="Description" item-value="Description" />
                            <text-box label="Reference No" class="mt-3" v-model="voucher.ReferenceNo" />
                            <combo-box label="Currency" class="mt-3" v-model="voucher.CurrencyID"
                                       :items="currencies" item-text="CurrencyShortName" item-value="CurrencyID" />
                            <text-box label="Amount" type="number" class="mt-3" v-model="voucher.Amount" />
                            <v-file-input label="Attachment"
                                          v-model="voucher.Attachment"
                                          outlined
                                          dense
                                          class="mt-3 small-text-field"
                                          hide-details="auto"
                                          prepend-icon="fa-file-image-o"
                                          accept="image/png, image/jpeg" />
                        </v-form>
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        name: "vouchers",
        title: "Vouchers",
        data(){
            return {
                groups: [],
                collectionHeads: [],
                offices: [],
                paymentModes: [],
                currencies: [],
                hierarchyID: 0,
                tableData: {
                    header: [
                        {text: "S.No", value: "SNo"},
                        {text: "Doc #", value: "DocumentNoString", applyFilter: true},
                        {text: "Date", value: "DocumentDate", applyFilter: true},
                        {text: "Office", value: "Office"},
                        {text: "Currency", value: "Currency"},
                        {text: "Amount", value: "Amount", applyFilter: true},
                        {text: "Attachment", value: "Attachment"},
                        {text: "Status", value: "Status"},
                        // {text: "Action", value: "Action"},
                    ],
                    data: [],
                    filters: {},
                    noOfRows: this.tableRows,
                    totalRecords: 1,
                    totalPages: 0,
                    pageNo: 1,
                },
                voucher: {
                    CollectionHeadID: 0,
                    FiscalYearID: 0,
                    OfficeID: 0,
                    PaymentMode: '',
                    ReferenceNo: '',
                    CurrencyID: 0,
                    Amount: 0,
                    Attachment: null,
                },
            };
        },
        computed: {
            user(){
                return this.$store.getters.user;
            },
        },
        methods: {
            statusColor(statusID){
                let color = "";

                switch(statusID) {
                    case 1:
                        color = 'orange darken-3';
                        break;
                    case 2:
                        color = 'green darken-3';
                        break;
                    case 3:
                        color = 'red darken-3';
                        break;
                    default:
                        break;
                }

                return color;
            },
            async getOffices(option){
                this.$loadingDialog.show();

                await axios.post("Vouchers/GetOffices", {Option: option})
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.offices = data.Data;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    FiscalYearID: 0,
                    CollectionHeadID: 0,
                    HierarchyID: this.hierarchyID,
                    StatusID: 0,
                    PageNumber: this.tableData.pageNo,
                    NoOfRows: this.tableData.noOfRows,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                }

                await axios.post("Vouchers/GetData", formData)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.tableData.data = data.Data.Items;
                        this.tableData.totalPages = data.Data.TotalPages;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            pageChanged(pageNo) {
                this.tableData.pageNo = pageNo;
                this.getData();
            },
            filterChanged(){
                this.getData();
            },
            newVoucher(){
                this.voucher = {
                    CollectionHeadID: this.collectionHeads[0].CollectionHeadID,
                    FiscalYearID: 0,
                    OfficeID: 0,
                    PaymentMode: '',
                    ReferenceNo: '',
                    CurrencyID: this.currencies[0].CurrencyID,
                    Amount: 0,
                    Attachment: null,
                };

                this.$refs.dlgVoucher.show();
            },
            async saveVoucher(){
                if(this.voucher.CollectionHeadID == 0) {
                    this.$snackBar.error("Please select a collection head");
                    return;
                }
                if(this.voucher.OfficeID == 0) {
                    this.$snackBar.error("Please select fee paid to");
                    return;
                }
                if(this.voucher.PaymentMode == 0) {
                    this.$snackBar.error("Please select a payment mode");
                    return;
                }
                if(this.voucher.CurrencyID == 0) {
                    this.$snackBar.error("Please select a currency");
                    return;
                }
                if(this.voucher.Amount == 0) {
                    this.$snackBar.error("Please enter a valid amount");
                    return;
                }
                if(this.voucher.Attachment == null) {
                    this.$snackBar.error("Please upload proof of payment attachment");
                    return;
                }

                this.$loadingDialog.show();

                let formData = new FormData();

                formData.append("HierarchyID", this.hierarchyID);

                Object.keys(this.voucher).forEach(key => {
                    if(key != 'Attachment')
                        formData.append(key, this.voucher[key]);
                });

                formData.append("Attachment", this.voucher.Attachment);

                const result = await axios.post("Vouchers/AddEdit", formData, {headers: {"Content-Type": "multipart/form-data"}})
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success': 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result) {
                    await this.getData();
                    this.$refs.dlgVoucher.hide();
                }

                this.$loadingDialog.hide();
            },
        },
        async mounted(){
            this.tableData.noOfRows = this.tableRows;

            this.$loadingDialog.show();

            this.groups = await this.getOrgHierarchy(2, 5, 0, '0');
            this.collectionHeads = await this.getCollectionHeads();
            this.paymentModes = await this.getGeneral(14, 0);
            this.currencies = await this.getCurrencies();
            await this.getOffices(1);

            if(this.user.ZoneID == 2 || this.user.ZoneID == 3 || this.user.ZoneID == 4) {
                let cr = this.currencies.find(x => x.CurrencyID == 1);
                this.currencies = [cr];
            }
            else if(this.user.ZoneID == 5) {
                let cr = this.currencies.find(x => x.CurrencyID == 2);
                this.currencies = [cr];
            }
            else if(this.user.ZoneID == 6) {
                let cr = this.currencies.find(x => x.CurrencyID == 4);
                this.currencies = [cr];
            }

            this.$loadingDialog.hide();

            this.hierarchyID = this.user.MyHierarchyID == 0 ? this.user.FKHierarchyID : this.user.MyHierarchyID;

            await this.getData();
        }
    }
</script>

<style scoped>

</style>