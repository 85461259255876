import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

import 'font-awesome/css/font-awesome.min.css' // Ensure you are using css-loader

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.green.darken4,
                secondary: colors.green.darken2,
                accent: colors.green.darken4,
            },
            dark: {
                primary: colors.green.darken4,
                secondary: colors.green.darken2,
                accent: colors.green.darken4,
            }
        },
    },
    icons: {
        iconfont: 'fa4',
    },
});