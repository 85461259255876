<template>
    <v-container fluid>
        <v-row>
            <v-col><h2>Members Arrival</h2></v-col>
            <v-col align="end">
                <v-btn color="primary" @click="excelUpload" class="py-4 ml-2" small>Upload Excel</v-btn>
                <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                       ref="excelUpload" @change="loadFile" hidden>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-row>
                    <v-col><h3>Total: {{ tableData.totalRecords }} Members</h3></v-col>
                    <v-col align="end">
                        <text-box label="Enter ITS Number"
                                  v-model="arrival.FKItsID"
                                  @keyup.enter="addAccomodation" autofocus />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <data-table :table-data="tableData"
                                    :items-per-page="tableData.noOfRows"
                                    :total-pages="tableData.totalPages"
                                    @pageChanged="pageChanged"
                                    @filterChanged="filterChanged" ></data-table>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgAccomodation" width="500" title="Accomodation Details" :on-confirm="saveArrival">
            <template v-slot:content>
                <v-form @submit.prevent="saveArrival">
                    <text-box label="Enter Accomodation Details"
                              v-model="arrival.AccomodationDetails" autofocus />
                    <v-btn type="submit" style="display: none;">Save</v-btn>
                </v-form>
            </template>
        </simple-dialog>
    </v-container>
</template>
<script>

import axios from "axios";

export default {
    name: 'c-arrived-members',
    props: ['miqaatId', 'miqaatHierarchyId'],
    data() {
        return {
            tableData: {
                header: [
                    {text: 'S.No', value: 'SNo'},
                    {text: 'ITS', value: 'FKItsID', applyFilter: true, },
                    {text: 'Full Name', value: 'FullName', applyFilter: true, },
                    {text: 'Arrival Date', value: 'ArrivalDate', applyFilter: true },
                    {text: 'Accomodation', value: 'AccomodationDetails', applyFilter: true },
                ],
                data: [],
                selected: [],
                filters: {
                    IsArrived: 1
                },
                totalRecords: 0,
                pageNo: 1,
                noOfRows: this.tableRows,
            },
            arrival: {
                FKItsID: 0,
                AccomodationDetails: "",
            }
        };
    },    
    methods: {
        async getData(){
            this.$loadingDialog.show();

            const formData = {
                MiqaatHierarchyID: this.miqaatHierarchyId,
                PageNumber: this.tableData.pageNo,
                NoOfRows: this.tableData.noOfRows,
                HasFilter: !!Object.keys(this.tableData.filters).length,
                Filters: this.tableData.filters,
            }

            await axios.post(`Miqaat/Registrations_GetData`, formData)
                .then(response => {
                    const data = response.data;
                    const status = data.Status;

                    if(status)
                    {
                        this.tableData.data = data.Data.Items;
                        this.tableData.totalRecords = data.Data.TotalRecords;
                        this.tableData.totalPages = data.Data.TotalPages;
                    }
                    else
                        this.$snackBar.error(data.Message);
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.error('An unexpected error has occurred');
                });

            this.$loadingDialog.hide();
        },
        pageChanged(pageNo){
            this.tableData.pageNo = pageNo;
            this.getData();
        },
        filterChanged(){
            this.getData();
        },
        addAccomodation(){
            if(this.arrival.FKItsID == 0)
            {
                this.$snackBar.error("Please enter an ITS number");
                return;
            }

            this.$refs.dlgAccomodation.show();
        },
        async saveArrival(){
            this.$loadingDialog.show();

            const formData = {
                MiqaatID: this.miqaatHierarchyId,
                FKItsID: this.arrival.FKItsID,
                AccomodationDetails: this.arrival.AccomodationDetails,
            };

            const result = await axios.post(`Miqaat/Registrations_UpdateArrival`, formData)
                .then(response => {
                    const data = response.data;
                    const status = data.Status;

                    this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                    return status
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.error('An unexpected error has occurred');
                });

            if(result)
            {
                await this.getData();
                this.$refs.dlgAccomodation.hide();
                this.arrival.FKItsID = 0;
            }

            this.$loadingDialog.hide();
        },
        excelUpload(){
            this.$refs.excelUpload.value = null;
            this.$refs.excelUpload.click();
        },
        async loadFile(e){
            const file = e.target.files[0];

            this.$loadingDialog.show();

            let formData = new FormData();
            formData.append("MiqaatID", this.miqaatHierarchyId);
            formData.append("ExcelFile", file);

            const result = await axios.post(`Miqaat/Registrations_UploadArrival`, formData,
                {headers: {'Content-Type': 'multipart/form-data'}})
                .then(response => {
                    const data = response.data;
                    const status = data.Status;

                    if(!status)
                        this.$snackBar.error(data.Message);

                    return status
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.error('An unexpected error has occurred');
                });

            if(result)
                await this.getData();

            this.$loadingDialog.hide();
        },
    },
    async mounted() {
        this.tableData.noOfRows = this.tableRows;

        await this.getData();
    },
}
</script>

<style>

</style>