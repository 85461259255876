<template>
    <v-container fluid>
        <v-row>
            <v-col><h2>Duty Points</h2></v-col>
            <v-col align="end">
                <v-btn color="primary" @click="addDutyPoint" class="py-4" small>New Duty Point</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <data-table :table-data="tableData" disable-pagination>
                    <template v-slot:item.Actions="{item}">
                        <context-menu :data-item="item" :menu-items="menu.items" />
                    </template>
                </data-table>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgDutyPoint" :title="dutyPointStatus" width="600" :on-confirm="saveDutyPoint">
            <template v-slot:content>
                <v-form @submit.prevent="saveDutyPoint">
                    <v-row>
                        <v-col>
                            <text-box label="Duty Point Name" v-model="dutyPoint.Name" />
                            <text-box label="Requirements" v-model="dutyPoint.Requirements" class="mt-2" multi-row rows="3" />
                            <v-btn type="submit" style="display: none;"></v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </simple-dialog>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        props: ['miqaatHierarchyId'],
        data() {
            return {
                tableData: {
                    header: [
                        {text: 'S.No', value:"SNo", width: "70px",},
                        {text: 'Duty Point', value:"Name", applyFilter: true,},
                        {text: 'Requirements', value:"Requirements", applyFilter: true,},
                        {text: 'Actions', value:"Actions" },
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                },
                dutyPoint: {
                    MiqaatHierarchyID: this.miqaatHierarchyId,
                    DutyPointID: 0,
                    Name: '',
                    Requirements: '',
                },
                menu: {
                    title: '',
                    items: [
                        { icon: 'fa-pencil', title: 'Edit', onClick: this.editDutyPoint },
                        { icon: 'fa-trash', title: 'Delete', onClick: this.deleteDutyPoint },
                    ]
                },
            }
        },
        computed: {
            dutyPointStatus(){
                return `${this.dutyPoint.DutyPointID == 0 ? 'New' : 'Edit'} Duty Point`;
            }
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                await axios.post(`Miqaat/DutyPoints_GetData`, {MiqaatHierarchyID: this.miqaatHierarchyId})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.message);

                        this.tableData.data = data.Data.Items;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.tableData.data.forEach((element, i) => {
                   element.SNo = i + 1;
                });

                this.$loadingDialog.hide();
            },
            addDutyPoint() {
                this.dutyPoint = {
                    MiqaatHierarchyID: this.miqaatHierarchyId,
                    DutyPointID: 0,
                    Name: '',
                    Requirements: '',
                };

                this.$refs.dlgDutyPoint.show();
            },
            editDutyPoint(item) {
                console.log(item);

                this.dutyPoint = {
                    MiqaatHierarchyID: this.miqaatHierarchyId,
                    DutyPointID: item.DutyPointID,
                    Name: item.Name,
                    Requirements: item.Requirements,
                };

                this.$refs.dlgDutyPoint.show();
            },
            async saveDutyPoint(){
                this.$loadingDialog.show();

                const result = await axios.post(`Miqaat/DutyPoints_AddEdit`, this.dutyPoint)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        return status
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                {
                    await this.getData();
                    this.$refs.dlgDutyPoint.hide();
                }

                this.$loadingDialog.hide();
            },
            async deleteDutyPoint(item){
                const isDelete = await this.$confirmDialog.show({
                    title: "Delete Duty Point",
                    message: `Are you sure you want to delete "${item.Name}"? Please confirm`,
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                this.$loadingDialog.show();

                const result = await axios.post(`Miqaat/DutyPoints_Delete`, {DutyPointID: item.DutyPointID})
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                    await this.getData();

                this.$loadingDialog.hide();
            },
        },
        async mounted(){
            await this.getData();
        }
    }
</script>

<style lang="scss">

</style>