<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h2 class="headline">Title Sharaf Form</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
<!--                <v-btn color="red darken-4 white&#45;&#45;text" small-->
<!--                       :disabled="selectedData.length == 0" @click="deleteSurvey">-->
<!--                    Delete-->
<!--                </v-btn>-->
                <v-spacer />
                <v-btn color="primary" small @click="exportToExcel">
                    Export To Excel
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <data-table v-model="tableData.selected"
                            :table-data="tableData"
                            :items-per-page="tableData.noOfRows"
                            :total-pages="tableData.totalPages"
                            @click:row="itemClick"
                            item-key="SurveyID"
                            show-select
                            @pageChanged="pageChanged"
                            @filterChanged="filterChanged">
                    <template v-slot:item.Photo="{item}">
                        <v-img style="max-height: 60px; min-height: 60px; max-width: 50px; border: 1px Solid #ccc; border-radius: 4px;"
                               class="my-1"
                               :src="item.PictureInITS" contain :alt="item.ItsID.toString()" />
                    </template>
                    <template v-slot:item.actions="{item}">
                        <context-menu :data-item="item" :menu-items="tableData.menu.items" />
                    </template>
                </data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from "axios";
    import moment from "moment";

    export default {
        title: "Title Sharaf Form",
        data(){
            return {
                tableData: {
                    header: [
                        {text: "S.No", value: 'SNo'},
                        {text: "Photo", value: 'Photo'},
                        {text: "Its ID", value: 'ItsID', applyFilter: true},
                        {text: "FullName", value: 'FullName', applyFilter: true},
                        {text: "Age", value: 'Age', applyFilter: true},
                        {text: "Rank", value: 'Rank', applyFilter: true},
                        {text: "Team", value: 'Hierarchy', applyFilter: true},
                        {text: "Jamiyat", value: 'Jamiat', applyFilter: true},
                        {text: "Years In BGI", value: 'YearsInBGI', applyFilter: true},
                        {text: "Actions", value: 'actions'},
                    ],
                    data: [],
                    filters: {},
                    noOfRows: this.tableRows,
                    totalPages: 1,
                    pageNo: 1,
                    selected: [],
                    menu: {
                        title: '',
                        items: [
                            { icon: 'fa-trash', title: 'Delete', onClick: this.deleteMember },
                        ]
                    },
                },
            };
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    SharafID: 1,
                    PageNumber: this.tableData.pageNo,
                    NoOfRows: this.tableData.noOfRows,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                }

                await axios.post(`Sharaf/GetData`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            this.tableData.data = data.Data.Items;
                            this.tableData.totalPages = data.Data.TotalPages;
                        }
                        else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            pageChanged(pageNo){
                this.tableData.pageNo = pageNo;
                this.getData();
            },
            filterChanged(){
                this.getData();
            },
            async deleteMember(item){
                const isDelete = await this.$confirmDialog.show({
                    title: 'Remove Member From List',
                    message: `Are you sure you want to remove ${item.FullName}? Please confirm`,
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                this.$loadingDialog.show();

                const formData = {
                    SharafID: item.FKSharafID,
                    ItsID: item.ItsID,
                };

                const result = await axios.post('Sharaf/DeleteMember', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({ message: data.Message, color: status ? 'success' : 'error' });

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result) {
                    await this.getData();
                }
            },
            async exportToExcel(){
                this.$loadingDialog.show();

                let status = await axios.post("Sharaf/GetData_Excel", { SharafID: 1 })
                    .then(async response => {
                        const data = response.data;
                        let status = data.Status;

                        if(status) {
                            let fileUrl = data.Data;
                            window.open(fileUrl);
                        }
                        else
                            this.$snackBar.error(data.Message);

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                return status;
            },
        },
        async mounted(){
            this.tableData.noOfRows = this.tableRows;
            await this.getData();
        },
    }
</script>

<style scoped>
</style>