<template>
    <v-snackbar v-model="display" :top="true" :color="color" :timeout="timeout">
      {{ message }}
        <v-btn v-if="button.length > 0" @click="btnClick" small color="primary" class="ml-10">{{ button }}</v-btn>
    </v-snackbar>
</template>

<script>
    import snackBar from './snackBar.js';

    export default {
      name: "snackBar",
      data() {
        return {
            display: false,
            color: 'success',
            timeout: 2000,
            message: '',
            button: "",
            btnClick: null,
        };
      },
      methods: {
        show(params) {
          // making modal visible
          this.display = true;
          // setting title and text
          this.message = params.message;

            // eslint-disable-next-line no-prototype-builtins
          if(params.hasOwnProperty('color'))
            this.color = params.color;

            // eslint-disable-next-line no-prototype-builtins
          if(params.hasOwnProperty('timeout'))
            this.timeout = params.timeout;

          if('button' in params) {
              this.button = params.button;
              this.btnClick = params.btnClick;
          }
          else
              this.button = '';
        },
      },
      beforeMount() {
        // here we need to listen for emited events
        // we declared those events inside our plugin
        snackBar.EventBus.$on('show', (params) => {
          this.show(params);
        })
      }
    }
</script>

<style scoped>

</style>
