<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h2 class="headline">
                    <template v-if="action == 'New'">
                        {{action}} Member
                    </template>
                    <template v-else>
                        Members Profile <!--{{ member.FullName }} | {{ member.ItsID }} -->
                    </template>
                </h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-chip left v-if="member.FKStatusID != 1" label
                            class="float-left mt-3 ml-3"
                            :color="(member.FKStatusID == 1 ? 'green' : 'amber') + ' darken-2 white--text'">
                        <b>Membership Status: {{ member.Status }}</b>
                    </v-chip>

                    <v-tabs right>
                        <v-tab>Profile</v-tab>
                        <v-tab v-if="member.FKStatusID == 1">Attendance</v-tab>

                        <v-tab-item>
                            <members-add-edit :member="member" />
                        </v-tab-item>
                        <v-tab-item v-if="action == 'Edit'">
                            <members-history :its="its" />
                        </v-tab-item>
                    </v-tabs>

                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from "axios";
    import moment from "moment";
    import membersAddEdit from "./membersAddEdit";
    import membersHistory from "./membersHistory";

    export default {
        name: "membersProfile",
        components: {
            membersAddEdit,
            membersHistory
        },
        computed: {
            action() {
                return this.$route.params.action == "new" ? "New" : "Edit";
            },
        },
        data(){
            return {
                its: "",
                member: {
                    ItsID: 0,
                    FKHierarchyID: 0,
                    FKRankID: 0,
                    Rank: "Member",
                    FKStatusID: 2,
                    Status: "Waiting",
                    Jamaat: "",
                    FullName: "",
                    Email: "",
                    MobileNo: "",
                    Personal: {
                        FirstNamePrefix: "",
                        FirstName: "",
                        FathersNamePrefix: "",
                        FathersName: "",
                        Surname: "",
                        HomeAddress: "",
                        Age: 0,
                        DateOfBirth: "",
                        MaritalStatus: "",
                        Height: 0,
                        Weight: 0,
                        BloodGroup: "",
                        IsDiabetic: "",
                        IsBloodPressure: "",
                        OtherHealthIssue: "",
                    },
                    Education: {
                        EducationLevel: "",
                        Degree: "",
                        DeeniTaalim: "",
                        QuranHifz: "",
                        Occupation: "",
                        OccupationField: "",
                        OccupationDetails: "",
                        LanguagesSpoken: "",
                    },
                    Idara: {
                        YearsInBGI: "",
                        JoiningDate: "",
                        OtherIdara: "",
                        IsAttendInternationalMiqaat: "",
                        LocalKhidmatTime: "",
                        LeadTaskZone: "",
                        ResponsibilityZone: "",
                        PreviousAchievements: "",
                        JoiningReason: "",
                        InterestArea: "",
                        IsFamilyJoin: true,
                    },
                    Titles: [],
                    Groups: [],
                },
            };
        },
        methods: {
            async getData() {
                this.$loadingDialog.show();

                const formData = {
                    HierarchyID: 0,
                    ItsID: this.its,
                    PageNumber: 1,
                    NoOfRows: 1,
                    HasFilter: false,
                    Filters: {},
                }

                await axios.post('Members/GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.member = data.Data.Items[0];
                        this.member.Personal.DateOfBirth = moment(this.member.Personal.DateOfBirth).format("YYYY-MM-DD");
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
        },
        async mounted(){
            if(this.action == "Edit")
            {
                this.its = this.decryptForEdit(this.$route.query.its);
                await this.getData();
            }
        }
    }
</script>

<style scoped>

</style>