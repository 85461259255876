<template>
    <v-menu left offset-y>
        <template v-slot:activator="{on, attrs}">
            <v-btn
                    v-bind="attrs"
                    v-on="on"
                    text
                    small>
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>
        <v-list dense>
            <v-list-item dense v-for="item in menuItems" :key="item.key" @click="item.onClick(dataItem)">
                <v-list-item-icon class="mr-2" v-if="item.icon"><v-icon small>{{ item.icon }}</v-icon></v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    export default {
        name: "contextMenu",
        props: ['dataItem', 'menuItems'],

    }
</script>

<style scoped>

</style>