<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-btn color="red darken-4 white--text" class="py-4" small
                       :disabled="selectedMembers.length == 0" @click="deleteMembers">
                    Delete Selected
                </v-btn>
            </v-col>
            <v-col align="end">
<!--                <v-btn color="primary" class="py-4 mr-2" small>Export To Excel</v-btn>-->
                <v-btn color="primary" small class="py-4"
                       @click="updateSelected"
                       :disabled="selectedMembers.length == 0" >
                    Mark As Active
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <data-table ref="tblMembers"
                            :table-data="tableData"
                            v-model="tableData.selected"
                            item-key="ItsID"
                            @pageChanged="pageChanged"
                            @filterChanged="filterChanged"
                            :total-pages="tableData.totalPages"
                            show-select>
                    <template v-slot:item.FullName="{item}">
                        {{ item.FullName }} <br/>
                        {{ item.FullName_Arabic }}
                    </template>
                    <template v-slot:item.FKRankID="{item}">
                        <combo-box background-color="white"
                                   v-model="item.FKRankID" :items="ranks"
                                   item-text="Description" item-value="Value01"
                                   class="my-1"
                                   @change="updateField(item.ItsID, 'FKRankID', $event)" />
                    </template>
                    <template v-slot:item.IsIncharge="{item}">
                        <v-simple-checkbox v-model="item.IsIncharge"
                                           @input="updateField(item.ItsID, 'IsIncharge', $event)"
                                           color="primary" />
                    </template>
                    <template v-slot:item.actions="{item}">
                        <v-btn icon @click="deleteMember(item.ItsID)" color="red darken-2" small><v-icon small>fa-trash</v-icon></v-btn>
                    </template>
                </data-table>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "New Members Enrollment",
        props: ['team'],
        data(){
            return {
                ranks: [],
                tableData: {
                    header: [
                        {text: "S.No", value: 'SNo'},
                        // {text: "Actions", value: 'actions'},
                        {text: "ITS", value: 'ItsID', applyFilter: true},
                        {text: "Full Name", value: 'FullName', applyFilter: true},
                        {text: "Jamaat", value: 'Jamaat', applyFilter: true},
                        {text: "Age", value: 'Age'},
                        {text: "Rank", value: 'FKRankID', width: '200' },
                        {text: "Incharge", value: 'IsIncharge' },
                        // {text: "Email", value: 'Email', applyFilter: true},
                        {text: "Mobile", value: 'MobileNo', applyFilter: true},
                        {text: "Status", value: 'Status', applyFilter: true},
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                    totalPages: 0,
                    totalRecords: 0,
                    noOfRows: this.tableRows,
                    pageNo: 1,
                },
            };
        },
        computed: {
            selectedMembers(){
                let members = [];
                this.tableData.selected.forEach(item => members.push(item.ItsID));
                return members;
            },
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    HierarchyID: this.team.HierarchyID,
                    PageNumber: this.tableData.pageNo,
                    NoOfRows: this.tableData.noOfRows,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                }

                await axios.post('Recruitment/GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.tableData.totalPages = data.Data.TotalPages;
                        this.tableData.data = data.Data.Items;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            pageChanged(pageNo) {
                this.tableData.pageNo = pageNo;
                this.getData();
            },
            filterChanged(){
                this.getData();
            },
            async updateField(itsID, field, value){
                // this.$loadingDialog.show();

                let formData = {
                    ItsID: itsID,
                    HierarchyID: this.team.HierarchyID,
                    Field: field,
                    Value: value,
                };

                let result = await axios.post('Recruitment/Update', formData)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result) {
                    await this.getData();
                }

                // this.$loadingDialog.hide();
            },
            async updateSelected(val){
                const result = await this.$confirmDialog.show({
                    title: 'Activate Members',
                    message: 'Are you sure you want to update selected members to status: Active? Please confirm.',
                });

                if(result) {
                    this.selectedMembers.forEach(item => this.updateField(item, 'FKStatusID', 1));
                    this.tableData.selected = [];
                }

            },
            async deleteMembers(){
                const result = await this.$confirmDialog.show({
                    title: 'Delete Members',
                    message: 'Are you sure you want to permanently delete selected member(s)? This action cannot be undone, please confirm.',
                    isCritical: true,
                });

                if(result) {
                    const formData = {
                        ItsIDs: this.selectedMembers.join(','),
                        Reason: 'Deleted From Enrollment',
                    };

                    let status = await axios.post('Members/Delete', formData)
                        .then(async response => {
                            const data = response.data;
                            const status = data.Status;

                            this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                            return status;
                        })
                        .catch(error => {
                            console.log(error);
                            this.$snackBar.error('An unexpected error has occurred');
                        });

                    if(status) {
                        await this.getData();
                    }
                }
            },
        },
        async mounted() {
            this.tableData.noOfRows = this.tableRows;

            this.$loadingDialog.show();

            this.ranks = await this.getGeneral(2, 0);

            this.$loadingDialog.hide();

            await this.getData();
        }
    }
</script>

<style scoped>

</style>