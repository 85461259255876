<template>
    <v-container fluid>
        <v-overlay v-if="isLoading">
            <v-progress-circular color="white" indeterminate size="53"
                                 class="mt-2 ml-3" />
        </v-overlay>

        <template>
            <v-row justify="center" align="center" v-if="requests.length == 0">
                <p class="py-5">No Data Available</p>
            </v-row>
            <v-row v-else>
                <v-col md="3" cols="12" v-for="request in requests" :key="request.ItsID">
                    <v-card outlined>
                        <v-card-text>
                            <v-img :src="request.PictureInITS" style="max-width: 100px; margin: auto; border: 1px Solid #666; border-radius: 4px;" />
                            <h4 class="mt-1">{{ request.ItsID }} - {{ request.FullName }} - {{ request.Rank }}</h4>
                            <p class="ma-0 mt-1 body-2"><b>Age:</b> {{ request.Age }}</p>
                            <p class="ma-0 mt-1 body-2"><b>Mobile No:</b> {{ request.MobileNo }}</p>
                            <p class="ma-0 mt-1 body-2"><b>Team:</b> {{ request.Hierarchy }}</p>
                            <p class="ma-0 mt-1 body-2"><b>Jamaat:</b> {{ request.Jamaat }}</p>
                            <v-row class="mt-2">
                                <v-col cols="12">
                                    <v-chip v-if="request.IsFormSubmitted" label outlined color="primary" style="width:100%;">
                                        <v-icon left small>fa-check-circle</v-icon>
                                        Form Submitted
                                    </v-chip>
                                    <v-btn color="primary" small class="py-4" block
                                           v-else
                                           @click="viewForm(request)">
                                        Submit Form
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        name: "enrollmentView",
        props: ['jamaat'],
        data(){
            return {
                isLoading: false,
                requests: [],
            };
        },
        methods: {
            async getData() {
                this.isLoading = true;

                const formData = {
                    JamaatID: this.jamaat.JamaatID,
                };

                await axios.post('Amalat/GetPendingMembers', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.requests = data.Data;

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.isLoading = false;
            },
            viewForm(member){
                this.$emit('viewForm', member);
            },
        },
        async mounted(){
            await this.getData();
        },
    }
</script>

<style scoped>

</style>