<template>
    <v-container fluid>
        <v-row>
            <v-col><h2>Duties Management</h2></v-col>
            <v-col class="d-flex">
                <combo-box label="Event"
                           v-model="event"
                           :items="events"
                           item-value="MiqaatHierarchyID"
                           item-text="Name"
                           @change="eventChanged"
                           return-object />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="d-flex">
                <combo-box label="Duty"
                           v-model="duty"
                           :items="duties"
                           item-value="MiqaatHierarchyID"
                           item-text="Name"
                           @change="dutyChanged"
                           return-object />
                <v-btn color="primary" @click="addDuty" class="ml-2 py-4" small :disabled="event.MiqaatHierarchyID == undefined">New Duty</v-btn>
            </v-col>
            <v-col cols="3" :disabled="duty.FKMiqaatHierarchyID == undefined">
                <v-card outlined style="height: 100%;">
                    <v-card-subtitle>Duty Info</v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <text-box label="Title" v-model="duty.Name" readonly />
                                <text-box label="Incharge" v-model="duty.Incharge" class="mt-2" readonly />
                                <text-box label="Duty For" v-model="duty.DutyFor" class="mt-2" readonly />
                                <text-box label="Reporting Time" v-model="duty.ReportingStart" class="mt-2" readonly />
                                <v-btn color="primary" block class="mt-2" @click="editDuty" v-if="duty.FKMiqaatHierarchyID != undefined && duty.FKMiqaatHierarchyID != 0">Edit</v-btn>
                                <v-btn color="primary" block class="mt-2" @click="sendNotification" v-if="duty.FKMiqaatHierarchyID != undefined && duty.FKMiqaatHierarchyID != 0">Send Notification</v-btn>
                                <v-btn color="primary" block class="mt-2" @click="viewQR" v-if="duty.FKMiqaatHierarchyID != undefined && duty.FKMiqaatHierarchyID != 0">Print QR Code</v-btn>
                                <v-btn color="red darken-4" block class="mt-2 white--text" @click="deleteDuty" v-if="duty.FKMiqaatHierarchyID != undefined && duty.FKMiqaatHierarchyID != 0">Delete</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card outlined style="height: 100%;">
                    <v-card-subtitle>Duty Officers</v-card-subtitle>
                    <v-card-text>
                        <v-form @submit.prevent="saveMember">
                            <v-row>
                                <v-col>
                                    <combo-box label="Officer"
                                               v-model="groupMember.FKItsID"
                                               :loading="members.loading"
                                               :search-input.sync="members.search"
                                               :items="members.items"
                                               item-text="FullName_ITS"
                                               item-value="ItsID"
                                               hide-no-data
                                               cache-items />

                                    <combo-box label="Role"
                                               v-model="groupMember.FKRoleID"
                                               :items="roles" item-text="Description"
                                               item-value="Value01"
                                               class="mt-2" />

                                    <v-btn type="submit"
                                           color="primary"
                                           class="py-4 mt-2" block small>Add</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>

                        <v-list dense v-if="groupMembers.length > 0" class="mt-2">
                            <v-list-item v-for="item in groupMembers" :key="item.FKItsID" dense>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.FullName }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.Role }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action><v-btn icon small @click="deleteMember(item)"><v-icon small>fa-trash</v-icon></v-btn></v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <h4 v-else class="mt-2">No Officers Assigned</h4>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card outlined>
                    <v-card-subtitle>
                        <div class="d-flex justify-space-between">
                            <p>Duty Allocation</p>
                            <p>Total Members Assigned: {{ totalAllocatedMembers }}</p>
                        </div>
                        <text-box label="Search" v-model="groupSearch" />
                    </v-card-subtitle>
                    <v-card-text style="height: 380px !important; overflow-y: auto;">
                        <v-list dense class="mt-2">
                            <v-list-item v-for="item in filteredGroups" :key="item.GroupID" dense>
                                <v-list-item-action>
                                    <v-checkbox v-model="item.IsAllocated" hide-details dense @change="saveGroup(item, true)" />
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.Name }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.TotalMembers }} Member(s)</v-list-item-subtitle>
                                    <v-list-item-subtitle>{{ item.Allocated }} Allocated Member(s)</v-list-item-subtitle>
                                    <v-list-item-subtitle>{{ item.TotalMembers - item.Allocated }} Balance Member(s)</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <text-box v-model="item.Allocation" style="width: 75px;" type="number" @change="saveGroup(item, false)" />
                                </v-list-item-action>
                                <v-list-item-action>
                                    <v-btn icon small><v-icon>fa-info-circle</v-icon></v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgDuty" :title="dutyTitle" width="600" :on-confirm="saveDuty">
            <template v-slot:content>
                <v-form @submit.prevent="saveDuty">
                    <v-row>
                        <v-col>
                            <text-box label="Event Name" :value="duty.Event" readonly="" />
                            <text-box label="Duty Name" v-model="duty.Name" ref="txtDutyName" class="mt-3" />
                            <text-box label="Job Description" v-model="duty.JobDescription" multi-row rows="2" class="mt-3" />
                            <text-box label="Requirements" v-model="duty.Requirements" multi-row rows="2" class="mt-3" />
                            <text-box label="Reporting Date" v-model="duty.ReportingDate" type="date" class="mt-3" />
                            <text-box label="Reporting Start" v-model="duty.ReportingStartTime" type="time" class="mt-3" />
                            <text-box label="Reporting End" v-model="duty.ReportingEndTime" type="time" class="mt-3" />
<!--                            <time-picker label="Reporting Start" v-model="duty.ReportingStartTime" class="mt-3" />-->
<!--                            <time-picker label="Reporting End" v-model="duty.ReportingEndTime" class="mt-3" />-->
                            <combo-box label="Duty For" v-model="duty.DutyFor" :items="['All', 'Gents', 'Ladies']" class="mt-3" />
                            <text-box label="Remarks" v-model="duty.Remarks" rows="3" multi-row class="mt-3" />
                            <v-btn type="submit" style="display: none;"></v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </simple-dialog>

        <simple-dialog ref="dlgQrCode" width="700" title="QR Code"
                       :hide-confirm-button="true" cancel-button-text="Close">
            <template v-slot:content>
                <v-flex xs12 v-show="qrCode.show">
                    <v-btn block class="primary mb-4" @click="printQR">Download QR Code</v-btn>
                    <div id="printSection">
<!--                        <h1 class="text-center">Burhani Guards Idara</h1>-->
                        <table style="width: 500px; margin: 25px auto;">
                            <tr>
                                <td style="width: 150px;" rowspan="3">
                                    <v-img src="@/assets/BGIlogo.png" contain style="width: 150px; height:110px;" />
                                </td>
                                <td>
                                    <h1 style="text-align: center;">Burhani Guards Idara</h1>
                                </td>
                            </tr>
                            <tr style="padding: 10px 0;">
                                <td>
                                    <h2 style="text-align: center;">{{ event.Name }} - {{ qrCode.Name }}</h2>
                                </td>
                            </tr>
                            <tr style="padding: 10px 0;">
                                <td>
                                    <h2 style="text-align: center;">{{ qrCode.ReportingTime }}</h2>
                                </td>
                            </tr>
                        </table>
                        <div class="mt-10">
                            <div style="width: 500px; margin: auto;">
                                <qrcode-vue :value="qrCode.Value" size="500" level="H" render-as="svg" />
                            </div>
                        </div>
                    </div>
                </v-flex>
            </template>
        </simple-dialog>

    </v-container>
</template>

<script>
    import axios from "axios";
    import moment from "moment";
    import QrcodeVue from 'qrcode.vue'
    import html2pdf from 'html2pdf.js';

    export default {
        name: "duties",
        props: ['miqaatId', 'miqaatHierarchyId'],
        components: {
            QrcodeVue
        },
        computed: {
            dutyTitle(){
                return `${this.duty.FKMiqaatHierarchyID == 0 ? 'New' : 'Edit'} Duty`;
            },
            eventOfficersTitle(){
                return `${this.event.Name} - ${this.duty.Name} - ${this.groupMember.HierarchyType} Officers`;
            },
            groupsTitle(){
                return `${this.event.Name} - ${this.duty.Name} - Assigned Groups`;
            },
            filteredGroups(){
                let filteredData = [];

                if(this.groupSearch.length == 0)
                    filteredData = this.groups;
                else {
                    filteredData = this.groups.filter(item => item.Name.toUpperCase().includes(this.groupSearch.toUpperCase()));
                }

                return filteredData;
            },
            totalAllocatedMembers(){
                return this.getTotal(this.groups, 'Allocated');
            }
        },
        data(){
            return {
                events: [],
                duties: [],
                roles: [],
                groupMembers: [],
                groups: [],
                event: {},
                duty: {},
                members: {
                    loading: false,
                    items: [],
                    search: '',
                },
                groupMember: {
                    FKMiqaatHierarchyID: 0,
                    FKItsID: 0,
                    FKRoleID: 0,
                },
                qrCode: {
                    show: false,
                },
                groupSearch: "",
            };
        },
        methods: {
            async getData() {
                this.$loadingDialog.show();

                const formData = {
                    ParentID: this.event.MiqaatHierarchyID,
                    PageNumber: 1,
                    NoOfRows: 999,
                    HasFilter: false,
                    Filters: {},
                }

                await axios.post(`Miqaat/Duties_GetData`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if (status) {
                            this.duties = data.Data.Items;
                        } else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async getMembersData(){
                const formData = {
                    MiqaatHierarchyID: this.duty.FKMiqaatHierarchyID,
                    PageNumber: 1,
                    NoOfRows: 9999,
                    HasFilter: false,
                    Filters: {},
                }

                return await axios.post(`Miqaat/GroupMembers_GetData`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            return data.Data.Items;
                        else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                        return [];
                    });
            },
            async getGroups() {
                const formData = {
                    MiqaatID: this.miqaatHierarchyId,
                    DutyID: this.duty.FKMiqaatHierarchyID,
                }

                return await axios.post(`Miqaat/Duties_GetGroups`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if (status) {
                            return data.Data;
                        } else
                            this.$snackBar.error(data.Message);

                        return [];
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                        return [];
                    });
            },
            async eventChanged() {
                this.$loadingDialog.show();
                await this.getData();
                this.$loadingDialog.hide();

                if(this.duties.length > 0) {
                    this.duty = this.duties[0];
                    await this.dutyChanged();
                }
                else {
                    this.duty = {};
                    this.groupMembers = [];
                    this.groups = [];
                }
            },
            async dutyChanged() {
                this.$loadingDialog.show();
                this.groups = await this.getGroups();
                this.groupMembers = await this.getMembersData();
                this.$loadingDialog.hide();
            },
            addDuty(){
                this.duty = {
                    Option: 1,
                    Event: this.event.Name,
                    EventID: this.event.MiqaatHierarchyID,
                    FKMiqaatHierarchyID: 0,
                    Name: '',
                    JobDescription: '',
                    Requirements: '',
                    ReportingDate: '',
                    ReportingStart: '',
                    ReportingEnd: '',
                    DutyFor: '',
                    Remarks: '',
                };

                this.$refs.dlgDuty.show();
                this.$refs.txtDutyName.focus();
            },
            editDuty(){
                this.duty.Option = 2;
                this.duty.ReportingDate = new moment(this.duty.ReportingStart).format("YYYY-MM-DD");
                this.duty.ReportingStartTime = new moment(this.duty.ReportingStart).format("HH:mm");
                this.duty.ReportingEndTime = new moment(this.duty.ReportingEnd).format("HH:mm");

                this.$refs.dlgDuty.show();
                this.$refs.txtDutyName.focus();
            },
            async saveDuty(){
                this.$loadingDialog.show();

                this.duty.ReportingStart = new moment(this.duty.ReportingDate + " " + this.duty.ReportingStartTime).format('DD-MMM-YYYY HH:mm');
                this.duty.ReportingEnd = new moment(this.duty.ReportingDate + " " + this.duty.ReportingEndTime).format('DD-MMM-YYYY HH:mm');

                const result = await axios.post('Miqaat/Duties_AddEdit', this.duty)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return {Status: status, DutyID: data.Data};
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result.Status)
                {
                    this.$refs.dlgDuty.hide();
                    await this.getData();
                    this.duty = this.duties.filter(duty => duty.FKMiqaatHierarchyID == result.DutyID)[0];
                    await this.dutyChanged();
                }

                this.$loadingDialog.hide();
            },
            async deleteDuty(){
                const item = this.duty;

                const isDelete = await this.$confirmDialog.show({
                    title: 'Delete Duty',
                    message: `Are you sure you want to delete duty "${item.Name}"? Please confirm`,
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                this.$loadingDialog.show();

                const formData = {
                    FKMiqaatHierarchyID: item.FKMiqaatHierarchyID,
                };

                const result = await axios.post('Miqaat/Duties_Delete', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                {
                    await this.getData();
                }

                this.$loadingDialog.hide();
            },
            async loadMembers(val){
                this.members.loading = true;
                this.members.items = await this.getMiqaatMembers(this.miqaatHierarchyId, {"FullName": val});
                this.members.loading = false;
            },
            async saveMember(){
                this.groupMember.FKMiqaatHierarchyID = this.duty.FKMiqaatHierarchyID;

                if(this.duty.FKMiqaatHierarchyID == 0)
                {
                    this.$snackBar.error("Please select a duty");
                    return;
                }
                if(this.groupMember.FKItsID == 0)
                {
                    this.$snackBar.error("Please select a member");
                    return;
                }
                if(this.groupMember.FKRoleID == 0)
                {
                    this.$snackBar.error("Please select a role");
                    return;
                }

                this.$loadingDialog.show();

                const result = await axios.post('Miqaat/GroupMembers_AddEdit', this.groupMember)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                {
                    this.groupMember.FKItsID = 0;
                    this.groupMember.FKRoleID = 0;
                    this.groupMembers = await this.getMembersData();
                }

                this.$loadingDialog.hide();
            },
            async deleteMember(item){
                const isDelete = await this.$confirmDialog.show({
                    title: 'Remove Officer',
                    message: `Are you sure you want to remove ${item.FullName} as ${item.Role} from this duty? Please confirm`,
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                this.$loadingDialog.show();

                const formData = {
                    FKMiqaatHierarchyID: this.duty.FKMiqaatHierarchyID,
                    FKItsID: item.FKItsID,
                };

                const result = await axios.post('Miqaat/GroupMembers_Delete', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                        return false;
                    });

                if(result)
                    this.groupMembers = await this.getMembersData();

                this.$loadingDialog.hide();
            },
            async saveGroup(item, isCheckbox){
                if(this.duty.FKMiqaatHierarchyID == 0)
                {
                    this.$snackBar.error("Please select a duty");
                    return;
                }

                if(item.IsAllocated && item.Allocation == 0)
                    item.Allocation = item.TotalMembers - item.Allocated;

                if(item.Allocation > 0 && !item.IsAllocated && !isCheckbox)
                    item.IsAllocated = true;

                const formData = {
                    MiqaatID: this.miqaatHierarchyId,
                    DutyID: this.duty.FKMiqaatHierarchyID,
                    FKGroupID: item.GroupID,
                    IsAllocated: item.IsAllocated,
                    Allocation: item.Allocation,
                };

                this.$loadingDialog.show();

                const result = await axios.post('Miqaat/Duties_AddGroup', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                    this.groups = await this.getGroups();

                this.$loadingDialog.hide();
            },
            async viewQR(){
                this.qrCode = {
                    show: true,
                    FKMiqaatHierarchyID: this.duty.FKMiqaatHierarchyID,
                    Name: this.duty.Name,
                    ReportingTime: this.duty.ReportingStart,
                };

                this.qrCode.Value = this.duty.FKMiqaatHierarchyID.toString();
                console.log(this.qrCode.Value);

                this.$refs.dlgQrCode.show();
            },
            printQR(){
                const element = document.getElementById('printSection');
                html2pdf().from(element).save();
            },
            async sendNotification(){
                const isSend = await this.$confirmDialog.show({
                    title: 'Send Duty Notification',
                    message: 'Are you sure you want to send the duty notification to selected teams? Please confirm.',
                });

                if(isSend){
                    this.$loadingDialog.show();

                    const formData = {
                        MiqaatID: this.miqaatHierarchyId,
                        DutyID: this.duty.FKMiqaatHierarchyID,
                    };

                    await axios.post('Miqaat/Duties_SendNotifications', formData)
                        .then(response => {
                            const data = response.data;
                            const status = data.Status;

                            this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                            return status;
                        })
                        .catch(error => {
                            console.log(error);
                            this.$snackBar.error('An unexpected error has occurred');
                        });

                    this.$loadingDialog.hide();
                }
            },
        },
        watch: {
            'members.search'(val) {
                val && val !== this.groupMember.FKItsID && this.loadMembers(val);
            },
        },
        async mounted(){
            this.$loadingDialog.show();
            this.events = await this.getMiqaatHierarchy(1, 2, this.miqaatHierarchyId, 0);
            this.roles = await this.getGeneral(8, 0);

            this.$loadingDialog.hide();

            if(this.events.length > 0) {
                this.event = this.events[0];
                await this.getData();
            }

            if(this.duties.length > 0) {
                this.duty = this.duties[0];

                this.$loadingDialog.show();
                this.groups = await this.getGroups();
                this.groupMembers = await this.getMembersData();
                this.$loadingDialog.hide();
            }
        }
    }
</script>

<style scoped>

</style>