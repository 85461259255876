<template>
    <v-container fluid>
        <v-row>
            <v-col offset="2" cols="8">
                <v-card>
                    <v-card-text>
                        <h2 class="headline text-center">{{ survey.SurveyTitle }}</h2>
                        <h4 class="text-center">{{ survey.SurveyDescription }}</h4>
                        <h4 class="text-center mt-2">From: {{ survey.StartDate }} To: {{ survey.EndDate }}</h4>
                        <v-divider class="my-4"></v-divider>
                        <div style="position:relative;" v-if="survey.HeaderImage.length > 0">
                            <v-img :src="survey.HeaderImage" max-height="250px" contain />
                            <v-btn color="red darken-4" icon style="position: absolute; right: 5px; top: 5px;"
                                   @click="removeHeaderImage">
                                <v-icon>fa-trash</v-icon>
                            </v-btn>
                        </div>
                        <div class="d-flex justify-center">
                            <v-btn color="primary" small class="py-4 mt-3"
                                   @click="$refs.imgUpload.click()">Upload Header Image</v-btn>
                        </div>
                        <input type="file" accept="image/jpeg" ref="imgUpload" @change="previewImage" hidden>
                    </v-card-text>
                </v-card>
                <draggable v-model="questions" @change="onSeqChanged">
                    <transition-group>
                        <template v-for="(question, i) in questions">
                            <v-card class="mt-3" :key="question.QuestionID">
                                <v-card-text>
                                    <v-row>
                                        <v-col class="d-flex justify-center" style="cursor: move !important;">
                                            <v-icon class="text-center">fa-ellipsis-h</v-icon>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <div style="position:relative;" v-if="question.Image.length > 0">
                                                <v-img :src="question.Image" max-height="150px" contain />
                                                <v-btn color="red darken-4" icon style="position: absolute; right: 5px; top: 5px;"
                                                       @click="removeAttachment(question, 1)">
                                                    <v-icon>fa-trash</v-icon>
                                                </v-btn>
                                            </div>
                                            <v-btn color="primary" small class="py-4 mb-3"
                                                   @click="$refs.q_imgUpload[i].click()">Upload Image</v-btn>

                                            <text-box label="Question" v-model="question.Question" />
                                            <text-box label="Description" v-model="question.Description" multi-row rows="2" class="mt-2" />

                                            <input type="file" accept="image/jpeg"
                                                   ref="q_imgUpload"
                                                   @change="questionImage(question, $event)" hidden>
                                            <input type="file" accept="*"
                                                   ref="q_attachmentUpload"
                                                   @change="questionAttachment(question, $event)" hidden>

                                            <template v-if="isShowOptions(question)">
                                                <span>Options:</span>
                                                <text-box v-for="option in question.Options"
                                                          :value="option" :key="option"
                                                          class="mt-2" append-outer-icon="fa-trash"
                                                          @click:append-outer="deleteOption(question, option)" />

                                                <text-box v-model="option" class="mt-2" label="New Option" ref="txtOption"
                                                          @keypress.enter="addOption(question, $event)" />
                                            </template>
                                        </v-col>
                                        <v-col cols="3">
                                            <combo-box label="Question Type" :items="questionTypes" v-model="question.QuestionType" />
                                        </v-col>
                                    </v-row>
                                    <v-divider class="my-4"></v-divider>
                                    <v-row>
                                        <v-col class="d-flex justify-end">
                                            <template v-if="question.Attachment.length > 0">
                                                <div class="d-flex align-center">
                                                    <h4>Att: <a :href="question.Attachment" target="_blank">
                                                        {{ question.AttachmentName }}</a>
                                                    </h4>
                                                    <v-btn icon color="red darken-3" class="ml-2"
                                                           @click="removeAttachment(question, 2)">
                                                        <v-icon>fa-trash</v-icon>
                                                    </v-btn>
                                                </div>
                                                <v-spacer></v-spacer>
                                            </template>
                                            <v-tooltip bottom v-else>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon color="blue darken-3" class="mr-2"
                                                           v-bind="attrs"
                                                           v-on="on"
                                                           @click="$refs.q_attachmentUpload[i].click()">
                                                        <v-icon>fa-paperclip</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Add Attachment (File)</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon color="blue darken-3" class="mr-2"
                                                           v-bind="attrs"
                                                           v-on="on"
                                                           @click="copyQuestion(question)">
                                                        <v-icon>fa-copy</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Copy Question</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon color="red darken-3"
                                                           v-bind="attrs"
                                                           v-on="on"
                                                           @click="deleteQuestion(question)">
                                                        <v-icon>fa-trash</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Delete Question</span>
                                            </v-tooltip>
                                            <v-divider vertical class="mx-4"></v-divider>
                                            <v-switch v-model="question.IsRequired" label="Required" class="mt-1" hide-details />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </template>
                    </transition-group>
                </draggable>
                <v-card class="mt-2" fixed>
                    <v-card-actions class="d-flex justify-end">
                        <v-btn color="primary" right @click="saveData">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn fixed color="primary"
                               fab
                               bottom
                               elevation="4"
                               v-bind="attrs"
                               v-on="on"
                               @click="addQuestion">
                            <v-icon large>fa-plus-circle</v-icon>
                        </v-btn>
                    </template>
                    <span>Add Question</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "Survey Form",
        data(){
            return {
                survey: {
                    HeaderImage: '',
                },
                surveyHeaderImage: {
                    name: '',
                    size: 0,
                    error: '',
                    file: null,
                },
                questionTypes: ['Text', 'Number', 'Description', 'Date', 'Dropdown', 'Multiselect', 'Checkbox', 'RadioGroup'],
                questions: [],
                question: {
                    FKSurveyID: this.surveyID,
                    QuestionID: 0,
                    Section: '',
                    Question: '',
                    Description: '',
                    QuestionType: '',
                    Options: [],
                    IsRequired: false,
                    Seq: 0,
                    AttachmentFile: null,
                    AttachmentName: '',
                    ImageFile: null,
                    Attachment: '',
                    Image: '',
                },
                option: '',
            };
        },
        computed: {
            surveyID(){
                return this.$route.params.id;
            }
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    SurveyID: this.surveyID,
                    PageNumber: 1,
                    NoOfRows: 1,
                    HasFilter: false,
                    Filters: {},
                }

                await axios.post(`Surveys/GetData`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            this.survey = data.Data.Items[0];
                            this.questions = this.survey.Questions.map(x => {
                                x.Options = x.Options.split(',');
                                return x;
                            });

                            if(this.questions.length == 0)
                                this.questions.push(this.question);
                        }
                        else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            previewImage(e){
                const file = e.target.files[0];
                let size = (file.size/1024).toFixed(2);

                if( size > 1000 )
                {
                    this.$snackBar.error("Picture size is exceeding the limit. Please select a smaller sized picture.");
                    return;
                }

                this.surveyHeaderImage.file = file;

                this.survey.HeaderImage = URL.createObjectURL(file);
                this.surveyHeaderImage.name = file.name;
                this.surveyHeaderImage.size = size;
                this.surveyHeaderImage.error = "";
            },
            questionImage(question, e){
                const file = e.target.files[0];
                let size = (file.size/1024).toFixed(2);

                if( size > 1000 )
                {
                    this.$snackBar.error("Picture size is exceeding the limit. Please select a smaller sized picture.");
                    return;
                }

                question.ImageFile = file;
                question.Image = URL.createObjectURL(file);
            },
            questionAttachment(question, e){
                const file = e.target.files[0];
                let size = (file.size/1024).toFixed(2);

                if( size > 10000 )
                {
                    this.$snackBar.error("File size is exceeding the limit (10 MB). Please select a smaller sized file.");
                    return;
                }

                question.AttachmentFile = file;
                question.AttachmentName = file.name;
                question.Attachment = URL.createObjectURL(file);
            },
            removeAttachment(question, option){
                if(option == 1) {
                    question.Image = "";
                    question.ImageFile = null;
                }
                else {
                    question.Attachment = "";
                    question.AttachmentFile = null;
                }
            },
            isShowOptions(question) {
                if(question.QuestionType == 'Dropdown' || question.QuestionType == 'Checkbox' ||
                    question.QuestionType == 'RadioGroup' || question.QuestionType == 'Multiselect')
                    return true;
                else
                    return false;
            },
            onSeqChanged(e){
                const ev = e.moved;
                const newSeq = ev.newIndex + 1;
                const oldSeq = ev.oldIndex + 1;

                if(oldSeq != newSeq) {
                    if(newSeq < oldSeq) {
                        this.questions.forEach(element => {
                            if(element.Seq >= newSeq && element.Seq < oldSeq)
                                element.Seq += 1;
                            else if(element.Seq == oldSeq)
                                element.Seq = newSeq;
                        });
                    }
                    else
                    {
                        this.questions.forEach(element => {
                            if(element.Seq == oldSeq)
                                element.Seq = newSeq
                            else if(element.Seq > oldSeq && element.Seq <= newSeq)
                                element.Seq -= 1;
                        });
                    }
                }

                console.log(this.questions);
            },
            addOption(question, event){
                let option = event.target.value;

                if(option.length > 0) {
                    question.Options.push(option);
                    this.option = "";
                }
            },
            deleteOption(question, option){
                let i = question.Options.indexOf(option);
                question.Options.splice(i, 1);
            },
            addQuestion(){
                let newQuestion = {
                    FKSurveyID: this.surveyID,
                    QuestionID: 0,
                    Section: '',
                    Question: '',
                    Description: '',
                    QuestionType: '',
                    Options: [],
                    IsRequired: false,
                    Seq: 0,
                    AttachmentFile: null,
                    AttachmentName: '',
                    ImageFile: null,
                    Attachment: '',
                    Image: '',
                };

                this.questions.push(newQuestion);
            },
            async deleteQuestion(question){
                const isDelete = await this.$confirmDialog.show(
                    {
                        title: 'Delete Question',
                        message: 'Are you sure you want to delete this question? Please confirm',
                        isCritical: true,
                    }
                );

                if(!isDelete)
                    return;

                let i = this.questions.indexOf(question);
                this.questions.splice(i, 1);
            },
            copyQuestion(question){
                let newQuestion = {
                    ...question
                };
                newQuestion.QuestionID = 0;

                this.questions.push(newQuestion);
            },
            async saveData(){
                this.$loadingDialog.show();

                let formData = new FormData();

                formData.append('SurveyID', this.surveyID);
                formData.append('HeaderImage', this.surveyHeaderImage.file);

                this.questions.forEach((item, i) => {
                    Object.keys(item).forEach(key => {
                        formData.append(`Questions[${i}].${key}`, key == 'Options' ? item['Options'].join() : item[key]);
                    });
                });

                await axios.post(`Surveys/Questions_AddEdit`, formData,
                    {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? "success" : "error", message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async removeHeaderImage(){
                const isDelete = await this.$confirmDialog.show({
                    title: 'Remove Header Image',
                    message: 'Are you sure you want to remove the header image? Please confirm.',
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                this.surveyHeaderImage.file = null;
                this.survey.HeaderImage = "";

                this.$loadingDialog.show();

                const result = await axios.post(`Surveys/UpdateHeaderImage`, {SurveyID: this.survey.SurveyID, ImagePath: ''})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? "success" : "error", message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                    await this.getData();

                this.$loadingDialog.hide();
            }
        },
        async mounted(){
            await this.getData();
        },
    }
</script>

<style scoped>

</style>