var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"py-4",attrs:{"color":"red darken-4 white--text","small":"","disabled":_vm.selectedMembers.length == 0},on:{"click":_vm.deleteMembers}},[_vm._v(" Delete Selected ")])],1),_c('v-col',{attrs:{"align":"end"}},[_c('v-btn',{staticClass:"py-4",attrs:{"color":"primary","small":"","disabled":_vm.selectedMembers.length == 0},on:{"click":_vm.updateSelected}},[_vm._v(" Mark As Active ")])],1)],1),_c('v-row',[_c('v-col',[_c('data-table',{ref:"tblMembers",attrs:{"table-data":_vm.tableData,"item-key":"ItsID","total-pages":_vm.tableData.totalPages,"show-select":""},on:{"pageChanged":_vm.pageChanged,"filterChanged":_vm.filterChanged},scopedSlots:_vm._u([{key:"item.FullName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.FullName)+" "),_c('br'),_vm._v(" "+_vm._s(item.FullName_Arabic)+" ")]}},{key:"item.FKRankID",fn:function(ref){
var item = ref.item;
return [_c('combo-box',{staticClass:"my-1",attrs:{"background-color":"white","items":_vm.ranks,"item-text":"Description","item-value":"Value01"},on:{"change":function($event){return _vm.updateField(item.ItsID, 'FKRankID', $event)}},model:{value:(item.FKRankID),callback:function ($$v) {_vm.$set(item, "FKRankID", $$v)},expression:"item.FKRankID"}})]}},{key:"item.IsIncharge",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"color":"primary"},on:{"input":function($event){return _vm.updateField(item.ItsID, 'IsIncharge', $event)}},model:{value:(item.IsIncharge),callback:function ($$v) {_vm.$set(item, "IsIncharge", $$v)},expression:"item.IsIncharge"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"red darken-2","small":""},on:{"click":function($event){return _vm.deleteMember(item.ItsID)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-trash")])],1)]}}]),model:{value:(_vm.tableData.selected),callback:function ($$v) {_vm.$set(_vm.tableData, "selected", $$v)},expression:"tableData.selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }