<template>
    <v-container fluid>
        <v-row>
            <v-col md="8" cols="12" class="offset-md-2">
                <v-card>
                    <v-card-text>
                        <h2 class="headline text-center">{{ form.FormTitle }}</h2>
                        <h4 class="text-center">{{ form.FormDescription }}</h4>
                        <template v-if="'Jamaat' in jamaat">
                            <v-divider class="my-3" />
                            <h3 class="text-center">{{ jamaat.Jamaat }} - {{ jamaat.Jamiat }}</h3>
                        </template>
                        <template v-if="memberProfile.ItsID != 0">
                            <v-divider class="my-3" />
                            <h3 class="text-center">{{ memberProfile.ItsID }} - {{ memberProfile.FullName }}</h3>
                            <h4 class="text-center"> {{ memberProfile.Jamaat }} - {{ memberProfile.Rank }}</h4>
                        </template>
                    </v-card-text>
                </v-card>
                <v-card class="mt-3" v-if="memberProfile.ItsID == 0">
                    <v-card-text>
                        <data-table :table-data="tableData" :total-pages="tableData.totalPages"
                                    @pageChanged="pageChanged" @filterChanged="filterChanged">
                            <template v-slot:item.Actions="{item}">
                                <v-btn color="primary" small @click="selectMember(item)">
                                    <v-icon small>fa-search</v-icon>
                                </v-btn>
                            </template>
                        </data-table>
                    </v-card-text>
                </v-card>
                <v-card class="mt-3" v-if="memberProfile.ItsID != 0">
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <template v-for="(question, i) in form.Questions">
                                    <div :key="question.QuestionID">
                                        <h4>{{ question.Question }}</h4>
                                        <p class="my-0">{{ question.Description }}</p>
                                        <template v-if="question.QuestionType == 'RadioGroup'">
                                            <v-radio-group hide-details row class="mt-2"
                                                           v-model="answer(question).Answer">
                                                <v-radio v-for="option in question.Options.split(',')"
                                                         :label="option"
                                                         :key="option"
                                                         :value="option"
                                                         color="primary" />
                                            </v-radio-group>
                                        </template>
                                        <template v-else-if="question.QuestionType == 'Text'">
                                            <text-box class="mt-2"
                                                      v-model="answer(question).Answer" />
                                        </template>
                                        <template v-else-if="question.QuestionType == 'Description'">
                                            <text-box multi-row rows="3" class="mt-2"
                                                      v-model="answer(question).Answer" />
                                        </template>
                                        <v-divider v-if="(i + 1 ) < form.Questions.length" class="my-4" />
                                    </div>
                                </template>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="mt-3" v-if="memberProfile.ItsID != 0">
                    <v-card-text>
                        <v-row>
                            <v-col align="end">
                                <v-btn text class="mr-2" @click="cancelForm">Cancel</v-btn>
                                <v-btn color="primary" @click="saveForm">Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "Enrollment Form",
        data(){
            return {
                jamaat: {},
                memberProfile: {
                    ItsID: 0,
                    FullName: '',
                    FullName_Arabic: '',
                    Rank: '',
                },
                formID: 2,
                form: {
                    FormTitle: '',
                    FormDescription: '',
                    Questions: [],
                },
                formDetail: [],
                tableData: {
                    header: [
                        { text: 'S.No', value: 'SNo', width: 40 },
                        { text: 'Actions', value: 'Actions' },
                        { text: 'ITS', value: 'ItsID', applyFilter: true },
                        { text: 'Name', value: 'FullName', applyFilter: true },
                        { text: 'Team', value: 'Hierarchy', applyFilter: true },
                        { text: 'Mobile No', value: 'MobileNo', applyFilter: true },
                        { text: 'Rank', value: 'Rank', applyFilter: true },
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                    noOfRows: this.tableRows,
                    totalRecords: 1,
                    totalPages: 0,
                    pageNo: 1,
                },
            };
        },
        computed: {
            isAmilForm(){
                return this.$route.name == 'recruitmentFormAmil';
            },
            user(){
                return this.$store.getters.user ?? {};
            },
        },
        methods: {
            async getMembers(){
                this.$loadingDialog.show();

                const formData = {
                    JamaatID: this.jamaat.JamaatID ?? 0,
                    HierarchyID: this.isAmilForm ? 0 : this.user.FKHierarchyID,
                    PageNumber: this.tableData.pageNo,
                    NoOfRows: this.tableData.noOfRows,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                }

                await axios.post('RecruitmentForm/GetMembers', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        console.log(data.Data);

                        this.tableData.totalPages = data.Data.TotalPages;
                        this.tableData.data = data.Data.Items;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async getForm(){
                this.$loadingDialog.show();

                const formData = {
                    FormID: this.formID,
                    PageNumber: 1,
                    NoOfRows: 1,
                    HasFilter: false,
                    Filters: {},
                    IsAmilSaheb: this.isAmilForm,
                }

                await axios.post('RecruitmentForm/GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.form = data.Data.Items[0];

                        this.form.Questions.forEach(item => {
                            this.formDetail.push({
                                FKQuestionID: item.QuestionID,
                                Answer: ''
                            });
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            pageChanged(pageNo) {
                this.tableData.pageNo = pageNo;
                this.getMembers();
            },
            filterChanged(){
                this.getMembers();
            },
            selectMember(member){
                this.memberProfile = {
                    ItsID: member.ItsID,
                    FullName: member.FullName,
                    Rank: member.Rank,
                    Jamaat: member.Jamaat,
                };
            },
            answer(question){
                let answer = this.formDetail.find(item => item.FKQuestionID == question.QuestionID);
                return answer;
            },
            async cancelForm(){
                const isCancel = await this.$confirmDialog.show({
                    title: 'Cancel Form',
                    message: 'Are you sure you want to cancel this form? All unsaved data will be lost.',
                    isCritical: true,
                });

                if(!isCancel)
                    return;

                this.formDetail.forEach(item => item.Answer = "");

                this.memberProfile = {
                    ItsID: 0,
                    FullName: '',
                    Rank: '',
                    Jamaat: '',
                };
            },
            async saveForm(){
                this.$loadingDialog.show();

                const formData = {
                    FormID: this.formID,
                    MemberItsID: this.memberProfile.ItsID,
                    Answers: this.formDetail,
                }

                let status = await axios.post('RecruitmentForm/UpdateAnswers', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(status) {
                    this.formDetail.forEach(item => item.Answer = "");

                    this.memberProfile = {
                        ItsID: 0,
                        FullName: '',
                        Rank: '',
                        Jamaat: '',
                    };
                }

                this.$loadingDialog.hide();
            }
        },
        async mounted(){
            this.tableData.noOfRows = this.tableRows;

            this.formID = this.isAmilForm ? 3 : 2;

            const query = this.$route.query.dt;

            if(this.isAmilForm) {
                if(query != undefined) {
                    const params = JSON.parse(window.atob(query));
                    this.jamaat = params;
                }
                else
                    await this.$router.push({path: '/'});
            }

            await this.getMembers();

            await this.getForm();
        },
    }
</script>

<style scoped>

</style>