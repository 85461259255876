<template>
    <v-row>
        <v-col md="8" cols="12" offset-md="2">
            <v-scroll-y-transition group leave-absolute hide-on-leave>
                <v-card v-if="isSubmitted" key="crd_Success">
                    <v-card-text class="text-center">
                        <v-icon style="font-size: 250pt;" color="primary">fa-check-circle</v-icon>
                        <h2>Feedback Has Been Submitted Successfully</h2>
                    </v-card-text>
                </v-card>
                <template v-else>
                    <v-card key="crd_SurveyTitle">
                        <v-card-text v-if="'SurveyTitle' in form">
                            <v-row>
                                <v-col>
                                    {{ form.SurveyTitle }}
                                    <v-divider class="my-3" />
                                    <div class="d-flex align-center">
                                        <v-img style="max-height: 120px; min-height: 60px; max-width: 100px; border: 1px Solid #ccc; border-radius: 4px;"
                                               :src="person.PictureInITS" />
                                        <div class="ml-3">
                                            <h3>{{ person.FullName }}</h3>
                                            <h5>{{ person.Rank }}</h5>
                                            <h5>{{ person.Zone }} | {{ person.Hierarchy }}</h5>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card class="mt-3" v-if="questions.length > 0" key="crd_SurveyQuestions">
                        <v-card-text>
                            <div class="d-flex align-center" key="d_pageNo">
                                <v-progress-linear :value="progress"
                                                   rounded
                                                   height="10px"
                                                   style="width:150px;"
                                                   :color="progress >= 75 ? 'green' : progress <= 25 ? 'orange' : 'yellow'"/>
                                <v-spacer />
                                <h4>
                                    Question &nbsp;
                                    <v-fade-transition leave-absolute>
                                            <span key="lbl_pageNo">
                                                {{ questionID + 1 }}
                                            </span>
                                    </v-fade-transition>
                                    &nbsp; of {{ questions.length }}
                                </h4>
                            </div>
                            <v-divider class="my-3" key="dv_1" />
                            <v-scroll-x-transition group leave-absolute>
                                <div v-for="(question, i) in questions" :key="`qd_${question.QuestionID}`"
                                     v-if="questionID == i">
                                    <h3>{{ question.Question }}</h3>
                                    <v-radio-group @change="answer(question, $event)"
                                                   :value="currentAnswer != null ? currentAnswer.OptionID : 0">
                                        <v-radio v-for="option in question.Options"
                                                 :key="option.OptionID"
                                                 :label="option.Answer"
                                                 :value="option.OptionID" />
                                    </v-radio-group>

                                    <template v-if="currentAnswer != null">
                                        <div v-if="(currentAnswer.Answer == 'Once' || currentAnswer.Answer == 'More Than Once') && ('SubQuestions' in question)"
                                             :key="`sq_${i}`">
                                            <h3>{{ question.SubQuestions[0].Question }}</h3>
                                            <text-box label="Please provide the number of times"
                                                      v-if="currentAnswer.Answer == 'More Than Once'"
                                                      :value="currentAnswer.SubQuestion.Answers.length"
                                                      @change="updateNoOfTimes(question, $event)"
                                                      class="mt-3" style="width:250px;" />
                                            <v-scroll-y-transition leave-absolute hide-on-leave>
                                                <div class="d-flex flex-column mt-3">
                                                    <div :class="i > 0 ? 'd-flex mt-3' : 'd-flex'" v-for="(sqAnswer, i) in currentAnswer.SubQuestion.Answers"
                                                         :key="`sqa_${i}`">
                                                        <text-box label="Location" v-model="sqAnswer.Location" />
                                                        <combo-box label="Month" v-model="sqAnswer.Month"
                                                                   :items="months" class="ml-3" />
                                                        <text-box label="Year" v-model="sqAnswer.Year" class="ml-3" type="number" />
                                                    </div>
                                                </div>
                                            </v-scroll-y-transition>
                                        </div>
                                    </template>
                                </div>
                            </v-scroll-x-transition>
                        </v-card-text>
                        <v-divider />
                        <v-card-actions>
                            <v-spacer />
                            <v-btn text @click="moveBack" v-if="questionID != 0">Back</v-btn>
                            <v-btn color="primary"
                                   @click="showNextQuestion" :disabled="currentAnswer == null">
                                {{ this.questionID < this.questions.length - 1 ? "Save & Next" : "Submit Feedback" }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-scroll-y-transition>
        </v-col>
    </v-row>
</template>

<script>
    import axios from "axios";

    export default {
        name: "formView",
        props: [ 'surveyID', 'person' ],
        data() {
            return {
                // surveyID: 1,
                form: {},
                questions: [],
                questionID: 0,
                isSubmitted: false,
                answers: [],
                subQuestionAnswers: [],
                subQuestionCount: 0,
                months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            };
        },
        computed: {
            progress(){
                return ((this.questionID + 1) / this.questions.length) * 100;
            },
            currentAnswer(){
                const question = this.questions[this.questionID];
                const item = this.answers.find(x => x.QuestionID == question.QuestionID);
                return item !== undefined ? item : null;
            }
        },
        methods: {
            async getForm(){
                this.$loadingDialog.show();

                await axios.post('Assessment/GetForm', {SurveyID: this.surveyID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.form = data.Data[0];
                        this.questions = data.Data;

                        // console.log(this.questions);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async saveForm(formData){
                this.$loadingDialog.show();

                const status = await axios.post('Assessment/Answers_AddEdit', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                return status;
            },
            async showNextQuestion(){
                if(this.verifySubQuestions()) {
                    this.$snackBar.error("Please enter all answers and details");
                    return;
                }

                if( this.questionID < this.questions.length - 1 )
                    this.questionID += 1;
                else {
                    const formData = {
                        SurveyID: this.surveyID,
                        ItsID: this.person.ItsID,
                        Answers: [],
                    }

                    this.answers.forEach(answer => {
                        let myAnswer = {
                            FKQuestionID: answer.QuestionID,
                            FKOptionID: answer.OptionID,
                            SubQuestion: '',
                        };

                        if('SubQuestion' in answer) {
                            let subQuestionAnswers = [];

                            answer.SubQuestion.Answers.forEach(sq => {
                                subQuestionAnswers.push(`${sq.Location}_${sq.Month}_${sq.Year}`);
                            });

                            myAnswer.SubQuestion = subQuestionAnswers.join(';');
                        }

                        formData.Answers.push(myAnswer);
                    });

                    const result = await this.saveForm(formData);

                    if(result) {
                        this.isSubmitted = true;

                        const vm = this;
                        setTimeout(function(){
                            vm.$emit('back');
                        }, 2000);
                    }
                }
            },
            verifySubQuestions(){
                let isBlank = false;
                if( this.currentAnswer.SubQuestion !== undefined ) {
                    this.currentAnswer.SubQuestion.Answers.forEach(x => {
                        if( x.Location.trim() == "" || x.Month.trim() == "" || x.Year.trim() == "" )
                            isBlank = true;
                    });
                }
                return isBlank;
            },
            moveBack(){
                this.questionID -= 1;
            },
            answer(question, value){
                console.log(question, value);

                const option = question.Options.find(x => x.OptionID == value);

                let answer = {
                    QuestionID: question.QuestionID,
                    OptionID: option.OptionID,
                    Answer: option.Answer,
                };

                if((option.Answer == 'Once' || option.Answer == 'More Than Once') && ('SubQuestions' in question)) {
                    const subQuesAnswer = {
                        QuestionID: question.SubQuestions[0].QuestionID,
                        Answers: [
                            {Location: '', Month: '', Year: ''},
                        ],
                    };

                    answer.SubQuestion = subQuesAnswer;
                }

                const oldAnswer = this.answers.find(x => x.QuestionID == question.QuestionID);

                if(oldAnswer != undefined)
                    this.answers.splice(this.answers.indexOf(oldAnswer), 1, answer);
                else
                    this.answers.push(answer);
            },
            updateNoOfTimes(question, times){
                const oldAnswer = this.answers.find(x => x.QuestionID == question.QuestionID);
                const ind = this.answers.indexOf(oldAnswer);

                const curAnswers = this.answers[ind].SubQuestion.Answers.length;
                let newAnswers = times - curAnswers

                if( newAnswers < 0 ) {
                    this.answers[ind].SubQuestion.Answers.splice((curAnswers - 1 + newAnswers), Math.abs(newAnswers));
                }
                else {
                    for(let i = 0; i < newAnswers; i++) {
                        this.answers[ind].SubQuestion.Answers.push({Location: '', Month: '', Year: ''});
                    }
                }
            }
        },
        async mounted(){
            await this.getForm();
        }
    }
</script>

<style scoped>

</style>