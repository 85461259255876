<template>
    <v-container fluid>
        <v-row>
            <v-col><h2 class="headline">New Members Enrollment</h2></v-col>
            <v-col align="end">
                <v-btn color="primary" @click="addGroup" class="ml-2">
                    <v-icon left>fa-plus</v-icon>
                    Add Group
                </v-btn>
                <v-btn color="primary" @click="addMembers(0)" class="ml-2">
                    <v-icon left>fa-plus</v-icon>
                    Add Members
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        {{ selectedComponent == 0 ? 'Teams' : `Members | ${selectedTeam.NameWithID}` }}
                        <v-spacer />
                        <v-btn color="primary" small class="py-4" @click="selectedComponent = 0"
                               v-if="selectedComponent == 1">
                            <v-icon left>fa-chevron-left</v-icon> Back To Teams
                        </v-btn>
                        <template v-if="selectedComponent == 0">
                            <text-box label="Search" v-model="teamSearch" class="mr-2" style="min-width: 300px"
                                      @keypress.enter="searchTeams" />
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary" small class="py-4"
                                           v-bind="attrs" v-on="on">
                                        Export To Excel
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item dense @click="exportSummary(1)">Assessment Completed</v-list-item>
                                    <v-list-item dense @click="exportSummary(2)">Assessment Pending</v-list-item>
                                    <v-list-item dense @click="exportSummary(3)">All Members (Waiting)</v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </v-card-title>
                    <v-card-text>
                        <v-alert color="orange white--text" v-if="alert.message.length > 0">
                            <v-icon>fa-warning</v-icon>
                            <b>
                                {{ alert.message }}
                                <a :href="alert.link">Download Log</a>
                            </b>
                        </v-alert>
                        <teams ref="teams" v-if="selectedComponent == 0" :filter="teamSearch"
                               @rowClick="teamSelected" @addMembers="addMembers" />
                        <members v-else-if="selectedComponent == 1" :team="selectedTeam" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgGroup" width="800" title="New Group" :on-confirm="saveGroup">
            <template v-slot:content>
                <v-row>
                    <v-col>
                        <text-box label="Group Name" v-model="group.Name"></text-box>
                        <combo-box label="Parent" v-model="group.ParentID" class="mt-2"
                                   :items="divisions" item-text="Lineage" item-value="HierarchyID" />
                        <combo-box label="Gender" v-model="group.Gender" class="mt-2"
                                   :items="genders" item-text="name" item-value="value"
                                   @change="genderSelected" />
                        <text-box label="Team ID" v-model="group.TeamID" type="number"
                                  class="mt-2" :append-icon="loadingIcon" :readonly="isLoadingID" />
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>

        <simple-dialog ref="dlgMembers" width="500" title="Add Members" :on-confirm="saveMembers">
            <template v-slot:content>
                <v-row>
                    <v-col>
                        <combo-box background-color="white" label="Team"
                                   v-model="members.FKHierarchyID" :items="groups"
                                   item-text="NameWithID" item-value="HierarchyID" />
                        <v-file-input v-model="members.File"
                                      label="Members File (Excel Format)"
                                      outlined
                                      dense
                                      class="mt-2 small-text-field"
                                      hide-details="auto"
                                      prepend-icon="fa-file-excel-o"
                                      @change="fileSelected"
                                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>

    </v-container>
</template>

<script>
    import Teams from './teams';
    import Members from './members';
    import axios from "axios";

    export default {
        title: "New Members Enrollment",
        components: {
            teams: Teams,
            members: Members,
        },
        data(){
            return {
                teamSearch: '',
                divisions: [],
                groups: [],
                selectedComponent: 0,
                selectedTeam: {},
                genders: [
                    {name: 'Gents', value: 'Male'},
                    {name: 'Ladies', value: 'Female'},
                ],
                group: {
                    HierarchyID: 0,
                    ParentID: 0,
                    FKTypeID: 5,
                    Name: '',
                    FKInchargeITS: 0,
                    TeamID: 0,
                    Gender: '',
                },
                isLoadingID: false,
                members: {
                    FKHierarchyID: 0,
                    File: null,
                },
                alert: {
                    message: '',
                    link: '',
                }
            };
        },
        computed: {
            loadingIcon(){
                return this.isLoadingID ? 'fa fa-spinner fa-spin' : '';
            }
        },
        methods: {
            fileSelected(file){
                console.log(file, this.members);
            },
            teamSelected(team){
                this.selectedTeam = team;
                this.selectedComponent = 1;
            },
            searchTeams(){
                this.$refs.teams.getData();
            },
            exportSummary(option){
                this.$refs.teams.reportSummary(option);
            },
            async genderSelected(){
                // this.$loadingDialog.show();
                this.isLoadingID = true;
                this.group.TeamID = 0;
                await axios.post('OrgHierarchy/GetNewTeamID', {Gender: this.group.Gender})
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);
                        else
                            this.group.TeamID = data.Data;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });
                    this.isLoadingID = false;
                // this.$loadingDialog.hide();
            },
            addGroup(){
                this.group = {
                    HierarchyID: 0,
                    ParentID: 0,
                    FKTypeID: 5,
                    Name: '',
                    FKInchargeITS: 0,
                    TeamID: 0,
                    Gender: '',
                };

                this.$refs.dlgGroup.show();
            },
            async saveGroup(){
                let result = false;

                if(this.group.Name.length == 0)
                {
                    this.$snackBar.error('Please enter a name for this group');
                    return;
                }
                if(this.group.ParentID == 0)
                {
                    this.$snackBar.error('Please select a parent for this group');
                    return;
                }

                this.$loadingDialog.show();

                const formData = {
                    Hierarchy: this.group,
                }

                result = await axios.post('OrgHierarchy/AddEdit', formData)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result) {
                    this.$refs.dlgGroup.hide();
                    await this.$refs.teams.getData();
                }
            },
            async addMembers(hierarchyID){
                this.$loadingDialog.show();
                this.groups = await this.getOrgHierarchy(1, 5, 0, `${hierarchyID}`);

                this.members.FKHierarchyID = hierarchyID;

                this.$refs.dlgMembers.show();

                this.$loadingDialog.hide();
            },
            async saveMembers(){
                if(this.members.File == null) {
                    this.$snackBar.error('Please select the members excel file');
                    return;
                }
                this.$loadingDialog.show();

                let formData = new FormData();
                formData.append('HierarchyID', this.members.FKHierarchyID);
                formData.append('File', this.members.File);

                const result = await axios.post('Recruitment/AddMembers', formData,
                    {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        console.log(data);

                        if(data.Data != null) {
                            this.alert.message = data.Message;
                            this.alert.link = data.Data;
                        }
                        else
                            this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result) {
                    this.$refs.dlgMembers.hide();
                    await this.$refs.teams.getData();
                }

                this.$loadingDialog.hide();
            }
        },
        async mounted() {
            this.$loadingDialog.show();
            this.divisions = await this.getOrgHierarchy(1, 4, 0, '0');
            // this.$loadingDialog.hide();
        }
    }
</script>

<style scoped>

</style>