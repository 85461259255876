import Vue from 'vue'
import App from './App.vue'

import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router';
import axios from 'axios';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import VueHtmlToPaper from "vue-html-to-paper";
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import draggable from 'vuedraggable'

import {routes} from './config/routes';
import {store} from './store/store';
import pageTitle from './mixins/pageTitle.js';
import customJS from './mixins/customJS.js';

import loadingDialog from "./components/dialogs/loadingDialog/loadingDialog";
import confirmDialog from "./components/dialogs/confirmDialog/confirmDialog";
import inputDialog from "./components/dialogs/inputDialog/inputDialog";
import infoDialog from "./components/dialogs/infoDialog/infoDialog";
import simpleDialog from "./components/dialogs/simpleDialog";
import snackBar from "./components/general/snackBar/snackBar";
import dataTable from "./components/general/dataTable";
import textBox from "./components/general/textBox";
import datePicker from "./components/general/datePicker";
import timePicker from "./components/general/timePicker";
import comboBox from "./components/general/comboBox";
import contextMenu from "./components/general/contextMenu";
import pieChart from './components/charts/pieChart'
import barChart from './components/charts/barChart'
import lineChart from './components/charts/lineChart'
import orgChart from './components/orgChart'

Vue.config.productionTip = false

Vue.use(VueRouter);
Vue.use(VueHtmlToPaper);
Vue.use(loadingDialog);
Vue.use(snackBar);
Vue.use(confirmDialog);
Vue.use(inputDialog);
Vue.use(infoDialog);
Vue.component('simple-dialog', simpleDialog);
Vue.component('data-table', dataTable);
Vue.component('text-box', textBox);
Vue.component('combo-box', comboBox);
Vue.component('context-menu', contextMenu);
Vue.component('date-picker', datePicker);
Vue.component('time-picker', timePicker);
Vue.component('pie-chart', pieChart);
Vue.component('bar-chart', barChart);
Vue.component('line-chart', lineChart);
Vue.component('draggable', draggable);
Vue.component('org-chart', orgChart);
Vue.mixin(pageTitle);
Vue.mixin(customJS);

Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'md'
})

const router = new VueRouter({
    routes,
    mode: 'history',
});

axios.defaults.baseURL = 'https://api.burhaniguards.com';
// axios.defaults.baseURL = 'http://192.168.100.162:1493/';
// axios.defaults.baseURL = 'http://192.168.18.2:1493/';

axios.interceptors.request.use(config => {
    if(store.getters.isAuthenticated)
    {
      config.headers.common['Authorization'] = `Bearer ${store.getters.token}`;
    }

    return config;
});

axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
        if(error.response.status == 401)
        {
            store.dispatch('logout').then(function() {
              router.replace({name: 'login'});
            });
        }
        else
            return Promise.reject(error);
    });

router.onError(error => {
    console.log(error);
});

router.beforeEach((to, from, next) => {
    if(to.path == '/auth')
        next();
    else if(to.path == '/admin/auth')
        next();
    else if(to.name == 'loginWaiting')
        next();
    else if(to.name == 'recruitmentFormAmil')
        next();
    else if(to.name == 'itsView')
        next();
    else if(to.name == 'amilView')
        next();
    else if(to.name == 'ashara')
        next();
    else {
        const user = store.getters.user;
        const modules = store.getters.modules;
        const rights = store.getters.rights;
        const rankID = store.getters.user.FKRankID;

        // console.log(to.name, store.getters.isAuthenticated, modules, rights);

        if((modules == '' || modules == undefined || modules == []) ||
            (rights == '' || rights == undefined || rights == [])) {
            store.dispatch('logout').then(function() {
              next({name: 'login'});
            });
        }

        if(!store.getters.isAuthenticated) {
            if(to.name == 'login')
                next();
            else
                next({name: 'login'});
        }
        else {
            if (to.path == '/' || to.name == 'login') {
                const module = modules[0];

                if (module.Links.length > 0)
                    next({name: module.Links[0].LinkName});
                else {
                    if(module.LinkName == 'membersAddEdit')
                        store.dispatch('logout').then(function() {
                            next({name: 'login'});
                        });
                    else
                        next({name: module.LinkName});
                }
            }
            else if (to.name != 'login') {
                const module = modules[0];
                let hasLink = false;

                rights.forEach(element => {
                    if (element.LinkName == to.name) {
                      hasLink = true;
                      return;
                    }
                });

                if (!hasLink && rankID == 1)
                    hasLink = true;

                if (hasLink)
                    next();
                else {
                    if (module.Links.length == 0)
                      next({name: module.LinkName});
                    else
                      next({name: module.Links[0].LinkName, params: {action: 'new'}});
                }
            }
            else
                next();
        }
    }
});

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
