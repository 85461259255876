import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth';

Vue.use(Vuex)

export const store = new Vuex.Store({
    state : {
        newMember: {},
    },
    getters: {
        newMember: state => state.newMember,
    },
    mutations: {
        ADD_NEW_MEMBER(state, member) {
            // localStorage.setItem("newMember", JSON.stringify(member));
            state.newMember = member;
        },
    },
    actions: {
        addNewMember({commit}, member) {
            commit('ADD_NEW_MEMBER', member);
        }
    },
    modules: {
        auth,
    }
});
