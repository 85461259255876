<template>
    <v-container>
        <v-overlay v-if="isLoading">
            <v-progress-circular color="white" indeterminate size="53"
                                 class="mt-2 ml-3" />
        </v-overlay>
        <v-row>
            <v-col>
                <template v-for="(question, i) in form.Questions">
                    <div :key="question.QuestionID">
                        <h4>{{ question.Question }}</h4>
                        <p class="my-0">{{ question.Description }}</p>
                        <template v-if="question.QuestionType == 'RadioGroup'">
                            <v-radio-group hide-details row class="mt-2"
                                           v-model="answer(question).Answer">
                                <v-radio v-for="option in question.Options.split(',')"
                                         :label="option"
                                         :key="option"
                                         :value="option"
                                         color="primary" />
                            </v-radio-group>
                        </template>
                        <template v-else-if="question.QuestionType == 'Text'">
                            <text-box class="mt-2"
                                      v-model="answer(question).Answer" />
                        </template>
                        <template v-else-if="question.QuestionType == 'Description'">
                            <text-box multi-row rows="3" class="mt-2"
                                      v-model="answer(question).Answer" />
                        </template>
                        <v-divider v-if="(i + 1 ) < form.Questions.length" class="my-4" />
                    </div>
                </template>
                <v-divider class="my-5" />
                <div class="d-flex justify-end">
                    <v-btn text class="mr-2" @click="cancelForm">Cancel</v-btn>
                    <v-btn color="primary" @click="saveForm">Save</v-btn>
                </div>
            </v-col>
        </v-row>

        <confirm-dialog />
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        name: "formView",
        props: ['member'],
        data(){
            return {
                isLoading: false,
                form: {
                    FormTitle: '',
                    FormDescription: '',
                    Questions: [],
                },
                formDetail: [],
            };
        },
        methods: {
            async getForm(){
                this.isLoading = true;

                const formData = {
                    FormID: 3,
                    PageNumber: 1,
                    NoOfRows: 1,
                    HasFilter: false,
                    Filters: {},
                    IsAmilSaheb: true,
                }

                await axios.post('RecruitmentForm/GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.form = data.Data.Items[0];

                        this.form.Questions.forEach(item => {
                            this.formDetail.push({
                                FKQuestionID: item.QuestionID,
                                Answer: ''
                            });
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.isLoading = false;
            },
            answer(question){
                let answer = this.formDetail.find(item => item.FKQuestionID == question.QuestionID);
                return answer;
            },
            viewMembers(){
                this.$emit('viewMembers');
            },
            async cancelForm(){
                const isCancel = await this.$confirmDialog.show({
                    title: 'Cancel Form',
                    message: 'Are you sure you want to cancel this form? All unsaved data will be lost.',
                    isCritical: true,
                });

                if(!isCancel)
                    return;

                this.formDetail.forEach(item => item.Answer = "");

                this.viewMembers();
            },
            async saveForm(){
                this.isLoading = true;

                const formData = {
                    FormID: 3,
                    MemberItsID: this.member.ItsID,
                    Answers: this.formDetail,
                }

                let status = await axios.post('RecruitmentForm/UpdateAnswers', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(status) {
                    this.formDetail.forEach(item => item.Answer = "");
                }

                this.isLoading = false;

                this.viewMembers();
            },
        },
        async mounted(){
            await this.getForm();
        }
    }
</script>

<style scoped>

</style>