<template>
    <v-dialog
            v-model="dialog"
            v-bind="$attrs"
            v-on="$listeners">

        <v-card color="background">
            <v-toolbar :color="isCritical ? 'red darken-4' : 'primary'" class="white--text mb-6" dense>
                <h3>{{ title }}</h3>
            </v-toolbar>
            <v-card-text>
                <slot name="content"></slot>
            </v-card-text>
            <v-card-actions align="end">
                <v-spacer></v-spacer>
                <slot name="actions"></slot>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>

<script>
    export default {
        name: "customDialog",
        props: ['title', 'isCritical'],
        data(){
            return {
                dialog: false,
            };
        },
        methods: {
            show(){
                this.dialog = true;
            },
            hide(){
                this.dialog = false;
            },

        }
    }
</script>

<style scoped>

</style>