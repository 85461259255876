<template>
    <v-container fluid>
        <v-row>
            <v-col md="6" offset-md="3">
                <v-card>
                    <v-card-title>
                        <v-col md="6" cols="12">
                            Currencies
                        </v-col>
                        <v-col md="6" cols="12" align="end">
                            <v-btn color="primary" @click="addCurrency" block><v-icon left>fa-plus</v-icon> New Currency</v-btn>
                        </v-col>
                    </v-card-title>
                    <v-card-text>
                        <v-list>
                            <v-list-item v-for="currency in currencies" :key="currency.CurrencyID">
                                <v-list-item-content>
                                    <v-list-item-title class="d-flex justify-start align-center">
                                        1 <span style="width: 40px; margin-left: 5px;">{{ currency.CurrencyShortName }}</span> =
                                        <text-box v-model="currency.ExchangeRate" class="ml-2"
                                                  :suffix="baseCurrency.CurrencyShortName"
                                                  @change="updateRate(currency)" />
                                        <template v-if="$vuetify.breakpoint.mdAndUp">
                                            <v-btn small class="ml-2" color="primary" v-if="!currency.IsBaseCurrency"
                                                   @click="updateStatus(currency.CurrencyID)"
                                                   outlined>Set Base Currency</v-btn>
                                            <v-chip label v-else color="primary"
                                                    class="ml-2"><v-icon left small>fa-check-circle</v-icon>Base Currency</v-chip>
                                            <v-btn icon small class="ml-2" color="primary"
                                                   @click="editCurrency(currency)"><v-icon small>fa-pencil</v-icon></v-btn>
                                            <v-btn icon small class="ml-2" color="red darken-4"
                                                   @click="deleteCurrency(currency)"><v-icon small>fa-trash</v-icon></v-btn>
                                        </template>
                                    </v-list-item-title>
                                    <template v-if="$vuetify.breakpoint.smAndDown">
                                        <div class="d-flex justify-start mt-2">
                                            <v-btn small color="primary" v-if="!currency.IsBaseCurrency"
                                                   @click="updateStatus(currency.CurrencyID)"
                                                   outlined>Set Base Currency</v-btn>
                                            <v-chip label v-else color="primary"><v-icon left small>fa-check-circle</v-icon>Base Currency</v-chip>
                                            <v-spacer />
                                            <v-btn icon small class="ml-2" color="primary"
                                                   @click="editCurrency(currency)">
                                                <v-icon small>fa-pencil</v-icon>
                                            </v-btn>
                                            <v-btn icon small class="ml-2" color="red darken-4"
                                                   @click="deleteCurrency(currency)"><v-icon small>fa-trash</v-icon></v-btn>
                                        </div>
                                        <v-divider class="mt-5" />
                                    </template>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgCurrency" :title="dlgTitle" width="500" :on-confirm="saveCurrency">
            <template v-slot:content>
                <v-form @submit="saveCurrency">
                    <v-row>
                        <v-col>
                            <text-box label="Name" v-model="currency.CurrencyName" />
                            <text-box label="Short Name" class="mt-2" v-model="currency.CurrencyShortName" />
                            <text-box label="Symbol" class="mt-2" v-model="currency.CurrencySymbol" />
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </simple-dialog>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "Currency Settings",
        computed: {
            dlgTitle(){
                return `${this.currency.CurrencyID == 0 ? 'New' : 'Edit'} Currency`;
            },
            baseCurrency(){
                return this.currencies.find(x => x.IsBaseCurrency);
            },
        },
        data(){
            return {
                currencies: [],
                currency: {
                    CurrencyID: 0,
                    CurrencyName: '',
                    CurrencyShortName: '',
                    CurrencySymbol: '',
                },
            };
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                await axios.post(`Currency/GetData`, {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.currencies = data.Data;
                        else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            addCurrency(){
                this.currency = {
                    CurrencyID: 0,
                    CurrencyName: '',
                    CurrencyShortName: '',
                    CurrencySymbol: '',
                };

                this.$refs.dlgCurrency.show();
            },
            editCurrency(currency){
                this.currency = currency;
                this.$refs.dlgCurrency.show();
            },
            async saveCurrency(){
                if(this.currency.CurrencyName.length == 0) {
                    this.$snackBar.error('Please enter a currency name');
                    return;
                }
                if(this.currency.CurrencyShortName.length == 0) {
                    this.$snackBar.error('Please enter a currency short name');
                    return;
                }
                if(this.currency.CurrencySymbol.length == 0) {
                    this.$snackBar.error('Please enter a currency symbol');
                    return;
                }

                this.$loadingDialog.show();

                const result = await axios.post('Currency/AddEdit', this.currency)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                {
                    this.$refs.dlgCurrency.hide();
                    await this.getData();
                }

                this.$loadingDialog.hide();
            },
            async updateStatus(currencyID){
                this.$loadingDialog.show();

                const result = await axios.post('Currency/UpdateStatus', {CurrencyID: currencyID})
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                    await this.getData();

                this.$loadingDialog.hide();
            },
            async updateRate(currency){
                this.$loadingDialog.show();

                const formData = {
                    CurrencyID: currency.CurrencyID,
                    ExchangeRate: currency.ExchangeRate,
                }

                const result = await axios.post('Currency/UpdateRate', formData)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                    await this.getData();

                this.$loadingDialog.hide();
            },
            async deleteCurrency(currency){
                const isDelete = await this.$confirmDialog.show({
                    title: 'Delete Currency',
                    message: `Are you sure you want to delete ${currency.CurrencyName}? Please confirm`,
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                this.$loadingDialog.show();

                const result = await axios.post('Currency/Delete', {CurrencyID: currency.CurrencyID})
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                    await this.getData();

                this.$loadingDialog.hide();
            },
        },
        async mounted() {
            await this.getData();
        }
    }
</script>

<style scoped>

</style>