<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h2 class="headline">Fees Dashboard</h2>
            </v-col>
            <v-col align="end" class="d-flex">
                <combo-box label="Group" background-color="white" v-model="fiscalYearID"
                           :items="fiscalYears" item-text="Description" item-value="FiscalYearID"
                           @change="getData" />
                <combo-box label="Collection Head" background-color="white" v-model="collectionHeadID" class="ml-2"
                           :items="collectionHeads" item-text="Description" item-value="CollectionHeadID"
                           @change="getData" />
            </v-col>
        </v-row>

        <v-expand-transition>
            <v-row v-if="'Submissions' in statistics">
                <v-col>
                    <v-card color="blue darken-3" @click="viewApprovals">
                        <v-card-title class="white--text pb-0"><b>{{ statistics.Submissions }}</b></v-card-title>
                        <v-card-text class="white--text"><b>Vouchers Pending Approval</b></v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card color="orange darken-3">
                        <v-card-title class="white--text pb-0">
                            <b>
                                {{ statistics.PendingMembers }} <small class="text-caption ml-1 mr-3">Members</small>
                                {{ statistics.PendingTeams }} <small class="text-caption ml-1">Teams</small>
                            </b>
                        </v-card-title>
                        <v-card-text class="white--text"><b>Fee Pending</b></v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card color="green darken-3">
                        <v-card-title class="white--text pb-0">
                            <b>
                                {{ statistics.PaidMembers }} <small class="text-caption ml-1 mr-3">Members</small>
                                {{ statistics.PaidTeams }} <small class="text-caption ml-1">Teams</small>
                            </b>
                        </v-card-title>
                        <v-card-text class="white--text"><b>Fee Paid</b></v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-expand-transition>

        <v-row>
            <v-col>
                <div class="d-flex justify-end">
                    <text-box label="Search" v-model="search" @keydown.enter="getData"
                              background-color="white" style="min-width: 350px;" />
                    <v-btn small class="py-4 ml-2" color="primary">Export To Excel</v-btn>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <data-table ref="tblMembers"
                            :table-data="tableData"
                            :total-pages="tableData.totalPages"
                            item-key="ItsID"
                            :item-class="rowColor"
                            hide-filters
                            @pageChanged="pageChanged"
                            @click:row="rowClick">
                    <template v-slot:item.TotalPaid="{item}">
                        {{ item.Currency }} {{ item.TotalPaid.formatWithComma() }}
                    </template>
                    <template v-slot:item.Paid="{item}">
                        {{ item.TotalMembers - item.Pending }}
                    </template>
                </data-table>
            </v-col>
        </v-row>

<!--        <simple-dialog ref="dlgApproval" title="Approve Submitted Vouchers"-->
<!--                       hide-confirm-button cancel-button-text="Close">-->
<!--            <template v-slot:content>-->
<!--                <data-table :table-data="vouchers.tableData"-->
<!--                            hide-filters-->
<!--                            disable-pagination>-->
<!--                    <template v-slot:item.Action="{item}">-->
<!--                        <v-btn small color="primary" class="my-1" outlined>-->
<!--                            <v-icon left x-small>fa-search</v-icon>-->
<!--                            View-->
<!--                        </v-btn>-->
<!--                        <v-btn small color="primary" class="ml-2 my-1">Approve</v-btn>-->
<!--                        <v-btn small color="red darken-3 white&#45;&#45;text" class="ml-2">Reject</v-btn>-->
<!--                    </template>-->
<!--                </data-table>-->
<!--            </template>-->
<!--        </simple-dialog>-->

<!--        <simple-dialog ref="dlgApproval" title="Approve Submitted Vouchers"-->
<!--                       :hide-confirm-button="true" cancel-button-text="Close">-->
<!--            <template v-slot:content>-->
<!--                <data-table :table-data="vouchers.tableData"-->
<!--                            hide-filters-->
<!--                            disable-pagination>-->
<!--                    <template v-slot:item.Action="{item}">-->
<!--                        <v-btn small color="primary" class="my-1" outlined @click="viewVoucherDetail(item.Folio)">-->
<!--                            <v-icon left x-small>fa-search</v-icon>-->
<!--                            View-->
<!--                        </v-btn>-->
<!--                        <v-btn small color="primary" class="ml-2 my-1" @click="updateVoucher(item.Folio, true)">Approve</v-btn>-->
<!--                        <v-btn small color="red darken-3 white&#45;&#45;text" class="ml-2" @click="updateVoucher(item.Folio, false)">Reject</v-btn>-->
<!--                    </template>-->
<!--                </data-table>-->
<!--            </template>-->
<!--        </simple-dialog>-->

<!--        <simple-dialog ref="dlgVoucherDetail" title="Voucher Detail"-->
<!--                       :hide-confirm-button="true" cancel-button-text="Close">-->
<!--            <template v-slot:content>-->
<!--                <data-table :table-data="voucherDetail.tableData"-->
<!--                            hide-filters-->
<!--                            disable-pagination>-->
<!--                </data-table>-->
<!--            </template>-->
<!--        </simple-dialog>-->

    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "Finance Dashboard",
        data(){
            return {
                fiscalYears: [],
                collectionHeads: [],
                fiscalYearID: 0,
                collectionHeadID: 0,
                search: '',
                statistics: {},
                tableData: {
                    header: [
                        { text: 'S.No', value: 'SNo', applyFilter: false, width: '70px' },
                        { text: 'Team', value: 'NameWithID', applyFilter: true },
                        { text: 'Incharge', value: 'Incharge', applyFilter: true },
                        { text: 'Contact No', value: 'MobileNo', applyFilter: true },
                        { text: 'Zone', value: 'Zone', applyFilter: true },
                        { text: 'Members', value: 'TotalMembers' },
                        { text: 'Pending', value: 'Pending' },
                        { text: 'Paid', value: 'Paid' },
                        // { text: 'Amount Paid', value: 'TotalPaid' },
                    ],
                    data: [],
                    selected: [],
                    noOfRows: this.tableRows,
                    totalRecords: 1,
                    totalPages: 0,
                    pageNo: 1,
                },
                vouchers: {
                    tableData: {
                        header: [
                            { text: 'S.No', value: 'SNo', width: '70px' },
                            { text: 'Folio', value: 'Folio' },
                            { text: 'Team', value: 'Team' },
                            { text: 'Received Date', value: 'DocumentDate' },
                            { text: 'Received By', value: 'ReceivedBy' },
                            { text: 'Payment Mode', value: 'PaymentMode' },
                            { text: 'Currency', value: 'Currency' },
                            { text: 'Amount', value: 'TotalAmount', format: 'decimal' },
                            { text: 'Members', value: 'TotalMembers' },
                            { text: 'Action', value: 'Action' },
                        ],
                        data: [],
                    },
                },
                voucherDetail: {
                    tableData: {
                        header: [
                            { text: 'S.No', value: 'SNo', width: '70px' },
                            { text: 'Its', value: 'ItsID' },
                            { text: 'Full Name', value: 'FullName' },
                            { text: 'Rank', value: 'Rank' },
                            { text: 'Amount', value: 'Amount' },
                        ],
                        data: [],
                    },
                }
            };
        },
        computed: {
            user(){
                return this.$store.getters.user;
            },
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    FiscalYearID: this.fiscalYearID,
                    CollectionHeadID: this.collectionHeadID,
                    PageNumber: this.tableData.pageNo,
                    NoOfRows: this.tableData.noOfRows,
                    Filter: this.search,
                }

                await axios.post('FeesCollection/Dashboard_GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.tableData.totalPages = data.Data.TotalPages;
                        this.tableData.data = data.Data.Items;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async getStatistics(){
                this.$loadingDialog.show();

                const formData = {
                    FiscalYearID: this.fiscalYearID,
                    CollectionHeadID: this.collectionHeadID,
                }

                await axios.post('FeesCollection/Dashboard_GetStatistics', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.statistics = data.Data;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async getVouchers(option, folio){
                this.$loadingDialog.show();

                const formData = {
                    FiscalYearID: this.fiscalYearID,
                    CollectionHeadID: this.collectionHeadID,
                    Option: option,
                    Folio: folio,
                }

                const result = await axios.post('FeesCollection/Dashboard_GetVouchers', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        return status ? data.Data : [];
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                return result;
            },
            pageChanged(pageNo) {
                this.tableData.pageNo = pageNo;
                this.getData();
            },
            rowColor(item){
                if(item.TotalMembers == item.Pending)
                    return 'red darken-3 white--text hand-cursor';
                else if(item.Pending == 0)
                    return 'green darken-3 white--text hand-cursor';
                else if(item.Pending > 0)
                    return 'yellow darken-3 white--text hand-cursor';
                else
                    return 'hand-cursor';
            },
            rowClick(item){
                console.log(item);
                this.$router.push({name: 'feeCollection', query: {hID: item.HierarchyID}});
            },
            async viewApprovals(){
                this.vouchers.tableData.data = await this.getVouchers(1,0);
                this.$refs.dlgApproval.show();
            },
            async viewVoucherDetail(folio){
                this.voucherDetail.tableData.data = await this.getVouchers(2, folio);
                this.$refs.dlgVoucherDetail.show();
            },
            async updateVoucher(folio, status){
                let remarks = '';

                const isUpdate = await this.$confirmDialog.show({
                    title: 'Update Voucher Status',
                    message: `Are you want to ${status ? 'approve' : 'reject'} voucher: ${folio}? Please confirm.`,
                });

                if(!isUpdate)
                    return;

                if(!status)
                    remarks = await this.$inputDialog.show({
                        title: 'Void Voucher',
                        message: 'Please provide a reason to void this voucher',
                    });

                if(!remarks)
                    return;

                this.$loadingDialog.show();

                const formData = {
                    FiscalYearID: this.fiscalYearID,
                    Folio: folio,
                    Status: status,
                    Remarks: remarks,
                }

                const result = await axios.post('FeesCollection/Dashboard_UpdateVoucher', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result) {
                    this.$refs.dlgApproval.hide();
                    await this.getStatistics();
                    await this.getData();
                }
            },
            async updateStatus(item){
                this.$loadingDialog.show();

                const formData = {
                    HierarchyID: item.HierarchyID,
                    Amount: item.Amount,
                }

                const result = await axios.post('FeesCollection/Update_Amount', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message})
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result)
                    await this.getData();
            }
        },
        async mounted(){
            this.$loadingDialog.show();

            this.collectionHeads = await this.getCollectionHeads();
            this.fiscalYears = await this.getFiscalYears(false);

            this.fiscalYearID = this.fiscalYears.find(x => x.IsActive == 1).FiscalYearID;
            this.collectionHeadID = this.collectionHeads[0].CollectionHeadID;

            this.$loadingDialog.hide();

            this.tableData.noOfRows = this.tableRows;

            // await this.getStatistics();
            await this.getData();

            console.log(this.statistics);
        }
    }
</script>

<style scoped>

</style>