<template>
    <custom-dialog ref="dlg" :title="title" max-width="500" :is-critical="isCritical">
        <template v-slot:content>
            <h4 v-html="message"></h4>
        </template>
        <template v-slot:actions>
            <v-btn text @click="hide">Close</v-btn>
        </template>
    </custom-dialog>
</template>

<script>
    import customDialog from "../customDialog";
    import infoDialog from "./infoDialog.js";

    export default {
        name: "infoDialog",
        components: {
            customDialog,
        },
        data(){
            return {
                title: '',
                message: '',
                isCritical: false,
            }
        },
        methods: {
            show(params){
                this.title = params.title;
                this.message = params.message;
                this.isCritical = params.isCritical;

                this.$refs.dlg.show();
            },
            hide(){
                this.$refs.dlg.hide();
            },
        },
        beforeMount() {
            // here we need to listen for emitted events
            // we declared those events inside our plugin
            infoDialog.EventBus.$on('show', (params) => {
                this.show(params);
            })
        }
    }
</script>

<style scoped>

</style>