<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h2 class="headline">Title Sharaf</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-simple-table dense class="cs-table">
                        <thead>
                            <tr>
                                <th>S. No</th>
                                <th>Jamiyat</th>
                                <th>Quota</th>
                                <th>Actions</th>
                                <th>Added Members</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(jam, i) in jamiyat">
                                <td>{{ i + 1 }}</td>
                                <td>{{ jam.Jamiyat }}</td>
                                <td>{{ jam.Quota }}</td>
                                <td>
                                    <div class="d-flex my-2">
<!--                                        <text-box v-model="itsID" label="Enter Member ITS Number" style="min-width: 250px;" @keydown.enter="loadMember(jam.FKSharafID, jam.ID, itsID)" />-->
                                        <text-box label="Enter Member ITS Number" style="min-width: 250px;" @keypress.enter="loadMember(jam.FKSharafID, jam.ID, $event.target.value)" @change="itsID = $event" />
                                        <v-btn small color="primary" class="ml-2 py-4" @click="loadMember(jam.FKSharafID, jam.ID, itsID)"><v-icon small left>fa-search</v-icon> Search</v-btn>
                                    </div>
                                </td>
                                <td><v-btn small color="brown" dark class="ml-2 py-4" @click="viewMembers(jam.FKSharafID, jam.ID)">View Members ({{ jam.Added }})</v-btn></td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgNewMember" title="Add Member For Title Sharaf" width="600" :on-confirm="saveMember">
            <template v-slot:content>
                <div class="d-flex">
                    <v-img :src="member.PictureInITS" max-height="150px" contain />
                    <div class="ml-3">
                        <div>Its: <b>{{ member.ItsID }}</b></div>
                        <div>Name: <b> {{ member.FullName }}</b></div>
                        <div>Age: <b> {{ member.Age }}</b></div>
                        <div>Rank: <b> {{ member.Rank }}</b></div>
                        <div>Jamiyat: <b> {{ member.Jamiyat }}</b></div>
                        <div>Team: <b> {{ member.Hierarchy }}</b></div>
                        <div>Years In Khidmat: <b> {{ member.YearsInBGI }}</b></div>
                    </div>
                </div>
            </template>
        </simple-dialog>

        <simple-dialog ref="dlgMembers" title="Members List For Title Sharaf" :hide-confirm-button="true" cancel-button-text="Close">
            <template v-slot:content>
                <data-table :table-data="membersTableData"
                            hide-filters
                            wrap-text
                            disable-pagination
                            disable-sort>
                    <template v-slot:item.Photo="{item}">
                        <v-img style="max-height: 60px; min-height: 60px; max-width: 50px; border: 1px Solid #ccc; border-radius: 4px;"
                               class="my-1"
                               :src="item.PictureInITS" contain :alt="item.ItsID.toString()" />
                    </template>
                    <template v-slot:item.Action="{item}">
                        <v-btn icon small color="red darken-3" @click="deleteMember(item)"><v-icon small>fa-trash</v-icon></v-btn>
                    </template>
                </data-table>
            </template>
        </simple-dialog>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        name: "formView",
        title: "Title Sharaf",
        data(){
            return {
                jamiyat: [],
                members: [],
                member: {},
                itsID: '',
                membersTableData: {
                    header: [
                        {text: "S.No", value: 'SNo'},
                        {text: "Photo", value: 'Photo'},
                        {text: "Its", value: 'ItsID'},
                        {text: "Name", value: 'FullName'},
                        {text: "Age", value: 'Age'},
                        {text: "Rank", value: 'Rank'},
                        {text: "Jamiyat", value: 'Jamiyat'},
                        {text: "Team", value: 'Hierarchy'},
                        {text: "Action", value: 'Action'},
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                },
            };
        },
        methods: {
            writeToConsole(param){
                console.log(param);
                console.log(param.target.value);
            },
            async getData(){
                this.$loadingDialog.show();

                await axios.post('Sharaf/GetJamiyat', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.jamiyat = data.Data;

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async loadMember(sharafId, quotaId, its) {
                let members = await this.getMembers({"ItsID": its, "Gender": "Male"});
                if( members.length > 0 ) {
                    this.member = members[0];
                    this.member["SharafID"] = sharafId;
                    this.member["QuotaID"] = quotaId;
                    this.$refs.dlgNewMember.show();
                }
                else
                    this.$snackBar.error("Its number not found");

                console.log(this.member);
            },
            async getAddedMembers(sharafId, quotaId){
                this.$loadingDialog.show();

                const formData = {
                    SharafID: sharafId,
                    SharafQuotaID: quotaId,
                };

                await axios.post('Sharaf/GetMembers', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.membersTableData.data = data.Data;

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async viewMembers(sharafId, quotaId){
                await this.getAddedMembers(sharafId, quotaId);
                this.$refs.dlgMembers.show();
            },
            async saveMember() {
                this.$loadingDialog.show();
                this.$refs.dlgNewMember.hide();

                const formData = {
                    SharafID: this.member.SharafID,
                    SharafQuotaID: this.member.QuotaID,
                    ItsID: this.member.ItsID,
                };

                const result = await axios.post('Sharaf/AddMember', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({ message: data.Message, color: status ? 'success' : 'error' });

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result) {
                    this.member = {}
                    await this.getData();
                }
            },
            async deleteMember(item){
                const isDelete = await this.$confirmDialog.show({
                    title: 'Remove Member From List',
                    message: `Are you sure you want to remove ${item.FullName}? Please confirm`,
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                this.$loadingDialog.show();

                const formData = {
                    SharafID: item.FKSharafID,
                    ItsID: item.FKItsID,
                };

                const result = await axios.post('Sharaf/DeleteMember', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({ message: data.Message, color: status ? 'success' : 'error' });

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result) {
                    await this.getAddedMembers(item.FKSharafID, item.FKSharafQuotaID);
                }
            }
        },
        async mounted(){
            await this.getData();
        },
    }
</script>

<style scoped>
    .cs-table {
        border: 1px Solid #c3c3c3 !important;
        border-radius: 4px !important;
    }
    .cs-table th {
        background-color: #c3c3c3;
        /*border: 1px Solid #c3c3c3 !important;*/
    }
</style>