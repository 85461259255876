<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-simple-table dense class="cs-table">
                    <thead>
                        <tr>
                            <th colspan="3" style="border-bottom: 1px Solid #666"></th>
                            <th colspan="2" style="border-bottom: 1px Solid #666;
                                                    border-left: 1px Solid #666;">
                                New Members Requests
                            </th>
                            <th colspan="3" style="border-bottom: 1px Solid #666;
                                                    border-left: 1px Solid #666;
                                                    border-right: 1px Solid #666;">
                                Assessment Forms Filled
                            </th>
                            <th colspan="3" style="border-bottom: 1px Solid #666"></th>
                        </tr>
                        <tr>
                            <th v-for="head in tableData.header">
                                {{ head.text }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in tableData.data" @click="rowClick(item)" :class="rowStyle(item)">
                            <td v-for="head in tableData.header">
                                <template v-if="head.value == 'PendingMembersRequest'">
                                    <v-btn small color="primary" @click.stop="viewNewMembers(item)">
                                        {{ item.PendingMembersRequest }}
                                    </v-btn>
                                </template>
                                <template v-else-if="head.value == 'CaptainAssessment'">
                                    <v-btn small color="primary" @click.stop="downloadCaptainForms(item)">
                                        {{ item.CaptainAssessment }}
                                    </v-btn>
                                </template>
                                <template v-else-if="head.value == 'AmilAssessment'">
                                    <v-btn small color="primary" @click.stop="downloadAmilForms(item)">
                                        {{ item.AmilAssessment }}
                                    </v-btn>
                                </template>
                                <template v-else-if="head.value == 'Actions'">
                                    <v-btn color="primary" small class="my-2 mr-2" @click.stop="addMembers(item.HierarchyID)">
                                        <v-icon left small>fa-plus</v-icon>
                                        Add Members
                                    </v-btn>
                                </template>
                                <template v-else>
                                    {{ item[head.value] }}
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
<!--                <data-table :table-data="tableData" disable-pagination-->
<!--                            class="wrap"-->
<!--                            :item-class="rowClass"-->
<!--                            @click:row="rowClick">-->
<!--                    <template v-slot:item.NewMembersRequest="{item}">-->
<!--                        <v-btn small color="primary" @click.stop="viewNewMembers(item)">{{ item.NewMembersRequest }}</v-btn>-->
<!--                    </template>-->
<!--                    <template v-slot:item.Actions="{item}">-->
<!--                        <v-btn color="primary" small class="my-2 mr-2" @click.stop="addMembers(item.HierarchyID)">-->
<!--                            <v-icon left small>fa-plus</v-icon>-->
<!--                            Add Members-->
<!--                        </v-btn>-->
<!--                        <context-menu :menu-items="tableData.menu" :data-item="item"></context-menu>-->
<!--                    </template>-->
<!--                </data-table>-->
            </v-col>
        </v-row>

        <simple-dialog ref="dlgResponses" :title="responses.title"
                       :hide-confirm-button="true"
                       cancel-button-text="Close">
            <template v-slot:content>
                <v-row>
                    <v-col align="end">
                        <v-btn small color="primary" class="mb-2" @click="generateExcel">Export to Excel</v-btn>
                        <div style="overflow: auto; max-height: 453px; padding: 0; margin: 0;">
                            <data-table :table-data="responses.tableData"
                                        hide-filters
                                        disable-pagination />
                        </div>
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>

        <simple-dialog ref="dlgMembers" title="New Members Request" :hide-confirm-button="true"
                       cancel-button-text="Close">
            <template v-slot:content>
                <div class="d-flex mb-3">
                    <v-btn color="primary" small class="py-4" @click="approveNewMembers"
                           :disabled="selectedMembers.length == 0">Approve Selected</v-btn>
                    <v-spacer />
                    <v-btn color="primary" small class="py-4" @click="exportNewMembers">Export To Excel</v-btn>
                </div>
                <data-table :table-data="newMembers.tableData" v-model="newMembers.selected" item-key="ItsID" disable-pagination hide-filters show-select>
                    <template v-slot:item.Status="{item}">
                        <combo-box :items="['Pending', 'Rejected']" v-model="item.Status" class="my-1" @change="updateNewMembers(item)" />
                    </template>
                    <template v-slot:item.Remarks="{item}">
                        <text-box v-model="item.Remarks" class="my-1" @keypress.enter="updateNewMembers(item)" />
                    </template>
                    <template v-slot:item.Actions="{item}">
                        <v-btn small color="red darken-3 white--text" icon @click="deleteNewMembers(item.ItsID)">
                            <v-icon small>fa-trash</v-icon>
                        </v-btn>
                    </template>
                </data-table>
            </template>
        </simple-dialog>

    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        name: "teams",
        props: ['filter'],
        data(){
            return {
                tableData: {
                    header: [
                        {text: "S.No", value: 'SNo', width:40},
                        {text: "Team", value: 'NameWithID', applyFilter: true},
                        // {text: "Parent", value: 'Lineage', applyFilter: true},
                        {text: "Zone", value: 'Zone', applyFilter: true},
                        {text: "Pending", value: 'PendingMembersRequest', width:100},
                        {text: "Total", value: 'NewMembersRequest', width:100},
                        // {text: "Members", value: 'TotalMembers', width:100},
                        {text: "Members", value: 'MembersAssessment', width:100},
                        {text: "Captain", value: 'CaptainAssessment', width:100},
                        {text: "Amil", value: 'AmilAssessment', width:100},
                        {text: "Actions", value: 'Actions', width:100},
                        {text: "Created On", value: 'CreateDate', width:100},
                    ],
                    data: [],
                    filters: {},
                    menu: [
                        {title: 'View New Members Request', onClick: this.viewNewMembers},
                        {title: 'View Incharge Assessment', onClick: this.downloadCaptainForms},
                        {title: 'View Amil Assessment', onClick: this.downloadAmilForms},
                    ]
                },
                newMembers: {
                    hierarchyID: 0,
                    tableData: {
                        header: [
                            { text: 'S.No', value: 'SNo', width: '100px' },
                            { text: 'ITS', value: 'ItsID', width: '150px' },
                            { text: 'Name', value: 'FullName' },
                            { text: 'Age', value: 'Age', width: '100px' },
                            { text: 'Jamaat', value: 'Jamaat' },
                            { text: 'Status', value: 'Status', width: '150px' },
                            { text: 'Remarks', value: 'Remarks' },
                            { text: 'Actions', value: 'Actions', width: '100px' },
                        ],
                        data: [],
                    },
                    selected: [],
                },
                responses: {
                    title: '',
                    hierarchyID: 1,
                    formID: 1,
                    tableData: {
                        header: [
                            {text: "S.No", value: 'SNo', width:50},
                        ],
                        data: [],
                        filters: {},
                    }
                }
            };
        },
        computed: {
            selectedMembers(){
                let members = [];
                this.newMembers.selected.forEach(it => members.push(it.ItsID));
                return members;
            },
        },
        methods: {
            rowClass(){
                return 'hand-cursor';
            },
            rowStyle(item){
                let totalAssessment = (item.MembersAssessment + item.CaptainAssessment + item.AmilAssessment) / 3;

                if(item.PendingMembersRequest > 0)
                    return 'hand-cursor red lighten-3';
                else if(item.TotalMembers == totalAssessment)
                    return 'hand-cursor green lighten-3';
                else
                    return 'hand-cursor yellow lighten-3';
            },
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    Filter: this.filter,
                    //HasFilter: !!Object.keys(this.tableData.filters).length,
                    //Filters: this.tableData.filters,
                }

                await axios.post('Recruitment/GetTeams', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.tableData.data = data.Data;

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            rowClick(item){
                this.$emit('rowClick', item);
            },
            addMembers(hierarchyID){
                this.$emit('addMembers', hierarchyID);
            },
            async downloadCaptainForms(item){
                this.responses.title = 'Incharge Assessment Forms';
                this.responses.formID = 2;
                this.responses.hierarchyID = item.HierarchyID;
                const result = await this.downloadResponses(2, 2, item.HierarchyID);

                if(result)
                    this.$refs.dlgResponses.show();
            },
            async downloadAmilForms(item){
                this.responses.title = 'Amil Saheb Assessment Forms';
                this.responses.formID = 3;
                this.responses.hierarchyID = item.HierarchyID;
                const result = await this.downloadResponses(2, 3, item.HierarchyID);

                if(result)
                    this.$refs.dlgResponses.show();
            },
            async generateExcel(){
                await this.downloadResponses(1, this.responses.formID, this.responses.hierarchyID);
            },
            async downloadResponses(option, formID, hierarchyID){
                this.$loadingDialog.show();

                let formData = {
                    Option: option,
                    FormID: formID,
                    HierarchyID: hierarchyID,
                };

                const result = await axios.post('RecruitmentForm/GetResponses', formData)
                    .then(response => {
                        const data = response.data;
                        let status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        if(status) {
                            if(option == 1) {
                                let fileUrl = data.Data;
                                window.open(fileUrl);
                            }
                            else {
                                let rows = data.Data;

                                if(rows.length > 0) {
                                    this.responses.tableData.header = [];
                                    Object.keys(rows[0]).forEach(item => {
                                        this.responses.tableData.header.push({text: item, value: item});
                                    });
                                    this.responses.tableData.data = rows;
                                }
                                else {
                                    this.$snackBar.error('No data is available');
                                    status = false;
                                }
                            }
                        }

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                return result;
            },
            async getNewMembers(){
                this.$loadingDialog.show();

                const result = await axios.post('Recruitment/Members_GetData',
                    { HierarchyID: this.newMembers.hierarchyID })
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.newMembers.tableData.data = data.Data;

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                return result;
            },
            async viewNewMembers(item){
                this.newMembers.hierarchyID = item.HierarchyID;
                await this.getNewMembers();
                this.$refs.dlgMembers.show();
            },
            async updateNewMembers(item){
                this.$loadingDialog.show();

                await axios.post('Recruitment/Members_Update',
                    { ItsID: item.ItsID, Status: item.Status, Remarks: item.Remarks })
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async deleteNewMembers(itsID){
                this.$loadingDialog.show();

                const formData = {
                    ItsIDs: itsID,
                };

                const result = await axios.post('Recruitment/Members_Delete', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result)
                    await this.getNewMembers();
            },
            async approveNewMembers(){
                this.$loadingDialog.show();

                const formData = {
                    ItsIDs: this.selectedMembers.join(','),
                };

                const result = await axios.post('Recruitment/Members_Approve', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result)
                    await this.getNewMembers();
            },
            async exportNewMembers(){
                await this.exportToExcel(this.newMembers.hierarchyID);
            },
            async reportSummary(option){
                this.$loadingDialog.show();

                await axios.post('Recruitment/Report_Summary',
                    { Option: option })
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        if(status) {
                            let fileUrl = data.Data;
                            window.open(fileUrl);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
        },
        async mounted(){
            await this.getData();
        },
    }
</script>

<style scoped>
    .cs-table {
        border: 1px Solid #c3c3c3 !important;
        border-radius: 4px !important;
    }
    .cs-table th {
        background-color: #c3c3c3;
        /*border: 1px Solid #c3c3c3 !important;*/
    }
</style>