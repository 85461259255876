<template>
    <v-row>
        <v-col cols="12" md="3" v-for="person in people">
            <v-card :key="person.ItsID">
                <v-card-text class="text-center">
                    <h3>{{ person.SurveyTitle }}</h3>
                    <v-divider class="my-3" />
                    <div class="d-flex flex-column align-center">
                        <v-img style="max-height: 120px; min-height: 60px; max-width: 100px; border: 1px Solid #ccc; border-radius: 4px;"
                               :src="person.PictureInITS" />
                        <h4 class="mt-3">{{ person.FullName }}</h4>
                        <h5>{{ person.Rank }}</h5>
                        <h5>{{ person.Zone }}<br/>{{ person.Hierarchy }}</h5>
                        <template v-if="person.IsSubmitted">
                            <v-chip label color="primary" style="width: 100%" class="mt-3 text-uppercase text-center">Feedback Submitted</v-chip>
                        </template>
                        <v-btn block color="primary" class="py-4 mt-3" small @click="startAssessment(person)" v-else>
                            Start Feedback
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import axios from "axios";

    export default {
        name: "samplesView",
        data(){
            return {
                people: [],
            };
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                await axios.post('Assessment/GetSamples', {SurveyID: this.surveyID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.people = data.Data;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            startAssessment(person){
                this.$emit("startAssessment", person.SurveyID, person);
            }
        },
        async mounted(){
            await this.getData();
        },
    }
</script>

<style scoped>

</style>