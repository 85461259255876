<template>
    <div>
        <h4 v-if="surveys.length == 0" style="height: 56vh" class="text-center">No Surveys Available</h4>
        <v-carousel v-model="carouselPage" hide-delimiters show-arrows-on-hover height="56vh" v-else>
            <v-carousel-item v-for="survey in surveys">
                <v-sheet tile light>
                    <v-row class="text-center">
                        <v-col class="pa-7 pt-0">
                            <h4 class="mt-2">{{ survey.SurveyTitle }}</h4>
                            <h5 class="mt-2">{{ survey.SurveyDescription }}</h5>
                            <h5 class="mt-2">{{ survey.StartDate }} - {{ survey.EndDate }}</h5>
<!--                            <v-btn small color="primary" class="my-5" @click="viewResponse(1, survey.SurveyID)">-->
<!--                                View Reponses ({{ survey.Responses }})-->
<!--                            </v-btn>-->
                            <br/>
                            <v-chip label class="mt-2"
                                    :color="survey.IsActive && validDate(survey.EndDate) ? 'green darken-3' : 'red darken-3'" dark>
                                {{ survey.IsActive && validDate(survey.EndDate) ? 'Active' : 'Closed' }}
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-carousel-item>
        </v-carousel>

        <simple-dialog ref="dlgResponses" title="Survey Responses" width="1099"
                       :hide-confirm-button="true"
                       cancel-button-text="Close">
            <template v-slot:content>
                <v-row>
                    <v-col align="end">
                        <v-btn small color="primary" class="mb-2" @click="generateExcel">Export to Excel</v-btn>
                        <div style="overflow: auto; max-height: 453px; padding: 0; margin: 0;">
                            <data-table :table-data="surveyResponses.tableData"
                                        hide-filters
                                        disable-pagination />
                        </div>
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>
    </div>
</template>

<script>
    import axios from "axios";
    import moment from "moment";

    export default {
        name: "surveysView",
        data(){
            return {
                carouselPage: 0,
                surveys: [],
                surveyResponses: {
                    tableData: {
                        header: [],
                        data: [],
                        filters: {},
                        selected: [],
                    },
                },
                surveyID: 0,
            };
        },
        methods: {
            validDate(surveyDate){
                return moment().isBefore(surveyDate);
            },
            async getSurveys() {
                this.$loadingDialog.show();

                await axios.post('Dashboard/GetSurveys', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.surveys = data.Data;

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$emit('updateCount', this.surveys.length);

                this.$loadingDialog.hide();
            },
            async viewResponse(option, surveyID){
                this.surveyID = surveyID;
                await this.getResponses(option, surveyID);
            },
            async getResponses(option, surveyID){
                this.$loadingDialog.show();

                let status = await axios.post("Dashboard/GetSurveyResponses", { Option: option, SurveyID: surveyID })
                    .then(async response => {
                        const data = response.data;
                        let status = data.Status;

                        //this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        if(status) {
                            if(option == 1) {
                                let rows = data.Data;

                                if(rows.length > 0) {
                                    this.surveyResponses.tableData.header = [];
                                    Object.keys(rows[0]).forEach(item => {
                                        this.surveyResponses.tableData.header.push({text: item, value: item});
                                    });
                                    this.surveyResponses.tableData.data = rows;

                                    this.$refs.dlgResponses.show();
                                }
                                else {
                                    this.$snackBar.error('No data is available');
                                    status = false;
                                }
                            }
                            else {
                                let fileUrl = data.Data;
                                window.open(fileUrl);
                            }
                        }
                        else
                            this.$snackBar.error(data.Message);

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                return status;
            },
            async generateExcel(){
                await this.getResponses(2, this.surveyID);
            },
        },
        async mounted(){
            await this.getSurveys();

            console.log(this.surveys);
        }
    }
</script>

<style scoped>

</style>