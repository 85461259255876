<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h2 class="headline">Survey Forms</h2>
            </v-col>
            <v-col align="end">
                <v-btn color="primary" @click="addSurvey"><v-icon left>fa-plus</v-icon> New Survey</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-btn color="red darken-4 white--text" small
                       :disabled="selectedData.length == 0" @click="deleteSurvey">
                    Delete
                </v-btn>
                <v-btn color="primary" small class="ml-2"
                       :disabled="selectedData.length == 0" @click="updateStatus(1)">
                    Mark As Active
                </v-btn>
                <v-btn color="primary" small class="ml-2"
                       :disabled="selectedData.length == 0" @click="updateStatus(0)">
                    Mark As In-Active
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <data-table v-model="tableData.selected"
                            :table-data="tableData"
                            :items-per-page="tableData.noOfRows"
                            :total-pages="tableData.totalPages"
                            :item-class="miqaatClass"
                            @click:row="itemClick"
                            item-key="SurveyID"
                            show-select
                            @pageChanged="pageChanged"
                            @filterChanged="filterChanged">
                    <template v-slot:item.IsActive="{item}">
                        <v-icon :color="item.IsActive ? 'primary' : 'red'">{{ item.IsActive ? 'fa-check-circle' : 'fa-times-circle' }}</v-icon>
                    </template>
                    <template v-slot:item.actions="{item}">
                        <context-menu :data-item="item" :menu-items="tableData.menu.items" />
                    </template>
                </data-table>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgSurvey" :title="surveyFormTitle" width="600" :on-confirm="saveSurvey">
            <template v-slot:content>
                <v-row>
                    <v-col>
                        <text-box label="Title" v-model="survey.SurveyTitle" />
                        <text-box label="Description" v-model="survey.SurveyDescription" rows="2" multi-row class="mt-2" />

                        <v-row>
                            <v-col>
                                <date-picker v-model="survey.StartDate" label="Start Date" date-format="DD-MMM-yyyy" class="mt-2" />
                            </v-col>
                            <v-col>
                                <date-picker v-model="survey.EndDate" label="End Date" date-format="DD-MMM-yyyy" class="mt-2" />
                            </v-col>
                        </v-row>

                        <combo-box label="Group" :items="orgHierarchy" item-text="Name" item-value="HierarchyID"
                                   v-model="survey.ForFKHierarchyIDs" multiple class="mt-2" />
                        <combo-box label="Rank"  :items="ranks" item-text="Description" item-value="Value01"
                                   v-model="survey.ForFKRankIDs" class="mt-2" multiple />
                        <combo-box label="Gender" :items="['All', 'Gents', 'Ladies']"
                                   v-model="survey.ForGender" class="mt-2" />
<!--                        <v-row>-->
<!--                            <v-col>-->
<!--                                <text-box label="From Age" type="number" v-model="survey.AgeFrom" class="mt-2" />-->
<!--                            </v-col>-->
<!--                            <v-col>-->
<!--                                <text-box label="To Age" type="number" v-model="survey.AgeTo" class="mt-2" />-->
<!--                            </v-col>-->
<!--                        </v-row>-->
                        <text-box label="Its IDs" v-model="survey.ForITS" rows="2" multi-row class="mt-2" @input="itsEntered" />
<!--                        <v-checkbox label="Allow multiple responses for each team" v-model="survey.IsAllowMultipleEntriesPerTeam" class="mt-2" hide-details />-->
                        <v-checkbox label="Allow multiple responses" v-model="survey.IsAllowMultipleEntriesPerUser" class="mt-2" hide-details />
<!--                        <v-checkbox label="Active" v-model="survey.IsActive" class="mt-2" hide-details />-->
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>

        <simple-dialog ref="dlgResponses" title="Survey Responses" width="100%"
                       :hide-confirm-button="true"
                       cancel-button-text="Close">
            <template v-slot:content>
                <v-row>
                    <v-col align="end">
                        <v-btn small color="primary" class="mb-2" @click="generateExcel">Export to Excel</v-btn>
                        <div style="overflow: auto; max-height: 453px; padding: 0; margin: 0;">
                            <data-table :table-data="surveyResponses.tableData"
                                        hide-filters
                                        disable-pagination wrap-text />
                        </div>
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>
    </v-container>
</template>

<script>
    import axios from "axios";
    import moment from "moment";

    export default {
        title: "Survey Forms",
        data(){
            return {
                tableData: {
                    header: [
                        {text: "S.No", value: 'SNo'},
                        {text: "Title", value: 'SurveyTitle', applyFilter: true},
                        {text: "Start Date", value: 'StartDate', applyFilter: true},
                        {text: "End Date", value: 'EndDate', applyFilter: true},
                        {text: "Responses", value: 'Responses', applyFilter: true},
                        {text: "Active", value: 'IsActive'},
                        {text: "Created On", value: 'CreateDate', applyFilter: true},
                        {text: "Actions", value: 'actions'},
                    ],
                    data: [],
                    filters: {},
                    noOfRows: this.tableRows,
                    totalPages: 1,
                    pageNo: 1,
                    selected: [],
                    menu: {
                        title: '',
                        items: [
                            { icon: 'fa-file-text', title: 'View Responses', onClick: this.viewReport },
                            { icon: 'fa-envelope', title: 'Send Notification', onClick: this.sendNotification },
                            { icon: 'fa-pencil', title: 'Edit', onClick: this.editSurvey },
                            { icon: 'fa-trash', title: 'Delete', onClick: this.deleteSingle },
                        ]
                    },
                },
                survey: {
                    SurveyID: 0,
                    FKMiqaatID: 0,
                    SurveyTitle: '',
                    SurveyDescription: '',
                    StartDate: '',
                    EndDate: '',
                    ForFKHierarchyIDs: [0],
                    ForFKRankIDs: [0],
                    ForGender: 'All',
                    ForAge: '',
                    AgeFrom: 0,
                    AgeTo: 0,
                    ForITS: '',
                    IsAllowMultipleEntriesPerTeam: false,
                    IsAllowMultipleEntriesPerUser: false,
                    IsActive: true,
                },
                orgHierarchy: [],
                ranks: [],
                surveyResponses: {
                    tableData: {
                        header: [],
                        data: [],
                        filters: {},
                        selected: [],
                    },
                },
            };
        },
        computed: {
            surveyFormTitle(){
                return this.survey.SurveyID == 0 ? 'New Survey' : 'Edit Survey';
            },
            selectedData(){
                let selected = [];
                this.tableData.selected.forEach(item => {
                    selected.push(item.SurveyID);
                });
                return selected;
            },
        },
        methods: {
            miqaatClass(){
                return 'hand-cursor';
            },
            itsEntered(event){
                this.survey.ForITS = event.replace(/\n/g, ",").replace(/,\s*$/, "");
            },
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    SurveyID: 0,
                    PageNumber: this.tableData.pageNo,
                    NoOfRows: this.tableData.noOfRows,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                }

                await axios.post(`Surveys/GetData`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            this.tableData.data = data.Data.Items;
                            this.tableData.totalPages = data.Data.TotalPages;
                        }
                        else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            pageChanged(pageNo){
                this.tableData.pageNo = pageNo;
                this.getData();
            },
            filterChanged(){
                this.getData();
            },
            itemClick(item){
                this.$router.push({name: 'surveyFormsAddEdit', params: {id: item.SurveyID}});
            },
            addSurvey(){
                this.survey = {
                    SurveyID: 0,
                    FKMiqaatID: 0,
                    SurveyTitle: '',
                    SurveyDescription: '',
                    StartDate: new moment().format('DD-MMM-YYYY'),
                    EndDate: new moment().add(1, "day").format('DD-MMM-YYYY'),
                    ForFKHierarchyIDs: [1],
                    ForFKRankIDs: [0],
                    ForGender: 'All',
                    ForAge: '',
                    AgeFrom: 0,
                    AgeTo: 0,
                    ForITS: '',
                    IsAllowMultipleEntriesPerTeam: false,
                    IsAllowMultipleEntriesPerUser: false,
                    IsActive: true,
                };

                this.$refs.dlgSurvey.show();
            },
            editSurvey(item){
                console.log(item);

                this.survey = {
                    SurveyID: item.SurveyID,
                    FKMiqaatID: item.FKMiqaatID,
                    SurveyTitle: item.SurveyTitle,
                    SurveyDescription: item.SurveyDescription,
                    StartDate: item.StartDate,
                    EndDate: item.EndDate,
                    ForFKHierarchyIDs: item.ForFKHierarchyIDs.split(',').map(x => parseInt(x, 10)),
                    ForFKRankIDs: item.ForFKRankIDs.split(',').map(x => parseInt(x, 10)),
                    ForGender: item.ForGender,
                    ForAge: item.ForAge,
                    AgeFrom: item.ForAge.split('-')[0],
                    AgeTo: item.ForAge.split('-')[1],
                    ForITS: item.ForITS,
                    IsAllowMultipleEntriesPerTeam: item.IsAllowMultipleEntriesPerTeam,
                    IsAllowMultipleEntriesPerUser: item.IsAllowMultipleEntriesPerUser,
                    IsActive: item.IsActive,
                };

                this.$refs.dlgSurvey.show();
            },
            async saveSurvey(){
                if(this.survey.SurveyTitle.length == 0) {
                    this.$snackBar.error('Please enter a survey title');
                    return;
                }
                if(this.survey.StartDate.length == 0) {
                    this.$snackBar.error('Please select a survey start date');
                    return;
                }
                if(this.survey.EndDate.length == 0) {
                    this.$snackBar.error('Please select a survey end date');
                    return;
                }
                if(this.survey.ForFKHierarchyIDs.length == 0) {
                    this.$snackBar.error('Please select at least one group');
                    return;
                }
                if(this.survey.ForFKRankIDs.length == 0) {
                    this.$snackBar.error('Please select at least one rank');
                    return;
                }

                this.$loadingDialog.show();

                const formData = {
                    ...this.survey
                };

                formData.ForFKHierarchyIDs = formData.ForFKHierarchyIDs.join();
                formData.ForFKRankIDs = formData.ForFKRankIDs.join();
                formData.ForAge = `${formData.AgeFrom}-${formData.AgeTo}`;

                const result = await axios.post('Surveys/AddEdit', formData)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                {
                    this.$refs.dlgSurvey.hide();
                    await this.getData();
                }

                this.$loadingDialog.hide();
            },
            async deleteSingle(item){
                this.tableData.selected.push(item);
                await this.deleteSurvey();
            },
            async deleteSurvey(){
                const result = await this.$confirmDialog.show({
                    title: "Delete Survey",
                    message: `Are you sure you want to delete selected Survey(s)? All responses will also be deleted, please confirm.`,
                    isCritical: true,
                });

                if(result){
                    this.$loadingDialog.show();

                    const resp = await axios.post("Surveys/Delete", { SurveyIDs: this.selectedData.join(',') })
                        .then(async response => {
                            const data = response.data;
                            const status = data.Status;

                            this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                            return status;
                        })
                        .catch(error => {
                            console.log(error);
                            this.$snackBar.error('An unexpected error has occurred');
                        });

                    if(resp) {
                        this.tableData.selected = [];
                        await this.getData();
                    }

                    this.$loadingDialog.hide();
                }
            },
            async updateStatus(isActive){
                this.$loadingDialog.show();

                const formData = {
                    SurveyIDs: this.selectedData.join(','),
                    IsActive: isActive,
                };

                const resp = await axios.post("Surveys/UpdateStatus", formData)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(resp) {
                    this.tableData.selected = [];
                    await this.getData();
                }

                this.$loadingDialog.hide();
            },
            async sendNotification(item) {
                this.$loadingDialog.show();

                await axios.post("Surveys/SendNotification", {SurveyID: item.SurveyID})
                    .then(async response => {
                        const data = response.data;
                        let status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async viewReport(item){
                this.surveyResponses.SurveyID = item.SurveyID;

                let status = await this.getResponses(1, item.SurveyID);

                if(status)
                    this.$refs.dlgResponses.show();
            },
            async generateExcel(){
                await this.getResponses(2, this.surveyResponses.SurveyID);
            },
            async getResponses(option, surveyID){
                this.$loadingDialog.show();

                let status = await axios.post("Surveys/GetResponses", { Option: option, SurveyID: surveyID })
                    .then(async response => {
                        const data = response.data;
                        let status = data.Status;

                        //this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        if(status) {
                            if(option == 1) {
                                let rows = data.Data;

                                if(rows.length > 0) {
                                    this.surveyResponses.tableData.header = [];
                                    Object.keys(rows[0]).forEach(item => {
                                        this.surveyResponses.tableData.header.push({text: item, value: item});
                                    });
                                    this.surveyResponses.tableData.data = rows;
                                }
                                else {
                                    this.$snackBar.error('No data is available');
                                    status = false;
                                }
                            }
                            else {
                                let fileUrl = data.Data;
                                window.open(fileUrl);
                            }
                        }
                        else
                            this.$snackBar.error(data.Message);

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                return status;
            },
        },
        async mounted(){
            this.tableData.noOfRows = this.tableRows;

            this.$loadingDialog.show();
            this.orgHierarchy = await this.getOrgHierarchy(2, 0, 0, 0);
            this.ranks = await this.getGeneral(2, 0);

            // this.orgHierarchy.splice(0, 0, { Name: "All", HierarchyID: 0 });
            this.ranks.splice(0, 0, { Description: "All", Value01: 0 });

            this.$loadingDialog.hide();

            await this.getData();
        },
    }
</script>

<style scoped>
</style>