<template>
    <div>
        <v-textarea
                ref="txt"
                v-bind="$attrs"
                v-on="$listeners"
                outlined
                class="small-text-field"
                dense
                hide-details="auto"
                v-if="$attrs.hasOwnProperty('multi-row')">
        </v-textarea>
        <v-text-field
                ref="txt"
                v-bind="$attrs"
                v-on="$listeners"
                outlined
                class="small-text-field"
                dense
                hide-details="auto"
                v-else>
        </v-text-field>
    </div>
</template>

<script>
    export default {
        name: "textBox",
        methods: {
            focus(){
                this.$refs.txt.focus();
            }
        }
    }
</script>

<style scoped>

</style>