<template>
    <v-row>
        <v-col>
            <v-data-table
                    v-bind="$attrs"
                    v-on="$listeners"
                    :headers="tableData.header"
                    :items="tableData.data"
                    :items-per-page="tableData.noOfRows"
                    style="border: 1px Solid #c3c3c3"
                    :class="'wrap-text' in $attrs ? 'wrap' : ''"
                    dense
                    hide-default-footer>
                <template v-slot:body.prepend v-if="!$attrs.hasOwnProperty('hide-filters') && (tableData.data.length > 0 || Object.keys(tableData.filters).length > 0)">
                    <tr>
                        <td v-if="$attrs.hasOwnProperty('show-select')"><v-icon>fa-filter</v-icon></td>
                        <td v-for="head in tableData.header" :key="head.value">
                            <v-text-field
                                    :value="tableData.filters[head.value]"
                                    :placeholder='"Type here to search " + head.text'
                                    class="small-text-field my-1"
                                    background-color="white"
                                    outlined
                                    dense
                                    hide-details
                                    :disabled="tableData.loading"
                                    :type="head.value.includes('Date') ? 'date' : 'text'"
                                    autcomplete="disable"
                                    @keyup.enter="customFilter($event, head)"
                                    v-if="head.applyFilter">
                            </v-text-field>
                        </td>
                    </tr>
                </template>
                <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
                    <slot :name="name" v-bind="data"></slot>
                </template>
            </v-data-table>
            <div class="mt-5" v-if="!$attrs.hasOwnProperty('disable-pagination')">
                <v-pagination v-model="pagination.page" :length="pagination.totalPages" :total-visible="20" @input="pageChanged($event)"></v-pagination>
            </div>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "dataTable",
        props: ['tableData', 'totalPages'],
        data(){
            return {
                pagination: {
                    page: 1,
                    totalPages: 1,
                }
            };
        },
        methods: {
            customFilter(value, head){
                let key = ('searchField' in head) ? head.searchField : head.value;

                if( value.target.value && value.target.value != "" )
                    this.tableData.filters[key] = value.target.value;
                else
                    delete this.tableData.filters[key];

                this.$emit('filterChanged');
            },
            pageChanged(param){
                this.$emit('pageChanged', param);
            },
            updatePages(pages){
                this.pagination.totalPages = pages;
            }
        },
        watch: {
            'totalPages'(){
                this.pagination.totalPages = this.totalPages;
            },
        },
        mounted() {
            if(this.totalPages)
                this.pagination.totalPages = this.totalPages;
        }
    }
</script>

<style scoped>
</style>