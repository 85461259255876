<template>
    <v-container fluid>
        <v-row>
            <v-col><h2>Dashboard - Duties</h2></v-col>
            <v-col>
                <combo-box label="Event"
                           v-model="eventID"
                           :items="events"
                           item-value="MiqaatHierarchyID"
                           item-text="Name"
                           @change="eventChanged" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <data-table :table-data="tableData" disable-pagination hide-filters disable-sort/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from "axios";

export default {
    name: 'dashboard_duties',
    props: ['miqaatId', 'miqaatHierarchyId'],
    computed: {
    },
    data() {
        return {
            events: [],
            eventID: 0,
            tableData: {
                header: [
                    {text: 'S.No', value: 'SNo'},
                    {text: 'Duty', value: 'Duty' },
                    {text: 'Reporting', value: 'ReportingTime' },
                    {text: 'Total Members', value: 'TotalMembers' },
                    {text: 'Total Attendance', value: 'TotalAttendance' },
                ],
                data: [],
            },
            timer: '',
        }
    },    
    methods: {
        async getData(opt){
            this.$loadingDialog.show();

            const formData = {
                MiqaatID: this.miqaatHierarchyId,
                EventID: this.eventID,
                DutyID: 0,
                Option: opt,
            };

            const data = await axios.post('Miqaat/Dashboard_Duties', formData)
                .then(response => {
                    const data = response.data;
                    const status = data.Status;

                    return status ? data.Data : [];
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.error('An unexpected error has occurred');
                });

            this.$loadingDialog.hide();

            return data;
        },
        async eventChanged(){
            this.tableData.data = await this.getData(1);
            // this.$snackBar.info("Fetched Duties Data");
        },
    },
    async mounted() {
        this.events = await this.getMiqaatHierarchy(1, 2, this.miqaatHierarchyId, 0);
        this.eventID = this.events[0].MiqaatHierarchyID;

        await this.eventChanged();
    },
    created(){
        this.timer = setInterval(this.eventChanged, 45000)
    },
    beforeDestroy() {
        clearInterval(this.timer);
    }
}
</script>

<style scoped>
</style>