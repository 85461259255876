<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-col>
                            <v-row>
                                <v-col md="6" cols="12">
                                    Collection Heads
                                </v-col>
        <!--                        <v-col md="6" cols="12" align="end">-->
        <!--                            <v-btn color="primary" @click="addCurrency" block><v-icon left>fa-plus</v-icon> New Head</v-btn>-->
        <!--                        </v-col>-->
                            </v-row>
                            <v-row>
                                <v-col md="6" cols="12">
                                    <combo-box label="Fiscal Year" background-color="white"
                                               v-model="collectionConfig.FKFiscalYearID"
                                               :items="fiscalYears" item-text="Description" item-value="FiscalYearID"
                                               @change="getData" />
                                </v-col>
                                <v-col md="6" cols="12">
                                    <combo-box label="Collection Head" background-color="white"
                                               v-model="collectionConfig.FKCollectionHeadID"
                                               :items="collectionHeads" item-text="Description"
                                               item-value="CollectionHeadID"
                                               @change="getData" />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card-title>
                    <v-card-text class="ma-0 pa-0">
                        <v-divider />
                        <v-simple-table style="max-height: 430px; overflow: auto;">
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Group</th>
                                    <th>Rank</th>
                                    <th width="150px">Currency</th>
                                    <th width="200px">Amount</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="background-color: #d3d3d3">
                                    <td>New</td>
                                    <td>
                                        <combo-box background-color="white" v-model="collectionConfig.FKHierarchyID"
                                                   :items="groups" item-text="NameWithID" item-value="HierarchyID"
                                                   multiple small-chips />
                                    </td>
                                    <td>
                                        <combo-box background-color="white" v-model="collectionConfig.FKRankID"
                                                   :items="ranks" item-text="Description" item-value="Value01"
                                                   multiple small-chips />
                                    </td>
                                    <td>
                                        <combo-box background-color="white" v-model="collectionConfig.FKCurrencyID"
                                                   :items="currencies" item-text="CurrencyShortName"
                                                   item-value="CurrencyID" />
                                    </td>
                                    <td>
                                        <text-box background-color="white" type="number" v-model="collectionConfig.Amount" />
                                    </td>
                                    <td>
                                        <v-btn small color="primary" class="py-4" @click="saveConfig(collectionConfig)">Add</v-btn>
                                    </td>
                                </tr>
                                <tr v-for="(config, i) in collectionConfigs" :key="i">
                                    <td>{{ config.SNo }}</td>
                                    <td>{{ config.Hierarchy }}</td>
                                    <td width="300px" style="text-wrap: normal;">{{ config.Rank }}</td>
                                    <td>
                                        <combo-box background-color="white" v-model="config.FKCurrencyID"
                                                   :items="currencies" item-text="CurrencyShortName"
                                                   item-value="CurrencyID" @change="saveConfig(config)" />
                                    </td>
                                    <td>
                                        <text-box background-color="white" type="number" v-model="config.Amount"
                                                  @change="saveConfig(config)" />
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <v-divider />
                    </v-card-text>
<!--                    <v-card-actions>-->
<!--                        <v-spacer />-->
<!--                        <v-btn color="primary" @click="createPayables">Generate Payables</v-btn>-->
<!--                        <v-spacer />-->
<!--                    </v-card-actions>-->
                </v-card>
            </v-col>
        </v-row>

<!--        <simple-dialog ref="dlgCurrency" :title="dlgTitle" width="500" :on-confirm="saveCurrency">-->
<!--            <template v-slot:content>-->
<!--                <v-form @submit="saveCurrency">-->
<!--                    <v-row>-->
<!--                        <v-col>-->
<!--                            <text-box label="Name" v-model="currency.CurrencyName" />-->
<!--                            <text-box label="Short Name" class="mt-2" v-model="currency.CurrencyShortName" />-->
<!--                            <text-box label="Symbol" class="mt-2" v-model="currency.CurrencySymbol" />-->
<!--                        </v-col>-->
<!--                    </v-row>-->
<!--                </v-form>-->
<!--            </template>-->
<!--        </simple-dialog>-->
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "Collection Heads",
        computed: {
            dlgTitle(){
                return `${this.currency.CurrencyID == 0 ? 'New' : 'Edit'} Currency`;
            },
        },
        data(){
            return {
                fiscalYears: [],
                collectionHeads: [],
                currencies: [],
                groups: [],
                ranks: [],
                collectionConfigs: [],
                collectionHead: {
                    CollectionHeadID: 0,
                    FKHierarchyID: 0,
                    CollectionHead: '',
                    Description: '',
                },
                collectionConfig: {
                    ConfigID: 0,
                    FKFiscalYearID: 0,
                    FKCollectionHeadID: 0,
                    FKHierarchyID: [],
                    FKRankID: [0],
                    FKCurrencyID: 0,
                    Amount: 0,
                },
            };
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    FiscalYearID: this.collectionConfig.FKFiscalYearID,
                    CollectionHeadID: this.collectionConfig.FKCollectionHeadID,
                };

                await axios.post(`CollectionHeads/Config_GetData`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status) {
                            this.collectionConfigs = data.Data;
                            this.collectionConfigs.forEach(item => {
                                item.FKHierarchyID = item.FKHierarchyID.split(',').map(hr => parseInt(hr));
                                item.FKRankID = item.FKRankID.split(',').map(hr => parseInt(hr));
                            });

                            console.log(this.collectionConfigs);
                        }
                        else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            addCurrency(){
                this.currency = {
                    CurrencyID: 0,
                    CurrencyName: '',
                    CurrencyShortName: '',
                    CurrencySymbol: '',
                };

                this.$refs.dlgCurrency.show();
            },
            editCurrency(currency){
                this.currency = currency;
                this.$refs.dlgCurrency.show();
            },
            async saveCurrency(){
                if(this.currency.CurrencyName.length == 0) {
                    this.$snackBar.error('Please enter a currency name');
                    return;
                }
                if(this.currency.CurrencyShortName.length == 0) {
                    this.$snackBar.error('Please enter a currency short name');
                    return;
                }
                if(this.currency.CurrencySymbol.length == 0) {
                    this.$snackBar.error('Please enter a currency symbol');
                    return;
                }

                this.$loadingDialog.show();

                const result = await axios.post('Currency/AddEdit', this.currency)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                {
                    this.$refs.dlgCurrency.hide();
                    await this.getData();
                }

                this.$loadingDialog.hide();
            },
            async saveConfig(config){
                this.$loadingDialog.show();

                let formData = {...config};

                formData.FKHierarchyID = formData.FKHierarchyID.join(',');
                formData.FKRankID = formData.FKRankID.join(',');

                const result = await axios.post('CollectionHeads/Config_AddEdit', formData)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                    await this.getData();

                this.$loadingDialog.hide();
            },
            async updateStatus(currencyID){
                this.$loadingDialog.show();

                const result = await axios.post('Currency/UpdateStatus', {CurrencyID: currencyID})
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                    await this.getData();

                this.$loadingDialog.hide();
            },
            async deleteCurrency(currency){
                const isDelete = await this.$confirmDialog.show({
                    title: 'Delete Currency',
                    message: `Are you sure you want to delete ${currency.CurrencyName}? Please confirm`,
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                this.$loadingDialog.show();

                const result = await axios.post('Currency/Delete', {CurrencyID: currency.CurrencyID})
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                    await this.getData();

                this.$loadingDialog.hide();
            },
            async createPayables(){
                this.$loadingDialog.show();

                const formData = {
                    FKFiscalYearID: this.collectionConfig.FKFiscalYearID,
                    FKCollectionHeadID: this.collectionConfig.FKCollectionHeadID,
                };

                await axios.post('CollectionHeads/Config_CreatePayables', formData)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
        },
        async mounted() {
            this.$loadingDialog.show();

            this.groups = await this.getOrgHierarchy(2, 0, 0, '0');
            this.ranks = await this.getGeneral(2, 0);
            this.currencies = await this.getCurrencies();
            this.fiscalYears = await this.getFiscalYears(false);
            this.collectionHeads = await this.getCollectionHeads();

            // this.ranks.splice(0, 0, {Description: 'All', Value01: 0});
            this.collectionConfig.FKHierarchyID = [this.groups[0].HierarchyID];
            this.collectionConfig.FKRankID = [this.ranks[0].Value01];
            this.collectionConfig.FKFiscalYearID = this.fiscalYears.find(x => x.IsActive).FiscalYearID;
            this.collectionConfig.FKCollectionHeadID = this.collectionHeads[0].CollectionHeadID;
            this.collectionConfig.FKCurrencyID = this.currencies[0].CurrencyID;

            this.$loadingDialog.hide();

            await this.getData();
        }
    }
</script>

<style scoped>

</style>