<template>
    <v-container fluid>
        <v-row>
            <v-col><h2>Miqaat Reports</h2></v-col>
            <v-col class="d-flex justify-end">
                <date-picker label="From Date" v-model="formData.FromDate"/>
                <date-picker label="To Date" v-model="formData.ToDate" class="ml-2" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-list>
                    <v-list-item @click="generateReport('Miqaat Registrations', 'msp_Reports_Miqaat_RegistrationDetail')">
                        <v-list-item-content>
                            <v-list-item-title>Miqaat Registrations</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="generateReport('Miqaat Registrations Team-wise Summary', 'msp_Reports_Miqaat_RegistrationSummary_Team')">
                        <v-list-item-content>
                            <v-list-item-title>Miqaat Registrations Summary Team-wise</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="generateReport('Miqaat Registrations Rank-wise Summary', 'msp_Reports_Miqaat_RegistrationSummary_Rank')">
                        <v-list-item-content>
                            <v-list-item-title>Miqaat Registrations Summary Rank-wise</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="generateReport('Duty Attendance Detail', 'msp_Reports_Miqaat_AttendanceDetail')">
                        <v-list-item-content>
                            <v-list-item-title>Duty Attendance Detail</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-col>
            <v-col>
                <combo-box label="Event"
                           v-model="formData.EventID"
                           :items="events"
                           item-value="MiqaatHierarchyID"
                           item-text="Name"
                           @change="eventChanged" />
                <combo-box label="Duty"
                           v-model="formData.DutyID"
                           :items="duties"
                           item-value="MiqaatHierarchyID"
                           item-text="Name"
                           class="mt-2" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment';

    export default {
        name: "miqaatReports",
        props: ['miqaatId', 'miqaatHierarchyId'],
        data(){
            return {
                formData: {
                    FromDate: new moment().startOf('month'), //.subtract(7, "days"),
                    ToDate: new moment(),
                    MiqaatID: this.miqaatHierarchyId,
                    EventID: 0,
                    DutyID: 0,
                },
                events: [],
                duties: [],
                event: {},
                duty: {},
            };
        },
        methods: {
            async eventChanged(){
                this.$loadingDialog.show();
                this.duties = await this.getMiqaatHierarchy(1, 3, this.formData.EventID, 0);

                if(this.duties.length > 0) {
                    this.duties.splice(0, 0, {MiqaatHierarchyID: 0, Name: 'All'});
                    this.formData.DutyID = this.duties[0]['MiqaatHierarchyID'];
                }

                this.$loadingDialog.hide();
            },
            async generateReport(reportName, procedureName){
                this.$loadingDialog.show();

                this.formData.FromDate = new moment(this.formData.FromDate).format('DD-MMM-YYYY');
                this.formData.ToDate = new moment(this.formData.ToDate).format('DD-MMM-YYYY');
                this.formData.ReportName = reportName;
                this.formData.ProcedureName = procedureName;

                await axios.post('Miqaat/Report', this.formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        if(status) {
                            let fileUrl = data.Data;
                            window.open(fileUrl);
                        }

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            }
        },
        async mounted() {
            this.$loadingDialog.show();
            this.events = await this.getMiqaatHierarchy(1, 2, this.miqaatHierarchyId, 0);

            if(this.events.length > 0) {
                this.events.splice(0, 0, {MiqaatHierarchyID: 0, Name: 'All'});
                this.formData.EventID = this.events[0]['MiqaatHierarchyID'];
                this.duties = await this.getMiqaatHierarchy(1, 3, this.formData.EventID, 0);
            }

            if(this.duties.length > 0) {
                this.duties.splice(0, 0, {MiqaatHierarchyID: 0, Name: 'All'});
                this.formData.DutyID = this.duties[0]['MiqaatHierarchyID'];
            }
            this.$loadingDialog.hide();
        }
    }
</script>

<style scoped>

</style>