<template>
    <v-container fluid>
        <v-row>
            <v-col><h2>Miqaat Members Quota</h2></v-col>
        </v-row>
        <v-row>
            <v-col>
                <data-table :table-data="tableData"
                            disable-pagination
                            @filterChanged="filterChanged">
                    <template v-slot:item.Quota="{item}">
                        <text-box solo class="mt-1" v-model="item.Quota" @keyup.enter="updateQuota(item)"></text-box>
                    </template>
                </data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import axios from 'axios';

    export default {
        name: 'miqaatQuota',
        props: ['miqaatId', 'miqaatHierarchyId', 'zone'],
        data() {
            return {
                tableData: {
                    header: [
                        {text: 'S.No', value:"SNo", width: "50px",},
                        {text: 'Group', value:"Hierarchy", applyFilter: true,},
                        // {text: 'Parent', value:"Lineage", width: "250px", applyFilter: true,},
                        {text: 'Quota', value:"Quota", width: "100px"},
                        {text: 'Registered', value:"RegisteredMembers"},
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                }
            }
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    MiqaatHierarchyID: this.miqaatHierarchyId,
                    ZoneID: this.zone.ZoneID,
                    Gender: this.zone.Gender,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                }

                await axios.post('Miqaat/Quotas_GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            this.tableData.data = data.Data;
                        }
                        else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            filterChanged(){
                this.getData();
            },
            async updateQuota(item){
                this.$loadingDialog.show();

                const formData = {
                    MiqaatHierarchyID: this.miqaatHierarchyId,
                    HierarchyID: item.HierarchyID,
                    Quota: parseInt(item.Quota),
                }

                await axios.post(`Miqaat/Quotas_UpdateData`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
        },
        async mounted() {
            await this.getData();
        }
    }
</script>

<style lang="scss">

</style>