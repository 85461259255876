<script>
    import { Line, mixins } from 'vue-chartjs'
    const { reactiveProp } = mixins

    export default {
        extends: Line,
        mixins: [reactiveProp],
        props: ['options'],
        data(){
          return {
              chartOptions: {
                  responsive: true,
                  maintainAspectRatio: false,
              },
          }
        },
        beforeMount() {
            if(this.options)
                this.chartOptions = this.options;
        },
        mounted () {
            this.renderChart(this.chartData, this.chartOptions)
        }
    }
</script>

<style scoped>

</style>