<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-scroll-y-transition group hide-on-leave leave-absolute>
                    <samples-view v-if="page == 1" key="samples" @startAssessment="startAssessment" />
                    <form-view v-else key="form" :surveyID="selectedSurveyID" :person="selectedPerson" @back="back" />
                </v-scroll-y-transition>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import formView from './formView';
    import samplesView from './samplesView';

    export default {
        name: "assessmentFormView",
        title: "Feedback",
        components: {
            formView,
            samplesView,
        },
        data(){
            return {
                page: 1,
                selectedSurveyID: 0,
                selectedPerson: {},
            };
        },
        methods: {
            startAssessment(surveyID, person){
                this.selectedSurveyID = surveyID;
                this.selectedPerson = person;
                this.page = 2;
            },
            back(){
                this.selectedSurveyID = 0;
                this.selectedPerson = {};
                this.page = 1;
            },
        },
    }
</script>

<style scoped>

</style>