<template>
    <v-container fluid>
        <v-row>
            <v-col><h2>Members Registration</h2></v-col>
            <v-col align="end">
                <v-btn color="primary" @click="addMember" class="py-4" small>New Registration</v-btn>
                <v-btn color="primary" @click="excelUpload" class="py-4 ml-2" small>Upload Excel</v-btn>
                <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                       ref="excelUpload" @change="loadFile" hidden>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-row>
                    <v-col><h3>Total: {{ tableData.totalRecords }} Members</h3></v-col>
                    <v-col align="end">
                        <v-btn color="primary" small @click="updateStatus(1, 'approve')" :disabled="this.tableData.selected.length == 0">Approve</v-btn>
                        <v-btn color="red darken-2" small class="white--text ml-2" @click="updateStatus(3, 'reject')" :disabled="this.tableData.selected.length == 0">Reject</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <data-table v-model="tableData.selected"
                                    :table-data="tableData"
                                    :items-per-page="tableData.noOfRows"
                                    :total-pages="tableData.totalPages"
                                    item-key="FKItsID"
                                    show-select
                                    @pageChanged="pageChanged"
                                    @filterChanged="filterChanged">
                            <template v-slot:item.Status="{item}">
                                <v-chip :color="item.FKStatusID == 1 ? 'success' : item.FKStatusID != 4 ? 'error' : ''"
                                        label
                                        small>
                                    {{ item.Status }}
                                </v-chip>
                            </template>
                            <template v-slot:item.Actions="{item}">
                                <context-menu :data-item="item" :menu-items="menu.items" />
                            </template>
                        </data-table>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgMember" width="500px" title="New Registration" :on-confirm="saveMember">
            <template v-slot:content>
                <v-row>
                    <v-col>
                        <combo-box label="Member"
                                   v-model="member.FKItsID"
                                   :loading="members.loading"
                                   :search-input.sync="members.search"
                                   :items="members.items"
                                   item-text="FullName_ITS"
                                   item-value="ItsID"
                                   hide-no-data
                                   cache-items />
                        <combo-box label="Registration Status"
                                   v-model="member.FKStatusID"
                                   :items="regStatus"
                                   item-text="Description"
                                   item-value="Value01"
                                   class="mt-3" />
                        <text-box label="Arrival Date"
                                  v-model="member.ArrivalDate"
                                  type="date"
                                  class="mt-3" />
                        <text-box label="Departure Date"
                                  v-model="member.DepartureDate"
                                  type="date"
                                  class="mt-3" />
                        <text-box label="Accommodation Details"
                                  v-model="member.AccomodationDetails"
                                  type="date"
                                  multi-row
                                  rows="3"
                                  class="mt-3" />
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>
    </v-container>
</template>

<script>
    import axios from "axios";
    import moment from "moment";

    export default {
        name: 'Miqaat Registrations',
        props: ['miqaatId', 'miqaatHierarchyId'],
        data() {
            return {
                tableData: {
                    header: [
                        {text: 'S.No', value: 'SNo'},
                        {text: 'ITS', value: 'FKItsID', applyFilter: true, },
                        {text: 'Name', value: 'FullName', applyFilter: true, },
                        {text: 'Rank', value: 'Rank', applyFilter: true, },
                        {text: 'Team', value: 'Hierarchy', applyFilter: true, },
                        {text: 'Zone', value: 'Zone', applyFilter: true, },
                        {text: 'Status', value: 'Status', applyFilter: true },
                        {text: 'Registration Date', value: 'RegistrationDate', applyFilter: true },
                        {text: 'Arrival Date', value: 'TentativeArrivalDate', applyFilter: true },
                        {text: 'Departure Date', value: 'TentativeDepartureDate', applyFilter: true },
                        {text: 'Accomodation', value: 'AccomodationDetails', applyFilter: true },
                        {text: 'Actions', value: 'Actions' },
                    ],
                    data: [],
                    selected: [],
                    filters: {},
                    totalRecords: 0,
                    pageNo: 1,
                    noOfRows: this.tableRows,
               },
                member: {
                    MiqaatID: this.miqaatId,
                    FKItsID: 0,
                    FKStatusID: 0,
                    ArrivalDate: '',
                    DepartureDate: '',
                    AccomodationDetails: '',
                },
                members: {
                    loading: false,
                    items: [],
                    search: '',
                },
                menu: {
                    title: '',
                    items: [
                        { icon: 'fa-pencil', title: 'Edit', onClick: this.editMember },
                        { icon: 'fa-trash', title: 'Delete', onClick: this.deleteMember },
                    ]
                },
                regStatus: [],
            }
        },
        computed: {
            selectedData(){
                let selected = [];
                this.tableData.selected.forEach(item => {
                    selected.push(item.FKItsID);
                });
                return selected;
            }
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    MiqaatHierarchyID: this.miqaatHierarchyId,
                    PageNumber: this.tableData.pageNo,
                    NoOfRows: this.tableData.noOfRows,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                }

                await axios.post(`Miqaat/Registrations_GetData`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            this.tableData.data = data.Data.Items;
                            this.tableData.totalRecords = data.Data.TotalRecords;
                            this.tableData.totalPages = data.Data.TotalPages;
                        }
                        else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            pageChanged(pageNo){
                this.tableData.pageNo = pageNo;
                this.getData();
            },
            filterChanged(){
                this.getData();
            },
            async loadMembers(val){
                this.members.loading = true;
                this.members.items = await this.getMembers({"FullName_ITS": val});
                this.members.loading = false;
            },
            addMember() {
                this.member = {
                    MiqaatID: this.miqaatHierarchyId,
                    FKItsID: 0,
                    FKStatusID: 0,
                    ArrivalDate: '',
                    DepartureDate: '',
                    AccomodationDetails: '',
                };

                this.$refs.dlgMember.show();
            },
            editMember(item) {
                this.member = {
                    MiqaatID: this.miqaatHierarchyId,
                    FKItsID: item.FKItsID,
                    FKStatusID: item.FKStatusID,
                    ArrivalDate: new moment(item.TentativeArrivalDate).format("YYYY-MM-DD"),
                    DepartureDate: new moment(item.TentativeDepartureDate).format("YYYY-MM-DD"),
                    AccomodationDetails: item.AccomodationDetails,
                };

                this.members.items.push({FKItsID: item.FKItsID, FullName: item.FullName});

                this.$refs.dlgMember.show();
            },
            async saveMember(){
                this.$loadingDialog.show();

                const result = await axios.post(`Miqaat/Registrations_AddEdit`, this.member)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        return status
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                {
                    await this.getData();
                    this.$refs.dlgMember.hide();
                }

                this.$loadingDialog.hide();
            },
            async updateStatus(statusID, status){
                const isUpdate = await this.$confirmDialog.show({
                    title: 'Update Registration Status',
                    message: `Are you sure you want to ${status} registration of selected members? Please confirm`
                });

                if(!isUpdate)
                    return;

                this.$loadingDialog.show();

                const formData = {
                    MiqaatID: this.miqaatHierarchyId,
                    FKItsID: this.selectedData.join(),
                    FKStatusID: statusID,
                };

                const result = await axios.post(`Miqaat/Members_UpdateStatus`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        return status
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                {
                    await this.getData();
                }

                this.$loadingDialog.hide();
            },
            excelUpload(){
                this.$refs.excelUpload.value = null;
                this.$refs.excelUpload.click();
            },
            async loadFile(e){
                const file = e.target.files[0];

                this.$loadingDialog.show();

                let formData = new FormData();
                formData.append("MiqaatID", this.miqaatHierarchyId);
                formData.append("ExcelFile", file);

                const result = await axios.post(`Miqaat/Registrations_Upload`, formData,
                    {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        return status
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                    await this.getData();

                this.$loadingDialog.hide();
            },
        },
        watch: {
            'members.search'(val) {
                val && val !== this.member.FKItsID && this.loadMembers(val);
            },
        },
        async mounted() {
            this.tableData.noOfRows = this.tableRows;

            this.$loadingDialog.show();
            this.regStatus = await this.getGeneral(7, 0);
            this.$loadingDialog.hide();

            await this.getData();
        }
    }
</script>

<style>

</style>