<template>
    <v-container fluid>

            <v-row v-if="!isLoading" key="r1">
                <v-col v-for="(stat, i) in statisticsHeaders" :key="stat.field">
                    <v-tooltip bottom>
                        <template v-slot:activator="{on, attrs}">
                            <v-card :color="statisticsColors[i%2]"
                                    v-bind="attrs"
                                    v-on="on" dark>
                                <v-card-title class="white--text pb-0"><b>{{ statistics[stat.field].formatWithComma() }}</b></v-card-title>
                                <v-card-text class="white--text"><b>{{ stat.heading }}</b></v-card-text>
                            </v-card>
                        </template>
                        <span>Gents: {{ statistics[stat.gents].formatWithComma() }}, Ladies: {{ statistics[stat.ladies].formatWithComma() }}</span>
                    </v-tooltip>
                </v-col>
            </v-row>

            <v-row v-if="!isLoading" key="r2">
                <v-col>
                    <v-tooltip bottom>
                        <template v-slot:activator="{on, attrs}">
                            <v-card color="primary"
                                    v-bind="attrs"
                                    v-on="on" dark>
                                <v-card-title class="white--text pb-0">
                                    <b>
                                        {{ (parseInt(feeStatus.Paid_Gents) + parseInt(feeStatus.Paid_Ladies)) }}
                                    </b>
                                    <small class="text-caption ml-2">Members</small>
                                </v-card-title>
                                <v-card-text class="white--text"><b>{{ feeStatus.FiscalYear }} Fees Paid</b></v-card-text>
                            </v-card>
                        </template>
                        <span>Gents: {{ feeStatus.Paid_Gents }}, Ladies: {{ feeStatus.Paid_Ladies }}</span>
                    </v-tooltip>
                </v-col>
                <v-col>
                    <v-tooltip bottom>
                        <template v-slot:activator="{on, attrs}">
                            <v-card color="red darken-2"
                                    v-bind="attrs"
                                    v-on="on" dark>
                                <v-card-title class="white--text pb-0">
                                    <b>
                                        {{ (parseInt(feeStatus.Pending_Gents) + parseInt(feeStatus.Pending_Ladies)) }}
                                    </b>
                                    <small class="text-caption ml-2">Members</small>
                                </v-card-title>
                                <v-card-text class="white--text"><b>{{ feeStatus.FiscalYear }} Fees Pending</b></v-card-text>
                            </v-card>
                        </template>
                        <span>Gents: {{ feeStatus.Pending_Gents }}, Ladies: {{ feeStatus.Pending_Ladies }}</span>
                    </v-tooltip>
                </v-col>
            </v-row>

        <v-fade-transition>
        </v-fade-transition>

        <v-row v-if="!isLoading">
            <v-col>
                <v-card>
                    <v-card-text style="height: 79vh;">
                        <MapChart country-colors :country-data="countryData"
                                  default-country-fill-color="#795548" :show-overlay="mapOverlay.show"
                                  @mouseenter="countryEnter" @mouseleave="mapOverlay.show = false">
                            <template v-slot:overlay>
                                <span class="text-h6 black--text">{{ mapOverlay.details }}</span>
                            </template>
                        </MapChart>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="4" cols="12">
                <v-row>
                    <v-col cols="4">
                        <v-card color="primary" dark>
                            <v-card-title class="white--text pb-0"><b>0</b></v-card-title>
                            <v-card-text class="white--text"><b>New Teams</b></v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="8">
                        <v-card color="primary" dark>
                            <v-card-title class="white--text pb-0">
                                <span class="mr-2"><b>{{ recruitmentStatus.Pending }}</b> <small>Pending</small></span>
                                <span><b>{{ recruitmentStatus.InProcess }}</b> <small>In-Process</small></span>
                            </v-card-title>
                            <v-card-text class="white--text"><b>New Members Requests</b></v-card-text>
                        </v-card>
                    </v-col>
<!--                    <v-col>-->
<!--                        <v-card color="primary" dark>-->
<!--                            <v-card-title class="white&#45;&#45;text pb-0"><b>0</b></v-card-title>-->
<!--                            <v-card-text class="white&#45;&#45;text"><b>New Members In-Process</b></v-card-text>-->
<!--                        </v-card>-->
<!--                    </v-col>-->
                </v-row>
                <v-card class="mt-3">
                    <v-card-title>Survey Forms ({{ surveysCount }})</v-card-title>
                    <surveys-view @updateCount="surveysCount = $event" />
                </v-card>
            </v-col>
            <v-col md="3" cols="12" v-if="user.FKRankID >= 3 && user.FKRankID <= 6">
                <v-card>
                    <v-card-title>Termination Requests ({{ requestsCount }})</v-card-title>
                    <requests-view @updateCount="requestsCount = $event" />
                </v-card>
            </v-col>
        </v-row>

        <v-row v-if="!isLoading">
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title>Jamiyat Data</v-card-title>
                    <v-card-text style="height: 465px; overflow: auto;">
                        <v-list dense>
                            <template v-for="(item, i) in jamiyatWiseCount">
                                <v-list-item dense :key="item.Zone"
                                             @click="showDetailView(1, item.JamiyatID)">
                                    <v-list-item-title>{{ item.Zone }}</v-list-item-title>
                                    <v-list-item-title class="text-right">
                                        <v-chip label color="green" class="white--text">{{ item.TotalMembers }}</v-chip>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-divider v-if="i < jamiyatWiseCount.length - 1" />
                            </template>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title>Hifz Data</v-card-title>
                    <v-card-text style="height: 465px; overflow: auto;">
                        <v-list dense>
                            <template v-for="(item, i) in hifzCount">
                                <v-list-item dense :key="item.QuranHifz"
                                             @click="showDetailView(3, item.QuranHifz)">
                                    <v-list-item-title>{{ item.QuranHifz }}</v-list-item-title>
                                    <v-list-item-title class="text-right">
                                        <v-chip label color="green" class="white--text">{{ item.TotalMembers }}</v-chip>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-divider v-if="i < hifzCount.length - 1" />
                            </template>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title>Functional Role Data</v-card-title>
                    <v-card-text style="height: 465px; overflow: auto;">
                        <v-list dense>
                            <template v-for="(item, i) in roleWiseCount">
                                <v-list-item dense :key="item.Role"
                                             @click="showDetailView(2, item.FKRoleID)">
                                    <v-list-item-title>{{ item.Role }}</v-list-item-title>
                                    <v-list-item-title class="text-right">
                                        <v-chip label color="green" class="white--text">{{ item.TotalMembers }}</v-chip>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-divider v-if="i < roleWiseCount.length - 1" />
                            </template>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title>Rank Data</v-card-title>
                    <v-card-text style="height: 465px; overflow: auto;">
                        <v-list dense>
                            <template v-for="(item, i) in rankWiseCount">
                                <v-list-item dense :key="item.Rank"
                                             @click="showDetailView(4, item.FKRankID)">
                                    <v-list-item-title>{{ item.Rank }}</v-list-item-title>
                                    <v-list-item-title class="text-right">
                                        <v-chip label color="green" class="white--text">{{ item.TotalMembers }}</v-chip>
                                    </v-list-item-title>
                                </v-list-item>
                                <v-divider v-if="i < rankWiseCount.length - 1" />
                            </template>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!--        <v-row v-if="!isLoading">-->
        <!--            <v-col>-->
        <!--                <v-card style="height: 100%;">-->
        <!--                    <v-card-title>Members Count</v-card-title>-->
        <!--                    <v-card-subtitle>Jamiyat-Wise</v-card-subtitle>-->
        <!--                    <v-card-text style="height: 465px; overflow: auto;">-->
        <!--                        <pie-chart :chart-data="zoneWiseCount.chartData"-->
        <!--                                   :styles="{ height: '450px' }"-->
        <!--                                   :options="{maintainAspectRatio: false, responsive: true}" />-->
        <!--                    </v-card-text>-->
        <!--                </v-card>-->
        <!--            </v-col>-->
        <!--        </v-row>-->

        <simple-dialog ref="dlgDetailView" :title="detailView.title" cancel-button-text="close" :hide-confirm-button="true">
            <template v-slot:content>
                <v-row>
                    <v-col align="end">
                        <v-btn small color="primary" class="mb-2" @click="generateExcel">Export to Excel</v-btn>
                        <div style="overflow: auto; height: 453px; padding: 0; margin: 0;">
                            <data-table :table-data="detailView.tableData"
                                        disable-pagination
                                        hide-filters />
                        </div>
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import MapChart from 'world-map-vue';
    import MapChart2 from 'vue-chart-map';
    import requestsView from "./requestsView";
    import surveysView from "./surveysView";

    export default {
        title: "Home",
        components: {
            MapChart,
            MapChart2,
            requestsView,
            surveysView,
        },
        data(){
            return {
                isLoading: true,
                statisticsColors: ['#1B5E20', '#795548'],
                statsColors: ['#1565C0', '#ef6c00', '#FDD835', '#2E7D32'],
                statisticsHeaders: [
                    {heading: 'Total Members', field: 'TotalCount', gents: 'GentsCount', ladies: 'LadiesCount'},
                    {heading: 'Total Teams', field: 'TotalTeams', gents: 'TeamsGents', ladies: 'TeamsLadies'},
                    {heading: 'Total Officers', field: 'TotalOfficers', gents: 'OfficersGents', ladies: 'OfficersLadies'},
                    {heading: 'Surah Al-Balad', field: 'SurahAlBalad', gents: 'BaladGents', ladies: 'BaladLadies'},
                ],
                colorsList: ['#9C27B0', '#3F51B5', '#4CAF50', '#FFEB3B', '#F57C00', '#F44336', '#795548', '#607D8B', '#757575', '#FFEB3B', '#F57C00', '#F44336'],
                countryData: {},
                countryDetails: [],
                statistics: {},
                jamiyatWiseCount: [],
                roleWiseCount: [],
                rankWiseCount: [],
                hifzCount: [],
                feeStatus: {},
                recruitmentStatus: {},
                mapOverlay: {
                    show: false,
                    details: '',
                },
                requestsCount: 0,
                surveysCount: 0,
                detailView: {
                    title: '',
                    tableData: {
                        header: [
                            {text: "S.No", value: 'SNo'},
                            {text: "Its ID", value: 'ItsID'},
                            {text: "Full Name", value: 'FullName'},
                            {text: "Zone", value: 'Zone'},
                            {text: "Team", value: 'Team'},
                            {text: "Rank", value: 'Rank'},
                            {text: "Mobile No", value: 'MobileNo'},
                            {text: "Email", value: 'Email'},
                        ],
                        data: [],
                    },
                    opt: 0,
                    filter: '',
                },
            };
        },
        computed: {
            user(){
                return this.$store.getters.user;
            }
        },
        methods: {
            async getStatistics(){
                this.$loadingDialog.show();

                await axios.post('Dashboard/GetStatistics', {})
                    .then(response => {
                        // console.log('api call responded');
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            return;

                        const statistics = data.Data;

                        this.statistics = statistics['Statistics 1'][0];
                        this.jamiyatWiseCount = statistics['Statistics 2'];
                        this.roleWiseCount = statistics['Statistics 3'];
                        this.hifzCount = statistics['Statistics 4'];
                        this.countryDetails = statistics['Statistics 5'];
                        this.feeStatus = statistics['Statistics 6'][0];
                        this.rankWiseCount = statistics['Statistics 7'];
                        this.recruitmentStatus = statistics['Statistics 8'][0];

                        this.countryDetails.forEach(item => {
                            this.countryData[item.CountryCode] = 'green';
                        });

                        // this.zoneWiseCount = this.generateChartData('Members Count', 'Zone', 'TotalMembers', statistics['Statistics 3']);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.isLoading = false;

                this.$loadingDialog.hide();
            },
            generateChartData(label, labelKey, valueKey, data){
                let chartData = {
                    chartData: {
                        labels: [],
                        datasets: [
                            {
                                label: label,
                                backgroundColor: ['#00BCD4', '#4CAF50', '#FFC107', '#FF6D00', '#F44336', '#795548',
                                    '#607D8B', '#757575', '#FFEB3B', '#F57C00', '#F44336', '#00BCD4', '#4CAF50', '#FFC107', '#FF6D00', '#F44336', '#795548',
                                    '#607D8B', '#757575', '#FFEB3B', '#F57C00', '#F44336', '#00BCD4', '#4CAF50', '#FFC107', '#FF6D00', '#F44336', '#795548',
                                    '#607D8B', '#757575', '#FFEB3B', '#F57C00', '#F44336'],
                                // backgroundColor: '#4CAF50',
                                data: [],
                            }
                        ],
                    }
                };

                data.forEach(element => {
                    chartData.chartData.labels.push(element[labelKey]);
                    chartData.chartData.datasets[0].data.push(element[valueKey]);
                });

                return chartData;
            },
            isValidNumber(value) {
                return !isNaN(value);
            },
            async showDetailView(opt, filter){
                this.detailView.opt = opt;
                this.detailView.filter = filter;

                switch(opt){
                    case 1:
                        this.detailView.title = "Jamiyat Data";
                        break;
                    case 2:
                        this.detailView.title = "Functional Role Data";
                        break;
                    case 3:
                        this.detailView.title = "Hifz Data";
                        break;
                    case 4:
                        this.detailView.title = "Rank Data";
                        break;
                }

                this.$loadingDialog.show();

                let formData = {
                    Option: opt,
                    Filter: filter,
                };

                let result =  await axios.post('Dashboard/GetStatisticsDetails', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.detailView.tableData.data = data.Data;
                        else
                            this.$snackBar.error(data.Message);

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result)
                    this.$refs.dlgDetailView.show();
            },
            async generateExcel(){
                this.$loadingDialog.show();

                let formData = {
                    Option: this.detailView.opt,
                    Filter: this.detailView.filter,
                };

                await axios.post('Dashboard/GetStatisticsDetails_Excel', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        if(status) {
                            let fileUrl = data.Data;
                            window.open(fileUrl);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            countryEnter(country){
                let item = this.countryDetails.find(item => item.CountryCode == country);
                if(item != undefined) {
                    this.mapOverlay.show = true;
                    this.mapOverlay.details = `${item.Country}: ${item.Members}`;
                }
            }
        },
        async mounted() {
            await this.getStatistics();
        },
    }
</script>

<style scoped>

</style>