import infoDialog from './infoDialog.vue';

const InfoDialog = {
    // eslint-disable-next-line no-unused-vars
    install(Vue, options) {
        // We must create new Eventbus
        // which is just another Vue instance that will be listening for and emitting events from our main instance
        // this EventBus will be available as Modal.EventBus
        this.EventBus = new Vue();

        // making our modal component global
        Vue.component('info-dialog', infoDialog);

        // exposing global $modal object with method show()
        // method show() takes object params as argument
        // inside this object we can have modal title, text, styles... and also our callback confirm function
        Vue.prototype.$infoDialog = {
            show(title, message) {
                // if we use this.$modal.show(params) inside our original Vue instance
                // we will emit 'show' event with parameters 'params'
                let params = {title: title, message: message, isCritical: false};
                InfoDialog.EventBus.$emit('show', params)
            }
        }
    }
}

export default InfoDialog
