<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "pdfViewer",
        data(){
            return {
                pdfPath: "https://api.burhaniguards.com/Downloads/BGI_Manual.pdf",
            };
        },
        async mounted(){
        }
    }
</script>

<style scoped>
</style>