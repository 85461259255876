<template>
    <v-carousel v-model="carouselPage" hide-delimiters show-arrows-on-hover height="70vh">
        <v-carousel-item v-for="request in requests" :key="request.RequestID">
            <v-sheet tile light>
                <v-row>
                    <v-col class="pa-5">
                        <v-img :src="request.PictureInITS"
                               style="max-width: 100px; margin: auto; border: 1px Solid #666; border-radius: 4px;" />
                        <p class="ma-0 mt-1 body-2"><b>{{ request.FKItsID }} - {{ request.FullName }} - {{ request.Rank }}</b></p>
                        <p class="ma-0 mt-1 body-2"><b>Age:</b> {{ request.Age }}</p>
                        <p class="ma-0 mt-1 body-2"><b>Mobile No:</b> {{ request.MobileNo }}</p>
                        <p class="ma-0 mt-1 body-2"><b>Team:</b> {{ request.Hierarchy }}</p>
                        <p class="ma-0 mt-1 body-2"><b>Reason For Termination:</b></p>
                        <p class="ma-0 body-2">{{ request.Reason }}</p>
                        <p class="ma-0 mt-1 body-2"><b>Detail:</b></p>
                        <p class="body-2">{{ request.Description }}</p>
<!--                        <h4 class="mt-1">Age:</h4>-->
<!--                        <p class="ma-0">{{ request.Age }}</p>-->
<!--                        <h4 class="mt-1">Mobile No:</h4>-->
<!--                        <p class="ma-0">{{ request.MobileNo }}</p>-->
<!--                        <h4 class="mt-1">Team:</h4>-->
<!--                        <p class="ma-0">{{ request.Hierarchy }}</p>-->
<!--                        <h4 class="mt-1">Reason For Termination:</h4>-->
<!--                        <p class="ma-0">{{ request.Reason }}</p>-->
<!--                        <h4>Detail:</h4>-->
<!--                        <p>{{ request.Description }}</p>-->
                        <v-row v-if="user.FKRankID == 3 || ( user.Gender == 'Female' && (user.FKRankID == 4 || user.FKRankID == 5) )">
                            <template v-if="request.ApprovedByCommander == 1">
                                <v-col cols="12" md="6">
                                    <v-btn color="red darken-3 white--text" small class="py-4" block
                                           @click="approveRequests(request.FKItsID, 2)">
                                        Approve
                                    </v-btn>
                                </v-col>
                                <v-col md="6" cols="12">
                                    <v-btn color="primary" small class="py-4" block
                                           @click="approveRequests(request.FKItsID, 3)">
                                        Disapprove
                                    </v-btn>
                                </v-col>
                            </template>

                            <v-col v-else>
                                <v-chip label style="width:100%" :color="(request.ApprovedByCommander == 2 ? 'green' : 'red') + ' darken-3 white--text'">
                                    <v-icon left small>{{ request.ApprovedByCommander == 2 ? 'fa-check-circle' : 'fa-times-circle' }}</v-icon>
                                    {{ request.CommanderStatus }} By Commander
                                </v-chip>
                                <v-chip label style="width:100%" class="mt-2" @click="showReason(request)"
                                        :color="(request.FKStatusID == 1 ? 'orange' :
                                                request.FKStatusID == 2 ? 'green' : 'red') + ' darken-3 white--text'">
                                    <v-icon left small>{{ request.FKStatusID == 1 ? 'fa-warning' :
                                                        request.FKStatusID == 2 ? 'fa-check-circle' : 'fa-times-circle' }}</v-icon>
                                    {{ request.FKStatusID == 1 ? 'Pending' : request.FKStatusID == 2 ? 'Approved' : 'Disapproved' }} By Idara
                                    {{ request.FKStatusID == 3 ? '(Click to view reason)' : '' }}
                                </v-chip>
                                <p class="mt-3 body-2" v-if="request.FKStatusID == 2 || request.FKStatusID == 3"><b>Status Date:</b> {{ request.CompletionDate }}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-sheet>
        </v-carousel-item>
    </v-carousel>
</template>

<script>
    import axios from "axios";

    export default {
        name: "requestsView",
        data(){
            return {
                carouselPage: 0,
                requests: [],
            };
        },
        computed: {
            user(){
                return this.$store.getters.user;
            }
        },
        methods: {
            async getTerminationRequests() {
                this.$loadingDialog.show();

                const formData = {
                    HierarchyID: 0,
                    JamaatID: 0,
                    Option: 3,
                };

                await axios.post('MembersRequests/Termination_GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.requests = data.Data;

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$emit('updateCount', this.requests.length);

                this.$loadingDialog.hide();
            },
            async approveRequests(itsID, status) {
                let rejectReason = "";

                const isApprove = await this.$confirmDialog.show({
                    title: `${status == 2 ? 'Approve' : 'Disapprove'} Termination Request`,
                    message: `Are you sure you want to ${status == 2 ? 'approve' : 'disapprove'} termination request of the selected member? Please confirm.`,
                    isCritical: status == 2,
                });

                if(!isApprove)
                    return;

                if(status == 3) {
                    rejectReason = await this.$inputDialog.show({
                        title: 'Disapprove Termination Request',
                        message: 'Burhani Guards Idara will verify the request and approve/disapprove as applicable.</br>' +
                            'Please enter reason to disapprove below.'
                    });

                    if(!rejectReason)
                        return;
                }

                this.isLoading = true;

                const formData = {
                    ItsID: itsID,
                    Status: status,
                    Reason: rejectReason,
                };

                const result = await axios.post('MembersRequests/Termination_Approve', formData)
                    .then(response => {
                        console.log(response);

                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                    await this.getTerminationRequests();

                this.isLoading = false;
            },
            showReason(request) {
                if(request.FKStatusID == 3)
                    this.$infoDialog.show('Reason For Disapproval', request.RejectionReason);
            }
        },
        async mounted(){
            await this.getTerminationRequests();

            console.log(this.requests);
        }
    }
</script>

<style scoped>

</style>