<template>
    <custom-dialog ref="dlg"
                   v-bind="$attrs"
                   v-on="$listeners"
                   :is-critical="isCritical">
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
            <slot :name="name" v-bind="data"></slot>
        </template>
        <template v-slot:actions>
            <v-btn text @click="hide">{{ cancelButtonText == undefined ? 'Cancel' : cancelButtonText }}</v-btn>
            <v-btn :color="isCritical ? 'red darken-4' : 'primary'" class="white--text" @click="ok" v-if="!hideConfirmButton">{{ confirmButtonText == undefined ? 'Save' : confirmButtonText }}</v-btn>
        </template>
    </custom-dialog>
</template>

<script>
    import customDialog from "./customDialog";

    export default {
        name: "mallyDialog",
        components: {
            customDialog
        },
        props: ['closeOnSave', 'isCritical', 'onConfirm', 'confirmButtonText', 'cancelButtonText', 'hideConfirmButton'],
        data(){
            return {
                isCloseOnSave: true,
            };
        },
        methods: {
            show(){
                this.$refs.dlg.show();
            },
            hide(){
                this.$refs.dlg.hide();
            },
            ok(){
                if(typeof this.onConfirm === 'function')
                    this.onConfirm(true);

                if(this.isCloseOnSave)
                    this.$refs.dlg.hide();
            }
        },
        created(){
            this.isCloseOnSave = this.closeOnSave !== undefined ? true : this.closeOnSave;
        }
    }
</script>

<style scoped>

</style>