<template>
    <v-container fluid>
        <v-row>
            <v-col><h2>Miqaat Tasks</h2></v-col>
            <v-col align="end">
                <v-btn color="primary" @click="addTask" class="py-4" small>New Task</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-row>
                    <v-col>
                        <data-table v-model="tableData.selected"
                                    :table-data="tableData"
                                    :items-per-page="tableData.noOfRows"
                                    :total-pages="tableData.totalPages"
                                    item-key="TaskID"
                                    @pageChanged="pageChanged"
                                    @filterChanged="filterChanged">
                            <template v-slot:item.Priority="{item}">
                                <v-chip label
                                        :color="priorityColor(item.Priority)"
                                        class="white--text">
                                    {{ item.Priority }}
                                </v-chip>
                            </template>
                            <template v-slot:item.IsCompleted="{item}">
                                <v-icon :color="item.IsCompleted ? 'success' : 'error'">{{ item.IsCompleted ? 'fa-check' : 'fa-close' }}</v-icon>
                            </template>
                            <template v-slot:item.actions="{item}">
                                <context-menu :data-item="item" :menu-items="tableData.menu.items" />
                            </template>
                        </data-table>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgTask"
                       width="500"
                       title="Task"
                       confirm-button-text="Save"
                       :on-confirm="saveTask">
            <template v-slot:content>
                <v-row>
                    <v-col>
                        <combo-box label="Type"
                                   v-model="task.TypeID"
                                   :items="types"
                                   item-text="Description"
                                   item-value="Value01"
                                   class="mt-2"
                                   @change="taskTypeChanged" />

                        <combo-box label="Parent"
                                   v-model="task.FKMiqaatHierarchyID"
                                   :items="miqaatHierarchy"
                                   item-text="Name"
                                   item-value="MiqaatHierarchyID"
                                   class="mt-2" />

                        <combo-box label="Priority"
                                   v-model="task.Priority"
                                   :items="['Critical', 'High', 'Normal']"
                                   class="mt-2" />

                        <text-box label="Task Description"
                                  v-model="task.Description"
                                  class="mt-2" />
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>

    </v-container>
</template>
<script>
import axios from "axios";

export default {
    name: 'c-tasks-list',
    props: ['miqaatId', 'miqaatHierarchyId'],
    computed: {
        selectedTasks(){
            return this.tableData.selected.map(item => item.TaskID);
        },
    },
    data() {
        return {
            types: [],
            miqaatHierarchy: [],
            tableData: {
                totalCount: 3,
                header: [
                    {text: 'S.No', value: 'SNo'},
                    {text: 'Task', value: 'Description', applyFilter: true, },
                    {text: 'Level', value: 'TaskType', applyFilter: true, },
                    {text: 'For', value: 'Name', applyFilter: true, },
                    {text: 'Priority', value: 'Priority', applyFilter: true, },
                    {text: 'Date', value: 'CreateDate', applyFilter: true, },
                    {text: 'Created By', value: 'CreatedByUser', applyFilter: true, },
                    {text: 'Status', value: 'IsCompleted' },
                    {text: 'Actions', value: 'actions' },
                ],
                data: [],
                filters: {},
                noOfRows: this.tableRows,
                totalPages: 1,
                pageNo: 1,
                selected: [],
                menu: {
                    title: '',
                    items: [
                        { icon: 'fa-pencil', title: 'Edit', onClick: this.editTask },
                        { icon: 'fa-trash', title: 'Delete', onClick: this.deleteTask },
                    ]
                },
            },
            task: {
                TaskID: 0,
                FKMiqaatHierarchyID: 0,
                TypeID: 0,
                Priority: '',
                Description: '',
            },
        }
    },    
    methods: {
        async getData() {
            this.$loadingDialog.show();

            const formData = {
                MiqaatHierarchyID: this.miqaatHierarchyId,
                PageNumber: this.tableData.pageNo,
                NoOfRows: this.tableData.noOfRows,
                HasFilter: !!Object.keys(this.tableData.filters).length,
                Filters: this.tableData.filters,
            }

            await axios.post(`Miqaat/Tasks_GetData`, formData)
                .then(response => {
                    const data = response.data;
                    const status = data.Status;

                    if (status) {
                        this.tableData.data = data.Data.Items;
                        this.tableData.totalPages = data.Data.TotalPages;
                    } else
                        this.$snackBar.error(data.Message);
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.error('An unexpected error has occurred');
                });

            this.$loadingDialog.hide();
        },
        priorityColor(item){
            const colors = [
                { name: 'Critical', color: 'red darken-2' },
                { name: 'High', color: 'orange darken-2' },
                { name: 'Normal', color: 'green darken-2' },
            ];

            return colors.find(color => item == color.name).color;
        },
        pageChanged(pageNo){
            this.tableData.pageNo = pageNo;
            this.getData();
        },
        filterChanged(){
            this.getData();
        },
        async taskTypeChanged(){
            this.$loadingDialog.show();
            let lineageID = 0;
            let parentID = 0;

            if(this.task.TypeID == 1)
                lineageID = this.miqaatHierarchyId;
            else if(this.task.TypeID != 3)
                parentID = this.miqaatHierarchyId;

            this.miqaatHierarchy = await this.getMiqaatHierarchy(1, this.task.TypeID, parentID, lineageID);
            this.$loadingDialog.hide();
        },
        addTask(){
            this.task = {
                TaskID: 0,
                FKMiqaatHierarchyID: 0,
                TypeID: 0,
                Priority: 'Normal',
                Description: '',
            };

            this.$refs.dlgTask.show();
        },
        async editTask(task){
            this.task = {
                TaskID: task.TaskID,
                FKMiqaatHierarchyID: task.FKMiqaatHierarchyID,
                TypeID: task.TypeID,
                Priority: task.Priority,
                Description: task.Description,
            };

            this.$loadingDialog.show();

            let lineageID = 0;
            let parentID = 0;

            if(this.task.TypeID == 1)
                lineageID = this.miqaatHierarchyId;
            else if(this.task.TypeID != 3)
                parentID = this.miqaatHierarchyId;

            this.miqaatHierarchy = await this.getMiqaatHierarchy(1, this.task.TypeID, parentID, lineageID);

            this.$refs.dlgTask.show();

            this.$loadingDialog.hide();
        },
        async saveTask() {
            this.$loadingDialog.show();

            const result = await axios.post(`Miqaat/Tasks_AddEdit`, this.task)
                .then(response => {
                    const data = response.data;
                    const status = data.Status;

                    return status;
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.error('An unexpected error has occurred');
                });

            if(result)
                await this.getData();

            this.$loadingDialog.hide();
        },
        async deleteTask(){
            const result = await this.$confirmDialog.show({
                title: 'Delete Task',
                message: 'Are you sure you want to delete this task? Please confirm',
            });

            if(result)
                console.log(this.selectedTasks);
        },
    },
    async mounted() {
        this.$loadingDialog.show();

        this.types = await this.getGeneral(5, 0);
        await this.getData();

        this.$loadingDialog.hide();
    },
}
</script>

<style scoped>

</style>