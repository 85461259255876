<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h2 class="headline">Miqaat</h2>
            </v-col>
            <v-col align="end">
                <v-btn color="primary" @click="addMiqaat" v-if="rights.allowAdd"><v-icon left>fa-plus</v-icon> New Miqaat</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <data-table v-model="tableData.selected"
                            :table-data="tableData"
                            :items-per-page="tableData.noOfRows"
                            :total-pages="tableData.totalPages"
                            item-key="MiqaatID"
                            show-select
                            disable-sort
                            wrap-text
                            @click:row="miqaatClick"
                            :item-class="miqaatClass"
                            @pageChanged="pageChanged"
                            @filterChanged="filterChanged">
                    <template v-slot:item.MiqaatDates="{item}">
                        {{ item.StartDate }} To {{ item.EndDate }}
                    </template>
                    <template v-slot:item.RegistrationDates="{item}">
                        {{ item.RegistrationStartDate }} To {{ item.RegistrationEndDate }}
                    </template>
                    <template v-slot:item.actions="{item}">
                        <context-menu :data-item="item" :menu-items="menu.items" />
                    </template>
                </data-table>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgMiqaat" width="800px" title="New Miqaat" :on-confirm="saveMiqaat">
            <template v-slot:content>
                <v-row>
                    <v-col>
                        <text-box label="Miqaat Name" v-model="miqaat.Name" />
                        <combo-box label="Miqaat Zone" v-model="miqaat.FKZoneID"
                                   class="mt-3"
                                   :items="zones"
                                   item-text="Name"
                                   item-value="HierarchyID" />
                        <v-row dense>
                            <v-col>
                                <combo-box label="Country"
                                           v-model="miqaat.Country"
                                           class="mt-3"
                                           :items="countries"
                                           item-text="Description"
                                           item-value="Description"
                                           autocomplete="off"/>
                            </v-col>
                            <v-col>
                                <text-box label="City"
                                          v-model="miqaat.City"
                                          class="mt-3" />
                            </v-col>
                            <v-col>
                                <combo-box label="Time Zone"
                                           v-model="miqaat.TimeZone"
                                           class="mt-3"
                                           :items="timeZones"
                                           item-text="Description"
                                           item-value="Description" />
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <text-box label="Miqaat Start Date"
                                          v-model="miqaat.StartDate"
                                          type="date"
                                          class="mt-3" />
                            </v-col>
                            <v-col>
                                <text-box label="Miqaat End Date"
                                          v-model="miqaat.EndDate"
                                          type="date"
                                          class="mt-3" />
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <text-box label="Registration Start Date"
                                          v-model="miqaat.RegistrationStartDate"
                                          type="date"
                                          class="mt-3" />
                            </v-col>
                            <v-col>
                                <text-box label="Registration End Date"
                                          v-model="miqaat.RegistrationEndDate"
                                          type="date"
                                          class="mt-3" />
                            </v-col>
                            <v-col>
                                <text-box label="Registration End Date (Team Approval)"
                                          v-model="miqaat.RegistrationEndDate_Teams"
                                          type="date"
                                          class="mt-3" />
                            </v-col>
                        </v-row>
                        <text-box label="Registration Instructions"
                                  v-model="miqaat.Instructions"
                                  class="mt-3"
                                  multi-row
                                  rows="2" />
<!--                        <text-box label="Members Quota"-->
<!--                                  v-model="miqaat.AllowedMembers"-->
<!--                                  class="mt-3" />-->
                        <combo-box label="Miqaat Type"
                                   v-model="miqaat.FKMiqaatTypeID"
                                   class="mt-3"
                                   :items="miqaatTypes"
                                   item-text="Description"
                                   item-value="Value01" />
                        <combo-box label="Miqaat Incharge"
                                   v-model="miqaat.FKInchargeITS"
                                   :loading="members.loading"
                                   :search-input.sync="members.search"
                                   :items="members.items"
                                   item-text="FullName"
                                   item-value="ItsID"
                                   class="mt-3"
                                   hide-no-data
                                   cache-items />
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>

        <simple-dialog ref="dlgAdmins" width="600" title="Miqaat Admins" hide-confirm-button="true" cancel-button-text="Close">
            <template v-slot:content>
                <v-row>
                    <v-col class="d-flex">
                        <combo-box label="Miqaat Admin"
                                   v-model="miqaatAdmin.FKItsID"
                                   :loading="membersAdmins.loading"
                                   :search-input.sync="membersAdmins.search"
                                   :items="membersAdmins.items"
                                   item-text="FullName_ITS"
                                   item-value="ItsID"
                                   hide-no-data
                                   cache-items />
                        <v-btn color="primary" class="ml-2 py-4" small @click="addAdmin">Add</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-list dense v-if="miqaatAdmin.Admins.length > 0">
                            <v-list-item v-for="admin in miqaatAdmin.Admins" :key="admin.ItsID">
                                <v-list-item-content>
                                    <v-list-item-title>{{ admin.FullName }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ admin.ItsID }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn color="red darken-4" icon small @click="deleteAdmin(admin)"><v-icon>fa-trash</v-icon></v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                        <h4 v-else>You can add an admin by clicking the "Add" button above</h4>
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>

    </v-container>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment';

    export default {
        title: 'Miqaat List',
        data() {
            return {
                tableData: {
                    header: [
                        {text: "S.No", value: 'SNo'},
                        {text: "Miqaat", value: 'Name', applyFilter: true},
                        {text: "Zone", value: 'Zone', applyFilter: true},
                        {text: "Miqaat Date", value: 'MiqaatDates'},
                        {text: "Registration Date", value: 'RegistrationDates'},
                        // {text: "Members Quota", value: 'AllowedMembers'},
                        // {text: "Zones", value: 'AllowedZonesNames', applyFilter: true},
                        {text: "Incharge", value: 'Incharge', applyFilter: true},
                        {text: "Created On", value: 'CreateDate'},
                        {text: "Actions", value: 'actions'},
                    ],
                    data: [],
                    filters: {},
                    noOfRows: this.tableRows,
                    totalPages: 1,
                    pageNo: 1,
                    selected: [],
                },
                menu: {
                    title: '',
                    items: [
                        { icon: 'fa-users', title: 'Admins', onClick: this.viewAdmins },
                    ]
                },
                miqaat: {
                    MiqaatID: 0,
                    FKZoneID: 0,
                    Name: '',
                    StartDate: '',
                    EndDate: '',
                    RegistrationStartDate: '',
                    RegistrationEndDate: '',
                    RegistrationEndDate_Teams: '',
                    Country: '',
                    City: '',
                    TimeZone: '',
                    FKMiqaatTypeID: 0,
                    Instructions: '',
                    FKInchargeITS: '',
                },
                zones: [],
                miqaatTypes: [],
                countries: [],
                timeZones: [],
                members: {
                    loading: false,
                    items: [],
                    search: '',
                },
                membersAdmins: {
                    loading: false,
                    items: [],
                    search: '',
                },
                miqaatAdmin: {
                    FKMiqaatHierarchyID: 0,
                    FKItsID: 0,
                    Admins: [],
                },
                rights: {
                    allowAdd: this.verifyRights(this.$route.name, 'A'),
                    allowEdit: this.verifyRights(this.$route.name, 'E'),
                    allowDelete: this.verifyRights(this.$route.name, 'D'),
                }
            };
        },
        methods: {
            miqaatClass(){
              return 'hand-cursor';
            },
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    MiqaatID: 0,
                    PageNumber: this.tableData.pageNo,
                    NoOfRows: this.tableData.noOfRows,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                }

                await axios.post(`Miqaat/GetData`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            this.tableData.data = data.Data.Items;
                            this.tableData.totalPages = data.Data.TotalPages;
                        }
                        else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            pageChanged(pageNo){
                this.tableData.pageNo = pageNo;
                this.getData();
            },
            filterChanged(){
                this.getData();
            },
            async loadMembers(val, isAdmin){
                if(isAdmin) {
                    this.membersAdmins.loading = true;
                    this.membersAdmins.items = await this.getMembers({"FullName_ITS": val});
                    this.membersAdmins.loading = false;
                }
                else {
                    this.members.loading = true;
                    this.members.items = await this.getMembers({"FullName_ITS": val});
                    this.members.loading = false;
                }
            },
            miqaatClick(item){
                this.$router.push({name: 'miqaatDetails', params: {miqaatId: item.MiqaatID.toString()}});
            },
            addMiqaat(){
                this.miqaat = {
                    MiqaatID: 0,
                    FKZoneID: 0,
                    Name: '',
                    StartDate: '',
                    EndDate: '',
                    RegistrationStartDate: '',
                    RegistrationEndDate: '',
                    RegistrationEndDate_Teams: '',
                    Country: 'India',
                    City: 'Mumbai',
                    TimeZone: 'GMT+5:30',
                    FKMiqaatTypeID: 0,
                    Instructions: '',
                    FKInchargeITS: '',
                };

                this.$refs.dlgMiqaat.show();
            },
            editMiqaat(item){
                this.miqaat = {
                    MiqaatID: item.MiqaatID,
                    FKZoneID: item.FKZoneID,
                    Name: item.Name,
                    StartDate: new moment(item.StartDate).format('YYYY-MM-DD'),
                    EndDate: new moment(item.EndDate).format('YYYY-MM-DD'),
                    RegistrationStartDate: new moment(item.RegistrationStartDate).format('YYYY-MM-DD'),
                    RegistrationEndDate: new moment(item.RegistrationEndDate).format('YYYY-MM-DD'),
                    RegistrationEndDate_Teams: new moment(item.RegistrationEndDate_Teams).format('YYYY-MM-DD'),
                    Country: item.Country,
                    City: item.City,
                    TimeZone: item.TimeZone,
                    FKMiqaatTypeID: item.FKMiqaatTypeID,
                    Instructions: item.Instructions,
                    FKInchargeITS: item.FKInchargeITS,
                };

                console.log(this.miqaat);

                this.members.items.push({FKItsID: item.FKInchargeITS, FullName: item.Incharge});

                this.$refs.dlgMiqaat.show();
            },
            async saveMiqaat(){
                if(this.miqaat.StartDate.length == 0) {
                    this.$snackBar.error('Please select a miqaat start date');
                    return;
                }

                if(this.miqaat.EndDate.length == 0) {
                    this.$snackBar.error('Please select a miqaat end date');
                    return;
                }

                if(this.miqaat.RegistrationStartDate.length == 0) {
                    this.$snackBar.error('Please select a registration start date');
                    return;
                }

                if(this.miqaat.RegistrationEndDate.length == 0) {
                    this.$snackBar.error('Please select a registration end date');
                    return;
                }

                if(this.miqaat.RegistrationEndDate_Teams.length == 0) {
                    this.$snackBar.error('Please select an approval end date for teams');
                    return;
                }

                this.$loadingDialog.show();

                // this.miqaat.AllowedZones = this.miqaat.AllowedZonesArray.toString();

                const formData = {
                    Miqaat: this.miqaat
                };

                const result = await axios.post('Miqaat/AddEdit', formData)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                {
                    this.$refs.dlgMiqaat.hide();
                    await this.getData();
                }

                this.$loadingDialog.hide();
            },
            async deleteMiqaat(item){
                console.log(item);

                const result = await this.$confirmDialog.show({
                    title: "Delete Miqaat",
                    message: `Are you sure you want to delete "${item.Name}"? Please confirm.`,
                    isCritical: true,
                });

                if(result){
                    this.$loadingDialog.show();

                    const resp = await axios.post("Miqaat/Delete", { MiqaatID: item.MiqaatHierarchyID })
                        .then(async response => {
                            const data = response.data;
                            const status = data.Status;

                            this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                            return status;
                        })
                        .catch(error => {
                            console.log(error);
                            this.$snackBar.error('An unexpected error has occurred');
                        });

                    if(resp)
                        await this.getData();

                    this.$loadingDialog.hide();
                }
            },
            async getAdmins(miqaatID){
                this.$loadingDialog.show();

                const resp = await axios.post("Miqaat/Admins_GetData", { MiqaatHierarchyID: miqaatID })
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            return data.Data;
                        else {
                            this.$snackBar.error(data.Message);
                            return [];
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                return resp;
            },
            async viewAdmins(item){
                this.miqaatAdmin = {
                    FKMiqaatHierarchyID: item.MiqaatHierarchyID,
                    FKItsID: 0,
                    Admins: await this.getAdmins(item.MiqaatHierarchyID),
                };

                this.$refs.dlgAdmins.show();
            },
            async addAdmin(){
                if(this.miqaatAdmin.FKItsID == 0) {
                    this.$snackBar.error('Please select a member first');
                    return;
                }

                this.$loadingDialog.show();

                const resp = await axios.post("Miqaat/Admins_Add", this.miqaatAdmin)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(resp)
                    this.miqaatAdmin.Admins = await this.getAdmins(this.miqaatAdmin.FKMiqaatHierarchyID);

                this.$loadingDialog.hide();
            },
            async deleteAdmin(item){
                const isDelete = await this.$confirmDialog.show({
                    title: "Remove Admin",
                    message: `Are you sure you want to remove "${item.FullName}" as admin? Please confirm.`,
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                const formData = {
                    FKMiqaatHierarchyID: this.miqaatAdmin.FKMiqaatHierarchyID,
                    FKItsID: item.ItsID,
                };

                this.$loadingDialog.show();

                const resp = await axios.post("Miqaat/Admins_Delete", formData)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(resp)
                    this.miqaatAdmin.Admins = await this.getAdmins(this.miqaatAdmin.FKMiqaatHierarchyID);

                this.$loadingDialog.hide();
            },
        },
        watch: {
            'members.search'(val) {
                val && val !== this.miqaat.miqaat_incharge && this.loadMembers(val, false);
            },
            'membersAdmins.search'(val) {
                val && val !== this.miqaatAdmin.FKItsID && this.loadMembers(val, true);
            },
        },
        async mounted() {
            this.tableData.noOfRows = this.tableRows;

            this.$loadingDialog.show();

            this.miqaatTypes = await this.getGeneral(6, 0);
            this.countries = await this.getGeneral(10, 0);
            this.timeZones = await this.getGeneral(11, 0);
            this.zones = await this.getOrgHierarchy(1, 2, 0, 0);

            this.$loadingDialog.hide();

            await this.getData();

            if(this.rights.allowEdit)
                this.menu.items.push({ icon: 'fa-pencil', title: 'Edit', onClick: this.editMiqaat });

            if(this.rights.allowDelete)
                this.menu.items.push({ icon: 'fa-trash', title: 'Delete', onClick: this.deleteMiqaat },);

            console.log(this.rights);
        },
    }
</script>

<style>
</style>
