<template>
    <v-container fluid>
        <v-row>
            <v-col md="8" cols="12" class="offset-md-2">
                <v-card>
                    <v-card-text>
                        <h2 class="headline text-center">{{ form.FormTitle }}</h2>
                        <h4 class="text-center">{{ form.FormDescription }}</h4>

                        <template v-if="'ITS_ID' in member">
                            <v-divider class="my-3" />
                            <div class="d-flex justify-center align-center">
                                <v-img :src="'data:image/jpeg;base64,' + member.Photo" class="mt-2 mr-2"
                                       style="max-width:100px !important; border: 2px Solid #c3c3c3; border-radius: 4px;"/>
                                <div>
                                    <h3>{{ member.ITS_ID }} - {{ member.Fullname }}</h3>
                                    <h4> {{ member.Jamaat }}</h4>
                                    <h4> Mobile No: {{ member.Mobile }}</h4>
                                </div>
                            </div>
                        </template>
                    </v-card-text>
                </v-card>
                <v-card class="mt-3">
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <template v-for="(question, i) in form.Questions">
                                    <div :key="question.QuestionID">
                                        <h4>{{ question.Question }}</h4>
                                        <p class="my-0">{{ question.Description }}</p>
                                        <template v-if="question.QuestionType == 'RadioGroup'">
                                            <v-radio-group hide-details row class="mt-2"
                                                           v-model="answer(question).Answer">
                                                <v-radio v-for="option in question.Options.split(',')"
                                                         :label="option"
                                                         :key="option"
                                                         :value="option"
                                                         color="primary" />
                                            </v-radio-group>
                                        </template>
                                        <template v-else-if="question.QuestionType == 'Text'">
                                            <text-box class="mt-2"
                                                      v-model="answer(question).Answer" />
                                        </template>
                                        <template v-else-if="question.QuestionType == 'Description'">
                                            <text-box multi-row rows="3" class="mt-2"
                                                      v-model="answer(question).Answer" />
                                        </template>
                                        <v-divider v-if="(i + 1 ) < form.Questions.length" class="my-4" />
                                    </div>
                                </template>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="mt-3">
                    <v-card-text>
                        <v-row>
                            <v-col align="end">
                                <v-btn text class="mr-2" @click="cancelForm">Cancel</v-btn>
                                <v-btn color="primary" @click="saveForm">Save</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "Enrollment Form",
        data(){
            return {
                member: {},
                form: {
                    FormTitle: '',
                    FormDescription: '',
                    Questions: [],
                },
                formDetail: [],
            };
        },
        computed: {
            user(){
                return this.$store.getters.user;
            },
        },
        methods: {
            async getForm(){
                this.$loadingDialog.show();

                const formData = {
                    FormID: 2,
                    PageNumber: 1,
                    NoOfRows: 1,
                    HasFilter: false,
                    Filters: {},
                    IsAmilSaheb: false,
                }

                await axios.post('RecruitmentForm/GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.form = data.Data.Items[0];

                        this.form.Questions.forEach(item => {
                            this.formDetail.push({
                                FKQuestionID: item.QuestionID,
                                Answer: ''
                            });
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            answer(question){
                let answer = this.formDetail.find(item => item.FKQuestionID == question.QuestionID);
                return answer;
            },
            async cancelForm(){
                const isCancel = await this.$confirmDialog.show({
                    title: 'Cancel Form',
                    message: 'Are you sure you want to cancel this form? All unsaved data will be lost.',
                    isCritical: true,
                });

                if(!isCancel)
                    return;

                this.$router.back();
            },
            async saveForm(){
                this.$loadingDialog.show();

                const formData = {
                    FormID: 2,
                    ItsID: this.member.ITS_ID,
                    HierarchyID: this.member.FKHierarchyID,
                    Answers: this.formDetail,
                    Member: [this.member],
                }

                let status = await axios.post('Enrollment/AddRequest', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(status) {
                    const vm = this;
                    setTimeout(function(){
                        vm.$router.back();
                    }, 1000);
                }

                this.$loadingDialog.hide();
            }
        },
        async mounted(){
            if('ITS_ID' in this.$store.getters.newMember == false) {
                this.$router.back();
                return;
            }

            this.member = this.$store.getters.newMember;
            // await this.$store.dispatch('addNewMember', '');

            await this.getForm();
        },
    }
</script>

<style scoped>

</style>