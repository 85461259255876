<template>
    <table dense class="my-table">
        <thead>
            <tr>
                <th width="50px">S No</th>
                <th width="75px">Its</th>
                <th width="425px">Full Name</th>
                <th width="225px">Team</th>
                <th width="150px">Rank</th>
                <th width="50px">Years In BGI</th>
                <th width="50px">Photo</th>
<!--                <th v-for="header in tableData.header">-->
<!--                    {{ header.text }}-->
<!--                </th>-->
            </tr>
        </thead>
        <tbody>
            <tr v-for="row in tableData.data">
                <td v-for="header in tableData.header" style="word-wrap: normal; word-break: normal; text-wrap: normal;">
                    <template v-if="header.value == 'PictureInITS'">
                        <v-img style="max-height: 60px; min-height: 60px; max-width: 50px; border: 1px Solid #ccc; border-radius: 4px;"
                               class="my-1"
                               :src="row[header.value]" contain :alt="row['ItsID'].toString()" />
                    </template>
                    <template v-else>
                        {{ row[header.value] }}
                    </template>
                </td>
            </tr>
        </tbody>
    </table>
<!--    <data-table ref="tblMembers"-->
<!--                :table-data="tableData"-->
<!--                hide-filters-->
<!--                disable-pagination>-->
<!--        <template v-slot:item.PictureInITS="{item}">-->
<!--            <v-img style="max-height: 60px; min-height: 60px; max-width: 50px; border: 1px Solid #ccc; border-radius: 4px;"-->
<!--                   class="my-1"-->
<!--                   :src="item.PictureInITS" contain :alt="item.ItsID.toString()" />-->
<!--        </template>-->
<!--    </data-table>-->
</template>

<script>
    export default {
        name: "reportViewer",
        props: ['dataSet'],
        data(){
            return {
                tableData: {
                    header: [],
                    data: [],
                    filters: {},
                    selected: [],
                },
            };
        },
        async mounted(){
            Object.keys(this.dataSet[0]).forEach(key => {
                this.tableData.header.push({text: key, value: key});
            });

            this.tableData.data = this.dataSet;
        }
    }
</script>

<style scoped>
    .my-table {
        word-wrap: normal;
    }

    .my-table {
        background: #ffffff;
        border-collapse: collapse;
        border: 2px Solid #c3c3c3;
    }

    .my-table th {
        border: 2px Solid #c3c3c3;
        padding: 0px 5px;
    }

    .my-table td {
        border: 1px Solid #c3c3c3;
        padding: 5px 7px;
    }

</style>