<template>
    <v-container fluid>
        <v-row>
            <v-col><h2>Dashboard</h2></v-col>
            <v-col align="end">
                <v-btn-toggle v-model="gender" color="grey" @change="getData">
                    <v-btn color="primary" value="All" small class="py-4">All</v-btn>
                    <v-btn color="primary" value="Male" small class="py-4">Gents</v-btn>
                    <v-btn color="primary" value="Female" small class="py-4">Ladies</v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-for="(key, i) in Object.keys(statistics)" :key="key">
                <v-card outlined :color="statisticsColors[i]">
                    <v-card-title class="white--text pb-0"><b>{{ statistics[key] }}</b></v-card-title>
                    <v-card-text class="white--text "><b>{{ key }}</b></v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card outlined>
                    <v-card-title>Registrations (Rank-wise)</v-card-title>
                    <v-card-text>
                        <pie-chart :chart-data="rankWiseRegistrationsGraph.chartData"
                                   :styles="{ height: '350px' }"
                                   :options="{maintainAspectRatio: false, responsive: true}" />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card outlined>
                    <v-card-title>Registration Status</v-card-title>
                    <v-card-text>
                        <pie-chart :chart-data="registrationGraph.chartData"
                                   :styles="{ height: '350px' }"
                                   :options="{maintainAspectRatio: false, responsive: true}" />
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title>Registrations</v-card-title>
                    <v-card-text>
                        <v-list dense>
                            <v-simple-table dense class="custom-table">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th width="50%">Team</th>
                                            <th width="10%">Quota</th>
                                            <th width="10%">Reg. Officers</th>
                                            <th width="10%">Reg. Members</th>
                                            <th width="20%">Total Registered</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(team) in registrations">
                                            <tr :key="team.HierarchyID" style="">
                                                <td width="50%">{{ team.Hierarchy }}</td>
                                                <td width="10%">{{ team.Quota }}</td>
                                                <td width="10%">{{ team.RegisteredOfficers }}</td>
                                                <td width="10%">{{ team.RegisteredMembers }}</td>
                                                <td width="20%">
                                                    <span style="font-weight: bold;">{{ team.RegisteredMembers + team.RegisteredOfficers }}</span>
                                                    <span style="float: right;">({{ parseFloat(((team.RegisteredMembers + team.RegisteredOfficers)/team.Quota)*100).formatDecimals() }}%)</span>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th width="50%">Total</th>
                                            <th width="10%">{{ getTotal(registrations, 'Quota') }}</th>
                                            <th width="10%">{{ getTotal(registrations, 'RegisteredOfficers') }}</th>
                                            <th width="10%">{{ getTotal(registrations, 'RegisteredMembers') }}</th>
                                            <th width="20%">{{ getTotal(registrations, 'RegisteredOfficers') + getTotal(registrations, 'RegisteredMembers') }}</th>
                                        </tr>
                                    </tfoot>
                                </template>
                            </v-simple-table>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from "axios";

export default {
    name: 'c-tasks-list',
    props: ['miqaatId', 'miqaatHierarchyId'],
    computed: {
    },
    data() {
        return {
            gender: 'All',
            colorsList: ['#9C27B0', '#3F51B5', '#4CAF50', '#FFEB3B', '#F57C00', '#F44336', '#795548', '#607D8B', '#757575', '#FFEB3B', '#F57C00', '#F44336'],
            statisticsColors: ['#00BCD4', '#4CAF50', '#FFC107', '#FF6D00', '#F44336'],
            statistics: {},
            registrationGraph: {},
            rankWiseRegistrationsGraph: {},
            registrations: [],
        }
    },    
    methods: {
        async getData(){
            this.$loadingDialog.show();

            const formData = {
                MiqaatID: this.miqaatId,
                MiqaatHierarchyID: this.miqaatHierarchyId,
                Gender: this.gender,
            };

            await axios.post('Dashboard/Miqaat', formData)
                .then(response => {
                    const data = response.data;
                    const status = data.Status;

                    if(!status)
                        return;

                    const statistics = data.Data;

                    this.statistics = statistics['Statistics 1'][0];
                    this.registrationGraph = this.generateChartData('Members Registrations', 'Status', 'TotalMembers', statistics['Statistics 2']);
                    this.rankWiseRegistrationsGraph = this.generateChartData('Members Registrations Rank-Wise', 'Rank', 'TotalCount', statistics['Statistics 3']);
                    this.registrations = statistics['Statistics 4'];
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.error('An unexpected error has occurred');
                });

            this.$loadingDialog.hide();
        },
        generateChartData(label, labelKey, valueKey, data){
            let chartData = {
                chartData: {
                    labels: [],
                    datasets: [
                        {
                            label: label,
                            backgroundColor: ['#00BCD4', '#4CAF50', '#FFC107', '#FF6D00', '#F44336', '#795548', '#607D8B', '#757575', '#FFEB3B', '#F57C00', '#F44336'],
                            data: [],
                        }
                    ],
                }
            };

            data.forEach(element => {
                chartData.chartData.labels.push(element[labelKey]);
                chartData.chartData.datasets[0].data.push(element[valueKey]);
            });

            return chartData;
        },
    },
    async mounted() {
        await this.getData();
    },
}
</script>

<style scoped>
    .custom-table tbody {
         display: block;
         height: 400px !important;
         overflow: auto !important;
    }
    .custom-table thead, tfoot, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
</style>