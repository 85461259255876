<template>
    <v-app :style="{backgroundImage: 'url(' + require('@/assets/bg_02.jpg') + ')'}">
        <v-app-bar app color="red darken-3">
            <v-toolbar-title class="headline white--text d-flex justify-center align-center">
                <v-img src="@/assets/BGIlogo.png" contain
                       :height="$vuetify.breakpoint.smAndDown ? '50px' : '80px'"
                       :width="$vuetify.breakpoint.smAndDown ? '50px' : '80px'"
                       :class="$vuetify.breakpoint.smAndDown ? 'elevation-4 pa-2' : 'mt-5 elevation-4 pa-2'"
                       style="background-color: #fff; border-radius: 70px;" />
            </v-toolbar-title>
            <v-toolbar-items class="ml-5">
                <v-btn text small color="white"><v-icon small left>fa-home</v-icon> Home</v-btn>
            </v-toolbar-items>
        </v-app-bar>

        <v-main app style="padding-top: 74px;">
            <v-container fluid>
                <v-overlay v-if="isLoading">
                    <v-progress-circular color="white" indeterminate size="53" class="mt-2 ml-3" />
                </v-overlay>

                <v-row>
                    <v-col>
                        <v-card class="py-3">
                            <h2 class="headline text-center">Ashara Mubaraka 1444H - London, UK</h2>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>

        <confirm-dialog />
        <snack-bar />

    </v-app>
</template>

<script>
    export default {
        title: 'Ashara Mubaraka 1444H',
        components: {
        },
        data(){
            return {
                isLoading: false,
            };
        },
        methods: {
        },
        async created(){
        },
    }
</script>

<style scoped>
</style>