<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h2 class="headline">Enrollment Requests</h2>
            </v-col>
            <v-col align="end">
                <v-btn small class="py-4" color="primary" @click="viewNewMembers"
                       v-if="rights.allowAdd">
                    <v-icon left small>fa-user-plus</v-icon> Add New Request
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <data-table ref="tblMembers"
                            v-model="tableData.selected"
                            :table-data="tableData"
                            :total-pages="tableData.totalPages"
                            item-key="ItsID"
                            @pageChanged="pageChanged"
                            @filterChanged="filterChanged"
                            disable-sort
                            show-select wrap-text>
                    <template v-slot:item.PictureInITS="{item}">
                        <v-img style="max-height: 60px; min-height: 60px; max-width: 50px; border: 1px Solid #ccc; border-radius: 4px;"
                               class="my-1"
                               :src="item.PictureInITS" contain :alt="item.ItsID.toString()" />
                    </template>
                    <template v-slot:item.Ranking>
                        <div class="d-flex">
                            <div class="mr-2 green--text text--darken-4">
                                <v-icon color="green">fa-thumbs-up</v-icon> 0
                            </div>
                            <div class="ml-2 red--text text--darken-4">
                                <v-icon color="red">fa-thumbs-down</v-icon> 0
                            </div>
                        </div>
                    </template>
                </data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "Members",
        props: ['HierarchyId'],
        data(){
            return {
                hierarchyID: 0,
                tableData: {
                    header: [
                        { text: 'S.No', value: 'SNo' },
                        { text: 'Photo', value: 'PictureInITS' },
                        { text: 'ITS', value: 'ItsID', applyFilter: true },
                        { text: 'Name', value: 'FullName', applyFilter: true },
                        { text: 'Team', value: 'Hierarchy', applyFilter: true },
                        { text: 'Jamaat', value: 'Jamaat', applyFilter: true },
                        { text: 'Mobile No', value: 'MobileNo' },
                        { text: 'Rank', value: 'Rank', applyFilter: true },
                        { text: 'Role', value: 'Role', applyFilter: true },
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                    noOfRows: this.tableRows,
                    totalRecords: 1,
                    totalPages: 0,
                    pageNo: 1,
                    menu: [
                        {title: 'View', icon: 'fa-search', onClick: this.viewMember},
                    ]
                },
                member: {
                    ItsID: 0,
                    FKHierarchyID: 0,
                    FKRankID: 0,
                    FKRoleID: 0,
                },
                groups: [],
                ranks: [],
                roles: [],
                members: {
                    loading: false,
                    items: [],
                    search: '',
                },
                rights: {
                    allowAdd: this.verifyRights(this.$route.name, 'A'),
                }
            };
        },
        computed: {
            user(){
                return this.$store.getters.user;
            },
            selectedData(){
                let selected = [];
                this.tableData.selected.forEach(item => {
                    selected.push(item.ItsID);
                });
                return selected;
            },
            rand() {
                return (Math.random() * 10) + 1;
            },
        },
        methods: {
            groupSelected(){
                this.member.FKHierarchyID = this.hierarchyID;
                this.$router.push({params: {orgID: this.hierarchyID}});
            },
            async loadMembers(val){
                this.members.loading = true;
                this.members.items = await this.getMembers({"FullName_ITS": val,
                    "FKHierarchyID": this.hierarchyID});
                this.members.loading = false;
            },
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    HierarchyID: this.hierarchyID,
                    ItsID: 0,
                    PageNumber: this.tableData.pageNo,
                    NoOfRows: this.tableData.noOfRows,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                    StatusID: 2,
                }

                await axios.post('Members/GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.tableData.totalPages = data.Data.TotalPages;
                        this.tableData.data = data.Data.Items;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            pageChanged(pageNo) {
                this.tableData.pageNo = pageNo;
                this.getData();
            },
            filterChanged(){
                this.getData();
            },
            async viewNewMembers(){

                const its = await this.$inputDialog.show({
                    title: 'New Member Enrollment',
                    message: 'Please enter Its ID below.<br/>' +
                        '1. New members should be at least 20 years of age or above.<br/>' +
                        '2. You may only enrol members of your own Jamaat in your team.<br/>' +
                        '3. Please ensure that the ITS number of the new member is accurate before entering into the system.<br/>' +
                        '4. New member enrolment status can be viewed on your bgi portal.',
                });

                this.$loadingDialog.show();

                await axios.post('Enrollment/Verify', { ItsID: its, HierarchyID: this.hierarchyID })
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({message: data.Message, color: status ? 'success' : 'error', timeout: 5000});
                        // console.log(data);

                        if(status) {
                            let member = data.Data;
                            member.FKHierarchyID = this.hierarchyID;

                            // console.log(member);

                            this.$store.dispatch('addNewMember', member);
                            this.$router.push({name: 'assessmentFormTeam'});
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                // await this.getNewMembers();
                // this.$refs.dlgMembers.show();
            },
        },
        watch: {
            'members.search'(val) {
                val && val !== this.members.incharge && this.loadMembers(val);
            },
        },
        async mounted() {
            this.tableData.noOfRows = this.tableRows;

            this.$loadingDialog.show();
            this.groups = await this.getOrgHierarchy(2, 0, 0, 0);
            this.$loadingDialog.hide();

            if('orgID' in this.$route.params) {
                if( this.$route.params.orgID === undefined )
                    this.hierarchyID = this.user.MyHierarchyID == 0 ? this.user.FKHierarchyID : this.user.MyHierarchyID;
                else
                    this.hierarchyID = parseInt(this.$route.params.orgID);
            }
            else
                this.hierarchyID = this.user.MyHierarchyID == 0 ? this.user.FKHierarchyID : this.user.MyHierarchyID;

            this.member.HierarchyID = this.hierarchyID;

            await this.getData();
        }
    }
</script>

<style scoped>

</style>