import Login from '../screens/login';
import LoginAdmin from '../screens/login_admin';
import LoginWaiting from '../screens/login_waiting';
import Auth from '../screens/auth';
import Home from '../screens/home';

import Dashboard from '../screens/dashboard/dashboard';

import OrgHierarchy from "../screens/administration/orgHierarchy";
import Designations from "../screens/administration/designations";

import Members from "../screens/members/members";
import MembersNew from "../screens/members/membersNew";
import MembersAddEdit from "../screens/members/membersProfile";

import MembersWaiting from "../screens/members/membersWaiting";

import MiqaatListing from '../screens/miqaat/miqaat'
import MiqaatDetails from '../screens/miqaat/miqaatDetails'

import Reports from "../screens/reports/reports";

import Notifications from "../screens/notifications";

import SurveyForms from "../screens/surveyForms/surveyForms";
import SurveyFormsQuestions from "../screens/surveyForms/surveyFormsQuestions";

import Collection from "../screens/finance/collection";
import FinanceDashboard from "../screens/finance/financeDashboard";
import Vouchers from "../screens/finance/vouchers/vouchers";
import VouchersDashboard from "../screens/finance/vouchers/vouchersDashboard";
import Currencies from "../screens/settings/currencies";
import FiscalYears from "../screens/finance/fiscalYears";
import CollectionHeads from "../screens/finance/collectionHeads";

import ITSSync from "../screens/settings/itsSync";
import Jamaat from "../screens/settings/jamaat";

import Recruitment from "../screens/recruitment/recruitment";
import Requests from "../screens/requests/membersRequests";

import RecruitmentForm from "../screens/recruitmentForms/recruitmentForm";
import CaptainAssessmentForm from "../screens/enrollment/captainAssessmentForm";
import SelfAssessmentForm from "../screens/enrollment/selfAssessment";

import Asbaaq from '../screens/asbaaq/asbaaq';
import AsbaaqViewer from '../screens/asbaaq/asbaaqViewer';

import Videos from '../screens/videos/videos';
import VideosViewer from '../screens/videos/videoViewer';

import ITSView from "../screens/its_view";

import AmilView from "../screens/amalat/amilView";

import AsharaHome from "../screens/ashara/asharaHome";

import PDFViewer from "../screens/pdfViewer";

import AssessmentFormView from "../screens/assessmentForms/assessmentFormView";

import SharafForm from "../screens/sharafForms/sharafForms";
import SharafFormView from "../screens/sharafForms/formView";

//import Members from "../screens/members/members";
//import MembersAddEdit from "../screens/members/membersAddEdit";

export const routes = [
    { path: '/login', component: Login, name: 'login' },
    { path: '/admin/auth', component: LoginAdmin, name: 'loginAdmin' },
    { path: '/waiting', component: LoginWaiting, name: 'loginWaiting' },
    { path: '/auth', component: Auth, name: 'auth' },
    { path: '/itsView', component: ITSView, name: 'itsView' },
    { path: '/amalat/amilView', component: AmilView, name: 'amilView' },
    { path: '/ashara', component: AsharaHome, name: 'ashara' },
    { path: '/', component: Home, children: [
            { path: '/home', component: Dashboard, name: 'dashboard' },
            { path: '/org', component: OrgHierarchy, name: 'org', query: {view: 0} },
            { path: '/designations', component: Designations, name: 'designations' },
            { path: '/enrollment', component: Recruitment, name: 'membersNew' },
            { path: '/requests', component: Requests, name: 'membersRequests' },
            { path: '/members/:orgID?', component: Members, name: 'members',
                props: (route) => ({ orgID: route.params.orgID || 1 }) },
            { path: '/members/waiting/:orgID?', component: MembersWaiting, name: 'membersWaiting',
                props: (route) => ({ orgID: route.params.orgID || 1 }) },
            { path: '/membersAddEdit/:action', component: MembersAddEdit, name: 'membersAddEdit' },
            { path: '/enrollmentRequest', component: MembersNew, name: 'enrollmentRequest' },
            { path: '/miqaat', component: MiqaatListing, name: 'miqaat' },
            { path: '/miqaat/:miqaatId', component: MiqaatDetails, name: 'miqaatDetails',
                props: (route) => ({ miqaatId: route.params.miqaatId || 1 }) },
            { path: '/notifications', component: Notifications, name: 'notifications' },
            { path: '/surveys', component: SurveyForms, name: 'surveyForms' },
            { path: '/surveys/:id', component: SurveyFormsQuestions, name: 'surveyFormsAddEdit' },
            { path: '/finance/fee', component: Collection, name: 'feeCollection' },
            { path: '/finance/dashboard', component: FinanceDashboard, name: 'financeDashboard' },
            { path: '/finance/vouchers', component: Vouchers, name: 'vouchers' },
            { path: '/finance/vouchersDashboard', component: VouchersDashboard, name: 'vouchersDashboard' },
            { path: '/finance/fiscalYears', component: FiscalYears, name: 'fiscalYears' },
            { path: '/finance/collectionSettings', component: CollectionHeads, name: 'collectionHeads' },
            { path: '/reports', component: Reports, name: 'reports' },
            { path: '/its', component: ITSSync, name: 'itsSync' },
            { path: '/jamaat', component: Jamaat, name: 'jamaat' },
            { path: '/currencies', component: Currencies, name: 'currencies' },
            { path: '/assessment/self', component: SelfAssessmentForm, name: 'assessmentFormSelf' },
            { path: '/assessment/incharge', component: CaptainAssessmentForm, name: 'assessmentFormTeam' },
            { path: '/assessment/team', component: RecruitmentForm, name: 'recruitmentForm' },
            { path: '/assessment/jamaat', component: RecruitmentForm, name: 'recruitmentFormAmil' },
            { path: '/asbaaq', component: Asbaaq, name: 'asbaaq' },
            { path: '/asbaaq/live', component: AsbaaqViewer, name: 'asbaaqViewer' },
            { path: '/videos', component: Videos, name: 'videoManagement' },
            { path: '/videos/view', component: VideosViewer, name: 'videoViewer' },
            { path: '/orientation/manual', component: PDFViewer, name: 'pdfViewer' },
            { path: '/assessment/view', component: AssessmentFormView, name: 'assessmentFormView' },
            { path: '/sharaf/view', component: SharafFormView, name: 'sharafFormView' },
            { path: '/sharafForms', component: SharafForm, name: 'sharafFormManagement' },
        ]
    },
]