<template>
    <v-container fluid>
        <v-overlay v-if="isLoading">
            <v-progress-circular color="white" indeterminate size="53"
                                 class="mt-2 ml-3" />
        </v-overlay>

        <template>
            <v-row justify="center" align="center" v-if="requests.length == 0">
                <p class="py-5">No Data Available</p>
            </v-row>
            <v-row v-else>
                <v-col md="3" cols="12" v-for="request in requests">
                    <v-card outlined>
                        <v-card-text>
                            <v-img :src="request.PictureInITS" style="max-width: 100px; margin: auto; border: 1px Solid #666; border-radius: 4px;" />
                            <h4 class="mt-1">{{ request.FKItsID }} - {{ request.FullName }} - {{ request.Rank }}</h4>
                            <p class="ma-0 mt-1 body-2"><b>Age:</b> {{ request.Age }}</p>
                            <p class="ma-0 mt-1 body-2"><b>Mobile No:</b> {{ request.MobileNo }}</p>
                            <p class="ma-0 mt-1 body-2"><b>Team:</b> {{ request.Hierarchy }}</p>
                            <p class="ma-0 mt-1 body-2"><b>Jamaat:</b> {{ request.Jamaat }}</p>
                            <p class="ma-0 mt-1 body-2"><b>Reason For Termination:</b></p>
                            <p class="ma-0 body-2">{{ request.Reason }}</p>
                            <p class="ma-0 mt-1 body-2"><b>Detail:</b></p>
                            <p class="body-2">{{ request.Description }}</p>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-btn color="red darken-3 white--text" small class="py-4" block
                                           @click="approveRequests(request.FKItsID, 2)">
                                        Approve
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-btn color="primary" small class="py-4" block
                                           @click="approveRequests(request.FKItsID, 3)">
                                        Disapprove
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        name: "requestsView",
        props: ['jamaat'],
        data(){
            return {
                isLoading: false,
                requests: [],
            };
        },
        methods: {
            async getTerminationRequests() {
                this.isLoading = true;

                const formData = {
                    JamaatID: this.jamaat.JamaatID,
                    Option: 2,
                };

                await axios.post('Amalat/Termination_GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.requests = data.Data;

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.isLoading = false;
            },
            async approveRequests(itsID, status) {
                let rejectReason = "";

                const isApprove = await this.$confirmDialog.show({
                    title: `${status == 2 ? 'Approve' : 'Reject'} Termination Request`,
                    message: `Are you sure you want to ${status == 2 ? 'approve' : 'reject'} termination request of the selected member? Please confirm.`,
                    isCritical: status == 2,
                });

                if(!isApprove)
                    return;

                if(status == 3) {
                    rejectReason = await this.$inputDialog.show({
                        title: 'Disapprove Termination Request',
                        message: 'Burhani Guards Idara will verify the request and approve/disapprove as applicable.</br>' +
                            'Please enter reason to disapprove below.'
                    });

                    if(!rejectReason)
                        return;
                }

                this.isLoading = true;

                const formData = {
                    ItsID: itsID,
                    Status: status,
                    Reason: rejectReason,
                };

                const result = await axios.post('Amalat/Termination_Approve', formData)
                    .then(response => {
                        console.log(response);

                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                    await this.getTerminationRequests();

                this.isLoading = false;
            },
        },
        async mounted(){
            await this.getTerminationRequests();
        },
    }
</script>

<style scoped>

</style>