<template>
    <v-container fluid>
<!--        <v-row>-->
<!--            <v-col cols="12"><h2 class="headline">Reports</h2></v-col>-->
<!--        </v-row>-->
        <v-row>
            <v-col cols="12" md="6" offset-md="3">
                <v-card v-if="!isReportView">
                    <v-card-title>Reports</v-card-title>
                    <v-card-text>
                        <combo-box label="Reports" v-model="report" :items="reports" item-text="ReportName" return-object
                                    @change="reportSelected" />
                        <combo-box label="Options" v-model="formData.ReportOptionID" :items="report.Options" item-text="ReportOption"
                                   item-value="OptionID" class="mt-3" v-if="report.Options.length > 0" />
                        <v-row class="my-3 mx-1">
                            <span style="font-weight: bold; color: #333; font-size: 14pt;">Filters</span>
                            <v-divider class="my-3 ml-2" />
                        </v-row>
                        <date-picker label="From Date" v-model="formData.FromDate" v-if="checkFilter('Date')" />
                        <date-picker label="To Date" v-model="formData.ToDate" class="mt-3" v-if="checkFilter('Date')" />
                        <combo-box label="Groups" v-model="formData.Hierarchy" class="mt-3" v-if="checkFilter('Hierarchy')"
                                   :items="zones" item-text="NameWithID" item-value="HierarchyID" multiple small-chips />
                        <combo-box label="Ranks" v-model="formData.Rank" class="mt-3" v-if="checkFilter('Rank')"
                                   :items="ranks" item-text="Description" item-value="Value01" multiple small-chips />
                        <combo-box label="Roles" v-model="formData.Role" class="mt-3" v-if="checkFilter('Role')"
                                   :items="ranks" item-text="Description" item-value="Value01" multiple small-chips />
                        <combo-box label="Gender" v-model="formData.Gender" class="mt-3" v-if="checkFilter('Gender')"
                                   :items="[{text: 'Gents', value: 'Male'},{text: 'Ladies', value: 'Female'}]"
                                   item-text="text" item-value="value" multiple small-chips />
                    </v-card-text>
                    <v-card-actions align="right">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" class="py-4" small v-if="report.DynamicFileName !== undefined && report.DynamicFileName !== ''" @click="getReport(1)">Dynamic Report</v-btn>
                        <v-btn color="primary" class="py-4" small :disabled="report.FileName == undefined || report.FileName == ''" @click="getReport(2)">View PDF</v-btn>
                        <v-btn color="primary" class="py-4" small :disabled="report.FileName == undefined || report.FileName == ''" @click="getReport(3)">View Excel</v-btn>
                    </v-card-actions>
                </v-card>

            </v-col>
            <v-col>
                <report-viewer v-if="isReportView" :data-set="report.data" />
            </v-col>
        </v-row>
        <!--<v-row>
            <v-col cols="4">
                <v-card>
                    <v-card-title>Reports List</v-card-title>
                    <v-card-text>
                        <v-list dense>

                            <v-list-item @click="getReport(2, 'Rpt_Finance_FeeSurvey', 'Fee Collection Summary', 'msp_Reports_Finance_FeeSurvey')">
                                <v-list-item-content>
                                    <v-list-item-title>Fee Collection Summary</v-list-item-title>
                                    <v-list-item-subtitle>Team-wise fee collection summary updated by the team incharges</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                            <v-list-item @click="getReport(2, 'Rpt_Miqaat_WaazSurvey', 'Waaz Survey', 'msp_Reports_Miqaat_WaazSurvey')">
                                <v-list-item-content>
                                    <v-list-item-title>Miqaat Waaz Survey</v-list-item-title>
                                    <v-list-item-subtitle>Daily report for waaz by gate incharges</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                            <v-list-item @click="getReport(2, 'Rpt_Miqaat_MajlisSurvey', 'Majlis Survey', 'msp_Reports_Miqaat_MajlisSurvey')">
                                <v-list-item-content>
                                    <v-list-item-title>Miqaat Majlis Survey</v-list-item-title>
                                    <v-list-item-subtitle>Daily report for majlis by gate incharges</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Daily Bubble Activity Survey</v-list-item-title>
                                    <v-list-item-subtitle>Daily report for each bubble by their respective incharges</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Members List</v-list-item-title>
                                    <v-list-item-subtitle>A members list with the selected fields and filters</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Survey Data</v-list-item-title>
                                    <v-list-item-subtitle>Filtered survey data with the selected fields</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Miqaat Report</v-list-item-title>
                                    <v-list-item-subtitle>A summarised miqaat report</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider />
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Members Profile</v-list-item-title>
                                    <v-list-item-subtitle>Filtered forms of members with their details</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title>Filters</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col class="d-flex justify-end">
                                <date-picker label="From Date" v-model="formData.FromDate"/>
                                <date-picker label="To Date" v-model="formData.ToDate" class="ml-2" />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary">Generate</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>-->
    </v-container>
</template>

<script>
    import moment from "moment";
    import axios from "axios";
    import reportViewer from "./reportViewer";

    export default {
        title: "Report Generator",
        components: {reportViewer},
        data(){
            return {
                isReportView: false,
                reports: [],
                zones: [],
                jamiyats: [],
                divisions: [],
                teams: [],
                ranks: [],
                roles: [],
                report: {
                    Parameters: [],
                    Options: [],
                    data: {},
                },
                formData: {
                    ReportID: 0,
                    ReportOptionID: 0,
                    FromDate: new moment().startOf('month'), //.subtract(7, "days"),
                    ToDate: new moment(),
                    Hierarchy: [0],
                    Rank: [0],
                    Role: [0],
                    Gender: ['Male', 'Female'],
                }
            };
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                await axios.post('Reports/GetData', {ModuleID: 0})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.reports = data.Data;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            checkFilter(filter){
                if(this.report.Parameters.length > 0) {
                    let items = this.report.Parameters.split(/\s*,\s*/);
                    return items.some(function(v) { return v === filter; });
                }
                else
                    return false;
            },
            reportSelected(){
                this.formData.ReportID = this.report.ReportID;
                this.formData.ReportOptionID = this.report.Options[0]['OptionID'];
            },
            async getReport(option) {
                // OPTION 1 = ADVANCED VIEW
                // OPTION 2 = EXPORT TO PDF
                // OPTION 3 = EXPORT TO EXCEL
                this.$loadingDialog.show();

                let params = {};

                this.report.Parameters.split(',').forEach(param => {
                    Object.keys(this.formData).forEach(parameter => {
                        if( parameter.toUpperCase() == param.trim().toUpperCase() ) {
                            if(Array.isArray(this.formData[parameter]))
                                params[param.trim()] = this.formData[parameter].join(',');
                            else
                                params[param.trim()] = this.formData[parameter];
                        }

                        if (( parameter.toUpperCase() == 'FROMDATE' || parameter.toUpperCase() == 'TODATE' ) && param.toUpperCase() == 'DATE')
                            params[parameter] = new moment(this.formData[parameter]).format('DD-MMM-YYYY');
                    });
                });

                let url = 'Reports/GenerateReport';
                let formData = {
                    "Option": option,
                    "ReportID": this.formData.ReportID,
                    "ReportOptionID": this.formData.ReportOptionID,
                    "Parameters": params
                };

                if(option == 2) {
                    await this.printDocument(url, formData);
                }
                else if(option == 3) {
                    const file = await this.downloadExcel(url, formData);

                    console.log(file);

                    const link = document.createElement('a');
                    link.href = file.fileUrl;
                    link.setAttribute('download', file.fileName);
                    document.body.appendChild(link);
                    link.click();
                }
                else {
                    this.$loadingDialog.show();

                    await axios.post(url, formData)
                        .then(response => {
                            const data = response.data;
                            const status = data.Status;

                            if (!status)
                                this.$snackBar.error(data.Message);

                            if(data.Data.length > 0) {
                                this.isReportView = true;
                                this.report.data = data.Data;
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            this.$snackBar.error('An unexpected error has occurred');
                        });

                    this.$loadingDialog.hide();
                }

                this.$loadingDialog.hide();
            },
        },
        async mounted(){
            this.$loadingDialog.show();

            this.zones = await this.getOrgHierarchy(2, 0, 0, '0');
            this.ranks = await this.getGeneral(2, 0);
            this.roles = await this.getGeneral(3, 0);

            this.formData.Hierarchy.splice(0, 0, this.zones[0].HierarchyID);
            this.ranks.splice(0, 0, {Value01: 0, Description: 'All'});
            this.ranks.push({Value01: 999, Description: 'Incharges'});
            this.roles.splice(0, 0, {Value01: 0, Description: 'All'});

            this.$loadingDialog.hide();

            await this.getData();
        }
    }
</script>

<style scoped>

</style>