<template>
    <v-app :style="{backgroundImage: 'url(' + require('@/assets/bg_02.jpg') + ')'}">

        <v-main>
            <v-container :class="isLoggedIn ? '' : 'fill-height'" fluid>
                <v-row align="center" justify="center" class="mt-n16" v-if="!isLoggedIn">
                    <v-col md="8">
                        <v-img src="@/assets/BGIlogo.png" height="100px" contain></v-img>
                        <v-card class="mt-5">
                            <v-card-text class="text-center">
                                <v-form @submit.prevent="login">
                                    <v-row>
                                        <v-col md="6" cols="12" offset-md="3">
                                            <h1>Authentication</h1>
                                            <h3 class="mt-5">Please enter password to continue</h3>
                                            <text-box label="Password" v-model="password" class="mt-5" type="password" />
                                            <v-btn color="success darken-4" class="white--text mt-2" type="submit" block @click="login">
                                                Login
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-row v-if="isLoggedIn">
                    <v-col>
                        <v-card>
                            <v-card-title class="heading">
                                ITS View
                            </v-card-title>
                            <v-card-text style="height: 550px; overflow: auto;">
                                <text-box label="ITS Number" class="mt-3" v-model="its" @keypress.enter="fetchFromITS" />
                                <v-row>
                                    <v-col md="2">
                                        <template>
                                            <v-progress-circular color="primary" indeterminate size="53"
                                                                 class="mt-2 ml-3" v-if="muminPhoto.isLoading" />
                                            <v-img :src="showPhoto" class="mt-2" v-else />
                                        </template>
                                    </v-col>
                                    <v-col md="10">
                                        <v-list dense>
                                            <template v-for="key in Object.keys(mumin)">
                                                <v-list-item dense v-if="key != 'photo'">
                                                    <b>{{ key }}</b>: {{ mumin[key] }}
                                                </v-list-item>
                                            </template>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>

        <v-footer absolute color="green darken-4" class="white--text">
            <v-col class="text-center">
                 Copyrights {{ new Date().getFullYear() }} - Burhani Guards Idara
            </v-col>
        </v-footer>

        <snack-bar />
        <loading-dialog />
    </v-app>
</template>

<script>
    import axios from "axios";

    export default {
        title: "ITS View",
        data(){
            return {
                background: 'background: url(\'@/assets/bg.jpg\')',
                isLoggedIn: false,
                password: "",
                its: "",
                mumin: {
                    photo: '',
                },
                muminPhoto: {
                    isLoading: false,
                    photo: '',
                },
            }
        },
        computed: {
            showPhoto(){
                if(this.muminPhoto.photo == '')
                    return '';
                else
                    return 'data:image/jpeg;base64,' + this.muminPhoto.photo;
            }
        },
        methods: {
            async login() {
                if(this.password === "itsView@5253")
                    this.isLoggedIn = true;
                else
                    this.$snackBar.error('Incorrect password');
            },
            async fetchFromITS(){
                this.$loadingDialog.show();
                this.muminPhoto.photo = '';

                await axios.post(`ITS/FetchFromITS`, {ItsID: this.its})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.mumin = data.Data;
                        else {
                            this.mumin = {};
                            this.$snackBar.error(data.Message);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                await this.fetchPhotoFromITS();
            },
            async fetchPhotoFromITS(){
                this.muminPhoto.isLoading = true;

                await axios.post(`ITS/FetchPhotoFromITS`, {ItsID: this.its})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.muminPhoto.photo = data.Data;
                        else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.muminPhoto.isLoading = false;
            }
        }
    }
</script>

<style scoped>

</style>