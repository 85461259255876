<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h2 class="headline">Fee Collection</h2>
            </v-col>
            <v-col align="end" class="d-flex">
                <combo-box label="Group" background-color="white" v-model="hierarchyID"
                           :items="groups" item-text="NameWithID" item-value="HierarchyID"
                           @change="getData" />
                <combo-box label="Collection Head" background-color="white" v-model="collectionHeadID" class="ml-2"
                           :items="collectionHeads" item-text="Description" item-value="CollectionHeadID"
                           @change="getData" />
            </v-col>
        </v-row>
        <v-row>
<!--            <v-col>-->
<!--                <v-btn color="red darken-3"-->
<!--                       class="white&#45;&#45;text"-->
<!--                       small-->
<!--                       :disabled="tableData.selected.length == 0"-->
<!--                       @click="updateGroup(false)">-->
<!--                    Mark As Not Received-->
<!--                </v-btn>-->
<!--                <v-btn color="primary"-->
<!--                       class="white&#45;&#45;text ml-2"-->
<!--                       small-->
<!--                       :disabled="tableData.selected.length == 0"-->
<!--                       @click="updateGroup(true)">-->
<!--                    Mark As Received-->
<!--                </v-btn>-->
<!--            </v-col>-->
            <v-col>
                <div class="d-flex justify-end">
                    <text-box label="Search" v-model="search" @keydown.enter="getData"
                              background-color="white" style="min-width: 350px;" />
                    <v-btn-toggle color="primary" v-model="option" @change="getData" class="ml-2">
                        <v-btn small class="py-4">All</v-btn>
                        <v-btn small class="py-4">Pending</v-btn>
                        <v-btn small class="py-4">Paid</v-btn>
                    </v-btn-toggle>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col align="end">
                <div style="max-height: 65vh; overflow: auto;">
                    <data-table ref="tblMembers"
                                :table-data="tableData"
                                :total-pages="tableData.totalPages"
                                disable-pagination
                                hide-filters>
                        <template v-slot:item.IsPaid="{item}">
                            <v-switch v-model="item.IsPaid"
                                      hide-details class="ma-0 my-2"
                                      @change="updateStatus(item.ItsID, $event)" :readonly="option != 1" />
                        </template>
                    </data-table>
                </div>
<!--                <v-btn color="primary" class="mt-2" @click="submitFee"-->
<!--                       v-if="option == 1">Submit Fees</v-btn>-->
            </v-col>
        </v-row>

<!--        <simple-dialog ref="dlgVoucher" title="Submit Fees" width="500" :on-confirm="updateVoucher">-->
<!--            <template v-slot:content>-->
<!--                <v-row>-->
<!--                    <v-col>-->
<!--                        <v-form @submit.prevent="updateVoucher">-->
<!--                            <date-picker label="Received Date" v-model="voucher.receivedDate" />-->
<!--                            <combo-box label="Received By" class="mt-2" v-model="voucher.receivedBy"-->
<!--                                       :items="offices" item-text="Description" item-value="Description" />-->
<!--                            <combo-box label="Payment Method" class="mt-2" v-model="voucher.paymentMode"-->
<!--                                       :items="paymentModes" item-text="Description" item-value="Description" />-->
<!--                        </v-form>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--            </template>-->
<!--        </simple-dialog>-->
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "Fee Collection",
        data(){
            return {
                groups: [],
                collectionHeads: [],
                offices: [],
                paymentModes: [],
                hierarchyID: 0,
                collectionHeadID: 0,
                option: 1,
                search: '',
                tableData: {
                    header: [
                        { text: 'S.No', value: 'SNo', applyFilter: false, width: '70px' },
                        { text: 'ITS', value: 'ItsID', applyFilter: true },
                        { text: 'Name', value: 'FullName', applyFilter: true },
                        { text: 'Mobile No', value: 'MobileNo', applyFilter: true },
                        { text: 'Rank', value: 'Rank', applyFilter: true },
                        { text: 'Received', value: 'IsPaid', applyFilter: false },
                    ],
                    data: [],
                    selected: [],
                    filters: {},
                },
                voucher: {
                    receivedDate: '',
                    receivedBy: '',
                    paymentMode: '',
                },
            };
        },
        computed: {
            user(){
                return this.$store.getters.user;
            },
            selectedIDs(){
                let selected = [];
                this.tableData.selected.forEach(item => {
                    selected.push({
                        FKCollectionHeadID: this.collectionHeadID,
                        FKItsID: item.ItsID,
                        FKHierarchyID: item.FKHierarchyID,
                        FKCurrencyID: item.FKCurrencyID,
                        Amount: item.Amount,
                        IsPaid: true,
                    });
                });
                return selected;
            }
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    HierarchyID: this.hierarchyID,
                    CollectionHeadID: this.collectionHeadID,
                    Option: this.option,
                    Filter: this.search,
                }

                await axios.post('FeesCollection/GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.tableData.data = data.Data;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            // async updateGroup(status){
            //     const result = await this.$confirmDialog.show({
            //         title: 'Update Fee Status',
            //         message: `Are you sure you want to update fee status as: ${status ? 'Received' : 'Not Received'}
            //         for the selected members? Please confirm`,
            //         confirmButtonText: 'Yes',
            //     });
            //
            //     if(!result)
            //         return;
            //
            //     await this.updateStatus(status);
            // },
            // async updateSingle(item, status){
            //     this.tableData.selected.push(item);
            //     await this.updateStatus(status);
            // },
            async updateStatus(itsID, status){
                this.$loadingDialog.show();

                const formData = {
                    CollectionHeadID: this.collectionHeadID,
                    ItsID: itsID,
                    IsPaid: status,
                }

                const result = await axios.post('FeesCollection/Update', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.tableData.selected = [];

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message})
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result)
                    await this.getData();
            },
            // async updateVoucher(){
            //     this.$loadingDialog.show();
            //
            //     const formData = {
            //         Folio: this.tableData.data[0].Folio,
            //         ReceivedDate: this.voucher.receivedDate,
            //         ReceivedBy: this.voucher.receivedBy,
            //         PaymentMode: this.voucher.paymentMode,
            //     }
            //
            //     const result = await axios.post('FeesCollection/UpdateVoucher', formData)
            //         .then(response => {
            //             const data = response.data;
            //             const status = data.Status;
            //
            //             this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message})
            //             return status;
            //         })
            //         .catch(error => {
            //             console.log(error);
            //             this.$snackBar.error('An unexpected error has occurred');
            //         });
            //
            //     this.$loadingDialog.hide();
            //
            //     if(result) {
            //         this.$refs.dlgVoucher.hide();
            //         await this.getData();
            //     }
            // },
            // submitFee(){
            //     this.$refs.dlgVoucher.show();
            // }
        },
        async mounted(){
            this.$loadingDialog.show();

            this.groups = await this.getOrgHierarchy(2, 5, 0, '0');
            this.collectionHeads = await this.getCollectionHeads();
            // this.offices = await this.getGeneral(13, 0);
            // this.paymentModes = await this.getGeneral(14, 0);

            // switch(this.user.ZoneID) {
            //     case 2:
            //         this.offices = ['Local Central Office'];
            //         break;
            //     case 3:
            //         this.offices = ['Surat Office'];
            //         break;
            //     case 4:
            //         this.offices = ['Surat Office', 'Local Central Office'];
            //         break;
            //     case 5:
            //         this.offices = ['Kuwait Office'];
            //         break;
            //     case 6:
            //         this.offices = ['Pakistan Office'];
            //         break;
            // }

            this.hierarchyID = this.user.FKHierarchyID;
            this.collectionHeadID = this.collectionHeads[0].CollectionHeadID;

            this.$loadingDialog.hide();

            if('hID' in this.$route.query)
                this.hierarchyID = this.$route.query.hID;

            await this.getData();
        }
    }
</script>

<style scoped>

</style>