<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h2 class="headline">Members Waiting List</h2>
            </v-col>
            <v-col align="end" class="d-flex">
                <combo-box label="Group" background-color="white" v-model="hierarchyID"
                           :items="groups" item-text="NameWithID" item-value="HierarchyID"
                           @change="groupSelected"></combo-box>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <data-table ref="tblMembers"
                            v-model="tableData.selected"
                            :table-data="tableData"
                            :total-pages="tableData.totalPages"
                            item-key="ItsID"
                            @pageChanged="pageChanged"
                            @filterChanged="filterChanged"
                            disable-sort
                            show-select wrap-text>
                    <template v-slot:item.PictureInITS="{item}">
                        <v-img style="max-height: 60px; min-height: 60px; max-width: 50px; border: 1px Solid #ccc; border-radius: 4px;"
                               class="my-1"
                               :src="item.PictureInITS" contain :alt="item.ItsID.toString()" />
                    </template>
                    <template v-slot:item.Actions="{item}">
                        <v-btn text color="primary" small @click="enrollmentForm(item)">
                            <v-icon small>fa-file-text</v-icon>
                        </v-btn>
                    </template>
                </data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "Members",
        props: ['HierarchyId'],
        data(){
            return {
                hierarchyID: 0,
                tableData: {
                    header: [
                        { text: 'S.No', value: 'SNo' },
                        { text: 'Photo', value: 'PictureInITS' },
                        { text: 'ITS', value: 'ItsID', applyFilter: true },
                        { text: 'Name', value: 'FullName', applyFilter: true },
                        { text: 'Team', value: 'Hierarchy', applyFilter: true },
                        { text: 'Jamaat', value: 'Jamaat', applyFilter: true },
                        { text: 'Mobile No', value: 'MobileNo' },
                        { text: 'Rank', value: 'Rank', applyFilter: true },
                        { text: 'Actions', value: 'Actions' },
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                    noOfRows: this.tableRows,
                    totalRecords: 1,
                    totalPages: 0,
                    pageNo: 1,
                    menu: [
                        {title: 'View', icon: 'fa-search', onClick: this.viewMember},
                    ]
                },
                member: {
                    ItsID: 0,
                    FKHierarchyID: 0,
                    FKRankID: 0,
                    FKRoleID: 0,
                },
                groups: [],
                ranks: [],
                roles: [],
                members: {
                    loading: false,
                    items: [],
                    search: '',
                },
                rights: {
                    allowAdd: this.verifyRights(this.$route.name, 'A'),
                    allowEdit: this.verifyRights(this.$route.name, 'E'),
                    allowDelete: this.verifyRights(this.$route.name, 'D'),
                    allowDeleteRequest: this.verifyRights(this.$route.name, 'DR'),
                    allowTransferRequest: this.verifyRights(this.$route.name, 'TR'),
                }
            };
        },
        computed: {
            user(){
                return this.$store.getters.user;
            },
            selectedData(){
                let selected = [];
                this.tableData.selected.forEach(item => {
                    selected.push(item.ItsID);
                });
                return selected;
            },
            rand() {
                return (Math.random() * 10) + 1;
            },
        },
        methods: {
            groupSelected(){
                this.member.FKHierarchyID = this.hierarchyID;
                this.$router.push({params: {orgID: this.hierarchyID}});
            },
            async loadMembers(val){
                this.members.loading = true;
                this.members.items = await this.getMembers({"FullName_ITS": val,
                    "FKHierarchyID": this.hierarchyID});
                this.members.loading = false;
            },
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    HierarchyID: this.hierarchyID,
                    ItsID: 0,
                    PageNumber: this.tableData.pageNo,
                    NoOfRows: this.tableData.noOfRows,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                    StatusID: 2,
                }

                await axios.post('Members/GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.tableData.totalPages = data.Data.TotalPages;
                        this.tableData.data = data.Data.Items;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            pageChanged(pageNo) {
                this.tableData.pageNo = pageNo;
                this.getData();
            },
            filterChanged(){
                this.getData();
            },
            enrollmentForm(item){
                let profile = {
                    ItsID: item.ItsID,
                    FullName: item.FullName,
                    Rank: item.Rank,
                    Jamaat: item.Jamaat,
                };

                let query = window.btoa(JSON.stringify({Profile: profile}));
                this.$router.push({name: 'recruitmentForm', query: {dt: query}})
            },
        },
        watch: {
            'members.search'(val) {
                val && val !== this.members.incharge && this.loadMembers(val);
            },
        },
        async mounted() {
            this.tableData.noOfRows = this.tableRows;

            this.$loadingDialog.show();
            this.groups = await this.getOrgHierarchy(2, 0, 0, 0);
            this.$loadingDialog.hide();

            if('orgID' in this.$route.params) {
                if( this.$route.params.orgID === undefined )
                    this.hierarchyID = this.user.MyHierarchyID == 0 ? this.user.FKHierarchyID : this.user.MyHierarchyID;
                else
                    this.hierarchyID = parseInt(this.$route.params.orgID);
            }
            else
                this.hierarchyID = this.user.MyHierarchyID == 0 ? this.user.FKHierarchyID : this.user.MyHierarchyID;

            this.member.HierarchyID = this.hierarchyID;

            await this.getData();
        }
    }
</script>

<style scoped>

</style>