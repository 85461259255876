<template>
    <v-container fluid>
<!--        <v-row>-->
<!--            <v-col cols="12">-->
<!--                <h2 class="headline">-->
<!--                    {{action}} Member-->
<!--                    <template v-if="action == 'Edit'">-->
<!--                        | {{ member.FullName }} | {{ member.ItsID }}-->
<!--                    </template>-->
<!--                </h2>-->
<!--            </v-col>-->
<!--        </v-row>-->
        <v-form ref="frmMember" v-model="isFormValid">
            <v-row>
                <v-col>
                    <v-row>
                        <v-col>
                            <v-card outlined>
                                <v-card-title>Personal Details</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col md="4" cols="12">
                                            <v-img style="min-height: 200px; border: 1px Solid #ccc; border-radius: 4px;"
                                                   :src="member.PictureInITS" contain :alt="member.ItsID.toString()" />
<!--                                            <text-box label="Date of Joining" v-model="member.Idara.JoiningDate" class="mt-2" readonly />-->
                                            <p class="ma-0 mt-2 body-2"><b>Zone:</b></p>
                                            <p class="ma-0 mt-1 body-2"> {{ member.Zone }}</p>
                                            <p class="ma-0 mt-2 body-2"><b>Commander:</b></p>
                                            <p class="ma-0 mt-1 body-2"> {{ member.Commander }}</p>
                                            <p class="ma-0 mt-2 body-2"><b>Incharge:</b></p>
                                            <p class="ma-0 mt-1 body-2"> {{ member.Incharge }}</p>
                                            <p class="ma-0 mt-2 body-2"><b>Rank:</b></p>
                                            <p class="ma-0 mt-1 body-2"> {{ member.Rank }}</p>
                                            <p class="ma-0 mt-2 body-2"><b>Jamaat:</b></p>
                                            <p class="ma-0 mt-1 body-2"> {{ member.Jamaat }}</p>

<!--                                            <text-box label="Zone" v-model="member.Zone" class="mt-2" readonly />-->
<!--                                            <text-box label="Commander" v-model="member.Commander" class="mt-2" readonly />-->
<!--                                            <text-box label="Incharge" v-model="member.Incharge" class="mt-2" readonly />-->
<!--                                            <text-box label="Rank" v-model="member.Rank" class="mt-2" readonly />-->
<!--                                            <text-box label="Jamaat" v-model="member.Jamaat" class="mt-2" readonly />-->
                                        </v-col>
                                        <v-col md="8" cols="12">
                                            <p class="ma-0 mt-2 body-2"><b>ITS No:</b></p>
                                            <p class="ma-0 mt-1 body-2"> {{ member.ItsID }}</p>
                                            <p class="ma-0 mt-2 body-2"><b>Full Name:</b></p>
                                            <p class="ma-0 mt-1 body-2"> {{ member.FullName }}</p>
                                            <p class="ma-0 mt-2 body-2"><b>Arabic Name:</b></p>
                                            <p class="ma-0 mt-1 body-2"> {{ member.FullName_Arabic }}</p>
                                            <p class="ma-0 mt-2 body-2"><b>Group:</b></p>
                                            <p class="ma-0 mt-1 body-2"> {{ member.Hierarchy }}</p>
                                            <p class="ma-0 mt-2 body-2"><b>Mobile No:</b></p>
                                            <p class="ma-0 mt-1 body-2"> {{ member.MobileNo }}</p>
                                            <p class="ma-0 mt-2 body-2"><b>Email:</b></p>
                                            <p class="ma-0 mt-1 body-2"> {{ member.Email }}</p>

<!--                                            <text-box label="ITS No" v-model="member.ItsID" :rules="validation.itsRules" readonly required/>-->
<!--                                            <combo-box label="Group" v-model="member.FKHierarchyID" :items="teams" item-text="Name" item-value="HierarchyID" class="mt-2" :rules="validation.teamRules" :readonly="isEdit || member.FKStatusID == 2" required />-->
<!--                                            <text-box label="Full Name" v-model="member.FullName" class="mt-2" readonly />-->
<!--                                            <text-box label="Arabic Name" v-model="member.FullName_Arabic" readonly class="mt-2"/>-->
<!--                                            <text-box label="Group" v-model="member.Hierarchy" :rules="validation.itsRules" readonly class="mt-2" required/>-->
<!--                                            <text-box label="Mobile No" v-model="member.MobileNo" class="mt-2" readonly />-->
<!--                                            <text-box label="Email" v-model="member.Email" class="mt-2" readonly />-->

<!--                                            <template v-if="member.FKStatusID == 1">-->
                                                <text-box label="Address" v-model="member.Personal.HomeAddress" class="mt-2" />
                                                <text-box label="Date of Birth" type="date" v-model="member.Personal.DateOfBirth" class="mt-2" readonly />
                                                <combo-box label="Marital Status" :items="maritalStatus" v-model="member.Personal.MaritalStatus" class="mt-2" />
                                                <text-box label="Height" v-model="member.Personal.Height" type="number" class="mt-2" />
                                                <text-box label="Weight" v-model="member.Personal.Weight" type="number" class="mt-2" />
                                                <text-box label="BMI" v-model="member.Personal.BMI" class="mt-2" type="number" />
                                                <combo-box label="Blood Group" :items="bloodGroups" v-model="member.Personal.BloodGroup" class="mt-2" />
                                                <v-checkbox label="Diabetic" v-model="member.Personal.IsDiabetic" hide-details class="mt-2"></v-checkbox>
    <!--                                            <text-box label="Diabetic"  v-model="member.Personal.IsDiabetic" class="mt-2" />-->
                                                <v-checkbox label="Blood Pressure" v-model="member.Personal.IsBloodPressure" hide-details class="mt-2"></v-checkbox>
    <!--                                            <text-box label="Blood Pressure" v-model="member.Personal.IsBloodPressure" class="mt-2" />-->
                                                <text-box label="Any Other Health Issue" v-model="member.Personal.OtherHealthIssue" class="mt-2" />
<!--                                            </template>-->
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card outlined>
                                <v-card-title>Education Details</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <combo-box label="Qualification" v-model="member.Education.EducationLevel"
                                                       :items="qualifications" item-text="Description" item-value="Description" />
                                            <text-box label="Deeni Taleem" v-model="member.Education.DeeniTaalim" class="mt-2" />
                                            <text-box label="Quran Hifz" v-model="member.Education.QuranHifz" class="mt-2" readonly />
                                            <text-box label="Occupation" v-model="member.Education.Occupation" class="mt-2" />
                                            <text-box label="Occupation Field" v-model="member.Education.OccupationField" class="mt-2" />
                                            <text-box label="Occupation Details" v-model="member.Education.OccupationDetails" class="mt-2" />
                                            <text-box label="Languages Spoken" v-model="member.Education.LanguagesSpoken" class="mt-2" />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col>
                    <v-row v-if="member.FKStatusID == 1">
                        <v-col>
                            <v-card outlined>
                                <v-card-title>Performance & Feedback</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <v-card outlined>
                                                <v-card-title>8/10 (80%)</v-card-title>
                                                <v-card-subtitle>Miqaat Attended vs Registered</v-card-subtitle>
                                            </v-card>
                                        </v-col>
                                        <v-col>
                                            <v-card outlined>
                                                <v-card-title>20</v-card-title>
                                                <v-card-subtitle>Duties Performed</v-card-subtitle>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-card outlined>
                                                <v-card-title>
                                                    <span class="green--text text--darken-4 mr-1">10-Good</span> |
                                                    <span class="red--text text--darken-4 ml-1">5-Bad</span>
                                                </v-card-title>
                                                <v-card-subtitle>Appraisals</v-card-subtitle>
                                            </v-card>
                                        </v-col>
                                        <v-col>
                                            <v-card outlined>
                                                <v-card-title>
                                                    <v-rating :length="5" :value="3"></v-rating>
                                                </v-card-title>
                                                <v-card-subtitle>Average Rating</v-card-subtitle>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-card outlined>
                                                <v-card-title>10,000.00</v-card-title>
                                                <v-card-subtitle>Total Contribution</v-card-subtitle>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-card outlined>
                                <v-card-title>{{ member.FKStatusID == 2 ? "BGI Khidmat Commitment" : "Idara Details" }}</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <template v-if="member.FKStatusID == 1">
                                                <text-box label="Joining Years"
                                                          v-model="member.Idara.YearsInBGI" readonly type="number" />
                                                <text-box label="Lead Task Zone"
                                                          v-model="member.Idara.LeadTaskZone" class="mt-2" />
                                                <text-box label="Responsibility Zone"
                                                          v-model="member.Idara.ResponsibilityZone" class="mt-2" />
                                                <text-box label="Achievements"
                                                          v-model="member.Idara.PreviousAchievements" class="mt-2" />
                                                <text-box label="Other Idara"
                                                          v-model="member.Idara.OtherIdara" class="mt-2" />
                                            </template>

                                            <text-box label="Reasons for joining BGI"
                                                      v-model="member.Idara.JoiningReason" class="mt-2" :rules="validation.reasonRules" required />
                                            <text-box label="Your interest area for BGI khidmat"
                                                      v-model="member.Idara.InterestArea" class="mt-2" :rules="validation.interestRules" required />
                                            <combo-box label="How many international miqaat you can attend for khidmat"
                                                       :items="['Yearly 7+', 'Yearly 4-6', 'Yearly 1-3']"
                                                      v-model="member.Idara.IsAttendInternationalMiqaat" class="mt-2" :rules="validation.miqaatRules" required />
                                            <text-box label="Other than international miqaat, how much time can you dedicate for local khidmat"
                                                      v-model="member.Idara.LocalKhidmatTime" class="mt-2" :rules="validation.localKhidmatRules" required />
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <template v-if="member.FKStatusID == 1">
                                    <v-card-title>Titles</v-card-title>
                                    <v-card-text>
                                        <v-list dense v-if="member.Titles.length > 0">
                                            <v-list-item v-for="title in member.Titles" :key="title.Title">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ title.Title }} ({{ title.TitleYear }})</v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                        <h4 v-else>No Titles Available</h4>
                                    </v-card-text>
                                    <v-card-title>Finance</v-card-title>
                                    <v-card-text>
                                        <v-list dense v-if="member.Finance.length > 0">
                                            <v-list-item v-for="finance in member.Finance" :key="finance.FiscalYearID">
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ finance.CollectionHead }} ({{ finance.FiscalYear }})
                                                        <v-icon color="green" small v-if="finance.IsPaid">fa-check-circle</v-icon>
                                                        <v-icon color="red" small v-else>fa-times-circle</v-icon>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                        <h4 v-else>No Finance Data Available</h4>
                                    </v-card-text>
                                </template>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
<!--        <v-row>-->
<!--            <v-col align="end">-->
<!--                <v-divider class="bg-primary"></v-divider>-->
<!--                <v-btn color="primary" class="mt-2" @click="saveMember">Save</v-btn>-->
<!--            </v-col>-->
<!--        </v-row>-->
    </v-container>
</template>

<script>
    import axios from "axios";
    import moment from 'moment';

    export default {
        title: "Members",
        props: ['member'],
        data(){
            return {
                its: "",
                teams: [],
                maritalStatus: ['Single', 'Engaged', 'Married', 'Separated', 'Divorced', 'Widow/Widower'],
                bloodGroups: ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'],
                qualifications: [], //['Primary Education', 'Secondary Education', 'High School', 'Graduate', 'Post-Graduate', 'Doctorate'],
                validation: {
                    reasonRules: [v => (!!v || v != 0) || 'Reason for joining BGI is required'],
                    interestRules: [v => (!!v || v != 0) || 'your interest area for BGI khidmat is required'],
                    miqaatRules: [v => !!v || 'No of international miqaat attendance is required'],
                    localKhidmatRules: [v => !!v || 'Local khidmat time commitment is required'],
                    // firstNameRules: [v => !!v || 'First name is required'],
                    // fathersNameRules: [v => !!v || 'Fathers name is required'],
                    // surnameRules: [v => !!v || 'Surname is required'],
                },
                // member: {
                //     ItsID: 0,
                //     FKHierarchyID: 0,
                //     FKRankID: 0,
                //     Rank: "Member",
                //     FullName: "",
                //     Email: "",
                //     MobileNo: "",
                //     Personal: {
                //         FirstNamePrefix: "",
                //         FirstName: "",
                //         FathersNamePrefix: "",
                //         FathersName: "",
                //         Surname: "",
                //         HomeAddress: "",
                //         DateOfBirth: "",
                //         MaritalStatus: "",
                //         Height: 0,
                //         Weight: 0,
                //         BloodGroup: "",
                //         IsDiabetic: "",
                //         IsBloodPressure: "",
                //         OtherHealthIssue: "",
                //     },
                //     Education: {
                //         EducationLevel: "",
                //         Degree: "",
                //         DeeniTaalim: "",
                //         QuranHifz: "",
                //         Occupation: "",
                //         OccupationField: "",
                //         OccupationDetails: "",
                //         LanguagesSpoken: "",
                //     },
                //     Idara: {
                //         YearsInBGI: "",
                //         JoiningDate: "",
                //         OtherIdara: "",
                //         IsAttendInternationalMiqaat: "",
                //         LocalKhidmatTime: "",
                //         LeadTaskZone: "",
                //         ResponsibilityZone: "",
                //         PreviousAchievements: "",
                //         JoiningReason: "",
                //         InterestArea: "",
                //         IsFamilyJoin: true,
                //     },
                //     Titles: [],
                //     Groups: [],
                // },
                isFormValid: false,
            };
        },
        computed: {
            action() {
                return this.$route.params.action == "new" ? "New" : "Edit";
            },
            isEdit(){
                return this.$route.params.action == "edit";
            },
        },
        methods: {
            async getData() {
                this.$loadingDialog.show();

                const formData = {
                    HierarchyID: 0,
                    ItsID: this.its,
                    PageNumber: 1,
                    NoOfRows: 1,
                    HasFilter: false,
                    Filters: {},
                }

                await axios.post('Members/GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.member = data.Data.Items[0];
                        this.member.Personal.DateOfBirth = moment(this.member.Personal.DateOfBirth).format("YYYY-MM-DD");
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async saveNewMember(){
                this.$loadingDialog.show();

                // this.member.FullName = `${this.member.Personal.FirstNamePrefix == "None" ? "" : this.member.Personal.FirstNamePrefix + " "}`+
                //     `${this.member.Personal.FirstName} Bhai `+
                //     `${this.member.Personal.FathersNamePrefix == "None" ? "" : this.member.Personal.FathersNamePrefix + " "}`+
                //     `${this.member.Personal.FathersName} Bhai ${this.member.Personal.Surname}`;

                // this.formData = {
                //     Action: this.action,
                //     Member: this.member,
                //     Personal: [this.member.Personal],
                //     Education: [this.member.Education],
                //     Idara: [this.member.Idara],
                // }

                let formData = new FormData();

                formData.append("Action", this.action);

                Object.keys(this.member).forEach(key => {
                    if(key != 'Personal' && key != 'Education' && key != 'Idara')
                        formData.append(`Member.${key}`, this.member[key]);
                });

                Object.keys(this.member.Personal).forEach(key => {
                    formData.append(`Personal[0].${key}`, this.member.Personal[key]);
                });

                Object.keys(this.member.Education).forEach(key => {
                    formData.append(`Education[0].${key}`, this.member.Education[key]);
                });

                Object.keys(this.member.Idara).forEach(key => {
                    formData.append(`Idara[0].${key}`, this.member.Idara[key]);
                });

                const result = await axios.post(`Members/AddEdit`, formData,
                    {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? "success" : "error", message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result)
                {
                    let self = this;
                    setTimeout(() => {
                        self.$router.push({name: "members"});
                    }, 1000);
                }

            },
            saveMember(){
                this.$refs.frmMember.validate();

                if(this.isFormValid)
                    this.saveNewMember();
                else
                    window.scrollTo(0,0);
            }
        },
        async mounted(){
            this.$loadingDialog.show();
            this.qualifications = await this.getGeneral(12, 0);
            this.teams = await this.getOrgHierarchy(1, 5, 0, '0');
            this.teams.map(item => item.Name = `${item.Name} (${item.Zone}) (${item.TeamID})`);
            this.$loadingDialog.hide();

            // if(this.action == "Edit")
            // {
            //     this.its = this.decryptForEdit(this.$route.query.its);
            //     // await this.getData();
            // }
        },
    }
</script>

<style scoped>

</style>