<template>
    <v-app :style="{backgroundImage: 'url(' + require('@/assets/bg_02.jpg') + ')'}">
        <v-main>
            <v-container fluid class="fill-height" :style="{backgroundImage: 'url(' + require('@/assets/bg_02.jpg') + ')'}">
                <v-row align="center" justify="center">
                    <v-col>
                        <v-img class="rotate" height="172px" src="@/assets/BGIlogo.png" contain></v-img>
                        <h4 class="text-h6 text-center mt-5 primary--text">Authenticating... Please Wait</h4>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    export default {
        name: "auth",
        title(){
            return "Authorization";
        },
        data(){
            return {
            };
        },
        computed: {
            id(){
                return window.atob(this.$route.query.sID);
            },
        },
        methods: {
            // async login(its){
            //     let formData = {
            //         grant_type: 'password',
            //         username: its,
            //         password: "",
            //     };

            //     await this.$store.dispatch('login', formData)
            //         .then(() => {
            //             const vm = this;
            //             setTimeout(function(){
            //                 const module = vm.$store.getters.modules[0];
            //                 const user = vm.$store.getters.user;

            //                 if(user.FKStatusID == 2) {
            //                     let its = vm.encryptForEdit(user.ItsID);
            //                     vm.$router.push({name: 'membersAddEdit', params: {action: 'edit'}, query: {its: its}});
            //                 }
            //                 else {
            //                     if(module.Links.length == 0)
            //                         vm.$router.push({name: module.LinkName});
            //                     else
            //                     {
            //                         const link = module.Links[0];
            //                         vm.$router.push({name: link.LinkName, params: {stage: link.LinkID, id: link.LinkID, action: 'new'}});
            //                     }
            //                 }

            //             }, 1000);
            //         })
            //         .catch(error => {
            //             console.log(error.response.data);
            //             // setTimeout(() => {
            //             //     this.$router.push({name: 'login'});
            //             // }, 1500);
            //             this.$snackBar.error(error.response.data.error_description);
            //         });
            // }
            async redirectToForms(its) {
                // window.location.href("?")
                window.location.href = "https://forms.burhaniguards.com/app/login?its="+its;
            }
        },
        async mounted(){
            let its = this.id.split(',')[0];
            // await this.login(its);
            await this.redirectToForms(its)
        }
    }
</script>

<style scoped>
    @keyframes rotation {
        from {
            transform: rotateY(180deg);
        }
        to {
            transform: rotateY(0deg);
        }
    }

.rotate {
    animation-name: rotation;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
</style>