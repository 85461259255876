<template>
    <v-container fluid>
        <v-row>
            <v-col cols="9">
                <h2 class="headline">Organization Hierarchy</h2>
            </v-col>
            <v-col cols="3" align="end">
                <v-btn color="primary" @click="addGroup"><v-icon left>fa-plus</v-icon>Add Group</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        {{ cardTitle[selectedViewID] }}
                        <v-spacer></v-spacer>
                        <v-btn small class="py-4 mr-2" color="primary" v-if="selectedViewID == 0"
                               @click="exportToExcel">Export To Excel</v-btn>
                        <v-btn-toggle v-model="selectedViewID" mandatory>
                            <v-btn small color="primary" class="py-4"><v-icon small class="white--text">fa-table</v-icon></v-btn>
                            <v-btn small color="primary" class="py-4"><v-icon small class="white--text">fa-sitemap</v-icon></v-btn>
                            <v-btn small color="primary" class="py-4"><v-icon small class="white--text">fa-list</v-icon></v-btn>
                        </v-btn-toggle>
                    </v-card-title>
                    <v-expand-transition>
                        <v-card-text>
                            <v-fade-transition mode="out-in">
                                <table-view key="tblView"
                                            @viewMembers="viewMembers"
                                            @editGroup="editGroup"
                                            @deleteGroup="deleteGroup"
                                            ref="tblView"
                                            v-if="selectedViewID == 0">
                                </table-view>
                                <chart-view key="chartView"
                                            style="width:100%"
                                            v-else-if="selectedViewID == 1">
                                </chart-view>
                                <tree-view key="chartView"
                                            @viewMembers="viewMembers"
                                            @editGroup="editGroup"
                                            @deleteGroup="deleteGroup"
                                            v-else-if="selectedViewID == 2">
                                </tree-view>
                            </v-fade-transition>
                        </v-card-text>
                    </v-expand-transition>
                </v-card>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgGroup" width="800" :title="groupFormTitle" :on-confirm="saveGroup">
            <template v-slot:content>
                <v-row>
                    <v-col>
                        <text-box label="Group Name" v-model="group.Name"></text-box>
                        <combo-box label="Group Type" v-model="group.FKTypeID" class="mt-2"
                                   :items="orgHierarchyTypes" item-text="Description" item-value="Value01"
                                   @change="groupTypeChanged" />
                        <combo-box label="Parent" v-model="group.ParentID" class="mt-2"
                                   :items="groups" item-text="Lineage" item-value="HierarchyID" />
                        <combo-box label="Gender" v-model="group.Gender" class="mt-2"
                                   :items="genders" item-text="name" item-value="value" @change="genderSelected" />
                        <text-box label="Team ID" v-model="group.TeamID" type="number" class="mt-2" v-if="group.FKTypeID == 5" />
                        <combo-box label="Incharge"
                                   v-model="group.FKInchargeITS"
                                   :loading="incharges.loading"
                                   :search-input.sync="incharges.search"
                                   :items="incharges.items"
                                   item-text="FullName_ITS"
                                   item-value="ItsID"
                                   class="mt-2"
                                   hide-no-data
                                   cache-items />
                        <combo-box label="Ladies Incharge"
                                   v-if="group.FKTypeID == 3"
                                   v-model="group.FKLadiesInchargeITS"
                                   :loading="ladiesIncharges.loading"
                                   :search-input.sync="ladiesIncharges.search"
                                   :items="ladiesIncharges.items"
                                   item-text="FullName_ITS"
                                   item-value="ItsID"
                                   class="mt-2"
                                   hide-no-data
                                   cache-items />
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>

<!--        <simple-dialog ref="dlgMembers" title="Members" :hide-confirm-button="true" cancel-button-text="Close">-->
<!--            <template v-slot:content>-->
<!--                <members-view :hierarchy-id="group.HierarchyID"></members-view>-->
<!--            </template>-->
<!--        </simple-dialog>-->

    </v-container>
</template>

<script>
    import axios from 'axios';
    import ChartView from "./views/chartView";
    import TableView from "./views/tableView";
    import TreeView from "./views/treeView";

    export default {
        title: "Org Hierarchy",
        components: {
            ChartView,
            TableView,
            TreeView,
        },
        data(){
            return {
                isLoaded: false,
                cardTitle: ['Table View', 'Hierarchy View', 'Tree View'],
                selectedViewID: 0,
                groups: [],
                orgHierarchyTypes: [],
                group: {
                    HierarchyID: 0,
                    ParentID: 0,
                    FKTypeID: 0,
                    Name: '',
                    FKInchargeITS: 0,
                    FKLadiesInchargeITS: 0,
                    TeamID: 0,
                    Gender: '',
                },
                incharges: {
                    loading: false,
                    items: [],
                    search: '',
                },
                ladiesIncharges: {
                    loading: false,
                    items: [],
                    search: '',
                },
                genders: [
                    {name: 'All', value: 'All'},
                    {name: 'Gents', value: 'Male'},
                    {name: 'Ladies', value: 'Female'},
                ],
            }
        },
        computed: {
            groupFormTitle(){
                return this.group.HierarchyID == 0 ? "New Group" : "Edit Group";
            }
        },
        methods: {
            async loadIncharges(val, gender) {

                let formData = {"FullName": val, "Rank": val, "Gender": gender};

                if(!isNaN(val))
                    formData = {"ItsID": val};

                if(gender == 'Male') {
                    this.incharges.loading = true;
                    this.incharges.items = await this.getMembers(formData);
                    this.incharges.items.map(e => e.FullName_ITS = `${e.FullName_ITS} (${e.Rank})`);
                    this.incharges.loading = false;
                }
                else {
                    this.ladiesIncharges.loading = true;
                    this.ladiesIncharges.items = await this.getMembers(formData);
                    this.ladiesIncharges.items.map(e => e.FullName_ITS = `${e.FullName_ITS} (${e.Rank})`);
                    this.ladiesIncharges.loading = false;
                }
            },
            async viewMembers(group){
                await this.$router.push({name: 'members', params: {orgID: group.HierarchyID}});
            },
            addGroup(){
                this.group = {
                    HierarchyID: 0,
                    ParentID: 0,
                    FKTypeID: 0,
                    Name: '',
                    FKInchargeITS: 0,
                    FKLadiesInchargeITS: 0,
                    TeamID: 0,
                };

                this.$refs.dlgGroup.show();
            },
            async editGroup(group){
                this.$loadingDialog.show();

                this.group = {
                    HierarchyID: group.HierarchyID,
                    ParentID: group.ParentID,
                    FKTypeID: group.FKTypeID,
                    Name: group.Name,
                    FKInchargeITS: group.FKInchargeITS,
                    FKLadiesInchargeITS: group.FKLadiesInchargeITS,
                    TeamID: group.TeamID,
                    Gender: group.Gender,
                };

                const typeID = this.group.FKTypeID - 1;
                this.groups = await this.getOrgHierarchy(1, typeID, 0, '0');
                await this.loadIncharges(group.FKInchargeITS, 'Male');

                if(this.group.FKTypeID == 3)
                    await this.loadIncharges(group.FKLadiesInchargeITS, 'Female');

                this.$loadingDialog.hide();

                this.$refs.dlgGroup.show();
            },
            async groupTypeChanged() {
                this.$loadingDialog.show();
                const typeID = this.group.FKTypeID - 1;
                this.groups = await this.getOrgHierarchy(1, typeID, 0, '0');
                this.$loadingDialog.hide();
            },
            async genderSelected(){
                if(this.group.FKTypeID == 5) {
                    this.$loadingDialog.show();
                    await axios.post('OrgHierarchy/GetNewTeamID', {Gender: this.group.Gender})
                        .then(async response => {
                            const data = response.data;
                            const status = data.Status;

                            if(!status)
                                this.$snackBar.error(data.Message);
                            else
                                this.group.TeamID = data.Data;
                        })
                        .catch(error => {
                            console.log(error);
                            this.$snackBar.error('An unexpected error has occurred');
                        });
                    this.$loadingDialog.hide();
                }
            },
            async deleteGroup(group){
                const isDelete = await this.$confirmDialog.show({
                    title: "Delete Group",
                    message: 'Are you sure you want to delete this group? Please confirm.',
                    isCritical: true,
                });

                if(isDelete)
                {
                    this.$loadingDialog.show();

                    const result = await axios.post(`OrgHierarchy/Delete`, {HierarchyID: group.HierarchyID})
                        .then(async response => {
                            const data = response.data;
                            const status = data.Status;

                            this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                            return status;
                        })
                        .catch(error => {
                            console.log(error);
                            this.$snackBar.error('An unexpected error has occurred');
                        });

                    if(result) {
                        setTimeout(()=>{
                            window.location.reload();
                        }, 1500);
                    }

                    this.$loadingDialog.hide();
                }
            },
            async saveGroup(){
                let result = false;

                if(this.group.Name.length == 0)
                {
                    this.$snackBar.error('Please enter a name for this group');
                    return;
                }
                if(this.group.FKTypeID == 0)
                {
                    this.$snackBar.error('Please select a type for this group');
                    return;
                }
                if(this.group.ParentID == 0 && this.group.FKTypeID != 1)
                {
                    this.$snackBar.error('Please select a parent for this group');
                    return;
                }

                this.$loadingDialog.show();

                const formData = {
                    Hierarchy: this.group,
                }

                result = await axios.post('OrgHierarchy/AddEdit', formData)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result) {
                    this.$refs.dlgGroup.hide();

                    if(this.selectedViewID == 0) {
                        await this.$refs.tblView.getData();
                    }
                    else {
                        setTimeout(()=>{
                            window.location.reload();
                        }, 1500);
                    }
                }
            },
            exportToExcel(){
                this.$refs.tblView.exportToExcel();
            }
        },
        watch: {
            'incharges.search'(val) {
                val && val !== this.group.incharge && this.loadIncharges(val, 'Male');
            },
            'ladiesIncharges.search'(val) {
                val && val !== this.group.ladiesIncharge && this.loadIncharges(val, 'Female');
            },
            'selectedViewID'(val){
                console.log(val);
                console.log(this.isLoaded);
                if(this.isLoaded)
                    this.$router.replace({query: {view: val}});
            }
        },
        async mounted(){
            if('view' in this.$route.query)
                this.selectedViewID = this.$route.query.view;

            this.isLoaded = false;
            this.$loadingDialog.show();
            this.orgHierarchyTypes = await this.getGeneral(1, 0);
            //this.$loadingDialog.hide();
            this.isLoaded = true;
        }
    }
</script>

<style scoped>

</style>