<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="6" offset-md="3">
                <h2 class="headline">
                    BGI Code Of Conduct
                </h2>
            </v-col>
        </v-row>
        <v-form ref="frmMember" v-model="isFormValid" @submit.stop.prevent="saveMember">
            <v-row v-if="'ItsID' in member">
                <v-col cols="12" md="6" offset-md="3">
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col class="d-flex align-start">
                                    <v-img style="max-width: 100px; border: 2px Solid #c3c3c3; border-radius: 4px;"
                                           class="mr-3"
                                           :src="member.PictureInITS" contain :alt="member.ItsID.toString()" />
                                    <div>
                                        <p class="ma-0 body-2"><b>ITS No:</b></p>
                                        <p class="ma-0 body-2"> {{ member.ItsID }}</p>
                                        <p class="ma-0 mt-1 body-2"><b>Full Name:</b></p>
                                        <p class="ma-0 body-2"> {{ member.FullName }}</p>
                                        <p class="ma-0 mt-1 body-2"><b>Age:</b></p>
                                        <p class="ma-0 body-2"> {{ member.Age }}</p>
                                        <p class="ma-0 mt-1 body-2"><b>Jamaat:</b></p>
                                        <p class="ma-0 body-2"> {{ member.Jamaat }}</p>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-title>Health Details</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <h4 class="ma-0">Blood Group</h4>
                                    <v-radio-group row v-model="member.Personal.BloodGroup" class="mt-0"
                                                   :rules="validation.bldGrpRules" required>
                                        <template v-for="grp in bloodGroups">
                                            <v-radio :label="grp" :value="grp"/>
                                        </template>
                                    </v-radio-group>
    <!--                                <combo-box :items="bloodGroups" v-model="member.Personal.BloodGroup" class="mt-2" />-->

                                    <h4 class="ma-0 mt-3">Diabetic?</h4>
                                    <v-radio-group row v-model="member.Personal.IsDiabetic.toString()" class="mt-0"
                                                   :rules="validation.diabeticRules" required>
                                        <v-radio label="Yes" value="true"/>
                                        <v-radio label="No" value="false"/>
                                    </v-radio-group>

                                    <h4 class="ma-0 mt-3">Blood pressure problem?</h4>
                                    <v-radio-group row v-model="member.Personal.IsBloodPressure.toString()" class="mt-0"
                                                   :rules="validation.bldPressureRules" required>
                                        <v-radio label="Yes" value="true"/>
                                        <v-radio label="No" value="false"/>
                                    </v-radio-group>

                                    <h4 class="ma-0 mt-3">Any Other Health Issue</h4>
                                    <text-box v-model="member.Personal.OtherHealthIssue" class="mt-2" required />
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-title>Education Details</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <h4 class="ma-0">Occupation</h4>
                                    <text-box v-model="member.Education.Occupation" class="mt-2"
                                              :rules="validation.occupationRules" required />

                                    <h4 class="ma-0 mt-3">Occupation Field</h4>
                                    <text-box v-model="member.Education.OccupationField" class="mt-2" required />

                                    <h4 class="ma-0 mt-3">Occupation Details</h4>
                                    <text-box v-model="member.Education.OccupationDetails" class="mt-2" required />

                                    <h4 class="ma-0 mt-3">Languages Spoken</h4>
                                    <text-box v-model="member.Education.LanguagesSpoken" class="mt-2" required />

                                    <h4 class="ma-0 mt-3">Skills</h4>
                                    <v-radio-group row v-model="member.Education.Skills" class="mt-0"
                                                   :rules="validation.skillsRules" required>
                                        <template v-for="skill in skills">
                                            <v-radio :label="skill" :value="skill"/>
                                        </template>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-title>BGI Khidmat Commitment</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <h4 class="ma-0">Reasons for joining BGI</h4>
                                    <text-box v-model="member.Idara.JoiningReason" class="mt-2"
                                              :rules="validation.reasonRules" required />

                                    <h4 class="ma-0 mt-3">Your interest area for BGI khidmat</h4>
                                    <text-box v-model="member.Idara.InterestArea" class="mt-2"
                                              :rules="validation.interestRules" required />

                                    <h4 class="ma-0 mt-3">How many international miqaat you can attend for khidmat</h4>
                                    <v-radio-group row v-model="member.Idara.IsAttendInternationalMiqaat"
                                                   class="mt-0" :rules="validation.miqaatRules" required>
                                        <v-radio label="Yearly 7+" value="Yearly 7+"/>
                                        <v-radio label="Yearly 4-6" value="Yearly 4-6"/>
                                        <v-radio label="Yearly 1-3" value="Yearly 1-3"/>
                                    </v-radio-group>

                                    <h4 class="ma-0 mt-3">Other than international miqaat, how much time can you
                                        dedicate for local khidmat</h4>
                                    <v-radio-group row v-model="member.Idara.LocalKhidmatTime"
                                                   class="mt-0" :rules="validation.localKhidmatRules" required>
                                        <v-radio label="3-4 days per week" value="3-4 days per week" />
                                        <v-radio label="Weekends" value="Weekends" />
                                        <v-radio label="None" value="None" />
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text>
                            <v-row>
                                <v-col align="end">
                                    <v-divider class="bg-primary"></v-divider>
                                    <v-btn color="primary" class="mt-2" type="submit">Save</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
    import axios from "axios";
    import moment from "moment";

    export default {
        name: "selfAssessment",
        title: "Self Assessment Form",
        computed: {
            user(){
                return this.$store.getters.user;
            },
        },
        data(){
            return {
                its: 0,
                bloodGroups: ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'],
                skills: ['Administration', 'Finance', 'HR Management', 'MS Office Basic', 'MS Excel Advanced',
                    'AutoCad', '3D Max or Similar', 'Video Making/Editing/Media Lab', 'Adobe Illustrator/Photoshop',
                    'Software Designing', 'Web Development', 'Database Management', 'Networking', 'Fire Fighting',
                    'First Aid/Medical Service', 'Civil Engineering', 'Carpeting', 'Plumbing', 'Electrical Engineering',
                    'Draughtsman', 'Crowd Control', 'Psychology Communication'],
                member: {},
                isFormValid: false,
                validation: {
                    bldGrpRules: [v => (!!v || v != 0) || 'Blood group is required'],
                    diabeticRules: [v => !!v || 'Please select if you have diabetes or not' ],
                    bldPressureRules: [v => !!v || 'Please select if you have blood pressure issues or not'],
                    occupationRules: [v => !!v || 'Occupation is required'],
                    skillsRules: [v => !!v || 'Skill selection is required'],
                    reasonRules: [v => (!!v || v != 0) || 'Reason for joining BGI is required'],
                    interestRules: [v => (!!v || v != 0) || 'Your interest area for BGI khidmat is required'],
                    miqaatRules: [v => !!v || 'No of international miqaat attendance is required'],
                    localKhidmatRules: [v => !!v || 'Local khidmat time commitment is required'],
                },
            };
        },
        methods: {
            async getData() {
                this.$loadingDialog.show();

                const formData = {
                    HierarchyID: 0,
                    ItsID: this.its,
                    PageNumber: 1,
                    NoOfRows: 1,
                    HasFilter: false,
                    Filters: {},
                    StatusID: 2,
                }

                await axios.post('Members/GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        console.log(data);

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.member = data.Data.Items[0];
                        this.member.Personal.DateOfBirth = moment(this.member.Personal.DateOfBirth).format("YYYY-MM-DD");
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            saveMember(){
                this.$refs.frmMember.validate();

                if(this.isFormValid)
                    this.saveNewMember();
                else
                    window.scrollTo(0,0);
            },
            async saveNewMember(){
                this.$loadingDialog.show();

                let formData = new FormData();

                formData.append("Action", "Edit");

                Object.keys(this.member).forEach(key => {
                    if(key != 'Personal' && key != 'Education' && key != 'Idara')
                        formData.append(`Member.${key}`, this.member[key]);
                });

                Object.keys(this.member.Personal).forEach(key => {
                    formData.append(`Personal[0].${key}`, this.member.Personal[key]);
                });

                Object.keys(this.member.Education).forEach(key => {
                    formData.append(`Education[0].${key}`, this.member.Education[key]);
                });

                Object.keys(this.member.Idara).forEach(key => {
                    formData.append(`Idara[0].${key}`, this.member.Idara[key]);
                });

                const result = await axios.post(`Members/AddEdit`, formData,
                    {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? "success" : "error", message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
        },
        async mounted(){
            this.its = this.user.ItsID;
            await this.getData();
        },
    }
</script>

<style scoped>

</style>