<template>
    <v-container fluid>
        <v-row>
            <v-col md="6" offset-md="3">
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col md="6" cols="12">
                                Fiscal Years
                            </v-col>
                            <v-col md="6" cols="12" align="end">
                                <v-btn color="primary" @click="addYear" block>
                                    <v-icon left>fa-plus</v-icon> New Year
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-list dense>
                            <v-list-item v-for="item in fiscalYears" :key="item.FiscalYearID">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.Description }}</v-list-item-title>
                                    <v-list-item-subtitle>Start: <b>{{ item.StartDate }}</b> End: <b>{{ item.EndDate }}</b></v-list-item-subtitle>
                                    <template v-if="$vuetify.breakpoint.smAndDown">
                                        <div class="d-flex mt-2">
                                            <template v-if="item.IsActive">
                                                <v-icon color="primary">fa-check-circle</v-icon>
                                            </template>
                                            <template v-else>
                                                <v-btn color="primary" small
                                                       class="py-4"
                                                       @click="updateStatus(item.FiscalYearID)">
                                                    <v-icon left small>fa-check-circle</v-icon> Set As Active
                                                </v-btn>
                                                <v-btn color="red darken-4" icon
                                                       class="ml-2"
                                                       @click="deleteYear(item)">
                                                    <v-icon>fa-trash</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-btn color="blue darken-4" icon
                                                   class="ml-2"
                                                   @click="editYear(item)">
                                                <v-icon>fa-pencil</v-icon>
                                            </v-btn>
                                        </div>
                                        <v-divider class="mt-3" />
                                    </template>
                                </v-list-item-content>
                                <v-list-item-action v-if="$vuetify.breakpoint.mdAndUp">
                                    <div class="d-flex">
                                        <template v-if="item.IsActive">
                                            <v-chip label color="primary">
                                                <v-icon left small>fa-check-circle</v-icon>
                                                Active
                                            </v-chip>
                                        </template>
                                        <template v-else>
                                            <v-btn color="primary" small
                                                   class="py-4"
                                                   @click="updateStatus(item.FiscalYearID)"
                                                   outlined>
                                                <v-icon left small>fa-check-circle</v-icon> Set As Active
                                            </v-btn>
                                            <v-btn color="red darken-4" icon
                                                   class="ml-2"
                                                   @click="deleteYear(item)">
                                                <v-icon>fa-trash</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-btn color="blue darken-4" icon
                                               class="ml-2"
                                               @click="editYear(item)">
                                            <v-icon>fa-pencil</v-icon>
                                        </v-btn>
                                    </div>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgYear" width="600" :on-confirm="saveYear">
            <template v-slot:content>
                <v-row>
                    <v-col>
                        <text-box label="Description" v-model="fiscalYear.Description" />
                        <date-picker label="Start Date" v-model="fiscalYear.StartDate" class="mt-2" />
                        <date-picker label="End Date" v-model="fiscalYear.EndDate" class="mt-2" />
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "Fiscal Years",
        computed: {

        },
        data(){
            return {
                fiscalYears: [],
                fiscalYear: {
                    FiscalYearID: 0,
                    Description: '',
                    StartDate: '',
                    EndDate: '',
                },
            };
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                await axios.post(`FiscalYears/GetData`, {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.fiscalYears = data.Data;
                        else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            addYear(){
                this.fiscalYear = {
                    FiscalYearID: 0,
                    Description: '',
                    StartDate: '',
                    EndDate: '',
                };

                this.$refs.dlgYear.show();
            },
            editYear(year){
                this.fiscalYear = year;
                this.$refs.dlgYear.show();
            },
            async deleteYear(year){
                const isDelete = await this.$confirmDialog.show({
                    title: 'Delete Fiscal Year',
                    message: `Are you sure you want to delete fiscal year: ${year.Description}? Please confirm.`,
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                this.$loadingDialog.show();

                const result = await axios.post('FiscalYears/Delete', {FiscalYearID: year.FiscalYearID})
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                    await this.getData();

                this.$loadingDialog.hide();
            },
            async saveYear(){
                if(this.fiscalYear.Description.length == 0) {
                    this.$snackBar.error('Please enter a description');
                    return;
                }
                if(this.fiscalYear.StartDate.length == 0) {
                    this.$snackBar.error('Please select a start date');
                    return;
                }
                if(this.fiscalYear.EndDate.length == 0) {
                    this.$snackBar.error('Please select an end date');
                    return;
                }

                this.$loadingDialog.show();

                const result = await axios.post('FiscalYears/AddEdit', this.fiscalYear)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                {
                    this.$refs.dlgYear.hide();
                    await this.getData();
                }

                this.$loadingDialog.hide();
            },
            async updateStatus(yearID){
                this.$loadingDialog.show();

                const result = await axios.post('FiscalYears/UpdateStatus', {FiscalYearID: yearID})
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                    await this.getData();

                this.$loadingDialog.hide();
            },
        },
        async mounted(){
            await this.getData();
        },
    }
</script>

<style scoped>

</style>