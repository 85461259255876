<template>
    <v-container fluid>
        <v-row>
            <v-col class="d-flex">
                <h2 class="headline">Vouchers Dashboard</h2>
                <v-spacer />
                <combo-box label="Fiscal Year" background-color="white" v-model="fiscalYearID"
                           :items="fiscalYears" item-text="Description" item-value="FiscalYearID"
                           @change="getData" />
                <combo-box label="Collection Head" background-color="white" v-model="collectionHeadID" class="ml-2"
                           :items="collectionHeads" item-text="Description" item-value="CollectionHeadID"
                           @change="getData" />
                <combo-box label="Office" background-color="white" v-model="officeID" class="ml-2"
                           :items="offices" item-text="Description" item-value="Value01"
                           @change="getData" />
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card color="orange darken-3" dark>
                    <v-card-title class="pb-0"><b>{{ statistics.Currency }} {{ statistics.PendingAmount.formatDecimals() }} ({{ statistics.Pending }})</b></v-card-title>
                    <v-card-text><b>Approval Pending</b></v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card color="green darken-3" dark>
                    <v-card-title class="pb-0"><b>{{ statistics.Currency }} {{ statistics.ApprovedAmount.formatDecimals() }} ({{ statistics.Approved }})</b></v-card-title>
                    <v-card-text><b>Approved</b></v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card color="red darken-3" dark>
                    <v-card-title class="pb-0"><b>{{ statistics.Currency }} {{ statistics.RejectedAmount.formatDecimals() }} ({{ statistics.Rejected }})</b></v-card-title>
                    <v-card-text><b>Rejected</b></v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="d-flex">
                <v-btn-toggle v-model="statusID" dark @change="statusChanged" mandatory>
                    <v-btn small class="py-4" color="orange darken-3" value="1">Pending</v-btn>
                    <v-btn small class="py-4" color="green darken-3" value="2">Approved</v-btn>
                    <v-btn small class="py-4" color="red darken-3" value="3">Rejected</v-btn>
                </v-btn-toggle>
                <v-spacer />
                <template v-if="statusID == 1">
                    <v-btn color="primary" small class="py-4" :disabled="tableData.selected.length == 0" @click="updateStatus(2)">
                        <v-icon left small>fa-check</v-icon> Approve Voucher(s)
                    </v-btn>
                    <v-btn color="red darken-3 white--text" small class="py-4 ml-2" :disabled="tableData.selected.length == 0" @click="updateStatus(3)">
                        <v-icon left small>fa-times</v-icon> Reject Voucher(s)
                    </v-btn>
                </template>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>
                        <data-table :table-data="tableData" v-model="tableData.selected" item-key="Folio" disable-sort wrap-text
                                    show-select
                                    @pageChanged="pageChanged"
                                    @filterChanged="filterChanged">
                            <template v-slot:item.Amount="{item}">
                                {{ item.Amount.formatDecimals() }}
                            </template>
                            <template v-slot:item.Attachment="{item}">
                                <v-btn small class="py-4 my-1" color="blue darken-3 white--text" :href="item.Attachment" target="_blank">
                                    <v-icon left small>fa-search</v-icon> Attachment
                                </v-btn>
                            </template>
                            <template v-slot:item.Status="{item}">
                                <v-chip label :color="statusColor(item.FKStatusID)" class="white--text"><b>{{ item.Status }}</b></v-chip>
                            </template>
                            <template v-slot:item.Action="{item}">
                                <v-btn small class="py-4" color="primary" @click="print(item.Folio)" v-if="item.FKStatusID == 2">
                                    <v-icon left small>fa-print</v-icon> Print
                                </v-btn>
                            </template>
                        </data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        name: "vouchers",
        title: "Vouchers Dashboard",
        data(){
            return {
                fiscalYears: [],
                collectionHeads: [],
                offices: [],
                officeID: 0,
                statusID: 1,
                fiscalYearID: 0,
                collectionHeadID: 0,
                statistics: {},
                tableData: {
                    header: [
                        {text: "S.No", value: "SNo"},
                        {text: "Team", value: "Team", applyFilter: true},
                        {text: "Zone", value: "Zone", applyFilter: true},
                        {text: "Doc #", value: "DocumentNoString", applyFilter: true},
                        {text: "Date", value: "DocumentDate", applyFilter: true},
                        {text: "Office", value: "Office", applyFilter: true},
                        {text: "Payment Mode", value: "PaymentMode", applyFilter: true},
                        {text: "Currency", value: "Currency", applyFilter: true},
                        {text: "Amount", value: "Amount", applyFilter: true},
                        {text: "Total Members", value: "TotalMembers" },
                        {text: "Total Paid", value: "PaidMembers" },
                        {text: "Attachment", value: "Attachment"},
                        {text: "Status", value: "Status"},
                        {text: "Action", value: "Action"},
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                    noOfRows: this.tableRows,
                    totalRecords: 1,
                    totalPages: 0,
                    pageNo: 1,
                },
            };
        },
        computed: {
            selectedVouchers(){
                let selected = [];

                this.tableData.selected.forEach(x => {
                    selected.push(x.Folio);
                });

                return selected;
            },
        },
        methods: {
            statusColor(statusID){
                let color = "";

                switch(statusID) {
                    case 1:
                        color = 'orange darken-3';
                        break;
                    case 2:
                        color = 'green darken-3';
                        break;
                    case 3:
                        color = 'red darken-3';
                        break;
                    default:
                        break;
                }

                return color;
            },
            statusChanged(){
                this.tableData.selected = [];
                this.tableData.data = [];
                this.getData();
            },
            async getOffices(option){
                this.$loadingDialog.show();

                await axios.post("Vouchers/GetOffices", {Option: option})
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.offices = data.Data;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    FiscalYearID: this.fiscalYearID,
                    CollectionHeadID: this.collectionHeadID,
                    OfficeID: this.officeID,
                    StatusID: this.statusID,
                    PageNumber: this.tableData.pageNo,
                    NoOfRows: this.tableData.noOfRows,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                }

                await axios.post("Vouchers/Dashboard_GetData", formData)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.statistics = data.Data.Statistics;
                        this.tableData.data = data.Data.Items;
                        this.tableData.totalPages = data.Data.TotalPages;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            pageChanged(pageNo) {
                this.tableData.pageNo = pageNo;
                this.getData();
            },
            filterChanged(){
                this.getData();
            },
            async updateStatus(statusID){
                const isUpdate = await this.$confirmDialog.show({
                    title: `${statusID == 2 ? 'Approve' : 'Reject'} Voucher(s)`,
                    message: `Are you sure you want to ${statusID == 2 ? 'approve' : 'reject'} the selected vouchers? This action cannot be undone. Please confirm.`,
                    isCritical: statusID == 3,
                });

                if(!isUpdate)
                    return;

                this.$loadingDialog.show();

                const formData = {
                    Folios: this.selectedVouchers.join(','),
                    StatusID: statusID,
                };

                const result = await axios.post("Vouchers/UpdateStatus", formData)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success': 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result) {
                    await this.getData();
                    this.tableData.selected = [];
                }

                this.$loadingDialog.hide();
            },
            async print(folio){
                this.$loadingDialog.show();

                let formData = {
                    Folio: folio,
                };
                await this.printDocument("Vouchers/Print", formData);

                this.$loadingDialog.hide();
            },
        },
        async mounted(){
            this.tableData.noOfRows = this.tableRows;

            this.$loadingDialog.show();

            this.fiscalYears = await this.getFiscalYears(false);
            this.collectionHeads = await this.getCollectionHeads();
            await this.getOffices(2);

            this.fiscalYearID = this.fiscalYears.find(x => x.IsActive == 1).FiscalYearID;
            this.collectionHeadID = this.collectionHeads[0].CollectionHeadID;
            this.officeID = this.offices[0].Value01;

            this.$loadingDialog.hide();

            await this.getData();
        },
    }
</script>

<style scoped>

</style>