var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('h2',{staticClass:"headline"},[_vm._v("Members Requests")])])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-spacer'),_c('text-box',{staticClass:"mr-2",attrs:{"label":"Search","background-color":"white"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search($event)}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),(_vm.tabIndex == 0)?[_c('v-btn',{staticClass:"py-4",attrs:{"color":"red darken-3 white--text","small":"","disabled":_vm.tableData.selected.length == 0},on:{"click":_vm.deleteSelected}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("fa-times")]),_vm._v(" Remove Member(s) ")],1),_c('v-btn',{staticClass:"py-4 ml-2",attrs:{"color":"blue darken-3 white--text","small":"","disabled":_vm.tableData.selected.length == 0},on:{"click":_vm.rejectSelected}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("fa-trash")]),_vm._v(" Reject Request(s) ")],1)]:_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"py-4 ml-2",attrs:{"color":"primary","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("fa-file-excel-o")]),_vm._v(" Export To Excel "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("fa-chevron-down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.exportToExcel(1)}}},[_vm._v("In-Process")]),_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.exportToExcel(2)}}},[_vm._v("Completed")]),(_vm.tabIndex == 0)?_c('v-list-item',{attrs:{"dense":""},on:{"click":function($event){return _vm.exportToExcel(3)}}},[_vm._v("Rejected")]):_vm._e()],1)],1)],2)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-tabs',{model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('v-tab',[_vm._v("Termination Requests")]),_c('v-tab',[_vm._v("Transfer Requests")])],1),_c('v-tabs-items',{staticStyle:{"height":"66vh","overflow":"auto"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[_c('v-tab-item',[_c('data-table',{staticClass:"ma-1",attrs:{"table-data":_vm.tableData,"total-pages":_vm.tableData.totalPages,"disable-sort":"","item-key":"FKItsID","show-select":"","hide-filters":"","wrap-text":""},on:{"pageChanged":_vm.pageChanged},scopedSlots:_vm._u([{key:"item.PictureInITS",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"my-1",staticStyle:{"max-height":"60px","min-height":"60px","max-width":"50px","border":"1px Solid #ccc","border-radius":"4px"},attrs:{"src":item.PictureInITS,"contain":"","alt":item.FKItsID.toString()}})]}},{key:"item.Reason",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Reason)+" "),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","x-small":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.showDescription(item.Description)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa-info")])],1)]}},{key:"item.ApprovedByAmil",fn:function(ref){
var item = ref.item;
return [(item.ApprovedByAmil == 1 || item.ApprovedByAmil == 2)?[_c('v-chip',{staticClass:"my-1",staticStyle:{"width":"100%","text-align":"center"},attrs:{"label":"","color":(item.ApprovedByAmil == 1 ? 'orange' : item.ApprovedByAmil == 2 ? 'green' : 'red') + ' darken-3 white--text'}},[(item.ApprovedByAmil == 1)?[_c('v-icon',{attrs:{"left":"","x-small":""}},[_vm._v("fa-exclamation")]),_vm._v(" Pending")]:_vm._e(),(item.ApprovedByAmil == 2)?[_c('v-icon',{attrs:{"left":"","x-small":""}},[_vm._v("fa-check")]),_vm._v(" Approved")]:_vm._e()],2)]:(item.ApprovedByAmil == 3)?_c('v-btn',{staticClass:"py-4",attrs:{"small":"","color":"red white--text"},on:{"click":function($event){return _vm.viewReason(item.ReasonByAmil)}}},[_c('v-icon',{attrs:{"left":"","x-small":""}},[_vm._v("fa-times")]),_vm._v(" Rejected ")],1):_vm._e()]}},{key:"item.ApprovedByCommander",fn:function(ref){
var item = ref.item;
return [(item.ApprovedByCommander == 1 || item.ApprovedByCommander == 2)?[_c('v-chip',{staticStyle:{"width":"100%","text-align":"center"},attrs:{"label":"","color":(item.ApprovedByCommander == 1 ? 'orange' : item.ApprovedByCommander == 2 ? 'green' : 'red') + ' darken-3 white--text'}},[(item.ApprovedByCommander == 1)?[_c('v-icon',{attrs:{"left":"","x-small":""}},[_vm._v("fa-exclamation")]),_vm._v(" Pending")]:_vm._e(),(item.ApprovedByCommander == 2)?[_c('v-icon',{attrs:{"left":"","x-small":""}},[_vm._v("fa-check")]),_vm._v(" Approved")]:_vm._e()],2)]:(item.ApprovedByCommander == 3)?_c('v-btn',{staticClass:"py-4",attrs:{"small":"","color":"red white--text"},on:{"click":function($event){return _vm.viewReason(item.ReasonByCommander)}}},[_c('v-icon',{attrs:{"left":"","x-small":""}},[_vm._v("fa-times")]),_vm._v(" Rejected ")],1):_vm._e()]}}]),model:{value:(_vm.tableData.selected),callback:function ($$v) {_vm.$set(_vm.tableData, "selected", $$v)},expression:"tableData.selected"}})],1),_c('v-tab-item',[_c('data-table',{staticClass:"ma-1",attrs:{"table-data":_vm.transferTableData,"disable-sort":"","disable-pagination":"","hide-filters":"","wrap-text":""},scopedSlots:_vm._u([{key:"item.PictureInITS",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"my-1",staticStyle:{"max-height":"60px","min-height":"60px","max-width":"50px","border":"1px Solid #ccc","border-radius":"4px"},attrs:{"src":item.PictureInITS,"contain":"","alt":item.FKItsID.toString()}})]}},{key:"item.TransferToFKHierarchyID",fn:function(ref){
var item = ref.item;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.completeTransferRequest(item)}}},[_c('div',{staticClass:"d-flex"},[_c('combo-box',{staticClass:"mr-2",attrs:{"label":"Group","background-color":"white","items":_vm.groups,"item-text":"NameWithID","item-value":"HierarchyID"},model:{value:(item.TransferToFKHierarchyID),callback:function ($$v) {_vm.$set(item, "TransferToFKHierarchyID", $$v)},expression:"item.TransferToFKHierarchyID"}}),_c('v-btn',{staticClass:"py-4",attrs:{"color":"primary","type":"submit","small":""}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fa-refresh")]),_vm._v(" Transfer")],1)],1)])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }