<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="6" offset-md="3">
                <v-card>
                    <v-card-title>
                        <v-row>
                            <v-col>
                                ITS Data Sync Utility
                            </v-col>
                            <v-col>
                                <combo-box label="Group" class="mt-2" background-color="white" v-model="hierarchyID"
                                           :items="groups" item-text="NameWithID" item-value="HierarchyID" />
                                <v-btn block color="primary" class="mt-2" @click="updateFromITS">
                                    <v-icon left>fa-refresh</v-icon>SYNC
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text style="height: 500px; overflow: auto;">
                        <v-list dense>
                            <v-list-item v-for="item in history" :key="item.ID" dense>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.CreatedByUser }}
                                    </v-list-item-title>
                                    <v-list-item-title>
                                        {{ item.Hierarchy }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        Started At: <b>{{ item.StartTime }}</b>
                                         | Ended At: <b>{{ item.EndTime }}</b>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "ITS Data Sync",
        data(){
            return {
                groups: [],
                history: [],
                hierarchyID: 0,
            };
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                await axios.post('ITS/GetData', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.history = data.Data;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async updateFromITS(){
                this.$loadingDialog.show();

                const result = await axios.post('ITS/UpdateFromITS', {HierarchyID: this.hierarchyID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                    await this.getData();

                this.$loadingDialog.hide();
            },
        },
        async mounted() {
            this.$loadingDialog.show();
            this.groups = await this.getOrgHierarchy(2, 0, 0, '0');
            this.hierarchyID = this.groups[0].HierarchyID;
            this.$loadingDialog.hide();

            await this.getData();
        }
    }
</script>

<style scoped>

</style>