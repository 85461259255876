<template>
    <v-container fluid>
        <v-row>
            <v-col><h2>Quota Management</h2></v-col>
        </v-row>
        <v-row>
            <v-col>
                <data-table :table-data="tableData"
                            disable-pagination
                            hide-filters
                            :item-class="pointerCss"
                            @click:row="zoneClick">
                    <template v-slot:item.AllowedCount="{item}">
                        <text-box solo
                                  class="mt-1"
                                  v-model="item.AllowedCount"
                                  @click.stop
                                  @keyup.enter="updateQuota(item)" />
                    </template>
                    <template v-slot:item.IsOpenForAll="{item}">
                        <v-checkbox dense
                                    class="mt-1"
                                    v-model="item.IsOpenForAll"
                                    hide-details
                                    @click.stop
                                    @change="updateQuota(item)" />
                    </template>
                </data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    import axios from 'axios';

    export default {
        name: 'miqaatQuota',
        props: ['miqaatId', 'miqaatHierarchyId'],
        data() {
            return {
                tableData: {
                    header: [
                        {text: 'S.No', value:"SNo", width: "50px",},
                        {text: 'Zone', value:"Zone"},
                        {text: 'Gender', value:"GenderName"},
                        {text: 'Quota', value:"AllowedCount", width: "125px"},
                        {text: 'Open Quota', value:"IsOpenForAll", width: "125px"},
                        {text: 'Registered', value:"Registered"},
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                }
            }
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    MiqaatHierarchyID: this.miqaatHierarchyId,
                }

                await axios.post('Miqaat/ZoneQuotas_GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            this.tableData.data = data.Data;
                        }
                        else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            filterChanged(){
                this.getData();
            },
            async updateQuota(item){
                if(item.IsOpenForAll)
                    item.AllowedCount = 0;

                this.$loadingDialog.show();

                const formData = {
                    MiqaatHierarchyID: this.miqaatHierarchyId,
                    ZoneID: item.ZoneID,
                    Gender: item.Gender,
                    AllowedCount: item.AllowedCount,
                    IsOpenForAll: item.IsOpenForAll,
                }

                await axios.post(`Miqaat/ZoneQuotas_UpdateData`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            pointerCss(item){
                if(item.AllowedCount > 0)
                    return 'cursor-pointer';
                else
                    return '';
            },
            zoneClick(item){
                if(item.AllowedCount > 0)
                    this.$emit('openTeamQuota', item);
            }
        },
        async mounted() {
            await this.getData();
        }
    }
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer !important;
}
</style>