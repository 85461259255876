<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h2 class="headline">Members List</h2>
            </v-col>
            <v-col align="end" class="d-flex">
                <combo-box label="Group" background-color="white" v-model="hierarchyID"
                           :items="groups" item-text="NameWithID" item-value="HierarchyID"
                           @change="groupSelected"></combo-box>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="d-flex">
                <template v-if="rights.allowDelete && user.FKRankID <= 2">
                    <v-btn color="red darken-4 white--text" class="py-4" small
                           :disabled="selectedData.length == 0" @click="deleteSelected">
                        <v-icon left small>fa-user-times</v-icon> Delete
                    </v-btn>
                </template>
                <v-spacer />
                <v-btn small class="py-4 mr-2" color="primary" @click="viewNewMembers"
                       v-if="rights.allowAdd">
                    <v-icon left small>fa-user-plus</v-icon> Enrollment
                </v-btn>
                <v-btn small class="py-4 mr-2" color="primary" @click="viewTransferRequest" v-if="rights.allowTransferRequest">
                    <v-icon left small>fa-refresh</v-icon> Transfer Request
                </v-btn>
                <v-btn small class="py-4 mr-2" color="red darken-3" dark @click="viewTerminationRequest" v-if="rights.allowDeleteRequest">
                    <v-icon left small>fa-user-times</v-icon> Termination Request
                </v-btn>
                <v-btn color="primary" class="py-4" small @click="exportExcel">
                    <v-icon left small>fa-file-excel-o</v-icon> Export To Excel
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <data-table ref="tblMembers"
                            v-model="tableData.selected"
                            :table-data="tableData"
                            :total-pages="tableData.totalPages"
                            item-key="ItsID"
                            @pageChanged="pageChanged"
                            @filterChanged="filterChanged"
                            disable-sort
                            show-select wrap-text>
                    <template v-slot:item.PictureInITS="{item}">
                        <v-img style="max-height: 60px; min-height: 60px; max-width: 50px; border: 1px Solid #ccc; border-radius: 4px;"
                               class="my-1"
                               :src="item.PictureInITS" contain :alt="item.ItsID.toString()" />
                    </template>
                    <template v-slot:item.Rank="{item}">
                        <template v-if="user.FKRankID <= 2">
                            <combo-box :value="item.FKRankID" :items="ranks"
                                       item-text="Description" item-value="Value01" @change="updateRank(item, $event)"/>
                        </template>
                        <template v-else>
                            {{ item.Rank }}
                        </template>
                    </template>
                    <template v-slot:item.Ranking>
                        <div class="d-flex">
                            <div class="mr-2 green--text text--darken-4">
                                <v-icon color="green">fa-thumbs-up</v-icon> 0
                            </div>
                            <div class="ml-2 red--text text--darken-4">
                                <v-icon color="red">fa-thumbs-down</v-icon> 0
                            </div>
                        </div>
                    </template>
                    <template v-slot:item.Actions="{item}">
                        <v-btn v-if="item.FKStatusID == 2" text color="primary" small @click="enrollmentForm(item)">
                            <v-icon small>fa-file-text</v-icon>
                        </v-btn>
                        <context-menu v-else :menu-items="tableData.menu" :data-item="item"></context-menu>
                        <!-- <v-btn small color="red" icon @click="deleteSingle(item)"><v-icon small>fa-trash</v-icon></v-btn> -->
                    </template>
                </data-table>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgMembers" title="New Members Request" :hide-confirm-button="true"
                       cancel-button-text="Close">
            <template v-slot:content>
                <v-btn color="primary" class="mb-2" @click="addMembers" v-if="rights.allowAdd">Add ITS Number(s)</v-btn>
                <data-table :table-data="newMembers.tableData" disable-pagination hide-filters>
                    <template v-slot:item.Actions="{item}">
                        <v-btn small color="red darken-3 white--text" icon @click="deleteNewMembers(item.ItsID)">
                            <v-icon small>fa-trash</v-icon>
                        </v-btn>
                    </template>
                </data-table>
            </template>
        </simple-dialog>

        <simple-dialog ref="dlgTerminationRequests" title="Termination Requests" :hide-confirm-button="true"
                       cancel-button-text="Close" width="800">
            <template v-slot:content>
                <v-row>
                    <v-col>
                        <v-btn class="py-4" small color="primary" @click="newTerminationRequest">
                            <v-icon small left>fa-plus</v-icon> New Request
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-list dense v-if="requests.data.length > 0">
                            <template v-for="(item, i) in requests.data">
                                <v-list-item :key="item.FKItsID">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.FullName }}</v-list-item-title>
                                        <v-list-item-subtitle>Reason: {{ item.Reason }}</v-list-item-subtitle>
                                        <v-list-item-subtitle>Detail: {{ item.Description }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>Amil Saheb Approval: {{ item.AmilStatus  }}</v-list-item-subtitle>
                                        <v-list-item-subtitle>Commander Approval: {{ item.CommanderStatus  }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon small color="red darken-3" @click="deleteTerminationRequest(item.RequestID)"><v-icon small>fa-trash</v-icon></v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-divider v-if="i < (requests.data.length - 1)" />
                            </template>
                        </v-list>
                        <h4 class="text-center mt-3" v-else>No Requests Available</h4>
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>

        <simple-dialog ref="dlgNewTerminationRequest" title="New Termination Request" width="800" :on-confirm="saveTerminationRequest">
            <template v-slot:content>
                <v-row>
                    <v-col>
                        <combo-box label="Member"
                                   v-model="terminationRequest.ItsID"
                                   :loading="members.loading"
                                   :search-input.sync="members.search"
                                   :items="members.items"
                                   item-text="FullName_ITS"
                                   item-value="ItsID"
                                   hide-no-data
                                   cache-items />
                        <combo-box label="Reason" v-model="terminationRequest.Reason" class="mt-3"
                                   :items="requests.reasons" item-text="Description" item-value="Description" />
                        <text-box label="Describe Reason In Detail" v-model="terminationRequest.Description" class="mt-3"
                                  multi-row rows="3" />
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>

        <simple-dialog ref="dlgTransferRequests" title="Transfer Requests" :hide-confirm-button="true"
                       cancel-button-text="Close" width="800">
            <template v-slot:content>
                <v-form @submit.prevent="saveTransferRequest">
                    <div class="d-flex">
                        <combo-box label="Member"
                                   v-model="transferRequest.ItsID"
                                   :loading="members.loading"
                                   :search-input.sync="members.search"
                                   :items="members.items"
                                   item-text="FullName_ITS"
                                   item-value="ItsID"
                                   class="mr-2"
                                   hide-no-data
                                   cache-items />
                        <v-btn color="primary" type="submit" small class="py-4"><v-icon small left>fa-plus</v-icon> Add</v-btn>
                    </div>
                </v-form>
                <v-divider class="my-5" />
                <v-row>
                    <v-col>
                        <v-list dense v-if="transferRequests.length > 0">
                            <template v-for="(item, i) in transferRequests">
                                <v-list-item :key="item.FKItsID">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.FKItsID }} - {{ item.FullName }}</v-list-item-title>
                                        <v-list-item-subtitle>Jamaat: {{ item.Jamaat }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon small color="red darken-3" @click="deleteTransferRequest(item.RequestID)">
                                            <v-icon small>fa-trash</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-divider v-if="i < (transferRequests.length - 1)" />
                            </template>
                        </v-list>
                        <h4 class="text-center mt-3" v-else>No Requests Available</h4>
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>

    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "Members",
        props: ['HierarchyId'],
        data(){
            return {
                hierarchyID: 0,
                tableData: {
                    header: [
                        { text: 'S.No', value: 'SNo' },
                        { text: 'Photo', value: 'PictureInITS' },
                        { text: 'ITS', value: 'ItsID', applyFilter: true },
                        { text: 'Name', value: 'FullName', applyFilter: true },
                        { text: 'Team', value: 'Hierarchy', applyFilter: true },
                        { text: 'Jamaat', value: 'Jamaat', applyFilter: true },
                        { text: 'Mobile No', value: 'MobileNo' },
                        { text: 'Rank', value: 'Rank', applyFilter: true },
                        { text: 'Role', value: 'Role', applyFilter: true },
                        { text: 'Actions', value: 'Actions' },
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                    noOfRows: this.tableRows,
                    totalRecords: 1,
                    totalPages: 0,
                    pageNo: 1,
                    menu: [
                        {title: 'View', icon: 'fa-search', onClick: this.viewMember},
                    ]
                },
                member: {
                    ItsID: 0,
                    FKHierarchyID: 0,
                    FKRankID: 0,
                    FKRoleID: 0,
                },
                groups: [],
                ranks: [],
                roles: [],
                members: {
                    loading: false,
                    items: [],
                    search: '',
                },
                dlgRank: {
                    title: "Update Rank",
                    message: "Please select the new rank below and click save",
                    option: 1,
                    value: 0,
                },
                newMembers: {
                    tableData: {
                        header: [
                            { text: 'S.No', value: 'SNo', width: '100px' },
                            { text: 'ITS', value: 'ItsID', width: '150px' },
                            { text: 'Name', value: 'FullName' },
                            { text: 'Age', value: 'Age', width: '100px' },
                            { text: 'Jamaat', value: 'Jamaat' },
                            { text: 'Status', value: 'Status', width: '150px' },
                            { text: 'Remarks', value: 'Remarks' },
                            { text: 'Actions', value: 'Actions', width: '100px' },
                        ],
                        data: [],
                    }
                },
                requests: {
                    type: 1,
                    FKItsID: 0,
                    FromID: 0,
                    ToID: 0,
                    teams: [],
                    ranks: [],
                    reasons: [],
                    data: [],
                },
                terminationRequest: {
                    HierarchyID: 0,
                    ItsID: 0,
                    Reason: '',
                    Description: '',
                },
                transferRequests: [],
                transferRequest: {
                    ItsID: 0,
                },
                rights: {
                    allowAdd: this.verifyRights(this.$route.name, 'A'),
                    allowEdit: this.verifyRights(this.$route.name, 'E'),
                    allowDelete: this.verifyRights(this.$route.name, 'D'),
                    allowDeleteRequest: this.verifyRights(this.$route.name, 'DR'),
                    allowTransferRequest: this.verifyRights(this.$route.name, 'TR'),
                }
            };
        },
        computed: {
            user(){
                return this.$store.getters.user;
            },
            selectedData(){
                let selected = [];
                this.tableData.selected.forEach(item => {
                    selected.push(item.ItsID);
                });
                return selected;
            },
            rand() {
                return (Math.random() * 10) + 1;
            },
            requestTitle(){
                let title = "";

                switch(this.requests.type) {
                    case 1:
                        title = "Termination Request";
                        break;
                    case 2:
                        title = "Transfer Request";
                        break;
                    case 3:
                        title = "Rank Change Request";
                        break;
                };

                return title;
            },
        },
        methods: {
            groupSelected(){
                this.member.FKHierarchyID = this.hierarchyID;
                this.$router.push({params: {orgID: this.hierarchyID}});
            },
            async loadMembers(val){
                this.members.loading = true;
                this.members.items = await this.getMembers({"FullName_ITS": val,
                    "FKHierarchyID": this.hierarchyID});
                this.members.loading = false;
            },
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    HierarchyID: this.hierarchyID,
                    ItsID: 0,
                    PageNumber: this.tableData.pageNo,
                    NoOfRows: this.tableData.noOfRows,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                    StatusID: 1,
                }

                await axios.post('Members/GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.tableData.totalPages = data.Data.TotalPages;
                        this.tableData.data = data.Data.Items;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            pageChanged(pageNo) {
                this.tableData.pageNo = pageNo;
                this.getData();
            },
            filterChanged(){
                this.getData();
            },
            editMember(item){
                let its = item.ItsID;
                its = this.encryptForEdit(its);
                this.$router.push({name: 'membersAddEdit', params: {action: 'edit'}, query: {its: its}});
            },
            viewMember(item){
                let its = item.ItsID;
                its = this.encryptForEdit(its);
                    this.$router.push({name: 'membersAddEdit', params: {action: 'view'}, query: {its: its}});
            },
            async deleteSelected(){
                const result = await this.$confirmDialog.show({
                    title: 'Delete Members',
                    message: 'Are you sure you want to permanently delete selected member(s)?\n This action cannot be undone, please confirm.',
                    isCritical: true,
                });

                if(result) {

                    const reason = await this.$inputDialog.show({
                        title: "Delete Reason",
                        message: "Please specify a reason to delete member",
                    });

                    if(!reason)
                        return;

                    const formData = {
                        ItsIDs: this.selectedData.join(','),
                        Reason: `${reason} - Members Screen`, // 'Deleted From Members Screen',
                    };

                    let status = await axios.post('Members/Delete', formData)
                        .then(async response => {
                            const data = response.data;
                            const status = data.Status;

                            this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                            return status;
                        })
                        .catch(error => {
                            console.log(error);
                            this.$snackBar.error('An unexpected error has occurred');
                        });

                    if(status) {
                        await this.getData();
                    }
                }
            },
            async getNewMembers(){
                this.$loadingDialog.show();

                const result = await axios.post('Recruitment/Members_GetData',
                    { HierarchyID: this.hierarchyID })
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.newMembers.tableData.data = data.Data;

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                return result;
            },
            async viewNewMembers(){

                const its = await this.$inputDialog.show({
                    title: 'New Member Enrollment',
                    message: 'Please enter Its ID below.<br/>' +
                        '1. New members should be at least 20 years of age or above.<br/>' +
                        '2. You may only enrol members of your own Jamaat in your team.<br/>' +
                        '3. Please ensure that the ITS number of the new member is accurate before entering into the system.<br/>' +
                        '4. New member enrolment status can be viewed on your bgi portal.',
                });

                this.$loadingDialog.show();

                await axios.post('Enrollment/Verify', { ItsID: its, HierarchyID: this.hierarchyID })
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({message: data.Message, color: status ? 'success' : 'error', timeout: 5000});
                        // console.log(data);

                        if(status) {
                            let member = data.Data;
                            member.FKHierarchyID = this.hierarchyID;

                            // console.log(member);

                            this.$store.dispatch('addNewMember', member);
                            this.$router.push({name: 'assessmentFormTeam'});
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                // await this.getNewMembers();
                // this.$refs.dlgMembers.show();
            },
            async addMembers(){
                const ItsIDs = await this.$inputDialog.show({
                    title: 'New Member Request',
                    message: 'Please enter Its ID(s) below.<br/>' +
                        '1. To add multiple ITS, enter each with a comma e.g: 12345678, 12345678.<br/>' +
                        '2. New members should be at least 20 years of age or above.<br/>' +
                        '3. You may only enrol members of your own Jamaat in your team.<br/>' +
                        '4. Please ensure that the ITS number of the new member is accurate before entering into the system.<br/>' +
                        '5. New member enrolment status can viewed on your dashboard.',
                    confirmButtonText: 'Save',
                });

                if(!ItsIDs)
                    return;

                this.$loadingDialog.show();

                const formData = {
                    HierarchyID: this.hierarchyID,
                    ItsIDs: ItsIDs,
                };

                const result = await axios.post('Recruitment/Members_Add', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result)
                    await this.getNewMembers();
            },
            async deleteNewMembers(itsID){
                this.$loadingDialog.show();

                const formData = {
                    ItsIDs: itsID,
                };

                const result = await axios.post('Recruitment/Members_Delete', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result)
                    await this.getNewMembers();
            },
            enrollmentForm(item){
                let profile = {
                    ItsID: item.ItsID,
                    FullName: item.FullName,
                    Rank: item.Rank,
                    Jamaat: item.Jamaat,
                };

                let query = window.btoa(JSON.stringify({Profile: profile}));
                this.$router.push({name: 'recruitmentForm', query: {dt: query}})
            },
            async exportExcel(){
                this.$loadingDialog.show();

                let formData = {
                    HierarchyID: this.hierarchyID,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                };

                await axios.post('Members/GetData_ForExcel', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        if(status) {
                            let fileUrl = data.Data;
                            window.open(fileUrl);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async getTerminationRequests() {
                this.$loadingDialog.show();

                const formData = {
                    HierarchyID: this.hierarchyID,
                    JamaatID: 0,
                    Option: 1,
                };

                const result = await axios.post('MembersRequests/Termination_GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.requests.data = data.Data;

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                return result;
            },
            async getTransferRequests() {
                this.$loadingDialog.show();

                const formData = {
                    HierarchyID: this.hierarchyID,
                    Option: 1,
                };

                const result = await axios.post('MembersRequests/Transfer_GetData', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.transferRequests = data.Data;

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                return result;
            },
            async viewTerminationRequest() {
                const result = await this.getTerminationRequests();

                if(result)
                    this.$refs.dlgTerminationRequests.show();
            },
            async viewTransferRequest() {
                const result = await this.getTransferRequests();

                if(result)
                    this.$refs.dlgTransferRequests.show();
            },
            async newTerminationRequest(){
                if(this.requests.reasons.length == 0) {
                    this.$loadingDialog.show();
                    this.requests.reasons = await this.getGeneral(15, 0);
                    this.$loadingDialog.hide();
                }

                this.terminationRequest.ItsID = 0;
                this.terminationRequest.Reason = '';
                this.terminationRequest.Description = '';

                this.$refs.dlgNewTerminationRequest.show();
            },
            async saveTerminationRequest() {
                if(this.terminationRequest.ItsID == 0) {
                    this.$snackBar.error("Please select a member");
                    return;
                }
                if(this.terminationRequest.Reason == '') {
                    this.$snackBar.error("Please select a reason for removal");
                    return;
                }
                if(this.terminationRequest.Description == '') {
                    this.$snackBar.error("Please enter a description for the reason of removal");
                    return;
                }

                this.$loadingDialog.show();

                const formData = {
                    HierarchyID: this.hierarchyID,
                    ItsID: this.terminationRequest.ItsID,
                    Reason: this.terminationRequest.Reason,
                    Description: this.terminationRequest.Description,
                };

                const result = await axios.post('MembersRequests/Termination_AddEdit', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result) {
                    this.$refs.dlgNewTerminationRequest.hide();
                    await this.getTerminationRequests();
                }
            },
            async deleteTerminationRequest(requestID) {
                const isDelete = await this.$confirmDialog.show({
                    title: 'Delete Request',
                    message: 'Are you sure you want to remove this termination request? Please confirm',
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                this.$loadingDialog.show();

                const result = await axios.post('MembersRequests/Termination_Delete', {RequestID: requestID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result)
                    await this.getTerminationRequests();
            },
            async saveTransferRequest() {
                if(this.transferRequest.ItsID == 0) {
                    this.$snackBar.error("Please select a member");
                    return;
                }

                this.$loadingDialog.show();

                const formData = {
                    HierarchyID: this.hierarchyID,
                    ItsID: this.transferRequest.ItsID,
                };

                const result = await axios.post('MembersRequests/Transfer_AddEdit', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result) {
                    this.transferRequest.ItsID = 0;
                    await this.getTransferRequests();
                }
            },
            async deleteTransferRequest(requestID) {
                const isDelete = await this.$confirmDialog.show({
                    title: 'Delete Request',
                    message: 'Are you sure you want to remove this transfer request? Please confirm',
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                this.$loadingDialog.show();

                const result = await axios.post('MembersRequests/Transfer_Delete', {RequestID: requestID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();

                if(result)
                    await this.getTransferRequests();
            },
            async updateRank(item, value) {
                let oldRankID = item.FKRankID;
                let newRankID = value;
                let newRank = this.ranks.find(x => x.Value01 == value);

                if(oldRankID != newRankID) {
                    const isUpdate = await this.$confirmDialog.show({
                        title: 'Update Rank',
                        message: `Are you sure you want to change rank of "${item.FullName}" from "${item.Rank}" to "${newRank.Description}"? Please confirm`,
                    });

                    if(isUpdate) {
                        this.$loadingDialog.show();

                        const result = await axios.post('Members/UpdateRank', {ItsID: item.ItsID, RankID: value})
                            .then(response => {
                                const data = response.data;
                                const status = data.Status;

                                this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                                return status;
                            })
                            .catch(error => {
                                console.log(error);
                                this.$snackBar.error('An unexpected error has occurred');
                            });

                        this.$loadingDialog.hide();

                        if(result)
                            await this.getData();
                    }
                }
            }
        },
        watch: {
            'members.search'(val) {
                val && val !== this.members.incharge && this.loadMembers(val);
            },
        },
        async mounted() {
            this.tableData.noOfRows = this.tableRows;
            //
            // this.rights = {
            //     allowAdd: this.verifyRights(this.$route.name, 'A'),
            //     allowEdit: this.verifyRights(this.$route.name, 'E'),
            //     allowDelete: this.verifyRights(this.$route.name, 'D'),
            //     allowDeleteRequest: this.verifyRights(this.$route.name, 'DR'),
            //     allowTransferRequest: this.verifyRights(this.$route.name, 'TR'),
            // };

            this.$loadingDialog.show();
            this.groups = await this.getOrgHierarchy(2, 0, 0, 0);
            //this.requests.teams = await this.getOrgHierarchy(2, 5, 0, 0);
            this.ranks = await this.getGeneral(2, 0);
            this.$loadingDialog.hide();

            if('orgID' in this.$route.params) {
                if( this.$route.params.orgID === undefined )
                    this.hierarchyID = this.user.MyHierarchyID == 0 ? this.user.FKHierarchyID : this.user.MyHierarchyID;
                else
                    this.hierarchyID = parseInt(this.$route.params.orgID);
            }
            else
                this.hierarchyID = this.user.MyHierarchyID == 0 ? this.user.FKHierarchyID : this.user.MyHierarchyID;

            this.member.HierarchyID = this.hierarchyID;

            await this.getData();

            if(this.rights.allowEdit)
                this.tableData.menu.push({title: 'Edit', icon: 'fa-pencil', onClick: this.editMember});
        }
    }
</script>

<style scoped>

</style>