import axios from 'axios';
import Aes from "crypto-js/aes";
import CryptoJS from "crypto-js";

Number.prototype.formatDecimals = function(){
    return this.valueOf().toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2});
};

Number.prototype.formatWithComma = function(){
    return this.valueOf().toLocaleString('en-US', {minimumFractionDigits:0, maximumFractionDigits:0});
};

export default {
    data(){
        return {
            tableRows: 75,
        };
    },
    methods: {
        encryptForEdit(doc){
            return Aes.encrypt(JSON.stringify(doc), "bgi53").toString();
        },
        decryptForEdit(doc){
            let bytes = CryptoJS.AES.decrypt(doc, 'bgi53');
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        },
        getGeneral(typeID, parentID){
            return new Promise((resolve, reject) => {
                axios.post('General/GetData', {TypeID: typeID, ParentID: parentID})
                    .then(response => {
                        resolve(response.data.Data);
                    })
                    .catch(error => {
                        console.log(error);
                        reject(error);
                    });
            })
        },
        async getOrgHierarchy(option, typeID, parentID, lineageID){
            return new Promise((resolve, reject) => {
                axios.post('Utilities/GetOrgHierarchy', {Option: option, TypeID: typeID, ParentID: parentID, LineageID: lineageID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        resolve(status ? data.Data : []);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred while fetching hierarchy');
                        reject(error);
                    });
            });
        },
        async getMiqaatHierarchy(option, typeID, parentID, lineageID){
            return new Promise((resolve, reject) => {
                axios.post('Utilities/GetMiqaatHierarchy', {Option: option, TypeID: typeID, ParentID: parentID, LineageID: lineageID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        resolve(status ? data.Data : []);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred while fetching hierarchy');
                        reject(error);
                    });
            });
        },
        async getMembers(filter){
            return new Promise((resolve, reject) => {
                const formData = {
                    HasFilter: !!Object.keys(filter).length,
                    Filters: filter,
                };

                axios.post('Utilities/GetMembers', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        resolve(status ? data.Data : []);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred while fetching members');
                        reject(error);
                    });
            });
        },
        async getMiqaatMembers(miqaatID, filter){
            return new Promise((resolve, reject) => {
                const formData = {
                    MiqaatID: miqaatID,
                    HasFilter: !!Object.keys(filter).length,
                    Filters: filter,
                };

                axios.post('Utilities/GetMiqaatMembers', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        resolve(status ? data.Data : []);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred while fetching members');
                        reject(error);
                    });
            });
        },
        async getFiscalYears(isShowActive){
            return new Promise((resolve, reject) => {
                axios.post('FiscalYears/GetData', {IsShowActive: isShowActive})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        resolve(status ? data.Data : []);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred while fetching fiscal years');
                        reject(error);
                    });
            });
        },
        async getCollectionHeads(){
            return new Promise((resolve, reject) => {
                axios.post('CollectionHeads/GetData', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        resolve(status ? data.Data : []);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred while fetching collection heads');
                        reject(error);
                    });
            });
        },
        async getCurrencies(){
            return new Promise((resolve, reject) => {
                axios.post('Currency/GetData', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        resolve(status ? data.Data : []);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred while fetching currencies');
                        reject(error);
                    });
            });
        },
        async getJamaat(){
            return new Promise((resolve, reject) => {
                axios.post('Utilities/GetJamaat', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        resolve(status ? data.Data : []);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred while fetching currencies');
                        reject(error);
                    });
            });
        },
        printDocument(url, formData){
            return new Promise((resolve, reject) => {
                axios.post(url, formData, { responseType: "blob" }).then(response => {

                    const file = new Blob([response.data], {type: "application/pdf"});
                    const fileUrl = URL.createObjectURL(file);
                    let myWindow = window.open(fileUrl, "_blank");
                    myWindow.print();

                    resolve(true);
                }).catch(error => {
                    console.log(error);
                    reject(error);
                });
            });
        },
        downloadExcel(url, formData){
            return new Promise((resolve, reject) => {
                axios.post(url, formData, { responseType: "blob" }).then(response => {

                    const file = new Blob([response.data], {type: response.headers["content-type"]});
                    const fileUrl = URL.createObjectURL(file);
                    const contentHeader = response.headers["content-disposition"];

                    console.log(response.headers);

                    const fileName = contentHeader == undefined ? "Report.xlsx" : contentHeader.substring(contentHeader.indexOf('filename=') + 9).replace(/["]+/g, '');

                    resolve({fileName: fileName, fileUrl: fileUrl});
                }).catch(error => {
                    console.log(error);
                    reject(error);
                });
            });
        },
        getTotal(arr, key){
            return !!arr && arr.length > 0 ? arr.reduce((a, b) => parseFloat(a) + parseFloat((b[key] || 0)), 0) : 0;
        },
        getMax(arr, key){
            return !!arr && arr.length > 0 ? (arr.reduce((a, b) => (a[key] > b[key]) ? a : b, 0))[key] : 0;
        },
        getCountWithCondition(arr, key, value){
            let count = 0;
            arr.forEach(item => count += item[key] == value ? 1 : 0 );
            return count;
        },
        isValidNumber(value) {
            return !isNaN(value);
        },
        verifyRights(route, right){
            const rights = this.$store.getters.rights;
            let hasRights = false;

            rights.forEach(element => {
                if(element.LinkName == route)
                {
                    hasRights = element.Rights.indexOf(right) >= 0;
                    return;
                }
            });

            return hasRights;
        },
        convertToPlainText(document, html){
            // Create a new div element
            let tempDivElement = document.createElement("div");

            // Set the HTML content with the given value
            tempDivElement.innerHTML = html;

            // Retrieve the text property of the element
            return tempDivElement.textContent || tempDivElement.innerText || "";
        },
    },
}