<template>
    <v-container fluid>
        <v-overlay v-if="isLoading">
            <v-progress-circular color="white" indeterminate size="53"
                                 class="mt-2 ml-3" />
        </v-overlay>

        <data-table :table-data="tableData" :total-pages="tableData.totalPages"
                    @pageChanged="pageChanged" @filterChanged="filterChanged" v-else>
            <template v-slot:item.PictureInITS="{item}">
                <v-img style="max-height: 60px; min-height: 60px; max-width: 50px; border: 1px Solid #ccc; border-radius: 4px;"
                       class="my-1"
                       :src="item.PictureInITS" contain :alt="item.ItsID.toString()" />
            </template>
            <template v-slot:item.Actions="{item}">
                <v-btn color="primary" small
                       v-if="item.FKStatusID == 2"
                       @click="viewForm(item)">
                    Submit Form
                </v-btn>
<!--                       v-if="item.FKStatusID == 2 && !item.IsAssessed"-->

<!--                <v-btn color="primary" small-->
<!--                       v-if="item.FKStatusID == 1">-->
<!--                    Print Report-->
<!--                </v-btn>-->
<!--                <v-chip label color="primary" small-->
<!--                        v-else-if="item.FKStatusID == 2 && item.IsAssessed">-->
<!--                    <v-icon left x-small>fa-check</v-icon> Report Submitted-->
<!--                </v-chip>-->
            </template>
        </data-table>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        name: "membersView",
        props: ['hierarchyId', 'status'],
        data(){
            return {
                isLoading: true,
                tableData: {
                    header: [
                        { text: 'S.No', value: 'SNo', width: 40 },
                        { text: 'Photo', value: 'PictureInITS' },
                        { text: 'ITS', value: 'ItsID', applyFilter: true },
                        { text: 'Name', value: 'FullName', applyFilter: true },
                        { text: 'Team', value: 'Hierarchy', applyFilter: true },
                        { text: 'Mobile No', value: 'MobileNo', applyFilter: true },
                        { text: 'Rank', value: 'Rank', applyFilter: true },
                        { text: '', value: 'Actions' },
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                    noOfRows: 75,
                    totalRecords: 1,
                    totalPages: 0,
                    pageNo: 1,
                },
            };
        },
        methods: {
            async getMembers(){
                this.isLoading = true;

                const formData = {
                    HierarchyID: this.hierarchyId,
                    Status: this.status,
                    PageNumber: this.tableData.pageNo,
                    NoOfRows: this.tableData.noOfRows,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                }

                await axios.post('Amalat/GetMembers', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        console.log(data.Data);

                        this.tableData.totalPages = data.Data.TotalPages;
                        this.tableData.data = data.Data.Items;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.isLoading = false;
            },
            pageChanged(pageNo) {
                this.tableData.pageNo = pageNo;
                this.getMembers();
            },
            filterChanged(){
                this.getMembers();
            },
            viewForm(member){
                this.$emit('viewForm', member);
            }
        },
        async mounted(){
            await this.getMembers();
        }
    }
</script>

<style scoped>

</style>     