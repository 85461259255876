<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <h2 class="headline">Miqaat Dashboard | <small>{{ miqaat.Name }} | From: {{ miqaat.StartDate }} To: {{ miqaat.EndDate }}</small></h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>
                        <v-tabs v-model="selectedTab" vertical v-if="!isLoading">
                            <v-tab v-for="(tab, index) in tabs"
                                   :key="index"
                                   class="mr-2"
                                   v-show="tab != 'Team Quotas'">
                                {{ tab }}
                            </v-tab>
<!--                            @click="selectedTab = index"-->
                            <v-tabs-items v-model="selectedTab">
                                <v-tab-item v-for="component in miqaatComponents" :key="component">
                                    <component v-bind:is="miqaatComponents[selectedTab]"
                                               :miqaat-id="miqaatId"
                                               :miqaat-hierarchy-id="miqaat.MiqaatHierarchyID"
                                               :zone="zone"
                                               :event="event"
                                               @onEventClick="eventClick"
                                               @openTeamQuota="openTeamQuota"/>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-tabs>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import MiqaatQuota from './detail/miqaatQuota';
    import MiqaatZoneQuota from './detail/miqaatZoneQuota';
    import MiqaatRegistrations  from './detail/miqaatRegistrations'
    import MiqaatArrivals  from './detail/miqaatArrivals'
    import MiqaatGroups from './detail/miqaatGroups'
    import MiqaatDutyPoints from './detail/dutyPoints'
    import MiqaatEvents from './detail/miqaatEvents'
    import TasksList from './detail/tasksList'
    import Duties from './detail/duties'
    import Dashboard from './detail/dashboard'
    import Reports from './detail/miqaatReports'
    import DutiesDashboard from './detail/dashboard_duties'
    import axios from "axios";

    export default {
        title: 'Miqaat Dashboard',
        props: ['miqaatId'],
        components: {
            cMiqaatQuota: MiqaatQuota,
            cMiqaatZoneQuota: MiqaatZoneQuota,
            cMiqaatEvents: MiqaatEvents,
            cMiqaatDutyPoints: MiqaatDutyPoints,
            cMiqaatGroups: MiqaatGroups,
            cMiqaatRegistrations: MiqaatRegistrations,
            cMiqaatArrivals: MiqaatArrivals,
            cTasksList: TasksList,
            cDuties: Duties,
            cDashboard: Dashboard,
            cReports: Reports,
            cDutiesDashboard: DutiesDashboard,
        },
        data() {
            return {
                isLoading: true,
                miqaat: {},
                miqaatComponents: [
                    'cDashboard',
                    'cDutiesDashboard',
                    'cMiqaatZoneQuota',
                    'cMiqaatEvents',
                    'cDuties',
                    // 'cMiqaatDutyPoints',
                    'cTasksList',
                    'cMiqaatRegistrations',
                    'cMiqaatArrivals',
                    'cMiqaatGroups',
                    'cReports',
                    'cMiqaatQuota',
                ],
                tabs: [
                    "Dashboard",
                    "Dashboard-Duties",
                    "Quota Management",
                    "Events",
                    "Duty Points",
                    // "Duties",
                    "Tasks List",
                    "Registration",
                    "Arrival",
                    "Groups",
                    "Reports",
                    "Team Quotas",
                    // "Feedback",
                    // "Chat Room"
                ],
                selectedTab: 0,
                event: {},
                zone: {},
            }
        },
        // computed: {
        //     miqaatID(){
        //         return this.$route.params.id;
        //     },
        // },
        methods: {
            async getData(){
                this.isLoading = true;
                this.$loadingDialog.show();

                const formData = {
                    MiqaatID: this.miqaatId,
                    PageNumber: 1,
                    NoOfRows: 1,
                    HasFilter: false,
                    Filters: {},
                }

                await axios.post(`Miqaat/GetData`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.miqaat = data.Data.Items[0];
                        else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.isLoading = false;
                this.$loadingDialog.hide();
            },
            eventClick(event){
                console.log(this.event);
                this.event = event;
                this.selectedTab = 8;
            },
            openTeamQuota(item){
                this.zone = item;
                this.selectedTab = 9;
            },
        },
        async mounted() {
            await this.getData();
        },
    }
</script>

<style lang="scss">
</style>
