<template>
    <v-container fluid>
        <v-overlay v-if="isLoading">
            <v-progress-circular color="white" indeterminate size="53"
                                 class="mt-2 ml-3" />
        </v-overlay>

        <v-simple-table dense class="cs-table">
            <thead>
            <tr>
                <th colspan="6" style="border-bottom: 1px Solid #666">
                </th>
                <th colspan="4" style="border-bottom: 1px Solid #666; border-left: 1px Solid #666;">
                    Assessment
                </th>
            </tr>
            <tr>
                <th v-for="head in tableData.header">
                    {{ head.text }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="tableData.data.length == 0">
                <td colspan="10" style="text-align: center;">No Data Available</td>
            </tr>
            <tr v-for="item in tableData.data" v-else>
                <td v-for="head in tableData.header">
                    <template v-if="head.value == 'Incharge'">
                        {{ item.ItsID }} - {{ item.FullName }} - {{ item.MobileNo }}
                    </template>
                    <template v-else-if="head.value == 'PendingAssessment'">
                        {{ parseInt(item.WaitingMembers) - parseInt(item.AssessmentCount) }}
                    </template>
                    <template v-else-if="head.value == 'TeamAction'">
                        <v-btn small class="my-1 primary" @click="viewMembers(item.HierarchyID, 1)">View All Members</v-btn>
                    </template>
                    <template v-else-if="head.value == 'WaitingAction'">
                        <v-btn small class="my-1 primary" @click="viewMembers(item.HierarchyID, 2)">View Waiting Members</v-btn>
                    </template>
                    <template v-else>
                        {{ item[head.value] }}
                    </template>
                </td>
            </tr>
            </tbody>
        </v-simple-table>

    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        name: "teamView",
        props: ['jamaat'],
        data(){
            return {
                isLoading: false,
                tableData: {
                    header: [
                        {text: "S.No", value: 'SNo', width:40},
                        {text: "Team", value: 'NameWithID'},
                        {text: "Zone", value: 'Zone'},
                        {text: "Incharge", value: 'Incharge'},
                        {text: "Team Count", value: 'TotalMembers'},
                        {text: "", value: 'TeamAction'},
                        {text: "Pending", value: 'PendingAssessment'},
                        {text: "Completed", value: 'AssessmentCount'},
                        {text: "Total", value: 'WaitingMembers'},
                        {text: "", value: 'WaitingAction'},
                    ],
                    data: [],
                    filters: {},
                },
            };
        },
        methods: {
            async getTeams(){
                this.isLoading = true;

                const formData = {
                    JamaatID: this.jamaat.JamaatID,
                };

                await axios.post('Amalat/GetTeams', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.tableData.data = data.Data;

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.isLoading = false;
            },
            viewMembers(hierarchyId, status){
                this.$emit('viewMembers', hierarchyId, status);
            }
        },
        async mounted(){
            await this.getTeams();
        }
    }
</script>

<style scoped>
    .cs-table {
        border: 1px Solid #c3c3c3 !important;
        border-radius: 4px !important;
        width: 100% !important;
        text-wrap: normal;
        word-wrap: normal;
        white-space: normal;
    }
    .cs-table th {
        background-color: #c3c3c3;
        /*border: 1px Solid #c3c3c3 !important;*/
    }
</style>