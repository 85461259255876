<template>
    <v-container fluid>
        <v-row>
            <v-col><h2>Miqaat Groups</h2></v-col>
            <v-col align="end">
                <v-btn color="primary" @click="addGroup" class="py-4" small>New Group</v-btn>
                <v-btn color="primary" @click="excelUpload" class="py-4 ml-2" small>Upload Excel</v-btn>
                <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                       ref="excelUpload" @change="loadFile" hidden>

            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <data-table v-model="tableData.selected"
                            :table-data="tableData"
                            :items-per-page="tableData.noOfRows"
                            :total-pages="tableData.totalPages"
                            @pageChanged="pageChanged"
                            @filterChanged="filterChanged">
                    <template v-slot:item.actions="{item}">
                        <context-menu :data-item="item" :menu-items="menu.items" />
                    </template>
                </data-table>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgGroup" :title="dlgTitle" width="500px" :on-confirm="saveGroup">
            <template v-slot:content>
                <text-box label="Group Name" v-model="group.Name" />
            </template>
        </simple-dialog>

        <simple-dialog ref="dlgMembers" width="900" title="Members" :hide-confirm-button="true" cancel-button-text="Close">
            <template v-slot:content>
                <v-form @submit.prevent="saveMember">
                    <v-row>
                        <v-col class="flex-row">
                            <combo-box label="Member"
                                       v-model="groupMember.FKItsID"
                                       :loading="members.loading"
                                       :search-input.sync="members.search"
                                       :items="members.items"
                                       item-text="FullName_ITS"
                                       item-value="ItsID"
                                       hide-no-data
                                       cache-items />
                        </v-col>
                        <v-col>
                            <combo-box label="Role"
                                       v-model="groupMember.FKRoleID"
                                       :items="roles"
                                       item-text="Description"
                                       item-value="Value01" />
                        </v-col>
                        <v-col>
                            <v-btn type="submit" color="primary" class="py-4" small>Add</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
                <v-row>
                    <v-col>
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <data-table v-model="membersTableData.selected"
                                    :table-data="membersTableData"
                                    :items-per-page="membersTableData.noOfRows"
                                    :total-pages="membersTableData.totalPages"
                                    @pageChanged="membersPageChanged"
                                    @filterChanged="membersFilterChanged">
                            <template v-slot:item.actions="{item}">
                                <v-btn color="success" text small class="mr-2"
                                       @click="setIncharge(item)" v-if="item.FKRoleID != 1"><v-icon left>fa-check</v-icon> Set As Incharge</v-btn>
                                <v-btn color="error" icon small @click="deleteMember(item)"><v-icon>fa-trash</v-icon></v-btn>
                            </template>
                        </data-table>
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'c-groups-list',
        props: ['miqaatId', 'miqaatHierarchyId'],
         data() {
            return {
                tableData: {
                    header: [
                        {text: "S.No", value: 'SNo'},
                        {text: "Group", value: 'Name', applyFilter: true},
                        {text: "Incharge", value: 'Incharge', applyFilter: true},
                        {text: "Members", value: 'TotalMembers'},
                        {text: "Actions", value: 'actions'},
                    ],
                    data: [],
                    filters: {},
                    noOfRows: this.tableRows,
                    totalPages: 1,
                    pageNo: 1,
                    selected: [],
                },
                membersTableData: {
                    header: [
                        {text: "S.No", value: 'SNo'},
                        {text: "Name", value: 'FullName', applyFilter: true},
                        {text: "Role", value: 'Role', applyFilter: true},
                        {text: "Actions", value: 'actions'},
                    ],
                    data: [],
                    filters: {},
                    noOfRows: 20,
                    totalPages: 1,
                    pageNo: 1,
                    selected: [],
                },
                menu: {
                    title: '',
                    items: [
                        { icon: 'fa-users', title: 'Members', onClick: this.viewMembers },
                        { icon: 'fa-pencil', title: 'Edit', onClick: this.editGroup },
                        { icon: 'fa-trash', title: 'Delete', onClick: this.deleteGroup },
                    ]
                },
                membersMenu: {
                    title: '',
                    items: [
                        { icon: 'fa-trash', title: 'Delete', onClick: this.deleteMember },
                    ]
                },
                group: {
                    MiqaatHierarchyID: 0,
                    ParentID: this.miqaatHierarchyId,
                    Name: '',
                },
                groupMember: {
                    FKMiqaatHierarchyID: 0,
                    FKItsID: 0,
                    FKRoleID: 0,
                },
                members: {
                    loading: false,
                    items: [],
                    search: '',
                },
                roles: [],
            }
        },
        computed: {
            dlgTitle(){
                return this.group.MiqaatHierarchyID == 0 ? 'New Group' : 'Edit Group';
            },
        },
        methods: {
            async getData() {
                this.$loadingDialog.show();

                const formData = {
                    MiqaatHierarchyID: this.miqaatHierarchyId,
                    PageNumber: this.tableData.pageNo,
                    NoOfRows: this.tableData.noOfRows,
                    HasFilter: !!Object.keys(this.tableData.filters).length,
                    Filters: this.tableData.filters,
                }

                await axios.post(`Miqaat/Groups_GetData`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if (status) {
                            this.tableData.data = data.Data.Items;
                            this.tableData.totalPages = data.Data.TotalPages;
                        } else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            pageChanged(pageNo){
                this.tableData.pageNo = pageNo;
                this.getData();
            },
            filterChanged(){
                this.getData();
            },
            async getMembersData(){
                this.$loadingDialog.show();

                const formData = {
                    MiqaatHierarchyID: this.group.MiqaatHierarchyID,
                    PageNumber: this.membersTableData.pageNo,
                    NoOfRows: this.membersTableData.noOfRows,
                    HasFilter: !!Object.keys(this.membersTableData.filters).length,
                    Filters: this.membersTableData.filters,
                }

                await axios.post(`Miqaat/GroupMembers_GetData`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            this.membersTableData.data = data.Data.Items;
                            this.membersTableData.totalPages = data.Data.TotalPages;
                        }
                        else
                            this.$snackBar.error(data.Message);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            membersPageChanged(pageNo){
                this.membersTableData.pageNo = pageNo;
                this.getMembersData();
            },
            membersFilterChanged(){
                this.getMembersData();
            },
            async loadMembers(val){
                this.members.loading = true;
                this.members.items = await this.getMiqaatMembers(this.miqaatHierarchyId, {"FullName_ITS": val});
                this.members.loading = false;
            },
            async viewMembers(item){
                this.group = {
                    MiqaatHierarchyID: item.MiqaatHierarchyID,
                    ParentID: this.miqaatHierarchyId,
                    Name: item.Name,
                };

                this.groupMember.FKMiqaatHierarchyID = item.MiqaatHierarchyID;

                await this.getMembersData();
                this.$refs.dlgMembers.show();
            },
            addGroup(){
                this.group = {
                    MiqaatHierarchyID: 0,
                    ParentID: this.miqaatHierarchyId,
                    Name: '',
                };

                this.$refs.dlgGroup.show();
            },
            editGroup(item){
                this.group = {
                    MiqaatHierarchyID: item.MiqaatHierarchyID,
                    ParentID: this.miqaatHierarchyId,
                    Name: item.Name,
                };

                this.$refs.dlgGroup.show();
            },
            async saveGroup(){
                this.$loadingDialog.show();

                const result = await axios.post('Miqaat/Groups_AddEdit', this.group)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                {
                    this.$refs.dlgGroup.hide();
                    await this.getData();
                }

                this.$loadingDialog.hide();
            },
            async deleteGroup(item){
                const isDelete = await this.$confirmDialog.show({
                    title: 'Delete Group',
                    message: `Are you sure you want to delete group "${item.Name}"? Please confirm`,
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                this.$loadingDialog.show();

                const formData = {
                    MiqaatHierarchyID: item.MiqaatHierarchyID,
                };

                const result = await axios.post('Miqaat/Groups_Delete', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                {
                    await this.getData();
                }

                this.$loadingDialog.hide();
            },
            async saveMember(){
                if(this.groupMember.FKItsID == 0)
                {
                    this.$snackBar.error("Please select a member");
                    return;
                }
                if(this.groupMember.FKRoleID == 0)
                {
                    this.$snackBar.error("Please select a role");
                    return;
                }

                this.$loadingDialog.show();

                const result = await axios.post('Miqaat/GroupMembers_AddEdit', this.groupMember)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                {
                    this.groupMember.FKItsID = 0;
                    this.groupMember.FKRoleID = 0;
                    await this.getMembersData();
                }

                this.$loadingDialog.hide();
            },
            async deleteMember(item){
                const isDelete = await this.$confirmDialog.show({
                    title: 'Remove Member',
                    message: `Are you sure you want to remove ${item.FullName} from this group? Please confirm`,
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                this.$loadingDialog.show();

                const formData = {
                    FKMiqaatHierarchyID: this.group.MiqaatHierarchyID,
                    FKItsID: item.FKItsID,
                };

                const result = await axios.post('Miqaat/GroupMembers_Delete', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                {
                    await this.getMembersData();
                }

                this.$loadingDialog.hide();
            },
            async setIncharge(item){
                const isDelete = await this.$confirmDialog.show({
                    title: 'Set As In-charge',
                    message: `Are you sure you want to make ${item.FullName} in-charge of this group? Please confirm`,
                });

                if(!isDelete)
                    return;

                this.$loadingDialog.show();

                const formData = {
                    FKMiqaatHierarchyID: this.group.MiqaatHierarchyID,
                    FKItsID: item.FKItsID,
                };

                const result = await axios.post('Miqaat/GroupMembers_SetIncharge', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                {
                    await this.getMembersData();
                }

                this.$loadingDialog.hide();
            },
            excelUpload(){
                this.$refs.excelUpload.value = null;
                this.$refs.excelUpload.click();
            },
            async loadFile(e){
                const file = e.target.files[0];

                this.$loadingDialog.show();

                let formData = new FormData();
                formData.append("MiqaatID", this.miqaatHierarchyId);
                formData.append("ExcelFile", file);

                const result = await axios.post(`Miqaat/Registrations_UploadGroups`, formData,
                    {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        return status
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result)
                    await this.getData();

                this.$loadingDialog.hide();
            },
        },
        watch: {
            'members.search'(val) {
                val && val !== this.groupMember.FKItsID && this.loadMembers(val);
            },
        },
        async mounted() {
            this.tableData.noOfRows = this.tableRows;

            this.$loadingDialog.show();
            this.roles = await this.getGeneral(8, 0);
            this.$loadingDialog.hide();

            await this.getData();
        },
    }
</script>

<style scoped>

</style>