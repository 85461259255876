<template>
    <v-autocomplete
            ref="txt"
            v-bind="$attrs"
            v-on="$listeners"
            outlined
            class="small-text-field"
            dense
            hide-details="auto">
    </v-autocomplete>
</template>

<script>
    export default {
        name: "textBox",
        methods: {
            focus(){
                this.$refs.txt.focus();
            }
        }
    }
</script>

<style scoped>

</style>