<template>
    <div>
<!--        <org-chart :datasource="data" />-->
        <organization-chart :datasource="data"
                            style="width:100%">
        </organization-chart>
    </div>
</template>

<script>
    import OrganizationChart from 'vue-organization-chart';
    import 'vue-organization-chart/dist/orgchart.css';
    import axios from "axios";

    export default {
        name: "chartView",
        components: {
            OrganizationChart,
        },
        data(){
            return {
                data: {},
            }
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                let formData = {
                    HierarchyID: 0,
                    ParentID: 0,
                    HasFilter: false,
                    Filters: {},
                };

                await axios.post(`OrgHierarchy/GetData_TreeView`, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);
                        else
                        {
                            this.data = data.Data.Items[0];
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
        },
        async mounted() {
            await this.getData();
        },
    }
</script>

<style scoped>

</style>