<template>
    <v-container fluid>
        <v-row>
            <v-col class="d-flex">
                <h2 class="headline">Live Sabaq
                    <template v-if="sabaq != null">
                        {{ sabaq.StreamLink.length > 0 ? ` | ${sabaq.Title}` : '' }}
                    </template>
                </h2>
                <v-btn class="py-4 ml-3" small color="primary" v-if="asbaaq.length > 1 && sabaq != null" @click="sabaq = null">
                    <v-icon small left>fa-chevron-left</v-icon> View List
                </v-btn>
                <v-spacer />
                <v-btn color="primary" v-if="isIncharge" small class="py-4" @click="viewMembers">Mark Team Attendance</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="8" offset-md="2">
                <v-card v-if="asbaaq.length > 1 && sabaq == null">
                    <v-card-title>Asbaaq List</v-card-title>
                    <v-list dense>
                        <template  v-for="(item, i) in asbaaq">
                            <v-list-item :key="item.SabaqID" @click="sabaq = item">
                                <v-icon small color="primary" class="mr-2">fa-chevron-right</v-icon>
                                <v-list-item-title>
                                    {{ item.Title }} | Live From: {{ item.StartDateTime }} (IST)
                                    <span class="primary--text">(Click here to view)</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-divider v-if="i < asbaaq.length - 1" />
                        </template>
                    </v-list>
                </v-card>
                <template v-else>
                    <template v-if="sabaq != null">
                        <vue-player v-if="sabaq.StreamLink.length > 0" ref="plyr">
                            <div class="plyr__video-embed">
                                <iframe
                                        :src="`https://www.youtube.com/embed/${sabaq.StreamLink}?amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1`"
                                        allowfullscreen
                                        allowtransparency
                                        style="max-height: 81vh;">
                                </iframe>
                            </div>
                        </vue-player>
                    </template>
                    <h2 v-else class="headline text-center">No Live Sabaq Is Available Right Now</h2>
                </template>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgMembers" title="Mark Attendance" confirm-button-text="Save Attendance" :on-confirm="markAttendanceBulk">
            <template v-slot:content>
                <div  style="height: 69vh; overflow: auto;">
                    <data-table :table-data="members" v-model="members.selected" item-key="ItsID"
                                disable-pagination disable-sort wrap-text hide-filters show-select>
                        <template v-slot:item.PictureInITS="{item}">
                            <v-img style="max-height: 60px; min-height: 60px; max-width: 50px; border: 1px Solid #ccc; border-radius: 4px;"
                                   class="my-1"
                                   :src="item.PictureInITS" contain :alt="item.ItsID.toString()" />
                        </template>
                    </data-table>
                </div>
            </template>
        </simple-dialog>
    </v-container>
</template>

<script>
    import VuePlyr from 'vue-plyr'
    import 'vue-plyr/dist/vue-plyr.css'
    import axios from "axios";

    export default {
        name: "asbaaqViewer",
        title: 'Live Sabaq',
        components: {
            vuePlayer: VuePlyr,
        },
        data(){
            return {
                viewLive: false,
                sabaq: null,
                asbaaq: [],
                isAttendanceMarked: false,
                members: {
                    header: [
                        { text: 'Photo', value: 'PictureInITS' },
                        { text: 'ITS', value: 'ItsID', applyFilter: true },
                        { text: 'Name', value: 'FullName', applyFilter: true },
                        { text: 'Mobile No', value: 'MobileNo' },
                        { text: 'Rank', value: 'Rank', applyFilter: true },
                    ],
                    data: [],
                    selected: [],
                },
            };
        },
        computed: {
            user(){
                return this.$store.getters.user;
            },
            isIncharge(){
                if(this.user.FKInchargeITS == this.user.ItsID && this.user.InchargeTypeID == 5)
                    return true;
                else
                    return false;
            },
            selectedData(){
                let selected = [];
                this.members.selected.forEach(item => {
                    selected.push(item.ItsID);
                });
                return selected.join(",");
            },
        },
        methods: {
            async getData(){
                this.$loadingDialog.show();

                await axios.post('Asbaaq/GetLive', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.asbaaq = data.Data;

                        if(this.asbaaq.length == 1)
                            this.sabaq = data.Data[0];
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async markAttendance(){
                await axios.post('Asbaaq/MarkAttendance', {SabaqID: this.sabaq.SabaqID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.isAttendanceMarked = status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });
            },
            async viewMembers(){
                this.$loadingDialog.show();
                this.members.data = await this.getMembers({"FKHierarchyID": this.user.MyHierarchyID});
                this.$loadingDialog.hide();
                this.$refs.dlgMembers.show();
            },
            async markAttendanceBulk(){
                if(this.selectedData.length == 0) {
                    this.$snackBar.error("Please select at least 1 member to mark attendance");
                    return;
                }

                this.$loadingDialog.show();

                const formData = {
                    SabaqID: this.sabaq.SabaqID,
                    ItsIDs: this.selectedData,
                };

                const result = await axios.post('Asbaaq/MarkAttendance_Bulk', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result) {
                    this.members.selected = [];
                    this.$refs.dlgMembers.hide();
                }

                this.$loadingDialog.hide();
            },
        },
        async mounted(){
            await this.getData();

            let logo = document.createElement('div');
            logo.style.cssText = "position: absolute; top:5px; left: 5px; z-index: 10;";
            logo.innerHTML = `<img src="`+ require('@/assets/BGIlogo.png') + `" width="50px" height="auto">`;

            let element = this.$refs.plyr.$el;
            element.append(logo);

            this.$refs.plyr.player.on('playing', () => {
                if(!this.isAttendanceMarked)
                    this.markAttendance();
            });
        },
    }
</script>

<style scoped>
    .plyr--video {
        border-radius: 8px;
    }
</style>