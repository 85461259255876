<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12"><h2 class="headline">Designations</h2></v-col>
        </v-row>
        <v-row>
            <v-col md="4" cols="12">
                <v-card>
                    <v-tabs v-model="tabID">
                        <v-spacer />
                        <v-tab>Ranks</v-tab>
                        <v-tab>ITS</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tabID">
                        <v-tab-item>
                            <v-list dense style="height: 68vh; overflow: auto;">
                                <v-list-item v-for="role in roles" :key="role.Value01" @click="getRights(role)" :class="itemClass(role)">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ role.Description }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action><v-btn icon small @click="editRole(role)"><v-icon small>fa-pencil</v-icon></v-btn></v-list-item-action>
                                    <v-list-item-action><v-btn icon small @click.prevent="deleteRole"><v-icon small>fa-trash</v-icon></v-btn></v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-tab-item>
                        <v-tab-item>
                            <div class="d-flex mb-2 pa-2">
                                <combo-box v-model="newMember" label="User" :loading="members.loading" :search-input.sync="members.search"
                                           :items="members.items" item-text="FullName_ITS" item-value="ItsID"
                                           hide-no-data cache-items return-object />
                                <v-btn color="primary" small class="py-4 ml-2" @click="addUser"><v-icon small left>fa-plus</v-icon>Add</v-btn>
                            </div>
                            <v-list dense style="height: 60vh; overflow: auto;">
                                <v-list-item v-for="user in users" :key="user.FKItsID" @click="getRights(user)" :class="itemClass(user)">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ user.FullName }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action><v-btn icon small @click.prevent="deleteUser(user)"><v-icon small>fa-trash</v-icon></v-btn></v-list-item-action>
                                </v-list-item>
                            </v-list>
                        </v-tab-item>
                    </v-tabs-items>
<!--                    <v-card-title>Roles</v-card-title>-->
                    <v-card-text>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title>Rights</v-card-title>
                    <v-card-text style="height: 70vh; overflow: auto;">
                        <v-row>
                            <v-col>
                                <data-table :table-data="tableData" disable-pagination hide-filters>
                                    <template v-slot:item.Rights="{item}">
                                        <div class="d-flex flex-wrap">
                                            <v-checkbox
                                                    v-if="item.Options.includes('V')"
                                                    v-model="item.Rights"
                                                    label="View"
                                                    value="V"
                                                    @change="updateRights(item)"
                                                    class="my-1 mx-2"
                                                    hide-details>
                                            </v-checkbox>
                                            <v-checkbox
                                                    v-if="item.Options.includes('A')"
                                                    v-model="item.Rights"
                                                    label="Add"
                                                    value="A"
                                                    @change="updateRights(item)"
                                                    class="my-1 mx-2"
                                                    hide-details>
                                            </v-checkbox>
                                            <v-checkbox
                                                    v-if="item.Options.includes('E')"
                                                    v-model="item.Rights"
                                                    label="Edit"
                                                    value="E"
                                                    @change="updateRights(item)"
                                                    class="my-1 mx-2"
                                                    hide-details>
                                            </v-checkbox>
                                            <v-checkbox
                                                    v-if="item.Options.includes('D')"
                                                    v-model="item.Rights"
                                                    label="Delete"
                                                    value="D"
                                                    @change="updateRights(item)"
                                                    class="my-1 mx-2"
                                                    hide-details>
                                            </v-checkbox>
                                            <v-checkbox
                                                    v-if="item.Options.includes('DR')"
                                                    v-model="item.Rights"
                                                    label="Termination Request"
                                                    value="DR"
                                                    @change="updateRights(item)"
                                                    class="my-1 mx-2"
                                                    hide-details>
                                            </v-checkbox>
                                            <v-checkbox
                                                    v-if="item.Options.includes('TR')"
                                                    v-model="item.Rights"
                                                    label="Transfer Request"
                                                    value="TR"
                                                    @change="updateRights(item)"
                                                    class="my-1 mx-2"
                                                    hide-details>
                                            </v-checkbox>
                                        </div>
                                    </template>
                                </data-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <simple-dialog ref="dlgRole" width="500" :title="role.Value01 == 0 ? 'New Role' : 'Edit Role'">
            <template v-slot:content>
                <v-row>
                    <v-col>
                        <text-box label="Role" v-model="role.Description"></text-box>
                    </v-col>
                </v-row>
            </template>
        </simple-dialog>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        title: "Designations",
        data(){
            return {
                users: [],
                roles: [],
                tabID: 0,
                rankID: 0,
                tableData: {
                    header: [
                        {text: 'S.No', value: 'SNo'},
                        {text: 'Module', value: 'Parent'},
                        {text: 'Link', value: 'Module'},
                        {text: 'Rights', value: 'Rights'},
                    ],
                    data: [],
                    filters: {},
                },
                role: {
                    Value01: 0,
                    Description: 0,
                },
                user: {
                    FKItsID: 0,
                    FullName: '',
                },
                members: {
                    loading: false,
                    items: [],
                    search: '',
                },
                newMember: {},
            };
        },
        methods: {
            itemClass(role){
                if(this.tabID == 0) {
                    if(this.role == role)
                        return 'selected elevation-2';
                    else
                        return '';
                } else {
                    if(this.user == role)
                        return 'selected elevation-2';
                    else
                        return '';
                }
            },
            async loadMembers(val){
                this.members.loading = true;
                this.members.items = await this.getMembers({"FullName_ITS": val,
                    "FKHierarchyID": this.hierarchyID});
                this.members.loading = false;
            },
            async getUsers(){
                this.$loadingDialog.show();

                await axios.post('UsersRoles/GetUsers', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        this.users = status ? data.Data : [];
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            async getRights(role){
                this.tableData.data = [];

                let url = "";
                let formData = {};

                if( this.tabID == 0 ) {
                    this.role = role;
                    url = "UsersRoles/GetRights";
                    formData = {
                        RankID: this.role.Value01,
                    };
                }
                else {
                    this.user = role;
                    url = "UsersRoles/GetRights_ITS";
                    formData = {
                        ItsID: this.user.FKItsID,
                    };
                }

                this.$loadingDialog.show();

                this.tableData.data = await axios.post(url, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(!status)
                            this.$snackBar.error(data.Message);

                        return status ? data.Data : [];
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
            editRole(item){
                this.role = {
                    Value01: item.Value01,
                    Description: item.Description,
                };

                this.$refs.dlgRole.show();
            },
            deleteRole(){
                const self = this;
                this.$confirmDialog.show({
                    title: 'Delete Role',
                    message: 'Are you sure you want to delete this designation? This action cannot be undone, please confirm.',
                    isCritical: true,
                    async onResult(result){
                        if(!result)
                            return;

                        self.$loadingDialog.show();
                        self.$loadingDialog.hide();
                    }
                });
            },
            addUser(){
                let newUser = {
                    FKItsID: this.newMember.ItsID,
                    FullName: this.newMember.FullName,
                };

                if(this.users.indexOf(newUser) == -1) {
                    this.users.splice(0, 0, newUser);
                    this.getRights(newUser);
                }
            },
            async deleteUser(user){
                const isDelete = await this.$confirmDialog.show({
                    title: 'Delete User',
                    message: `Are you sure you want to remove rights of ${user.FullName}? This action cannot be undone, please confirm.`,
                    isCritical: true,
                });

                if(!isDelete)
                    return;

                this.$loadingDialog.show();
                const result = await axios.post("UsersRoles/DeleteRights_ITS", {ItsID: user.FKItsID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                if(result) {
                    this.tableData.data = [];
                    this.user = {};
                    await this.getUsers();
                }

                this.$loadingDialog.hide();
            },
            async updateRights(item){
                let url = "";
                let formData = {};

                if( this.tabID == 0 ) {
                    url = "UsersRoles/UpdateRights";
                    formData = {
                        UserRoleID: this.role.Value01,
                        ModuleID: item.ModuleID,
                        Rights: item.Rights,
                    };
                }
                else {
                    url = "UsersRoles/UpdateRights_ITS";
                    formData = {
                        ItsID: this.user.FKItsID,
                        ModuleID: item.ModuleID,
                        Rights: item.Rights,
                    };
                }

                this.$loadingDialog.show();
                await axios.post(url, formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });

                this.$loadingDialog.hide();
            },
        },
        watch: {
            'members.search'(val) {
                val && val !== this.user.FKItsID && this.loadMembers(val);
            },
        },
        async mounted() {
            this.$loadingDialog.show();
            await this.getUsers();
            this.roles = await this.getGeneral(2, 0);
            this.$loadingDialog.hide();
        }
    }
</script>

<style scoped>
    .selected {
        background: #c3c3c3;
    }
</style>