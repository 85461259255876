<template>
    <v-app :style="{backgroundImage: 'url(' + require('@/assets/bg_02.jpg') + ')', backgroundAttachment: 'fixed'}">
        <nav-bar @onDrawerClick="onDrawerClick" @logout="logout" class="no-print" />
        <nav-drawer ref="navDrawer" v-if="$vuetify.breakpoint.mdAndDown" @logout="logout" />

        <v-main app style="padding-top: 74px;" id="v_main">
            <router-view></router-view>
        </v-main>

        <loading-dialog />
        <confirm-dialog />
        <input-dialog />
        <snack-bar />
        <info-dialog />
    </v-app>
</template>

<script>
    import navDrawer from "../components/navDrawer";
    import navBar from "../components/navBar";
    import axios from "axios";

    export default {
        name: "home",
        components: {
            navBar,
            navDrawer
        },
        data(){
            return {
                drawer: false,
                // modules: [],
            };
        },
        methods: {
            onDrawerClick(draw){
                this.drawer = draw;
                this.$refs.navDrawer.openDrawer();
            },
            async getSabaq(){
                await axios.post('Asbaaq/GetLive', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status) {
                            let sabaq = data.Data[0];
                            // console.log(sabaq);

                            if(sabaq != null) {
                                let vm = this;
                                this.$snackBar.show({
                                    color: 'info', message: 'Live streaming has been started!',
                                    timeout: 5000,
                                    button: 'Click Here To View',
                                    btnClick: function(){
                                        vm.$router.push({name: 'asbaaqViewer'});
                                    },
                                });
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error('An unexpected error has occurred');
                    });
            },
            async logout(){
                await this.$store.dispatch('logout');
                await this.$router.push({name: 'login'});
            },
        },
        // async created(){
        //     if(this.$store.getters.isAuthenticated)
        //         await this.getSabaq();
        // },
        async beforeMount(){
            if(this.$store.getters.isAuthenticated)
                await this.getSabaq();
        },
        async mounted() {
            if(this.$route.name != 'recruitmentFormAmil') {
                await this.$store.dispatch('fetch_modules');
                await this.$store.dispatch('fetch_rights');
            }
        }
    }
</script>

<style scoped>
    @media print {
        #v_main {
            padding-top: 0px !important;
        }
    }
</style>